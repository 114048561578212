import { apiSlice } from "../../apiSlice";

export const buyShareSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    buyShare: builder.mutation({
      query: (credentials) => ({
        url: `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/buy_shares`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Shares"],
    }),
  }),
});

export const { useBuyShareMutation } = buyShareSlice;
