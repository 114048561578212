import React, { useState } from "react";

import { CgProfile } from "react-icons/cg";
import { Row, Col } from "react-bootstrap";
import styles from "../Group.module.css";

import {
  DownArrowIcon,
  //  GridIcon, ListIcon
} from "../../../assets/icons";

import { FilterAddNew } from "../../../components/Filters";

import JebaModal from "../../../components/ui/Modal/Modal";
import { contentHandler } from "../../../helperFunctions/helpersFunctions";
import AddCashRound from "../../../components/Forms/AddCashRound";
import { useGetAllCashRoundQuery } from "../../../store/api/requests/GET/getAllCashRoundsApiSlice";
import CashRoundCard from "../../../components/Dashboard/CashRoundCard/CashRoundCard";

// eslint-disable-next-line react/prop-types
function CashRound() {
  const { data, isFetching, refetch } = useGetAllCashRoundQuery();

  const items = data?.cash_round_data;
  const numberOfItems = items?.length;

  const [isOpen, setIsOpen] = useState(false);

  const openHandler = () => setIsOpen(true);

  return (
    <>
      <JebaModal
        title="Register A Cash Round"
        show={isOpen}
        onHide={() => setIsOpen(false)}
        size="lg"
      >
        <AddCashRound close={() => setIsOpen(false)} />
      </JebaModal>

      <div className={styles.group__sort}>
        <div className={styles.group__count}>
          <h2>{`${numberOfItems || 0} Cash Round(s)`} </h2>
        </div>
        <div className={styles.group__sort__button}>
          <button className={styles.sort__button} type="button">
            Sort by <DownArrowIcon />
          </button>
          {/* <GridIcon />
          <ListIcon /> */}
        </div>
      </div>
      <FilterAddNew
        searchItem="Cash Round"
        buttonText="Add Cash Round"
        open={openHandler}
        refresh={refetch}
        onRefresh={isFetching}
      />
      <Row xl={4} lg={2} md={2} sm={1} xs={1} className="g-4">
        {contentHandler(
          isFetching,
          items?.length,
          "Loading Cash Round...",
          "No Cash Round Yet",
          items?.map((item) => (
            <Col key={item.id}>
              <CashRoundCard
                title={item.name}
                description={`Status ${item.status}, ${item.date_created}`}
                icon={<CgProfile />}
                link={item.id}
              />
            </Col>
          )),
        )}
      </Row>
    </>
  );
}

export default CashRound;
