import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useChangeOrderStatusMutation } from "../../../../store/api/requests/POST/changeOrderStatusApiSlice";

function OrderStatus({ rowId, initialValue }) {
  const [changeOrderStatus, { isLoading }] = useChangeOrderStatusMutation();
  const { categoryName, Id } = useParams();
  const [status, setStatus] = useState(initialValue);
  const [buttonStatus, setButtonStatus] = useState("none");

  const request = async (event) => {
    event.preventDefault();
    try {
      const sentStatus = status;
      await changeOrderStatus({
        groupType: categoryName,
        id: Id,
        orderId: rowId,
        new_status: sentStatus,
      });
      toast.success("Order Updated ");
      setButtonStatus("none");
    } catch (error) {
      toast.error(error);
    }
  };
  const onChangeHandler = (event) => {
    setStatus(event.target.value);
    setButtonStatus("block");
  };
  const getStatusColor = (selectedStatus) => {
    switch (selectedStatus.toLowerCase()) {
      case "pending":
        return "#FFC724";
      case "successful":
        return "#30E584";
      case "failed":
        return "#D93B3B";
      default:
        return "#30E584";
    }
  };

  return (
    <form
      onSubmit={request}
      style={{
        maxHeight: "100%",
        margin: 0,
        display: "flex",
        alignItems: "center",
        gap: "4px",
        width: "90%",
      }}
    >
      <select
        onChange={onChangeHandler}
        style={{
          background: "none",
          border: "none",
          color: getStatusColor(status),
        }}
        value={status}
        disabled={isLoading}
      >
        <option
          style={{
            color: getStatusColor("Pending"),
          }}
          value="Pending"
        >
          Pending
        </option>
        <option value="Processing">Processing</option>
        <option value="Dispatched">Dispatched</option>
        <option value="Delivered">Delivered</option>
        <option
          style={{
            color: getStatusColor("Completed"),
          }}
          value="Completed"
        >
          Completed
        </option>
        <option value="On hold">On hold</option>

        <option
          style={{
            color: getStatusColor("Refunded"),
          }}
          value="Refunded"
        >
          Refunded
        </option>
        <option
          style={{
            color: getStatusColor("failed"),
          }}
          value="Failed"
        >
          Failed
        </option>
      </select>
      <button
        type="submit"
        disabled={isLoading}
        style={{
          color: "#fff",
          fontSize: "8px",
          background: "#0866be",
          border: "none",
          borderRadius: "10px",
          display: buttonStatus,
          width: "100%",
          minWidth: "70px",
          paddingLeft: "6px",
          paddingRight: "6px",
          paddingTop: "2px",
          paddingBottom: "2px",
        }}
      >
        Click To Update
      </button>
    </form>
  );
}
OrderStatus.propTypes = {
  rowId: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
};
export default OrderStatus;
