import React from "react";
import PropTypes from "prop-types";

import { HiOutlineUserGroup } from "react-icons/hi";
import styles from "./StatCard.module.css";

function StatCard({ item, value }) {
  return (
    <div className={styles.statcard__wrapper}>
      <div className={styles.statcard__icon__container}>
        <div className={styles.icon}>
          <HiOutlineUserGroup />
        </div>
      </div>
      <div className={styles.details}>
        <h5 className={styles.item}>{item}</h5>
        <h4>Total: {value}</h4>
      </div>
    </div>
  );
}

StatCard.propTypes = {
  item: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default StatCard;
