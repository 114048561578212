import { apiSlice } from "../../apiSlice";

export const getSavingsPerAccountTypeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSavingsPerAccountType: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/account_type_savings`,
      providesTags: ["Saving"],
    }),
  }),
});

export const { useGetSavingsPerAccountTypeQuery } =
  getSavingsPerAccountTypeSlice;
