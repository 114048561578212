/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const openSidebar = createSlice({
  name: "open sidebar",
  initialState: {
    openMenu: false,
  },
  reducers: {
    open: (state) => {
      state.openMenu = true;
    },
    close: (state) => {
      state.openMenu = false;
    },
  },
});
export const { open, close } = openSidebar.actions;
export const openSidebarMenu = (state) => state.open.openMenu;
export default openSidebar.reducer;
