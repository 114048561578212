// /build_credit/caabbddcae4874e513099aee94cc9c/schedules

import { apiSlice } from "../../apiSlice";

export const getExpectedAmountSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExpectedAmount: builder.query({
      query: (urlParameters) =>
        `/build_credit/${urlParameters.id}/payments/today`,
      providesTags: ["BuildCredit"],
    }),
  }),
});

export const { useGetExpectedAmountQuery } = getExpectedAmountSlice;
