import { apiSlice } from "../../apiSlice";

export const verifyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verify: builder.mutation({
      query: (credentials) => ({
        url: "/verify_user",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useVerifyMutation } = verifyApiSlice;
