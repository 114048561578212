/* eslint-disable react/prop-types */
import React from "react";
import styles from "./LoanListCard.module.css";

function LoanListCard({ data, onClick, buttonText }) {
  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#FFC724";
      case "Approved":
        return "#30E584";
      case "Declined":
        return "#D93B3B";
      default:
        return "#30E584";
    }
  };
  return (
    <div className={styles.loan_list_card}>
      <h4 className={styles.loan_list_card__title}>
        {data.loan_product_name || data.loan_application_id}
        <span
          className={styles.loan_list_card__status}
          style={{ color: getStatusColor(data.loan_status) }}
        >
          {data.loan_status}
        </span>
      </h4>
      <h5 className={styles.loan_list_card__type}>
        {data.applicant_name || "Applicant Name"}
        <span>{data.application_date}</span>
      </h5>
      <div className={styles.loan_list_card__details}>
        <p className={styles.loan_list_card__details__amount}>
          UGX {data.loan_amount_requested || data.loan_amount_approved}
        </p>
        <button
          type="button"
          disabled={data.disbursed}
          onClick={onClick}
          className={styles.loan_list_card__details__button}
        >
          {buttonText || "Details"}
        </button>
      </div>
    </div>
  );
}

export default LoanListCard;
