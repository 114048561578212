import { apiSlice } from "../../apiSlice";

export const getSavingProductSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSavingProduct: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/savings_products`,
      providesTags: ["SavingProduct"],
    }),
  }),
});

export const { useGetSavingProductQuery } = getSavingProductSlice;
