/* eslint-disable react/prop-types */
import React from "react";

import { NavLink } from "react-router-dom";
import styles from "./GroupSingleButton.module.css";

function GroupSingleButton({ text, link, icon }) {
  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        styles.group__button + (isActive ? ` ${styles.active}` : "")
      }
    >
      {icon && <span style={{ marginRight: "8px" }}>{icon}</span>}
      {text}
    </NavLink>
  );
}

export default GroupSingleButton;
