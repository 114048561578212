import { apiSlice } from "../../apiSlice";

export const getBorrowersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBorrowers: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/borrowers`,
    }),
  }),
});

export const { useGetBorrowersQuery } = getBorrowersSlice;
