import React, { useState } from "react";

import { CgProfile } from "react-icons/cg";

import { Row, Col } from "react-bootstrap";
import styles from "../Group.module.css";
import GroupCard from "../../../components/Group/GroupCard/GroupCard";
import { DownArrowIcon, GridIcon, ListIcon } from "../../../assets/icons";
import CreateClub from "../../../components/Forms/CreateClub";
import { useGetGroupsQuery } from "../../../store/api/requests/GET/groupsApiSlice";
import { FilterAddNew } from "../../../components/Filters";
import { contentHandler } from "../../../helperFunctions/helpersFunctions";

// eslint-disable-next-line react/prop-types
function Club() {
  const { data, isFetching } = useGetGroupsQuery();

  const [isOpen, setIsOpen] = useState(false);

  const attachedClubs = data?.clubs;
  const numberOfClubs = attachedClubs?.length;

  const openHandler = () => setIsOpen(true);

  return (
    <div>
      {" "}
      {isOpen ? (
        <CreateClub onClick={() => setIsOpen(false)} />
      ) : (
        <>
          <div className={styles.group__sort}>
            <div className={styles.group__count}>
              <h2>{`${numberOfClubs || 0} Club(s)`}</h2>
            </div>
            <div className={styles.group__sort__button}>
              <button className={styles.sort__button} type="button">
                Sort by <DownArrowIcon />
              </button>
              <GridIcon />
              <ListIcon />
            </div>
          </div>
          <FilterAddNew
            searchItem="Club"
            buttonText="Add Club"
            open={openHandler}
          />
          <Row>
            {contentHandler(
              isFetching,
              attachedClubs?.length,
              "Loading Clubs...",
              "No Clubs Yet",
              attachedClubs?.map((sacco) => (
                <Col xl={6} md={6} sm={12} key={sacco.id}>
                  <GroupCard
                    icon={<CgProfile />}
                    title={sacco.name}
                    subtitle={`${sacco.total_members} participants`}
                    description="Created by Sharon, 6.9.2023"
                    link={sacco.id}
                  />
                </Col>
              )),
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default Club;
