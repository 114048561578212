/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoCall } from "react-icons/io5";

import styles from "./LoginForm.module.css";

import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

import { setUserDetails } from "../../store/slice/regSlice";

import { useSendLoginOtpSmsMutation } from "../../store/api/requests/POST/sendLoginOtpSmsAPiSlice";

function GenerateSmsOtp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendLoginOtpSms, { isLoading }] = useSendLoginOtpSmsMutation();

  const [userEmail, setUserEmail] = useState("");
  const [codeError, setCodeError] = useState("");

  useEffect(() => {
    setCodeError("");
  }, [userEmail]);

  const codeChangeHandler = (e) => {
    setUserEmail(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (userEmail.length < 1) {
      setCodeError("Please provide your Phone Number");
    }

    try {
      const userData = {
        tel_number: userEmail,
      };
      const response = await sendLoginOtpSms(userData).unwrap();
      toast.success(`${response.message}`);
      dispatch(setUserDetails(userData));
      navigate("/login-with-sms-otp");
    } catch (err) {
      toast.error(err.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <h2>Generate OTP</h2>
      <p>Enter your phone number and we will send you a code to login</p>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.form__control}>
          <label htmlFor="email">Phone Number</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <IoCall />
            </div>
            <input
              type="number"
              id="phone"
              name="phone"
              placeholder="Enter Phone Number"
              onChange={codeChangeHandler}
              className={codeError ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {codeError && codeError}
          </span>
        </div>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading || !userEmail}>
            {isLoading ? <LoadingSpinner /> : "Send OTP"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default GenerateSmsOtp;
