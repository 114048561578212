import { apiSlice } from "../../apiSlice";

export const loginWithSmsOtpApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginWithSmsOtp: builder.mutation({
      query: (credentials) => ({
        url: "/users/sms_otp_login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useLoginWithSmsOtpMutation } = loginWithSmsOtpApiSlice;
