/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import LoanApproval from "../../Forms/LoanApproval";
import { selectRole } from "../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../constants/constants";

function LoanApplicationDetails({ loan, close }) {
  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;
  return (
    <div style={{ height: "400px", overflow: "auto" }}>
      <table>
        <tbody>
          <tr>
            <td>
              <p>Applicant</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.applicant_name}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Loan Requested Amount</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.loan_amount_requested}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Loan Purpose</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.loan_purpose}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Loan Repayment Source</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.loan_repayment_source}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Laon Rquired Date</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.loan_required_date}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Loan Repaymnet Period</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.repayment_period}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Application Date</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.application_date}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Application Attachment</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.attachment}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Application Review</p>
            </td>
            <td>&nbsp;</td>
            <td>
              <p>
                <strong>{loan.application_review}</strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      {isAdmin && <LoanApproval loanId={loan.id} close={close} />}
    </div>
  );
}

export default LoanApplicationDetails;
