import { apiSlice } from "../../apiSlice";

export const cashOutApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    cashOut: builder.mutation({
      query: (credentials) => ({
        url: `build_credit/${credentials?.Id}/cashout`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["BuildCredit"],
    }),
  }),
});

export const { useCashOutMutation } = cashOutApiSlice;
