/* eslint-disable react/prop-types */
import React from "react";
import { formatAmount } from "../../helperFunctions/helpersFunctions";

function CashRoundContributionCard({ amount, date }) {
  return (
    <div
      style={{ backgroundColor: "#fff", borderRadius: "15px", padding: "12px" }}
    >
      <div className="mb-3">
        <span style={{ fontSize: "12px", color: "#adb5bd" }}>
          Amount Contributed
        </span>
        <h4 style={{ fontSize: "20px" }}>{formatAmount(amount)}</h4>
      </div>
      <div>
        <span style={{ fontSize: "12px", color: "#adb5bd" }}>Payment Date</span>
        <p>{date}</p>
      </div>
    </div>
  );
}

export default CashRoundContributionCard;
