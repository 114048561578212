import React from "react";

function ListIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M6.00006 8.08017C7.42496 8.08017 8.58006 6.92507 8.58006 5.50017C8.58006 4.07527 7.42496 2.92017 6.00006 2.92017C4.57515 2.92017 3.42004 4.07527 3.42004 5.50017C3.42004 6.92507 4.57515 8.08017 6.00006 8.08017Z"
        fill="#38414C"
      />
      <path
        d="M12.455 6.17017H27.955C28.1207 6.17017 28.2797 6.10432 28.3969 5.98712C28.5141 5.86992 28.58 5.71092 28.58 5.54517C28.58 5.37942 28.5141 5.22042 28.3969 5.10322C28.2797 4.98601 28.1207 4.92017 27.955 4.92017H12.455C12.2892 4.92017 12.1303 4.98601 12.013 5.10322C11.8958 5.22042 11.83 5.37942 11.83 5.54517C11.83 5.71092 11.8958 5.86992 12.013 5.98712C12.1303 6.10432 12.2892 6.17017 12.455 6.17017Z"
        fill="#38414C"
      />
      <path
        d="M6.00006 15.0802C7.42496 15.0802 8.58006 13.9251 8.58006 12.5002C8.58006 11.0753 7.42496 9.92017 6.00006 9.92017C4.57515 9.92017 3.42004 11.0753 3.42004 12.5002C3.42004 13.9251 4.57515 15.0802 6.00006 15.0802Z"
        fill="#38414C"
      />
      <path
        d="M27.955 11.9202H12.455C12.2892 11.9202 12.1303 11.986 12.013 12.1032C11.8958 12.2204 11.83 12.3794 11.83 12.5452C11.83 12.7109 11.8958 12.8699 12.013 12.9871C12.1303 13.1043 12.2892 13.1702 12.455 13.1702H27.955C28.1207 13.1702 28.2797 13.1043 28.3969 12.9871C28.5141 12.8699 28.58 12.7109 28.58 12.5452C28.58 12.3794 28.5141 12.2204 28.3969 12.1032C28.2797 11.986 28.1207 11.9202 27.955 11.9202Z"
        fill="#38414C"
      />
      <path
        d="M6.00006 22.0802C7.42496 22.0802 8.58006 20.9251 8.58006 19.5002C8.58006 18.0753 7.42496 16.9202 6.00006 16.9202C4.57515 16.9202 3.42004 18.0753 3.42004 19.5002C3.42004 20.9251 4.57515 22.0802 6.00006 22.0802Z"
        fill="#38414C"
      />
      <path
        d="M27.955 18.9202H12.455C12.2892 18.9202 12.1303 18.986 12.013 19.1032C11.8958 19.2204 11.83 19.3794 11.83 19.5452C11.83 19.7109 11.8958 19.8699 12.013 19.9871C12.1303 20.1043 12.2892 20.1702 12.455 20.1702H27.955C28.1207 20.1702 28.2797 20.1043 28.3969 19.9871C28.5141 19.8699 28.58 19.7109 28.58 19.5452C28.58 19.3794 28.5141 19.2204 28.3969 19.1032C28.2797 18.986 28.1207 18.9202 27.955 18.9202Z"
        fill="#38414C"
      />
      <path
        d="M6.00006 29.0802C7.42496 29.0802 8.58006 27.9251 8.58006 26.5002C8.58006 25.0753 7.42496 23.9202 6.00006 23.9202C4.57515 23.9202 3.42004 25.0753 3.42004 26.5002C3.42004 27.9251 4.57515 29.0802 6.00006 29.0802Z"
        fill="#38414C"
      />
      <path
        d="M27.955 25.9202H12.455C12.2892 25.9202 12.1303 25.986 12.013 26.1032C11.8958 26.2204 11.83 26.3794 11.83 26.5452C11.83 26.7109 11.8958 26.8699 12.013 26.9871C12.1303 27.1043 12.2892 27.1702 12.455 27.1702H27.955C28.1207 27.1702 28.2797 27.1043 28.3969 26.9871C28.5141 26.8699 28.58 26.7109 28.58 26.5452C28.58 26.3794 28.5141 26.2204 28.3969 26.1032C28.2797 25.986 28.1207 25.9202 27.955 25.9202Z"
        fill="#38414C"
      />
    </svg>
  );
}

export default ListIcon;
