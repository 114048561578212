import React from "react";
import { Link, useParams } from "react-router-dom";

import { MdOutlineDelete } from "react-icons/md";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import styles from "./ProductCartCard.module.css";
import Image from "../../../assets/images/51-TFGHQE2L._AC_SL1000_.jpg";
import { useRemoveFromCartMutation } from "../../../store/api/requests/DELETE/removeCartProductApiSlice";

import ProductCounter from "../../Common/ProductCounter/ProductCounter";

function ProductCartCard({ id, name, cost, description, qty, productId }) {
  const { categoryName, Id } = useParams();
  const [removeFromCart, { isLoading }] = useRemoveFromCartMutation();

  const RemoveFromCartHandler = async () => {
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        cart_item_id: id,
      };
      await removeFromCart(data).unwrap();
      toast.success("Product Removed");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.product_cart}>
      <div className={styles.product_info_section}>
        <div className={styles.product_info}>
          {" "}
          <div className={styles.product_img}>
            <img src={Image} alt="Product name" />
          </div>
          <div className={styles.product_name}>
            <h4>
              <Link style={{ textDecoration: "none" }} to={`../${productId}`}>
                {name}
              </Link>
            </h4>
            <p className="w-75">{description}</p>
          </div>
        </div>
        <div>
          <h3>UGX{cost}</h3>
        </div>
      </div>
      <div className={styles.product_actions}>
        <div className={styles.remove_button}>
          <button
            type="button"
            onClick={RemoveFromCartHandler}
            disabled={isLoading}
          >
            <MdOutlineDelete />
            Remove
          </button>
        </div>
        <div>
          <ProductCounter initialValue={qty} productId={productId} />
        </div>
      </div>
    </div>
  );
}

ProductCartCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default ProductCartCard;
