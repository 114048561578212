import { apiSlice } from "../../apiSlice";

export const getCashRoundWithdrawApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCashRoundWithdraw: builder.query({
      query: (urlParameters) =>
        `/${
          urlParameters.group === "club"
            ? "investment_club"
            : urlParameters.group
        }s/${urlParameters.groupId}/withdraws`,
      providesTags: ["Draws"],
    }),
  }),
});

export const { useGetCashRoundWithdrawQuery } = getCashRoundWithdrawApiSlice;
