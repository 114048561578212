import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import MonthlySavings from "./MonthlySavings/MonthlySavings";
import StatCard from "../../../../../components/Dashboard/StatCard/StatCard";
import SavingAccounts from "./SavingAccounts/SavingAccounts";
import { useGetCurrentMonthSavingQuery } from "../../../../../store/api/requests/GET/getCurrentMonthSavingApiSlice";
import { useGetUserTotalSavingsQuery } from "../../../../../store/api/requests/GET/getTotalUserSavingsApiSlice";

function SavingsSection() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };
  const { data } = useGetCurrentMonthSavingQuery(group);
  const { data: totalSavings } = useGetUserTotalSavingsQuery(group);

  return (
    <Row style={{ marginTop: "25px" }}>
      <Col lg={5}>
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            backgroundColor: "#fff",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <MonthlySavings />
        </div>
      </Col>
      <Col lg={7}>
        <Row>
          <Col lg={7}>
            <SavingAccounts />
          </Col>
          <Col lg={5}>
            <div
              style={{
                height: "100%",
                borderRadius: "10px",
                backgroundColor: "#fff",
                paddingLeft: "18px",
                paddingRight: "18px",
                paddingTop: "15px",
                paddingBottom: "18px",
              }}
            >
              <h5
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Saving Trends
              </h5>
              <StatCard
                item="Current Month"
                value={data?.current_month_savings}
              />
              {/* current_month_savings: 20022010000, current_year_savings:
              20022010000, message: 'Sacco saving reports retrieved successful',
              no_savings_products: 2, number_of_savings_accounts */}
              <StatCard
                item="Member Accounts"
                value={totalSavings?.number_of_savings_accounts}
              />
              <StatCard
                item="Savings To Date"
                value={totalSavings?.total_savings}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SavingsSection;
