import React from "react";

import { Col, Row } from "react-bootstrap";

import TotalLoans from "./TotalLoans/TotalLoans";
import TotalSavings from "./TotalSavings/TotalSavings";
import TotalContributions from "./TotalContributions/TotalContributions";
import TotalBalance from "./TotalBalannce/TotalBalance";

function TotalValueSection() {
  return (
    <div>
      {" "}
      <Row>
        <Col lg={3} md={6} sm={6} xs={12}>
          <TotalBalance />
        </Col>
        <Col lg={3} md={6} sm={6} xs={12}>
          <TotalLoans />
        </Col>
        <Col lg={3} md={6} sm={6} xs={12}>
          <TotalSavings />
        </Col>
        <Col lg={3} md={6} sm={6} xs={12}>
          <TotalContributions />
        </Col>
      </Row>
    </div>
  );
}

export default TotalValueSection;
