import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import styles from "./Cart.module.css";
import ProductCartCard from "../../../../../components/Dashboard/ProductCartCard/ProductCartCard";
import { useGetCartProductsQuery } from "../../../../../store/api/requests/GET/getCartProductsApiSlice";
import { formatAmount } from "../../../../../helperFunctions/helpersFunctions";
import JebaModal from "../../../../../components/ui/Modal/Modal";
import CheckoutForm from "../../../../../components/Forms/Checkout";
import { updateCart } from "../../../../../store/slice/cartSlice";

function Cart() {
  const dispatch = useDispatch();
  const { categoryName, Id } = useParams();
  const { data, isFetching } = useGetCartProductsQuery({
    groupType: categoryName,
    id: Id,
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    dispatch(updateCart(data?.marketplace_products));
  }, [isFetching]);

  const totalCost = data?.marketplace_products?.reduce((total, curVal) => {
    return total + curVal.cost;
  }, 0);
  const sortedCartProducts = data?.marketplace_products
    ?.slice()
    .sort((a, b) => a.quantity - b.quantity);

  return (
    <>
      <h2>Cart</h2>
      <Row>
        <Col lg={8}>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
            className={styles.cart}
          >
            <h3>Cart ({data?.marketplace_products?.length || 0})</h3>
            <Row className="g-4" style={{ opacity: isFetching ? 0.5 : 1 }}>
              {sortedCartProducts?.map((product) => (
                <Col key={product.id} lg={12}>
                  <ProductCartCard
                    id={product.id}
                    name={product.name}
                    qty={product.quantity}
                    cost={product.cost}
                    description={product.description}
                    productId={product.product_id}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Col>
        <Col lg={4}>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
            className={styles.cart}
          >
            <h3>Cart Summary</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "12px",
              }}
            >
              <h5>Subtotal</h5>
              <h3>{formatAmount(totalCost)}</h3>
            </div>
            <button
              style={{
                background: "#0866be",
                border: "none",
                color: "#fff",
                fontSize: "16px",
                fontWeight: 600,
                padding: "8px",
                width: "100%",
                marginTop: "12px",
                textTransform: "uppercase",
              }}
              type="button"
              onClick={() => setOpen(true)}
              disabled={data?.marketplace_products?.length === 0}
            >
              Checkout({formatAmount(totalCost)}){" "}
            </button>
            <JebaModal
              show={open}
              onHide={() => setOpen(false)}
              title="Checkout"
            >
              <CheckoutForm close={() => setOpen(false)} />
            </JebaModal>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Cart;
