import { apiSlice } from "../../apiSlice";

export const approveShareTransferSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    approveShareTransfer: builder.mutation({
      query: (credentials) => ({
        url: `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/share_transfer_approvals`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["ShareTransfers"],
    }),
  }),
});

export const { useApproveShareTransferMutation } = approveShareTransferSlice;
