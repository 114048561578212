/* eslint-disable react/prop-types */
import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BsInfoCircle } from "react-icons/bs";
// import { formatAmount } from "../../helperFunctions/helpersFunctions";
import { useAcceptNumberChangeMutation } from "../../store/api/requests/POST/acceptNumberChangeApiSlice";

function NumberChangeCard({
  sender,
  receiver,
  status,
  approval,
  itemId,
  onClick,
  unitCost,
}) {
  const { categoryName, Id } = useParams();
  const [acceptNumberChange, { isLoading }] = useAcceptNumberChangeMutation();

  const declineHandler = async () => {
    try {
      await acceptNumberChange({
        groupType: categoryName,
        id: Id,
        itemId,
        approval_status: "Reject",
      }).unwrap();
      toast.success("Number change declined successfully");
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "12px",
        border: "1px solid #dedede",
        marginBottom: "10px",
      }}
    >
      <div className="mb-3">
        {status ? (
          <p>
            You have been requested by <strong> {sender}</strong> for round
            number exchange{" "}
          </p>
        ) : (
          <p>
            You have requested <strong>{receiver}</strong> for number exchange{" "}
          </p>
        )}
      </div>
      <div
        className="mb-3 d-flex align-items-center gap-2"
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <BsInfoCircle />
        <span>
          <em>Number change will cost {unitCost}</em>
        </span>
      </div>

      {approval === "Accepted" && <p style={{ color: "green" }}>{approval}</p>}
      {status && approval !== "Accepted" && (
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={onClick}
            disabled={isLoading}
          >
            Accept
          </button>
          <button
            type="button"
            className="btn btn-lg btn-danger"
            onClick={declineHandler}
          >
            {isLoading ? "Declining..." : "Decline"}
          </button>
        </div>
      )}
    </div>
  );
}

export default NumberChangeCard;
