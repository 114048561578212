import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import { useGetEventToRolloverQuery } from "../../../store/api/requests/GET/getEventToRolloverApiSlice";
import styles from "../BuildCreditProduct/BuildCreditProduct.module.css";
import { formatAmount } from "../../../helperFunctions/helpersFunctions";

import { useRollOverMutation } from "../../../store/api/requests/POST/rollOverApiSlice";

function Rollover() {
  const { Id } = useParams();
  const { data } = useGetEventToRolloverQuery();

  const [rollOver, { isLoading }] = useRollOverMutation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    amount: 90000,
    frequency: "Daily",
  });
  const [estimation, setEstimation] = useState({
    estimate: "",
    period: "",
  });
  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const estimateCalculator = (installments, amount, days = 30) => {
    const freq = installments.toLowerCase();
    if (freq === "weekly") {
      const weeks = days / 7;
      const estimateValue = formatAmount(amount / weeks);
      setEstimation({ estimate: estimateValue, period: "Week" });
      return;
    }
    if (freq === "monthly") {
      const months = days / 30;
      const estimateValue = formatAmount(amount / months);
      setEstimation({
        ...estimation,
        estimate: estimateValue,
        period: "Month",
      });
      return;
    }
    if (freq === "daily") {
      setEstimation({ estimate: formatAmount(amount / days), period: "Day" });
    }
  };
  useEffect(() => {
    estimateCalculator(
      formData.frequency,
      +formData.amount,
      data?.product?.subscription_period,
    );
  }, [formData.frequency, formData.amount]);

  const submitHandler = async () => {
    try {
      await rollOver({
        product_id: Id,
        credit_building_amount: +formData.amount,
        installment_payment_frequency: formData.frequency,
      }).unwrap();
      navigate("../build-credit/overview");
      toast.success("Rolled over Successfully");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <Row>
      <Col lg={8}>
        {" "}
        <div
          style={{
            borderRadius: "10px",
            backgroundColor: "#fff",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "15px",
            paddingBottom: "15px",
            marginTop: "12px",
          }}
        >
          <div>
            <h2>{data?.product?.product_name}</h2>
            <p>{data?.product?.product_description}</p>
            <p>{data?.name}</p>
          </div>
          <h3>Roll-Over Details</h3>
          <form className={styles.subscription_form}>
            <div className={styles.subscription_form__control}>
              <label htmlFor="amount">Credit Building Amount</label>
              <input
                type="number"
                name="amount"
                id="amount"
                onChange={inputChangeHandler}
                value={formData.amount}
              />
            </div>
            <div className={styles.subscription_form__control}>
              <label htmlFor="frequency">Installment Payment Frequency</label>
              <input
                type="text"
                name="frequency"
                id="frequency"
                onChange={inputChangeHandler}
                value={formData.frequency}
              />
            </div>
          </form>
        </div>
      </Col>
      <Col lg={4}>
        {" "}
        <div
          style={{
            borderRadius: "10px",
            backgroundColor: "#fff",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "15px",
            paddingBottom: "15px",
            marginTop: "12px",
          }}
        >
          <h3>Your Estimate</h3>

          <span style={{ fontSize: "20px", fontWeight: 600 }}>
            {estimation.estimate}
          </span>
          <span>/Per {estimation.period}</span>
          <div>
            <div className="my-4">
              <h4>Additional information</h4>

              <p className="my-2">
                <em>UGX 5,000</em> One Time Subscription Fee
              </p>
              <p className="my-2">Cancel Subscription At Any Time</p>
              <p className="my-2">Change Plan At Your Convenience</p>
            </div>{" "}
            <button
              className="btn btn-primary w-100 btn-lg mt-4"
              type="button"
              onClick={submitHandler}
              disabled={isLoading}
            >
              {" "}
              {isLoading ? "ROLLING OVER..." : "ROLLOVER"}
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Rollover;
