import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { toast } from "react-toastify";
import styles from "./ExtendSession.module.css";
import { useExtendSessionMutation } from "../../../store/api/requests/POST/extendSessionApiSlice";
import {
  selectCurrentToken,
  selectRefreshToken,
  setCredentials,
} from "../../../store/slice/authSlice";

function ExtendSession({ close, extend }) {
  const [extendSession, { isLoading }] = useExtendSessionMutation();
  const token = useSelector(selectCurrentToken);
  const refresh = useSelector(selectRefreshToken);
  const [accessTokenCopy] = useState(token);
  const dispatch = useDispatch();

  const extendHandler = async () => {
    dispatch(setCredentials({ accessToken: refresh }));
    const data = { access_token: accessTokenCopy };
    try {
      const res = await extendSession(data).unwrap();

      dispatch(
        setCredentials({
          accessToken: res.user_access_token,
          refreshToken: res.user_refresh_token,
        }),
      );
      toast.success("Session Extended Enjoy!");
      extend();
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <form className={styles.extend_form}>
      <p>Your session expired, please extend session or Logout </p>
      <div className={styles.extend_buttons}>
        <button
          type="button"
          className={styles.extend_buttons_logout}
          onClick={close}
          disabled={isLoading}
        >
          Logout
        </button>
        <button
          type="button"
          className={styles.extend_buttons_extend}
          onClick={extendHandler}
          disabled={isLoading}
        >
          Extend Session
        </button>
      </div>
    </form>
  );
}

ExtendSession.propTypes = {
  close: PropTypes.func.isRequired,
  extend: PropTypes.func.isRequired,
};

export default ExtendSession;
