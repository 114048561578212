/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "handleModal",
  initialState: {
    repayModal: false,
    viewModal: false,
    scheduleModal: false,
    loanId: "",
  },
  reducers: {
    showModal: (state, { payload }) => {
      const { id, actionType } = payload;
      if (actionType === "repay") {
        state.repayModal = true;
      }
      if (actionType === "view") {
        state.viewModal = true;
      }
      if (actionType === "schedule") {
        state.scheduleModal = true;
      }
      state.loanId = id;
    },
    closeModal: (state) => {
      state.repayModal = false;
      state.viewModal = false;
      state.scheduleModal = false;
      state.loanId = "";
    },
  },
});

export const { showModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;

export const repayModalStatus = (state) => state.modal.repayModal;
export const viewModalStatus = (state) => state.modal.viewModal;
export const scheduleModalStatus = (state) => state.modal.scheduleModal;
export const loanId = (state) => state.modal.loanId;
