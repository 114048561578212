import { apiSlice } from "../../apiSlice";

export const getMonthlySavingsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMonthlySavings: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/monthly_savings`,
      providesTags: ["Saving"],
    }),
  }),
});

export const { useGetMonthlySavingsQuery } = getMonthlySavingsSlice;
