// total_contributions;
import { apiSlice } from "../../apiSlice";

export const getUserTotalContributionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserTotalContributions: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/total_contributions`,
      providesTags: ["Contributions"],
    }),
  }),
});

export const { useGetUserTotalContributionsQuery } =
  getUserTotalContributionsSlice;
