import React from "react";

function Profile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16 16C17.3845 16 18.7378 15.5895 19.889 14.8203C21.0401 14.0511 21.9373 12.9579 22.4672 11.6788C22.997 10.3997 23.1356 8.99224 22.8655 7.63437C22.5954 6.2765 21.9287 5.02922 20.9497 4.05026C19.9708 3.07129 18.7235 2.4046 17.3656 2.13451C16.0078 1.86441 14.6003 2.00303 13.3212 2.53285C12.0421 3.06266 10.9489 3.95987 10.1797 5.11101C9.41054 6.26216 9 7.61553 9 9C9 10.8565 9.7375 12.637 11.0503 13.9497C12.363 15.2625 14.1435 16 16 16ZM16 4C16.9889 4 17.9556 4.29325 18.7779 4.84265C19.6001 5.39206 20.241 6.17296 20.6194 7.08659C20.9978 8.00022 21.0969 9.00555 20.9039 9.97545C20.711 10.9454 20.2348 11.8363 19.5355 12.5355C18.8363 13.2348 17.9454 13.711 16.9755 13.9039C16.0055 14.0969 15.0002 13.9978 14.0866 13.6194C13.173 13.241 12.3921 12.6001 11.8427 11.7779C11.2932 10.9556 11 9.98891 11 9C11 7.67392 11.5268 6.40215 12.4645 5.46447C13.4021 4.52679 14.6739 4 16 4Z"
        fill="white"
      />
      <path
        d="M17 18H15C12.0826 18 9.28473 19.1589 7.22183 21.2218C5.15893 23.2847 4 26.0826 4 29C4 29.2652 4.10536 29.5196 4.29289 29.7071C4.48043 29.8946 4.73478 30 5 30H27C27.2652 30 27.5196 29.8946 27.7071 29.7071C27.8946 29.5196 28 29.2652 28 29C28 26.0826 26.8411 23.2847 24.7782 21.2218C22.7153 19.1589 19.9174 18 17 18ZM6.06 28C6.3059 25.8006 7.35351 23.769 9.00268 22.2932C10.6518 20.8175 12.7869 20.0011 15 20H17C19.2131 20.0011 21.3482 20.8175 22.9973 22.2932C24.6465 23.769 25.6941 25.8006 25.94 28H6.06Z"
        fill="white"
      />
    </svg>
  );
}

export default Profile;
