import { apiSlice } from "../../apiSlice";

export const getNewsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/news`,
      providesTags: ["News"],
    }),
  }),
});

export const { useGetNewsQuery } = getNewsSlice;
