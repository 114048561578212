import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import styles from "./SavingsSection.module.css";
import { useGetSavingAccountsQuery } from "../../../../../store/api/requests/GET/getSavingAccountsApiSlice";
import SavingAccountCard from "../../../../../components/Group/SavingAccountCard/SavingAccountCard";

function SavingsSection({ refresh, setLoading }) {
  const { categoryName, Id } = useParams();

  const {
    data: savings,
    isFetching,
    refetch,
  } = useGetSavingAccountsQuery({
    groupType: categoryName,
    id: Id,
  });

  useEffect(() => {
    setLoading(isFetching);
    refetch();
    return () => {
      setLoading(false);
    };
  }, [refresh]);

  useEffect(() => {
    if (isFetching) {
      setLoading(isFetching);
    } else {
      setLoading(false);
    }
  }, [isFetching]);

  const renderedContent = () => {
    if (isFetching) {
      return <p>Loading Saving Accounts...</p>;
    }
    if (savings?.savings_accounts.length < 1) {
      return <p>No Savings Yet, Please Add Saving</p>;
    }
    return (
      <div className={styles.saving_account_section}>
        {savings?.savings_accounts.map((account) => (
          <SavingAccountCard
            key={account.id}
            accountBalance={account.account_balance}
            accountNumber={account.account_number}
            accountType={account.product_name}
            interest={account.interest_rate}
            holderName={account.account_holder_name}
          />
        ))}
      </div>
    );
  };

  return renderedContent();
}

export default SavingsSection;
