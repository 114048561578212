import React from "react";
import { CiCreditCard2 } from "react-icons/ci";
import PropTypes from "prop-types";

import styles from "./ContributeCard.module.css";
import { formatAmount } from "../../../helperFunctions/helpersFunctions";

function ContributeCard({ data }) {
  return (
    <div className={styles.contribute_card}>
      <div className={styles.contribute_card__heading}>
        <div className={styles.contribute_card__icon}>
          <CiCreditCard2 />
        </div>
        <div className={styles.contribute_card__name}>
          <h2>{data.product_name}</h2>
          <p>{data.account_holder_name}</p>
        </div>
      </div>
      <div className={styles.contribute_card__details}>
        <div>
          <h3>{formatAmount(data.account_balance)}</h3>
          <p>Balance</p>
        </div>
        <div>
          <h3>{formatAmount(data.account_number)}</h3>
          <p>Account Number</p>
        </div>
        <div>
          <h3>{data.interest_rate}</h3>
          <p>Interest Rate</p>
        </div>
        {/* <div className={styles.contribute_card__button}>
          <button type="button">More Info</button>
        </div> */}
      </div>
    </div>
  );
}
ContributeCard.propTypes = {
  data: PropTypes.shape(PropTypes.string).isRequired,
};

export default ContributeCard;
