import React from "react";
import PropTypes from "prop-types";

import { BsThreeDotsVertical } from "react-icons/bs";

import ProfileImage from "../../../assets/images/user (1).png";
import styles from "./MemberReportsCard.module.css";
import { formatNumber } from "../../../helperFunctions/helpersFunctions";

function AnalyticalItem({ itemName, itemValue }) {
  return (
    <div className={styles.MemberReportsCard_details__item}>
      <h4 className={styles.MemberReportsCard_details__item__value}>
        UGX {formatNumber(itemValue)}
      </h4>
      <p className={styles.MemberReportsCard_details__item__name}>{itemName}</p>
    </div>
  );
}
AnalyticalItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemValue: PropTypes.number.isRequired,
};

function MemberReportsCard({ contribution, onOpen, setMemberId }) {
  return (
    <div className={styles.MemberReportsCard}>
      <div className={styles.MemberReportsCard_header}>
        <div className={styles.MemberReportsCard__image}>
          <img src={ProfileImage} alt="user name" />
        </div>
        <div>
          <h3 className={styles.MemberReportsCard_details__name}>
            {contribution?.member_name}
            <p>Total Contribution</p>
          </h3>
        </div>
        <div className={styles.MemberReportsCard_view}>
          <BsThreeDotsVertical />
          <button
            type="button"
            onClick={() => {
              setMemberId(contribution.member_id);
              onOpen();
            }}
          >
            View Details
          </button>
        </div>
      </div>

      <div className={styles.MemberReportsCard_details}>
        <div className={styles.MemberReportsCard_details__items}>
          <AnalyticalItem
            itemName="loans"
            itemValue={contribution?.member_loan}
          />
          <AnalyticalItem
            itemName="savings"
            itemValue={contribution?.member_saving}
          />
          <AnalyticalItem
            itemName="Shares"
            itemValue={contribution?.member_share}
          />
          <AnalyticalItem
            itemName="balance"
            itemValue={contribution?.member_outstanding_balance}
          />
        </div>
      </div>
    </div>
  );
}
MemberReportsCard.propTypes = {
  contribution: PropTypes.objectOf(PropTypes.string).isRequired,
  onOpen: PropTypes.func.isRequired,
  setMemberId: PropTypes.func.isRequired,
};

export default MemberReportsCard;
