/* eslint-disable no-nested-ternary */
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useGetEventToRolloverQuery } from "../../../../store/api/requests/GET/getEventToRolloverApiSlice";
import BuildCreditCard from "../../../../components/Dashboard/BuildCreditCard/BuildCreditCard";

function RolloverProducts() {
  const { data, isFetching } = useGetEventToRolloverQuery();

  return (
    <Row className="g-5 align-items-end overflow-auto mt-2">
      {data?.credit_to_roll_over?.length === 0 ? (
        <p>No Events To Rollover</p>
      ) : isFetching ? (
        <p>Loading Events...</p>
      ) : (
        data?.credit_to_roll_over?.map((product) => (
          <Col lg={3} md={6} key={product.id}>
            <BuildCreditCard
              name={product.credit_status}
              days={product.credit_period}
              description={product.product_type}
              fee={product.subscription_fee}
              Id={product.id}
              status={product.user_subscribed}
              rollover
            />
          </Col>
        ))
      )}
    </Row>
  );
}

export default RolloverProducts;
