import { apiSlice } from "../../apiSlice";

export const addToCartSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addToCart: builder.mutation({
      query: (credentials) => ({
        url: `/${credentials.groupType}s/${credentials?.id}/marketplace/cart`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Cart", "Product"],
    }),
  }),
});

export const { useAddToCartMutation } = addToCartSlice;
