/* eslint-disable react/prop-types */
import React, { useState } from "react";

import styles from "./NewsCard.module.css";

// import image from "../../../assets/images/rectangle-109.png";

function NewsCard({ title, content, author, date }) {
  const [readMore, setReadMore] = useState(false);
  return (
    <div
      style={{
        borderRadius: "10px",
        backgroundColor: "#fff",
        paddingLeft: "18px",
        paddingRight: "18px",
        paddingTop: "15px",
        paddingBottom: "15px",
        display: "flex",
        gap: "12px",
        marginBottom: "18px",
      }}
    >
      {/* <img alt="for an article" src={image} /> */}
      <div className={styles.news_main}>
        <div className={styles.news__title}>
          <h3>{title}</h3>
        </div>
        <div className={styles.news__content}>
          {readMore ? (
            <p>
              {content} <br />
              <button type="button" onClick={() => setReadMore(false)}>
                See Less
              </button>
            </p>
          ) : (
            <p>
              {`${content?.substring(0, 70)}...`}
              <button type="button" onClick={() => setReadMore(true)}>
                Read More
              </button>
            </p>
          )}
        </div>
        <div className={styles.news__details}>
          <span>{author}</span>
          <span>{date}</span>
          <span className={styles.download}>Download Attachment</span>
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
