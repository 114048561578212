/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import styles from "./LoginForm.module.css";

import { useAddSavingMutation } from "../../store/api/requests/POST/addSaving";
import { useGetSavingProductQuery } from "../../store/api/requests/GET/getSavingProductApiSlice";

function SavingForm({ close }) {
  const { categoryName, Id } = useParams();

  const [addSaving, { isLoading }] = useAddSavingMutation();
  const { data } = useGetSavingProductQuery({
    groupType: categoryName,
    id: Id,
  });

  const [inputValues, setInputValues] = useState({
    amount: "",
    type: "",
    pin: "",
  });
  const [errors, setErrors] = useState({});

  const [step, setStep] = useState(1);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const savingData = {
        groupType: categoryName,
        id: Id,
        savings_amount: +inputValues.amount,
        savings_type: inputValues.type,
        wallet_pin: inputValues.pin,
      };

      await addSaving(savingData).unwrap();
      close();

      toast.success("Saving added successfully");
    } catch (error) {
      setErrors({ ...errors, pin: error.data.message });
      toast.error(error);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form}>
        <Row>
          {step === 1 && (
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="amount">Amount</label>
                <div className={styles.form__input}>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    placeholder="Enter Amount"
                    onChange={inputChangeHandler}
                    //   value={inputValues.saccoName}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>

              <div className={styles.form__control}>
                <label htmlFor="type">Saving Account</label>
                <div className={styles.form__input}>
                  <select name="type" id="type" onChange={inputChangeHandler}>
                    <option>Select Account</option>
                    {data?.data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.savings_product_name}
                      </option>
                    ))}
                  </select>
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__login}>
                <button
                  type="button"
                  onClick={() => setStep(2)}
                  disabled={
                    isLoading || !inputValues.amount || !inputValues.type
                  }
                >
                  {isLoading ? <LoadingSpinner /> : "Next"}
                </button>
              </div>
            </Col>
          )}
          {step === 2 && (
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="amount">Wallet Pin</label>
                <div className={styles.form__input}>
                  <input
                    type="password"
                    id="pin"
                    name="pin"
                    placeholder="Enter Pin"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoName}
                    className={errors.pin ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors && errors.pin}
                </span>
              </div>

              <div className={styles.form__login}>
                <button
                  type="button"
                  onClick={onSubmitHandler}
                  disabled={isLoading || !inputValues.pin}
                >
                  {isLoading ? <LoadingSpinner /> : "Save Money"}
                </button>
              </div>
            </Col>
          )}
        </Row>
      </form>
    </div>
  );
}

export default SavingForm;
