import React, { useState } from "react";

import { CgProfile } from "react-icons/cg";

import { Row, Col } from "react-bootstrap";
import styles from "../Group.module.css";
import CreateSacco from "../../../components/Forms/CreateSacco";
import GroupCard from "../../../components/Group/GroupCard/GroupCard";
import { DownArrowIcon, GridIcon, ListIcon } from "../../../assets/icons";
import { useGetGroupsQuery } from "../../../store/api/requests/GET/groupsApiSlice";
import { FilterAddNew } from "../../../components/Filters";

import JebaModal from "../../../components/ui/Modal/Modal";
import { contentHandler } from "../../../helperFunctions/helpersFunctions";

// eslint-disable-next-line react/prop-types
function Sacco() {
  const { data, isFetching, refetch } = useGetGroupsQuery();

  const attachedSaccos = data?.saccos;
  const numberOfSaccos = attachedSaccos?.length;

  const [isOpen, setIsOpen] = useState(false);

  const openHandler = () => setIsOpen(true);

  return (
    <>
      <JebaModal
        title="Register A Sacco"
        show={isOpen}
        onHide={() => setIsOpen(false)}
        size="lg"
      >
        <CreateSacco close={() => setIsOpen(false)} />
      </JebaModal>

      <div className={styles.group__sort}>
        <div className={styles.group__count}>
          <h2>{`${numberOfSaccos || 0} Sacco(s)`} </h2>
        </div>
        <div className={styles.group__sort__button}>
          <button className={styles.sort__button} type="button">
            Sort by <DownArrowIcon />
          </button>
          <GridIcon />
          <ListIcon />
        </div>
      </div>
      <FilterAddNew
        searchItem="Sacco"
        buttonText="Add Sacco"
        open={openHandler}
        refresh={refetch}
        onRefresh={isFetching}
      />
      <Row>
        {contentHandler(
          isFetching,
          attachedSaccos?.length,
          "Loading Saccos...",
          "No Saccos Yet",
          attachedSaccos?.map((sacco) => (
            <Col xl={6} md={6} sm={12} key={sacco.id}>
              <GroupCard
                icon={<CgProfile />}
                title={sacco.name}
                subtitle={`${sacco.active_members} participants`}
                description={`Created by ${sacco.creator_name}, ${sacco.date_created}`}
                link={sacco.id}
              />
            </Col>
          )),
        )}
      </Row>
    </>
  );
}

export default Sacco;
