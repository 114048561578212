import { apiSlice } from "../../apiSlice";

export const updateUserDetailsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateUserDetails: builder.mutation({
      query: (credentials) => ({
        url: "/users/profile",
        method: "PATCH",
        body: { ...credentials },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const { useUpdateUserDetailsMutation } = updateUserDetailsApiSlice;
