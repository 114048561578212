/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const regSlice = createSlice({
  name: "reg",
  initialState: { userDetails: {} },
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    emptyUserDetails: (state) => {
      state.userDetails = {};
    },
  },
});

export const { setUserDetails, emptyUserDetails } = regSlice.actions;
export default regSlice.reducer;

export const selectUserDetails = (state) => state.reg.userDetails;
