import React from "react";

function LeftArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.51628 22.5675C7.36745 22.7163 7.1814 22.7907 6.97675 22.7907C6.7721 22.7907 6.58605 22.7163 6.43721 22.5675C6.13954 22.2698 6.13954 21.7861 6.43721 21.4884L15.9256 12L6.43721 2.51168C6.13954 2.214 6.13954 1.73028 6.43721 1.43261C6.73489 1.13493 7.21861 1.13493 7.51628 1.43261L17.5442 11.4605C17.8419 11.7582 17.8419 12.2419 17.5442 12.5396L7.51628 22.5675Z"
        fill="black"
      />
    </svg>
  );
}

export default LeftArrow;
