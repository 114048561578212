import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import BarChart from "../../../../../../components/Dashboard/Charts/BarChart/BarChart";

import { useGetMonthlyContributionReportsQuery } from "../../../../../../store/api/requests/GET/getMonthlyContributionReportsApiSlice";

function MonthlyContributions({ title }) {
  const { categoryName, Id } = useParams();

  const group = { groupType: categoryName, id: Id };
  const { data } = useGetMonthlyContributionReportsQuery(group);

  const accounts = data?.list_of_account_types;

  return (
    <BarChart
      title={title || "Savings"}
      months={data?.list_of_months || []}
      data={accounts || []}
    />
  );
}
MonthlyContributions.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MonthlyContributions;
