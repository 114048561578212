import { apiSlice } from "../../apiSlice";

export const getTrackIncomeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrackIncome: builder.query({
      query: () => "/user/money-tracker/cash-in",
      providesTags: ["TRACK_INCOME"],
    }),
  }),
});

export const { useGetTrackIncomeQuery } = getTrackIncomeApiSlice;
