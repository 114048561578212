// build_credit / subscriptions;

import { apiSlice } from "../../apiSlice";

export const buildCreditSubscriptionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    buildCreditSubscription: builder.mutation({
      query: (credentials) => ({
        url: "/build_credit/subscriptions",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["BuildCredit"],
    }),
  }),
});

export const { useBuildCreditSubscriptionMutation } =
  buildCreditSubscriptionSlice;
