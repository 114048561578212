// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FaCheck } from "react-icons/fa6";
import Image from "../../../assets/images/noun-credit-score-2479138.svg";
import styles from "./BuildCreditCard.module.css";
import { formatAmount } from "../../../helperFunctions/helpersFunctions";

function CashOutCard({
  name,
  days,
  description,
  fee,
  onClick,
  cashOut,
  amount,
  cashOutAmount,
}) {
  const HeightCalculator = (numberOfDays) => {
    if (numberOfDays === 180) {
      return "350px";
    }
    if (numberOfDays === 365) {
      return "400px";
    }
    return "300px";
  };
  //   const navigate = useNavigate();

  return (
    <div
      className={styles.build_credit}
      style={{ height: HeightCalculator(days) }}
    >
      <div className={styles.clip_path}>
        <h4>{days}</h4>
        <p>Days</p>
      </div>
      <div className={styles.build_credit_image}>
        <img src={Image} alt="price plan" />
      </div>
      <div>
        <div className={styles.build_credit_title}>
          <p>Most Popular</p>
        </div>
        <div className={styles.build_credit_cost}>
          <h3>{name}</h3>
        </div>
      </div>
      <div className={styles.build_credit_includes}>
        <ul>
          <li>
            {" "}
            <FaCheck /> Cashed Out: {cashOut ? "Yes" : "No"}
          </li>
          <li>
            {" "}
            <FaCheck /> {description}
          </li>
          <li>
            {" "}
            <FaCheck />
            <em>UGX {fee}</em> Post Paid Subscription Fee
          </li>
          <li>
            {" "}
            <FaCheck />
            Credit Amount {amount}
          </li>
        </ul>
      </div>
      <div className={styles.build_credit_button}>
        <button type="button" onClick={onClick} disabled={cashOut}>
          {cashOut ? "Cashed Out" : "Cash Out"} {formatAmount(cashOutAmount)}
        </button>
      </div>
    </div>
  );
}

CashOutCard.propTypes = {
  name: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  cashOutAmount: PropTypes.string.isRequired,
  fee: PropTypes.number.isRequired,
  cashOut: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CashOutCard;
