/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import styles from "./LoginForm.module.css";

import { useAddProductMutation } from "../../store/api/requests/POST/addProductApiSlice";

function AddProduct({ close }) {
  const { categoryName, Id } = useParams();
  const [addProduct, { isLoading }] = useAddProductMutation();

  const [inputValues, setInputValues] = useState({
    name: "",
    price: "",
    description: "",
    discount: "",
    availableQuantity: "",
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const productData = {
        groupType: categoryName,
        id: Id,
        category_id: "66aa203f23cbe1aaf432240897c194",
        name: inputValues.name,
        description: inputValues.description,
        image: "No image",
        price: +inputValues.price,
        available_quantity: +inputValues.availableQuantity,
        discount: +inputValues.discount,
      };

      await addProduct(productData).unwrap();
      close();

      toast.success("Product Added");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <Row>
          <Col>
            <div className={styles.form__control}>
              <label htmlFor="name">Name</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoName}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="price">Price</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="price"
                  name="price"
                  placeholder="Enter Price"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoName}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="availableQuantity">Available Quantity</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="availableQuantity"
                  name="availableQuantity"
                  placeholder="Enter Available Quantity"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoName}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="discount">Discount</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="discount"
                  name="discount"
                  placeholder="Enter Discount"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoName}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="description">Description</label>
              <div className={styles.form__input}>
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Enter Description"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoName}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </Col>
        </Row>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Add Product"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
