import { apiSlice } from "../../apiSlice";

export const getGroupsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => "/users/my_group",
      providesTags: ["Group"],
    }),
  }),
});

export const { useGetGroupsQuery } = getGroupsSlice;
