import React from "react";
import ReactApexChart from "react-apexcharts";

function DonutChart() {
  const donutSeries = [44, 55, 41, 17, 15];
  const donutOptions = {
    chart: {
      type: "donut",
    },
    title: {
      text: "Total Bills",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Rubik",
        color: "#111111",
      },
    },
    labels: ["Water", "Umeme", "Yaka", "Airtime", "Others"],

    options: {
      chart: {
        // height: 250,
        // width: 380,
      },

      //   legend: {
      //     show: true,
      //     position: "bottom",
      //     horizontalAlign: "center",
      //   },
    },
  };

  return (
    <div>
      {" "}
      <ReactApexChart
        options={donutOptions}
        series={donutSeries}
        height={250}
        type="donut"
        width="100%"
      />
    </div>
  );
}

export default DonutChart;
