/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

import { selectUserDetails } from "../../store/slice/regSlice";
import { useRestPasswordMutation } from "../../store/api/requests/PATCH/resetPasswordApiSlice";

function SetNewPassword() {
  const navigate = useNavigate();
  const { email: useEmail } = useSelector(selectUserDetails);
  const [restPassword, { isLoading }] = useRestPasswordMutation();

  const [userEmail, setUserEmail] = useState({
    newPassword: "",
    confNewPassword: "",
  });
  const [codeError, setCodeError] = useState("");

  useEffect(() => {
    setCodeError("");
  }, [userEmail]);

  const codeChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserEmail({ ...userEmail, [name]: value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (userEmail.length < 1) {
      setCodeError("Please provide your Email");
    }

    try {
      const userData = {
        email_or_telno: useEmail,
        new_password: userEmail.newPassword,
        new_password1: userEmail.confNewPassword,
      };
      const response = await restPassword(userData).unwrap();
      toast.success(`${response.message}, you can now login`);
      navigate("/login");
    } catch (err) {
      toast.error(err.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <h2>New Password</h2>
      <p>Enter new password</p>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.form__control}>
          <label htmlFor="newPassword">New Password</label>
          <div className={styles.form__input}>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              placeholder="Enter New Password"
              onChange={codeChangeHandler}
              className={codeError ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {codeError && codeError}
          </span>
        </div>
        <div className={styles.form__control}>
          <label htmlFor="confNewPassword">Confirm New Password</label>
          <div className={styles.form__input}>
            <input
              type="password"
              id="confNewPassword"
              name="confNewPassword"
              placeholder="Enter Confirm New Password"
              onChange={codeChangeHandler}
              className={codeError ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {codeError && codeError}
          </span>
        </div>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Set Password"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default SetNewPassword;
