import { apiSlice } from "../../apiSlice";

export const addSavingProductSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSavingProduct: builder.mutation({
      query: (credentials) => ({
        url: `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/savings_products`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["SavingProduct"],
    }),
  }),
});

export const { useAddSavingProductMutation } = addSavingProductSlice;
