import React from "react";
import { useParams } from "react-router-dom";

import TransactionSummary from "../../../../../../components/Dashboard/TransactionSummary/TransactionSummary";
import { useGetContributionPerAccountReportsQuery } from "../../../../../../store/api/requests/GET/getContributionsPerAccountTypeApiSlice";

function ContributionAccount() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };
  const { data } = useGetContributionPerAccountReportsQuery(group);

  return (
    <div>
      {data?.sacco_contributions?.map((account) => (
        <TransactionSummary
          key={Math.random()}
          item={account?.contributions_account_name}
          itemValue={account.total_transaction_amount[0]}
        />
      ))}
    </div>
  );
}

export default ContributionAccount;
