import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useLoanRepaymentMutation } from "../../store/api/requests/POST/loanRepaymentApiSlice";
import { closeModal, loanId } from "../../store/slice/modalSlice";

function LoanRepayment() {
  const { categoryName, Id } = useParams();
  const dispatch = useDispatch();
  const selectedLoan = useSelector(loanId);
  const [loanRepayment, { isLoading }] = useLoanRepaymentMutation();
  const [inputValues, setInputValues] = useState({
    amount: "",
    pin: "",
  });
  const [errors, setError] = useState({
    amountError: "",
  });

  const [step, setStep] = useState(1);

  useEffect(() => {
    setError({ ...errors, amountError: "" });
  }, [inputValues]);

  const inputValueHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (inputValues.amount.length < 1) {
      setError({ ...errors, amountError: "Amount is required" });
    }
    try {
      await loanRepayment({
        groupType: categoryName,
        id: Id,
        loan_id: selectedLoan,
        repaid_amount: inputValues.amount,
        wallet_pin: inputValues.pin,
      }).unwrap();
      dispatch(closeModal());
    } catch (err) {
      setError({ ...errors, amountError: err?.data?.message });
    }
  };
  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        {step === 1 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="amount">Amount</label>
              <div className={styles.form__input}>
                <div className={styles.form__input__icon}>
                  {/* <EmailIcon /> */}
                </div>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  onChange={inputValueHandler}
                  className={errors.amountError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {errors.amountError && errors.amountError}
              </span>
            </div>
            <div className={styles.form__login}>
              <button
                type="button"
                onClick={() => setStep(2)}
                disabled={isLoading || inputValues.amount.length < 1}
              >
                {isLoading ? <LoadingSpinner /> : "Next"}
              </button>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="pin">Wallet Pin</label>
              <div className={styles.form__input}>
                <div className={styles.form__input__icon}>
                  {/* <EmailIcon /> */}
                </div>
                <input
                  type="pin"
                  id="pin"
                  name="pin"
                  placeholder="Enter Pin"
                  onChange={inputValueHandler}
                  className={errors.amountError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {errors.amountError && errors.amountError}
              </span>
            </div>
            <div className={styles.form__login}>
              <button
                type="submit"
                onClick={onSubmitHandler}
                disabled={isLoading || inputValues.pin.length < 1}
              >
                {isLoading ? <LoadingSpinner /> : "Repay Loan"}
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default LoanRepayment;
