import { apiSlice } from "../../apiSlice";

export const getTrackExpenseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrackExpense: builder.query({
      query: () => "/user/money-tracker/cash-out",
      providesTags: ["TRACK_EXPENSE"],
    }),
  }),
});

export const { useGetTrackExpenseQuery } = getTrackExpenseApiSlice;
