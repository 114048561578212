import { apiSlice } from "../../apiSlice";

export const getRegisteredMembersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRegisteredMembers: builder.query({
      query: () => "/users",
    }),
  }),
});

export const { useGetRegisteredMembersQuery } = getRegisteredMembersSlice;
