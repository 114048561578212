import { apiSlice } from "../../apiSlice";

export const getGroupMembersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupMembers: builder.query({
      query: (urlParameters) =>
        `/${
          urlParameters.group === "club"
            ? "investment_club"
            : urlParameters.group
        }s/${urlParameters.groupId}/members`,
    }),
  }),
});

export const { useGetGroupMembersQuery } = getGroupMembersSlice;
