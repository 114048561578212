import React from "react";

function DrawIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      x="0px"
      y="0px"
      height="1em"
      width="1em"
      fill="currentColor"
    >
      <g>
        <path d="M256.5,479.22a219,219,0,0,1-219-219c0-105.27,75-195.79,178.26-215.24a5,5,0,1,1,1.85,9.82C119,73.33,47.46,159.72,47.46,260.18c0,115.27,93.78,209,209,209s209-93.77,209-209C465.54,159.52,393.83,73.1,295,54.69a5,5,0,0,1,1.84-9.83C400.4,64.15,475.54,154.71,475.54,260.18a219,219,0,0,1-219,219Z" />
        <path d="M256.5,336.53a76.35,76.35,0,1,1,76.35-76.35A76.44,76.44,0,0,1,256.5,336.53Zm0-142.69a66.35,66.35,0,1,0,66.35,66.34A66.42,66.42,0,0,0,256.5,193.84Z" />
        <path d="M256.5,500.48A240.33,240.33,0,0,1,207.65,24.86a5,5,0,1,1,2,9.79,230.25,230.25,0,1,0,93.25-.08,5,5,0,0,1,2-9.8A240.32,240.32,0,0,1,256.5,500.48Z" />
        <path d="M322.31,236.09a5,5,0,0,1-2-9.59l130.1-55.9a5,5,0,0,1,3.95,9.19l-130.1,55.89A4.9,4.9,0,0,1,322.31,236.09Z" />
        <path d="M60.6,348.53a5,5,0,0,1-2-9.6L188.72,283a5,5,0,1,1,3.95,9.19L62.57,348.12A5.08,5.08,0,0,1,60.6,348.53Z" />
        <path d="M340.67,460.26a5,5,0,0,1-4.61-3L281.13,328.08a5,5,0,1,1,9.2-3.92l54.93,129.15a5,5,0,0,1-2.64,6.55A4.83,4.83,0,0,1,340.67,460.26Z" />
        <path d="M228.23,198.56a5,5,0,0,1-4.59-3l-55.9-130.1a5,5,0,0,1,9.19-3.94l55.89,130.09a5,5,0,0,1-2.62,6.57A4.9,4.9,0,0,1,228.23,198.56Z" />
        <path d="M283,197.84a4.83,4.83,0,0,1-1.85-.36A5,5,0,0,1,278.4,191L330.87,59.47a5,5,0,0,1,9.29,3.71L287.68,194.69A5,5,0,0,1,283,197.84Z" />
        <path d="M177.49,462.4a5,5,0,0,1-4.65-6.84L224.35,325a5,5,0,0,1,9.3,3.67L182.14,459.23A5,5,0,0,1,177.49,462.4Z" />
        <path d="M454.53,343.37a5.05,5.05,0,0,1-1.85-.35L321.17,290.55a5,5,0,1,1,3.71-9.29l131.51,52.47a5,5,0,0,1-1.86,9.64Z" />
        <path d="M190,237.82a4.88,4.88,0,0,1-1.86-.36L56.61,185a5,5,0,1,1,3.71-9.28l131.51,52.47a5,5,0,0,1-1.85,9.64Z" />
        <path d="M328.12,263.89a5,5,0,0,1,0-10L469.68,253h0a5,5,0,0,1,0,10l-141.59.94Z" />
        <path d="M43.29,265.77a5,5,0,0,1,0-10l141.59-.94h0a5,5,0,0,1,0,10l-141.59.94Z" />
        <path d="M257.91,477.57a5,5,0,0,1-5-5l-.43-140.34a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5l.43,140.34a5,5,0,0,1-5,5Z" />
        <path d="M256,192.74a5,5,0,0,1-5-4.93l-.94-67a5,5,0,0,1,10-.14l.94,67a5,5,0,0,1-4.93,5.07Z" />
        <path d="M306.81,213.38a5,5,0,0,1-3.56-8.51l99.46-100.78a5,5,0,0,1,7.12,7L310.37,211.9A5,5,0,0,1,306.81,213.38Z" />
        <path d="M106.73,416.12a4.94,4.94,0,0,1-3.49-1.43,5,5,0,0,1-.08-7.07l98.21-100.27a5,5,0,1,1,7.14,7l-98.2,100.27A5,5,0,0,1,106.73,416.12Z" />
        <path d="M408.26,414.13a5,5,0,0,1-3.51-1.44L304,313.23a5,5,0,0,1,7-7.12l100.78,99.46a5,5,0,0,1-3.51,8.56Z" />
        <path d="M205.52,214.05a5,5,0,0,1-3.51-1.44L101.23,113.15a5,5,0,1,1,7-7.11L209,205.49a5,5,0,0,1-3.51,8.56Z" />
        <path d="M256.5,125.76a17.77,17.77,0,0,1-16.57-11l-33.8-79.22A18,18,0,0,1,222.7,10.5h67.6a18,18,0,0,1,16.57,25.09l-33.8,79.22A17.77,17.77,0,0,1,256.5,125.76ZM215.32,31.67l33.8,79.21a8,8,0,0,0,14.76,0l33.8-79.21A8,8,0,0,0,290.3,20.5H222.7a8,8,0,0,0-7.38,11.17Z" />
      </g>
    </svg>
  );
}

export default DrawIcon;
