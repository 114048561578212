/* eslint-disable react/prop-types */
import React from "react";

import { Col, Row } from "react-bootstrap";

import styles from "./Invitations.module.css";
import InvitationCard from "../../components/Dashboard/InvitationCard/InvitationCard";
import { useGetAllUserInvitationsQuery } from "../../store/api/requests/GET/getAllUserInvitationsApiSlice";

function Content({
  clubMemberInvitations,
  saccoMemberInvitations,
  vslaMemberInvitations,
  cashRoundMemberInvitations,
}) {
  if (
    clubMemberInvitations?.length > 0 ||
    saccoMemberInvitations?.length > 0 ||
    vslaMemberInvitations?.length > 0 ||
    cashRoundMemberInvitations?.length > 0
  ) {
    return (
      <Row>
        {clubMemberInvitations?.map((invitation) => (
          <Col md={12} lg={6} sm={12} key={invitation.id}>
            {" "}
            <InvitationCard
              sender={invitation.requested_by_name}
              middleText="invited you to join"
              groupName={invitation?.sacco_name}
              message={invitation.message}
              expDate={invitation.expiration_date}
              groupId={invitation.investment_club_id}
              groupType="investment_club"
            />
          </Col>
        ))}
        {saccoMemberInvitations?.map((invitation) => (
          <Col md={12} lg={6} sm={12} key={invitation.id}>
            {" "}
            <InvitationCard
              key={invitation.id}
              sender={invitation.requested_by_name}
              middleText="invited you to join"
              groupName={invitation?.sacco_name}
              message={invitation.message}
              expDate={invitation.expiration_date}
              groupId={invitation.sacco_id}
              groupType="sacco"
            />
          </Col>
        ))}
        {vslaMemberInvitations?.map((invitation) => (
          <Col md={12} lg={6} sm={12} key={invitation.id}>
            {" "}
            <InvitationCard
              key={invitation.id}
              sender={invitation.requested_by_name}
              middleText="invited you to join"
              groupName={invitation?.sacco_name}
              message={invitation.message}
              expDate={invitation.expiration_date}
              groupId={invitation.vsla_id}
              groupType="vsla"
            />
          </Col>
        ))}
        {/* approval_date : null approval_status : "Pending" approved_by :
        "21ea1ecd7d6cb60dea956cd4d97803" date_created : "Sat, 25 May 2024
        14:59:25 GMT" expiration_date : "Sat, 01 Jun 2024 14:59:25 GMT" group_id
        : "f86fb611d2c6a3e792d2e62bd08a36" group_name : "Jeba Cash Round" id :
        "f86fb611d2c6a3e792d2e62bd08a36" invited_by : "Olum Fredrick Narsh"
        invited_user_name : "Ssemugenyi isaac" */}
        {cashRoundMemberInvitations?.map((invitation) => (
          <Col md={12} lg={6} sm={12} key={invitation.id}>
            {" "}
            <InvitationCard
              key={invitation.id}
              sender={invitation.invited_user_name}
              middleText="invited you to join"
              groupName={invitation?.group_name}
              message={invitation.message}
              expDate={invitation.expiration_date}
              groupId={invitation.group_id}
              isCashRound
              id={invitation.id}
              groupType="cashround"
            />
          </Col>
        ))}
      </Row>
    );
  }
  return <p>No invitations yet</p>;
}

function Invitations() {
  const { data: invitations, isFetching } = useGetAllUserInvitationsQuery();

  const clubMemberInvitations = invitations?.investment_club_member_requests;
  const saccoMemberInvitations = invitations?.sacco_member_requests;
  const vslaMemberInvitations = invitations?.vsla_member_requests;
  const cashRoundMemberInvitations = invitations?.cash_round_group_invitations;

  return (
    <div className={styles.jeba_invitations__container}>
      <header className={styles.jeba_invitations__container__header}>
        <h4>Invitations</h4>
      </header>
      <div className={styles.jeba_invitations}>
        {isFetching ? (
          <p>Loading Invitations...</p>
        ) : (
          <Content
            clubMemberInvitations={clubMemberInvitations}
            saccoMemberInvitations={saccoMemberInvitations}
            vslaMemberInvitations={vslaMemberInvitations}
            cashRoundMemberInvitations={cashRoundMemberInvitations}
          />
        )}
      </div>
    </div>
  );
}

export default Invitations;
