/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import styles from "./LoginForm.module.css";
import { useGetRegisteredMembersQuery } from "../../store/api/requests/GET/registeredMembersApiSlice";

import { useInviteMemberCashRoundMutation } from "../../store/api/requests/POST/inviteMemberCashRoundApiSlice";

function InviteMemberCashRound({ close }) {
  const { categoryName, Id } = useParams();
  const { data } = useGetRegisteredMembersQuery();
  const [inviteMemberCashRound, { isLoading }] =
    useInviteMemberCashRoundMutation();

  const [inputValues, setInputValues] = useState({
    userId: "",
    message: "",
  });

  const usersData = data?.map((user) => {
    return {
      value: user.id,
      label: user.user_name,
    };
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };

  const selectInputChangeHandler = (event) => {
    setInputValues({ ...inputValues, userId: event.value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const inviteData = {
        id: Id,
        groupType: categoryName,
        user_id: inputValues.userId,
        message: inputValues.message,
      };

      const response = await inviteMemberCashRound(inviteData).unwrap();
      if (response?.status === 200) {
        close();
        toast.success("Invitation sent successfully");
      }
    } catch (error) {
      toast.error(error.data.message);
    }
  };
  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <Row>
          <Col>
            <div className={styles.form__control}>
              <label htmlFor="userId">Name</label>
              <div className={styles.form__input}>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#0866be" : "#0866be",
                      borderRadius: "10px",
                    }),
                  }}
                  className={styles.jeba_select__container}
                  classNamePrefix="jeba_select"
                  name="userId"
                  options={usersData}
                  onChange={selectInputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="message">Message</label>
              <div className={styles.form__input}>
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  placeholder="Enter Message"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoPhoneNumber}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </Col>
        </Row>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Invite Member"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default InviteMemberCashRound;
