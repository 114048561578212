import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetCashRoundPolicyQuery } from "../../../../store/api/requests/GET/getCashRoundPolicyApiSlice";
import { formatAmount } from "../../../../helperFunctions/helpersFunctions";
import LoadingText from "../../../../components/ui/Loaders/LoadingText";

function Policy() {
  const { categoryName, Id } = useParams();
  const urlParameters = {
    group: categoryName,
    groupId: Id,
  };
  const { data, isFetching } = useGetCashRoundPolicyQuery(urlParameters);

  const scheduleHandler = (schedule) => {
    const scheduleLower = schedule?.toLowerCase();
    switch (scheduleLower) {
      case "weekly":
        return "Week";
      case "bi-weekly":
        return "Bi-Week";
      case "monthly":
        return "Month";
      default:
        return "Day";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          padding: "3rem",
        }}
      >
        <div className="d-flex justify-content-between mb-3">
          <h1>Policy</h1>
          <div className="d-flex gap-2">
            <p>Cash Round Start Date:</p>
            <p>
              <strong>
                {data?.cashround_policy?.start_date ?? "Not Started"}
              </strong>
            </p>
          </div>
        </div>
        <div className="">
          <Row lg={3} md={2} sm={1}>
            <Col>
              <h4>Contribution Amount: </h4>

              <p>
                {isFetching ? (
                  <LoadingText css={{ marginLeft: "40px" }} />
                ) : (
                  `${formatAmount(
                    data?.cashround_policy?.collection_amount_per_schedule,
                  )} Per ${scheduleHandler(
                    data?.cashround_policy?.pay_schedule,
                  )}`
                )}
              </p>
            </Col>
            <Col>
              <h4> Withdrawal Amount Per Member:</h4>
              <p>
                {isFetching ? (
                  <LoadingText />
                ) : (
                  formatAmount(
                    data?.cashround_policy?.withdrawal_amount_per_member,
                  )
                )}
              </p>
            </Col>
            <Col>
              <h4>No Members:</h4>
              <p>
                {isFetching ? (
                  <LoadingText />
                ) : (
                  data?.cashround_policy?.no_members
                )}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Policy;
