import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
import { useGetOrderProductsQuery } from "../../../../../../store/api/requests/GET/getProductOrdersApiSlice";
import Table from "../../../../../../components/Dashboard/Table/Table";
import { ORDER_PRODUCT_COLUMNS } from "../../../../../../components/Dashboard/Table/tableData/columns";
import { formatAmount } from "../../../../../../helperFunctions/helpersFunctions";

function OrderSingle() {
  const { categoryName, Id, orderId } = useParams();
  const { data, isFetching } = useGetOrderProductsQuery({
    groupType: categoryName,
    id: Id,
  });

  const selectedOrder = data?.orders.filter((order) => order.id === orderId)[0]
    ?.order_items;

  const totalCost = selectedOrder?.reduce((total, curVal) => {
    return total + curVal.cost;
  }, 0);

  const orderStatus = data?.orders.filter((order) => order.id === orderId)[0]
    ?.status;
  const orderID = data?.orders.filter((order) => order.id === orderId)[0]
    ?.order_id;

  const stepHandler = (status) => {
    const lowStatus = status.toLowerCase();
    if (lowStatus === "processing") {
      return 1;
    }
    if (lowStatus === "completed") {
      return 2;
    }
    if (lowStatus === "dispatched") {
      return 3;
    }
    if (lowStatus === "delivered") {
      return 5;
    }
    return 0;
  };
  return (
    <div>
      <Row>
        <Col
          lg={8}
          style={{
            borderRadius: "10px",
            backgroundColor: "#fff",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "15px",
            paddingBottom: "15px",
            marginRight: "12px",
          }}
        >
          <div>
            <h3>Track Your Order</h3>
            <div className="my-5">
              <h5>Order ID :{orderID}</h5>
            </div>
            <StepProgressBar
              startingStep={stepHandler(orderStatus)}
              //   onSubmit={onFormSubmit}
              buttonWrapperClass="d-none"
              wrapperClass="step-progress"
              steps={[
                {
                  label: "Pending",
                  name: "Pending",
                  validator: true,
                },
                {
                  label: "Processing",
                  name: "Processing",
                },
                {
                  label: "Completed",
                  name: "Completed",
                },
                {
                  label: "Dispatched",
                  name: "Dispatched",
                },
                {
                  label: "Delivered",
                  name: "Delivered",
                },

                {
                  label: "Step 3",
                  name: "step 7",
                },
              ]}
            />
          </div>
          <div className="my-5">
            <h5>Your Order Is {orderStatus}</h5>
            <span>Jeba Pesa Express</span>
          </div>
          <div>
            <h3>All Products</h3>
            <div>
              <Table
                data={selectedOrder || []}
                columns={ORDER_PRODUCT_COLUMNS}
                isLoading={isFetching}
              />
            </div>
          </div>
        </Col>
        <Col
          lg={3}
          style={{
            borderRadius: "10px",
            backgroundColor: "#fff",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <div>
            <h3>Total</h3>
          </div>
          <div>
            <h4>Amount: {formatAmount(totalCost)}</h4>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OrderSingle;
