import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import {
  BsArrowDownUp,
  BsArrowLeftRight,
  BsCreditCard2Front,
} from "react-icons/bs";
import { CiGrid42 } from "react-icons/ci";
import { HiOutlineUserGroup } from "react-icons/hi";
import ListItem from "../ListItem/ListItem";
import styles from "./Sidebar.module.css";
import {
  WalletIcon,
  HelpIcon,
  LogoutIcon,
  ProfileIcon,
} from "../../../assets/icons";
import { useLogOutMutation } from "../../../store/api/requests/POST/logOutApiSlice";
import JebaModal from "../../ui/Modal/Modal";

import { logOut as logOutUser } from "../../../store/slice/authSlice";

function Sidebar({ closeMenu }) {
  const [logOut, { isLoading }] = useLogOutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmitIncomeHandler = async (event) => {
    event.preventDefault();
    try {
      await logOut().unwrap();
      closeMenu();
      dispatch(logOutUser());
      navigate("/login");

      toast.success("You Logged Out");
    } catch (error) {
      toast.error(error);
    }
  };
  const lists = [
    {
      title: "dashboard",
      icon: <CiGrid42 />,
    },
    {
      title: "build credit",
      icon: <BsCreditCard2Front />,
    },
    {
      title: "groups",
      icon: <HiOutlineUserGroup />,
    },
    {
      title: "my wallet",
      icon: <WalletIcon />,
    },
    {
      title: "transactions",
      icon: <BsArrowLeftRight />,
    },
    {
      title: "money tracker",
      icon: <BsArrowDownUp />,
    },
    {
      title: "profile",
      icon: <ProfileIcon />,
    },
    {
      title: "help",
      icon: <HelpIcon />,
    },
    // {
    //   title: "settings",
    //   icon: <SettingIcon />,
    // },
    {
      title: "logout",
      icon: <LogoutIcon />,
      logOut: onSubmitIncomeHandler,
    },
  ];

  return (
    <div className={styles.sidebar}>
      <JebaModal show={isLoading} closeButton>
        <p>Logging Out</p>
      </JebaModal>
      <h2 className={styles.sidebar__title}>Jeba Pesa</h2>
      {lists.slice(0, -1).map((list) => (
        <ListItem
          icon={list.icon}
          title={list.title}
          key={Math.random()}
          closeMenu={closeMenu}
        />
      ))}

      <div className={styles.separator} />
      {lists.slice(-1).map((list) => (
        <ListItem
          icon={list.icon}
          title={list.title}
          key={Math.random()}
          closeMenu={closeMenu}
          onClick={list.logOut}
        />
      ))}
    </div>
  );
}
Sidebar.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};
export default Sidebar;
