// /build_credit/caabbddcae4874e513099aee94cc9c/schedules

import { apiSlice } from "../../apiSlice";

export const getPaymentHistorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentHistory: builder.query({
      query: (urlParameters) => `/build_credit/${urlParameters.id}/payments`,
      providesTags: ["BuildCredit"],
    }),
  }),
});

export const { useGetPaymentHistoryQuery } = getPaymentHistorySlice;
