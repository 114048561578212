import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FiMinus, FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import styles from "./ProductCounter.module.css";
import { useChangeProductQuantityMutation } from "../../../store/api/requests/PATCH/changeProductQuantityApiSlice";

function ProductCounter({ initialValue, productId }) {
  const { categoryName, Id } = useParams();
  const [changeProductQuantity, { isLoading }] =
    useChangeProductQuantityMutation();
  const [productQuantity, setProductQuantity] = useState(initialValue);

  const quantityHandler = async (actionType) => {
    try {
      const productData = {
        groupType: categoryName,
        id: Id,
        action: actionType,
        product_id: productId,
      };
      await changeProductQuantity(productData).unwrap();
      toast.success("Product Updated Successfully");
    } catch (error) {
      toast.error(error);
    }
  };

  const increaseQuantity = () => {
    setProductQuantity((prev) => prev + 1);
    quantityHandler("increment");
  };

  const decreaseQuantity = () => {
    if (productQuantity > 1) {
      setProductQuantity((prev) => prev - 1);
      quantityHandler("decrement");
    }
  };
  return (
    <form className={styles.increase_quantity_form}>
      <button
        className={styles.decrease_btn}
        type="button"
        onClick={decreaseQuantity}
        disabled={productQuantity < 2 || isLoading}
      >
        <FiMinus />
      </button>
      <input type="text" value={productQuantity} readOnly />
      <button
        type="button"
        className={styles.increase_btn}
        onClick={increaseQuantity}
        disabled={isLoading}
      >
        <FiPlus />
      </button>
    </form>
  );
}
ProductCounter.propTypes = {
  initialValue: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
};
export default ProductCounter;
