// transactions_report;
import { apiSlice } from "../../apiSlice";

export const getTransactionReportsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionReports: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/transactions_reports`,
      providesTags: ["Saving"],
    }),
  }),
});

export const { useGetTransactionReportsQuery } = getTransactionReportsSlice;
