import React from "react";
import { useParams } from "react-router-dom";
import { useGetTotalLoanQuery } from "../../../../../../store/api/requests/GET/getTotalLoanApiSlice";
import Card from "../../../../../../components/Dashboard/Card/Card";
import { thousandSeparator } from "../../../../../../helperFunctions/helpersFunctions";

function TotalLoans() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };

  const { data } = useGetTotalLoanQuery(group);

  return (
    <Card
      title="Loans"
      total={thousandSeparator(data?.total_loan_amount_disbursed)}
    />
  );
}

export default TotalLoans;
