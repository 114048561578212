import { apiSlice } from "../../apiSlice";

export const getEventToRolloverSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEventToRollover: builder.query({
      query: () => "/build_credit/roll_overs",
      providesTags: ["BuildCredit"],
    }),
  }),
});

export const { useGetEventToRolloverQuery } = getEventToRolloverSlice;
