/* eslint-disable react/prop-types */
import React from "react";
import styles from "./MoneyTrackerCard.module.css";

function MoneyTrackerCard({ title, value, type }) {
  return (
    <div
      className={`${styles.MoneyTrackerCard} ${
        type === "income" ? styles.income : styles.expense
      }`}
    >
      <p className={styles.heading}>{title}</p>
      <h3 className={styles.value}>{value}</h3>
    </div>
  );
}

export default MoneyTrackerCard;
