/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { FaArrowRightArrowLeft } from "react-icons/fa6";

import Table from "../../../../components/Dashboard/Table/Table";

import { FilterAddNew } from "../../../../components/Filters";

import JebaModal from "../../../../components/ui/Modal/Modal";
import LoanApplicationForm from "../../../../components/Forms/LoanApplicationForm";

import styles from "../Members/Members.module.css";
import AddLoanProduct from "../../../../components/Forms/AddLoanProduct";
import { useGetLoanProductsQuery } from "../../../../store/api/requests/GET/getLoanProductsApiSlice";
import LoanListCard from "../../../../components/Group/LoanListCard/LoanListCard";
import LoanProductCard from "../../../../components/Group/LoanProductCard/LoanProductCard";
import { useGetAllLoanApplicationsQuery } from "../../../../store/api/requests/GET/getAllLoanApplicationsApiSlice";
import LoanProductDetails from "../../../../components/Group/LoanProductDetails/LoanProductDetails";

import { selectRole } from "../../../../store/slice/memberRoleSlice";

import { USER_ROLES } from "../../../../constants/constants";
import { useGetLoansQuery } from "../../../../store/api/requests/GET/getLoansApiSlice";
import LoanApplicationDetails from "../../../../components/Group/LoanApplicationDetails/LoanApplicationDetails";
import LoanDetails from "../../../../components/Group/LoanDetails/LoanDetails";
import { LOAN_COLUMNS } from "../../../../components/Dashboard/Table/tableData/columns";
import LoanRepayment from "../../../../components/Forms/LoanRepayment";
import {
  closeModal,
  repayModalStatus,
  scheduleModalStatus,
  viewModalStatus,
} from "../../../../store/slice/modalSlice";
import LoanSchedule from "../../../../components/Group/LoanSchedule/LoanSchedule";
import ViewLoan from "../../../../components/Group/ViewLoan/ViewLoan";
import { contentHandler } from "../../../../helperFunctions/helpersFunctions";

function Loans() {
  const dispatch = useDispatch();
  const repay = useSelector(repayModalStatus);
  const view = useSelector(viewModalStatus);
  const schedule = useSelector(scheduleModalStatus);

  const { categoryName, Id } = useParams();

  const {
    data: LoanProducts,
    isFetching,
    refetch: refetchLoanProducts,
  } = useGetLoanProductsQuery({
    groupType: categoryName,
    id: Id,
  });
  const {
    data: loanApplications,
    isFetching: isFetchingLoanApplications,
    refetch: refetchLoanApplications,
  } = useGetAllLoanApplicationsQuery({
    groupType: categoryName,
    id: Id,
  });
  const {
    data: loans,
    isFetching: loanIsFetching,
    refetch: refetchLoans,
  } = useGetLoansQuery({
    groupType: categoryName,
    id: Id,
  });

  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const [OpenLoanRequest, setOpenLoanRequest] = useState(false);
  const [OpenLoanProduct, setOpenLoanProduct] = useState(false);
  const [OpenLoanProductDetails, setOpenLoanProductDetails] = useState(false);
  const [loanProduct, setLoanProduct] = useState({});
  const [openLoanApplicationDetails, setOpenLoanApplicationDetails] =
    useState(false);
  const [loan, setLoan] = useState({});
  const [loanApp, setLoanApp] = useState({});
  const [openLoanDetails, setOpenLoanDetails] = useState(false);

  const openLoanRequestHandler = () => setOpenLoanRequest(true);
  const closeLoanRequestHandler = () => setOpenLoanRequest(false);

  const openLoanProductHandler = () => setOpenLoanProduct(true);
  const closeLoanProductHandler = () => setOpenLoanProduct(false);

  const closeLoanProductDetailsHandler = () => setOpenLoanProductDetails(false);

  const [switchToLoan, setSwitchToLoan] = useState(false);

  const data =
    loans?.loan_data?.filter((transaction) => transaction.disbursed) || [];

  return (
    <div>
      <FilterAddNew
        searchItem="Loan"
        buttonText="Request Loan"
        open={openLoanRequestHandler}
        refresh={() => {
          refetchLoanProducts();
          refetchLoanApplications();
          refetchLoans();
        }}
        onRefresh={loanIsFetching || isFetching || isFetchingLoanApplications}
      />

      <JebaModal
        show={OpenLoanRequest}
        onHide={closeLoanRequestHandler}
        title="Request Loan"
      >
        <LoanApplicationForm close={closeLoanRequestHandler} />
      </JebaModal>

      <Row>
        <Col xl={7} md={7} sm={12} className="mb-2">
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
            className={styles.content_container}
          >
            {" "}
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
                textTransform: "capitalize",
              }}
            >
              <div style={{ display: "flex" }}>
                {!switchToLoan
                  ? isAdmin
                    ? "Member Loans"
                    : "My Loans"
                  : "Approved Loans"}
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50px",
                    backgroundColor: "#d11124",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  {!switchToLoan ? 0 : loans?.loan_data?.length || 0}
                </span>
              </div>
              {isAdmin && (
                <button
                  type="button"
                  style={{
                    color: "#30e584",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    border: "none",
                    borderRadius: "50px",
                    backgroundColor: "#eafcf3",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "8px",
                  }}
                  onClick={() => setSwitchToLoan((prevState) => !prevState)}
                >
                  <FaArrowRightArrowLeft />{" "}
                  {switchToLoan ? "Member Loans" : "Approved Loans"}
                </button>
              )}
            </h4>
            <div style={{ maxHeight: "520px", overflow: "auto" }}>
              {!switchToLoan ? (
                <Table
                  data={data}
                  columns={LOAN_COLUMNS}
                  isLoading={isFetchingLoanApplications}
                  noDataText="Your Loans will appear here"
                  showActions
                />
              ) : (
                <Row style={{ margin: 0 }}>
                  {contentHandler(
                    loanIsFetching,
                    loans?.loan_data?.length,
                    "Loading Loans...",
                    "No Loans",
                    loans?.loan_data?.map((loanData) => (
                      <Col
                        lg={6}
                        key={loanData.id}
                        style={{ marginBottom: "12px" }}
                      >
                        <LoanListCard
                          data={loanData}
                          buttonText={
                            loanData.disbursed ? "Disbursed" : "Disburse"
                          }
                          onClick={() => {
                            setOpenLoanDetails(true);
                            setLoan(
                              loans?.loan_data?.find(
                                (loanItem) => loanItem.id === loanData.id,
                              ),
                            );
                          }}
                        />
                      </Col>
                    )),
                  )}

                  <JebaModal
                    title="Loan Details"
                    show={openLoanDetails}
                    onHide={() => setOpenLoanDetails(false)}
                  >
                    <LoanDetails
                      data={loan}
                      close={() => setOpenLoanDetails(false)}
                    />
                  </JebaModal>
                </Row>
              )}
            </div>
            <JebaModal
              title={loanApp.loan_product_name}
              show={openLoanApplicationDetails}
              onHide={() => setOpenLoanApplicationDetails(false)}
            >
              <LoanApplicationDetails
                loan={loanApp}
                close={() => setOpenLoanApplicationDetails(false)}
              />
            </JebaModal>
          </div>
        </Col>

        <Col xl={5} md={5} sm={12} className="mb-2">
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Loan Applications
            </h4>
            <div
              style={{
                maxHeight: "520px",
                overflow: "auto",
              }}
            >
              <Row style={{ margin: 0 }}>
                {contentHandler(
                  isFetchingLoanApplications,
                  loanApplications?.loan_application_data?.length,
                  "Loading Loan Applications...",
                  "No Loan Applications Yet",
                  loanApplications?.loan_application_data?.map(
                    (loanApplication) => (
                      <Col
                        lg={12}
                        style={{ marginBottom: "12px" }}
                        key={loanApplication.id}
                      >
                        <LoanListCard
                          data={loanApplication}
                          onClick={() => {
                            setOpenLoanApplicationDetails(true);
                            setLoanApp(
                              loanApplications?.loan_application_data?.find(
                                (item) => loanApplication.id === item.id,
                              ),
                            );
                          }}
                        />
                      </Col>
                    ),
                  ),
                )}
              </Row>
            </div>
          </div>
        </Col>

        <Col xl={7} md={7} sm={12} className="mt-4">
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Loan Products
              {isAdmin && (
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    border: "none",
                    borderRadius: "50px",
                    backgroundColor: "#0866be",
                    fontSize: "10px",
                    textTransform: "capitalize",
                    padding: "8px",
                  }}
                  type="button"
                  onClick={openLoanProductHandler}
                >
                  Add loan product
                </button>
              )}
            </h4>
            <JebaModal
              show={OpenLoanProduct}
              onHide={closeLoanProductHandler}
              title="Add Loan Product"
            >
              <AddLoanProduct close={closeLoanProductHandler} />
            </JebaModal>
            <div style={{ maxHeight: "520px", overflow: "auto" }}>
              {LoanProducts?.sacco_loan_products?.length === 0 && (
                <p>No Loan Products</p>
              )}
              {isFetching ? (
                <p>Loading Loan Products...</p>
              ) : (
                LoanProducts?.sacco_loan_products?.map((product) => (
                  <LoanProductCard
                    key={product.id}
                    openLoanProductDetailsHandler={setOpenLoanProductDetails}
                    data={product}
                    setLoanProduct={setLoanProduct}
                    products={LoanProducts?.sacco_loan_products}
                  />
                ))
              )}
              <JebaModal
                title={loanProduct?.product_name}
                show={OpenLoanProductDetails}
                onHide={closeLoanProductDetailsHandler}
                size="lg"
              >
                <LoanProductDetails loanProduct={loanProduct} />
              </JebaModal>
            </div>
          </div>
        </Col>
      </Row>
      <JebaModal
        title="Loan Repayment"
        show={repay}
        onHide={() => dispatch(closeModal())}
      >
        <LoanRepayment />
      </JebaModal>

      <JebaModal
        title="View Loan"
        show={view}
        onHide={() => dispatch(closeModal())}
      >
        <ViewLoan />
      </JebaModal>

      <JebaModal
        title="Loan Schedule"
        show={schedule}
        onHide={() => dispatch(closeModal())}
      >
        <LoanSchedule />
      </JebaModal>
    </div>
  );
}

export default Loans;
