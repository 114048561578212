import { apiSlice } from "../../apiSlice";

export const getCashRoundRoundsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCashRoundRounds: builder.query({
      query: (urlParameters) =>
        `/${
          urlParameters.group === "club"
            ? "investment_club"
            : urlParameters.group
        }s/${urlParameters.groupId}/member_rounds`,
      providesTags: ["Draws"],
    }),
  }),
});

export const { useGetCashRoundRoundsQuery } = getCashRoundRoundsApiSlice;
