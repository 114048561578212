import React from "react";

function GridIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M12.5 3.375H5.5C4.93682 3.37632 4.39709 3.60063 3.99886 3.99886C3.60063 4.39709 3.37632 4.93682 3.375 5.5V12.5C3.37632 13.0632 3.60063 13.6029 3.99886 14.0012C4.39709 14.3994 4.93682 14.6237 5.5 14.625H12.5C13.0632 14.6237 13.6029 14.3994 14.0012 14.0012C14.3994 13.6029 14.6237 13.0632 14.625 12.5V5.5C14.6237 4.93682 14.3994 4.39709 14.0012 3.99886C13.6029 3.60063 13.0632 3.37632 12.5 3.375ZM13.375 12.5C13.3737 12.7317 13.2811 12.9535 13.1173 13.1173C12.9535 13.2811 12.7317 13.3737 12.5 13.375H5.5C5.26835 13.3737 5.04655 13.2811 4.88273 13.1173C4.71892 12.9535 4.62631 12.7317 4.625 12.5V5.5C4.62631 5.26835 4.71892 5.04655 4.88273 4.88273C5.04655 4.71892 5.26835 4.62631 5.5 4.625H12.5C12.7317 4.62631 12.9535 4.71892 13.1173 4.88273C13.2811 5.04655 13.3737 5.26835 13.375 5.5V12.5Z"
        fill="#38414C"
      />
      <path
        d="M26.5 3.375H19.5C18.9368 3.37632 18.3971 3.60063 17.9988 3.99886C17.6006 4.39709 17.3763 4.93682 17.375 5.5V12.5C17.3763 13.0632 17.6006 13.6029 17.9988 14.0012C18.3971 14.3994 18.9368 14.6237 19.5 14.625H26.5C27.0632 14.6237 27.6029 14.3994 28.0012 14.0012C28.3994 13.6029 28.6237 13.0632 28.625 12.5V5.5C28.6237 4.93682 28.3994 4.39709 28.0012 3.99886C27.6029 3.60063 27.0632 3.37632 26.5 3.375ZM27.375 12.5C27.3737 12.7317 27.2811 12.9535 27.1173 13.1173C26.9535 13.2811 26.7317 13.3737 26.5 13.375H19.5C19.2684 13.3737 19.0465 13.2811 18.8827 13.1173C18.7189 12.9535 18.6263 12.7317 18.625 12.5V5.5C18.6263 5.26835 18.7189 5.04655 18.8827 4.88273C19.0465 4.71892 19.2684 4.62631 19.5 4.625H26.5C26.7317 4.62631 26.9535 4.71892 27.1173 4.88273C27.2811 5.04655 27.3737 5.26835 27.375 5.5V12.5Z"
        fill="#38414C"
      />
      <path
        d="M12.5 17.375H5.5C4.93682 17.3763 4.39709 17.6006 3.99886 17.9988C3.60063 18.3971 3.37632 18.9368 3.375 19.5V26.5C3.37632 27.0632 3.60063 27.6029 3.99886 28.0012C4.39709 28.3994 4.93682 28.6237 5.5 28.625H12.5C13.0632 28.6237 13.6029 28.3994 14.0012 28.0012C14.3994 27.6029 14.6237 27.0632 14.625 26.5V19.5C14.6237 18.9368 14.3994 18.3971 14.0012 17.9988C13.6029 17.6006 13.0632 17.3763 12.5 17.375ZM13.375 26.5C13.3737 26.7317 13.2811 26.9535 13.1173 27.1173C12.9535 27.2811 12.7317 27.3737 12.5 27.375H5.5C5.26835 27.3737 5.04655 27.2811 4.88273 27.1173C4.71892 26.9535 4.62631 26.7317 4.625 26.5V19.5C4.62631 19.2684 4.71892 19.0465 4.88273 18.8827C5.04655 18.7189 5.26835 18.6263 5.5 18.625H12.5C12.7317 18.6263 12.9535 18.7189 13.1173 18.8827C13.2811 19.0465 13.3737 19.2684 13.375 19.5V26.5Z"
        fill="#38414C"
      />
      <path
        d="M26.5 17.375H19.5C18.9368 17.3763 18.3971 17.6006 17.9988 17.9988C17.6006 18.3971 17.3763 18.9368 17.375 19.5V26.5C17.3763 27.0632 17.6006 27.6029 17.9988 28.0012C18.3971 28.3994 18.9368 28.6237 19.5 28.625H26.5C27.0632 28.6237 27.6029 28.3994 28.0012 28.0012C28.3994 27.6029 28.6237 27.0632 28.625 26.5V19.5C28.6237 18.9368 28.3994 18.3971 28.0012 17.9988C27.6029 17.6006 27.0632 17.3763 26.5 17.375ZM27.375 26.5C27.3737 26.7317 27.2811 26.9535 27.1173 27.1173C26.9535 27.2811 26.7317 27.3737 26.5 27.375H19.5C19.2684 27.3737 19.0465 27.2811 18.8827 27.1173C18.7189 26.9535 18.6263 26.7317 18.625 26.5V19.5C18.6263 19.2684 18.7189 19.0465 18.8827 18.8827C19.0465 18.7189 19.2684 18.6263 19.5 18.625H26.5C26.7317 18.6263 26.9535 18.7189 27.1173 18.8827C27.2811 19.0465 27.3737 19.2684 27.375 19.5V26.5Z"
        fill="#38414C"
      />
    </svg>
  );
}

export default GridIcon;
