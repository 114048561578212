import { apiSlice } from "../../apiSlice";

export const getGroupTransactionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupTransactions: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/transactions`,
      providesTags: ["Saving", "GroupTransactions"],
    }),
  }),
});

export const { useGetGroupTransactionsQuery } = getGroupTransactionsSlice;
