/* eslint-disable react/prop-types */
import React from "react";

import { BsGraphUpArrow } from "react-icons/bs";
import { IoShieldCheckmark } from "react-icons/io5";

import styles from "./SavingAccountCard.module.css";
import { thousandSeparator } from "../../../helperFunctions/helpersFunctions";

function SavingAccountCard({
  accountType,
  holderName,
  interest,
  accountBalance,
  accountNumber,
}) {
  return (
    <div className={styles.jeba_saving_account}>
      <div>
        <h4 className={styles.jeba_saving_account__type}>{accountType}</h4>
        <p className={styles.jeba_saving_account__holder}>{holderName}</p>
      </div>
      <div className={styles.jeba_saving_account__details}>
        <span className={styles.jeba_saving_account__interest}>
          <BsGraphUpArrow /> {interest}% Interest
        </span>
        <span className={styles.jeba_saving_account__status}>
          <IoShieldCheckmark />
          Active
        </span>
      </div>
      <div className={styles.jeba_saving_account__footer}>
        <div className={styles.jeba_saving_account__Balance}>
          {" "}
          <h5>Balance</h5>
          <span>UGX{thousandSeparator(accountBalance)}</span>
        </div>
        <div className={styles.jeba_saving_account__number}>
          {" "}
          <h5>Account Number</h5>
          <span>{accountNumber}</span>
        </div>
      </div>
    </div>
  );
}

export default SavingAccountCard;
