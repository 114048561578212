import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { CgProfile } from "react-icons/cg";

import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import styles from "../Group.module.css";
import GroupCard from "../../../components/Group/GroupCard/GroupCard";
import { DownArrowIcon, GridIcon, ListIcon } from "../../../assets/icons";
import CreateVsla from "../../../components/Forms/CreateVsla";
import { useGetGroupsQuery } from "../../../store/api/requests/GET/groupsApiSlice";
import { FilterAddNew } from "../../../components/Filters";
import { contentHandler } from "../../../helperFunctions/helpersFunctions";

// eslint-disable-next-line react/prop-types
function Vsla() {
  const { data, error, isError, isFetching } = useGetGroupsQuery();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const attachedVslas = data?.vslas;
  const numberOfVslas = attachedVslas?.length;

  const openHandler = () => setIsOpen(true);

  if (isError && error.status === 401) {
    toast.error("Session Expired!", { toastId: "session-id" });
    navigate("/login");
  }

  return (
    <div>
      {" "}
      {isOpen ? (
        <CreateVsla onClick={() => setIsOpen(false)} />
      ) : (
        <>
          <div className={styles.group__sort}>
            <div className={styles.group__count}>
              <h2>{`${numberOfVslas || 0} Vsla(s)`}</h2>
            </div>
            <div className={styles.group__sort__button}>
              <button className={styles.sort__button} type="button">
                Sort by <DownArrowIcon />
              </button>
              <GridIcon style={{ color: "#0866be" }} />
              <ListIcon />
            </div>
          </div>
          <FilterAddNew
            searchItem="Vsla"
            buttonText="Add Vsla"
            open={openHandler}
          />
          <Row>
            {contentHandler(
              isFetching,
              attachedVslas?.length,
              "Loading Vslas...",
              "No Vslas Yet",
              attachedVslas?.map((sacco) => (
                <Col xl={6} md={6} sm={12} key={sacco.id}>
                  <GroupCard
                    icon={<CgProfile />}
                    title={sacco.name}
                    subtitle={`${sacco.total_members} participants`}
                    description="Created by Sharon, 6.9.2023"
                    link={sacco.id}
                  />
                </Col>
              )),
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default Vsla;
