import React from "react";

// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import styles from "./Modal.module.css";

function JebaModal(props) {
  // eslint-disable-next-line react/prop-types
  const { children, title, size, danger, closeButton } = props;
  return (
    <Modal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size={size || "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
    >
      <Modal.Header
        closeButton={!closeButton}
        className={danger && "bg-danger text-light"}
      >
        <Modal.Title id="contained-modal-title-vcenter" className="mt-8 ">
          <h4>{title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.jeba_modal__content}>{children}</div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default JebaModal;
