/* eslint-disable react/prop-types */
import React from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useDisburseLoanMutation } from "../../store/api/requests/POST/disburseLoanApiSlice";

function DisburseLoan({ loadId, close }) {
  const { categoryName, Id } = useParams();
  const [disburseLoan, { isLoading }] = useDisburseLoanMutation();

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await disburseLoan({
        groupType: categoryName,
        id: Id,
        loan_id: loadId,
      }).unwrap();
      close();
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form}>
        <div className={styles.form__login}>
          <button type="button" onClick={onSubmitHandler} disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Disburse Loan"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default DisburseLoan;
