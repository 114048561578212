import { apiSlice } from "../../apiSlice";

export const sendMessageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: (credentials) => ({
        url: "/user/supports",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Message"],
    }),
  }),
});

export const { useSendMessageMutation } = sendMessageApiSlice;
