import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./ProductSingle.module.css";
import Image from "../../../../../assets/images/51-TFGHQE2L._AC_SL1000_.jpg";
import { useGetProductDetailsQuery } from "../../../../../store/api/requests/GET/getProductDetailsApiSlice";
import { useAddToCartMutation } from "../../../../../store/api/requests/POST/addToCartApiSlice";
import { formatAmount } from "../../../../../helperFunctions/helpersFunctions";
import ProductCounter from "../../../../../components/Common/ProductCounter/ProductCounter";

function ProductSingle() {
  const { categoryName, Id, productId: pdtId } = useParams();

  const { data } = useGetProductDetailsQuery({
    groupType: categoryName,
    id: Id,
    productId: pdtId,
  });
  const isAdded = data?.marketplace_single_product?.in_user_cart;
  const addedQuantity =
    data?.marketplace_single_product?.quantity_in_user_cart[0]?.quantity;

  const [addToCart, { isLoading }] = useAddToCartMutation();

  const addToCartHandler = async () => {
    try {
      const productData = {
        groupType: categoryName,
        id: Id,
        product_id: pdtId,
        quantity: 1,
      };
      await addToCart(productData).unwrap();
      toast.success("Product Added Successfully");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-4">
        <img src={Image} alt="Product Name" className="w-100 rounded" />
      </div>
      <div className="col-lg-5">
        <h3>{data?.marketplace_single_product?.name}</h3>
        <span className="text-danger fs-5">
          {data?.marketplace_single_product?.available_quantity} Units Available
        </span>
        <p>{data?.marketplace_single_product?.description}</p>
        <span className={styles.product_price}>
          {formatAmount(data?.marketplace_single_product?.price)}
        </span>
        <div className="mt-4">
          {isAdded ? (
            <ProductCounter initialValue={addedQuantity} productId={pdtId} />
          ) : (
            <button
              type="button"
              onClick={addToCartHandler}
              disabled={isLoading}
              className={styles.add_to_cart}
            >
              Add to cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductSingle;
