import { apiSlice } from "../../apiSlice";

export const getLoanProductSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoanProducts: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/products`,
      providesTags: ["LoanProducts"],
    }),
  }),
});

export const { useGetLoanProductsQuery } = getLoanProductSlice;
