/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cartHandle",
  initialState: {
    products: [],
  },
  reducers: {
    updateCart: (state, { payload }) => {
      state.products = payload;
    },
  },
});
export const { updateCart } = cartSlice.actions;

export default cartSlice.reducer;
export const cartProducts = (state) => state.cart.products;
