/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { LeftArrowIcon } from "../../../assets/icons";
import styles from "./ProgressBar.module.css";

function ProgressBar() {
  return (
    <div className={styles.progressbar__wrapper}>
      <div className={styles.progressbar}>
        <CircularProgressbar value={60} text={`${60}`} />
      </div>
      <div className={styles.progressbar__details}>
        <div className={styles.progressbar__title}>SACCOs</div>
        <div className={styles.progressbar__button}>
          <LeftArrowIcon />
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
