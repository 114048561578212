import { apiSlice } from "../../apiSlice";

export const rollOverSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    rollOver: builder.mutation({
      query: (credentials) => ({
        url: `/build_credit/${credentials.product_id}/roll_overs`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidateTags: ["BuildCredit"],
    }),
  }),
});

export const { useRollOverMutation } = rollOverSlice;
