import React, { useState } from "react";

import { Outlet } from "react-router-dom";

import styles from "./Dashboard.module.css";

import Header from "../../components/Dashboard/Header/Header";

import Sidebar from "../../components/Dashboard/Sidebar/Sidebar";
import MobileSidebar from "../../components/Dashboard/Sidebar/MobileSiderbar/MobileSidebar";

function Dashboard() {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.dashboard}>
      <div>
        <Header openMenu={() => setOpen(true)} />
        <div className={styles.dashboard__sidebar}>
          <Sidebar closeMenu={() => setOpen(false)} />
          <MobileSidebar open={open} closeMenu={() => setOpen(false)} />
        </div>
      </div>

      <div className={styles.dashboard__content}>
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
