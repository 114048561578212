import React from "react";

function ScoreHistory() {
  return (
    <div>
      <h1>Score History</h1>
      <p>No Score History</p>
    </div>
  );
}

export default ScoreHistory;
