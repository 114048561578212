import { apiSlice } from "../../apiSlice";

export const createVslaSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createVsla: builder.mutation({
      query: (credentials) => ({
        url: "/vslas",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Group"],
    }),
  }),
});

export const { useCreateVslaMutation } = createVslaSlice;
