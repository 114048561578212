import { apiSlice } from "../../apiSlice";

export const getAllCashRoundSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCashRound: builder.query({
      query: () => "/cashrounds",
      providesTags: ["Group"],
    }),
  }),
});

export const { useGetAllCashRoundQuery } = getAllCashRoundSlice;
