import React from "react";
import { useParams } from "react-router-dom";

import Card from "../../../../../../components/Dashboard/Card/Card";
import { useGetTotalSavingQuery } from "../../../../../../store/api/requests/GET/getTotalSavingApiSlice";
import { thousandSeparator } from "../../../../../../helperFunctions/helpersFunctions";

function TotalSavings() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };
  const { data } = useGetTotalSavingQuery(group);

  return (
    <Card title="Savings" total={thousandSeparator(data?.total_savings)} />
  );
}

export default TotalSavings;
