import { apiSlice } from "../../apiSlice";

export const setWalletPinSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    setWalletPin: builder.mutation({
      query: (credentials) => ({
        url: "/wallets/set_pin",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useSetWalletPinMutation } = setWalletPinSlice;
