import { apiSlice } from "../../apiSlice";

export const createCashRoundApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCashRound: builder.mutation({
      query: (credentials) => ({
        url: "/cashrounds",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Group"],
    }),
  }),
});

export const { useCreateCashRoundMutation } = createCashRoundApiSlice;
