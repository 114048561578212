import { apiSlice } from "../../apiSlice";

export const getGroupBalanceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupBalance: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/wallet_topup`,
    }),
  }),
});

export const { useGetGroupBalanceQuery } = getGroupBalanceSlice;
