import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import CashOutCard from "../../../components/Dashboard/BuildCreditCard/CashOutCard";
import { useGetEventToCashOutQuery } from "../../../store/api/requests/GET/getEventsToCahsOutApiSlice";
import { contentHandler } from "../../../helperFunctions/helpersFunctions";
import JebaModal from "../../../components/ui/Modal/Modal";
import CashOutForm from "../../../components/Forms/CashOutForm";

function CashOut() {
  const { data, isFetching } = useGetEventToCashOutQuery();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  const content = data?.credit_to_cashout?.map((event) => (
    <Col key={event.id} className="mt-4">
      <CashOutCard
        name={event.event_name}
        days={event.credit_period}
        description={`Cash Out Amount ${event.amount_paid}`}
        fee={5000}
        onClick={() => {
          setOpen(true);
          setId(event.id);
        }}
        cashOut={event.cashed_out}
        cashOutAmount={event.amount_paid}
        amount={event.credit_amount}
      />
    </Col>
  ));

  return (
    <div>
      <h1>CashOut</h1>
      <Row xs={1} sm={1} md={2} lg={3}>
        {contentHandler(
          isFetching,
          data?.credit_to_cashout?.length,
          "Cash Out Events Loading...",
          "No Events To Cash Out",
          content,
        )}
      </Row>
      <JebaModal
        show={open}
        onHide={() => setOpen(false)}
        title="Cash Out Money"
      >
        <CashOutForm id={id} closeModal={() => setOpen(false)} />
      </JebaModal>
    </div>
  );
}

export default CashOut;
