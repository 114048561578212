import { apiSlice } from "../../apiSlice";

export const loginWithOtpApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginWithOtp: builder.mutation({
      query: (credentials) => ({
        url: "/users/otp_login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useLoginWithOtpMutation } = loginWithOtpApiSlice;
