import { apiSlice } from "../../apiSlice";

export const startCashRoundApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startCashRound: builder.mutation({
      query: (credentials) => ({
        url: `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/status`,
        method: "PATCH",
        body: { ...credentials },
      }),
      invalidatesTags: ["Draws"],
    }),
  }),
});

export const { useStartCashRoundMutation } = startCashRoundApiSlice;
