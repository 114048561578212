/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import { useTable, usePagination, useGlobalFilter } from "react-table";

import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsCalendar3, BsEye } from "react-icons/bs";
import styles from "./Table.module.css";
import { showModal } from "../../../store/slice/modalSlice";

function Table({
  data,
  columns,
  isLoading,
  noDataText,
  showActions,
  search,
  // nextPagination,
  // previousPagination,
}) {
  const dispatch = useDispatch();
  const actionsColumns = {
    Header: "Actions",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Repay</Tooltip>}
        >
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              border: "none",
              borderRadius: "50px",
              // border: "1px solid #0866be",
              backgroundColor: " #0866be",
              fontSize: "10px",
              textTransform: "capitalize",
              paddingLeft: "6px",
              paddingRight: "6px",
              paddingTop: "3px",
              paddingBottom: "3px",
            }}
            type="button"
            onClick={() => {
              dispatch(showModal({ id: row.original.id, actionType: "repay" }));
            }}
          >
            Repay
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top"> View Details</Tooltip>}
        >
          <button
            type="button"
            style={{
              border: "none",
              background: "transparent",
              color: "#0866be",
            }}
            onClick={() => {
              dispatch(showModal({ id: row.original.id, actionType: "view" }));
            }}
          >
            <BsEye />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Loan Schedule</Tooltip>}
        >
          <button
            style={{
              border: "none",
              background: "transparent",
              color: "#0866be",
            }}
            type="button"
            onClick={() => {
              dispatch(
                showModal({ id: row.original.id, actionType: "schedule" }),
              );
            }}
          >
            <BsCalendar3 />
          </button>
        </OverlayTrigger>
      </div>
    ),
  };
  const [tableColumns, setTableColumns] = useState([]);

  useEffect(() => {
    if (showActions) {
      setTableColumns([...columns, actionsColumns]);
    } else {
      setTableColumns(columns);
    }
  }, [columns]);

  const getNoDataText = () => {
    if (isLoading) {
      return "Loading data...";
    }
    return noDataText ?? "No data";
  };

  useEffect(() => {
    getNoDataText();
  }, [isLoading]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,

    setGlobalFilter,
  } = useTable(
    {
      columns: tableColumns,
      data,
      initialState: { pageSize: 5 },
    },

    useGlobalFilter,
    usePagination,
  );
  const { pageIndex } = state;

  useEffect(() => {
    setGlobalFilter(search);
  }, [search]);

  return (
    <div>
      <div className={styles.jeba__table__wrapper}>
        <table {...getTableProps} className={styles.jeba__table}>
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody
            {...getTableBodyProps}
            style={{ opacity: isLoading ? 0.5 : 1 }}
          >
            {page?.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <p style={{ textAlign: "center", marginTop: "12px" }}>
        {page?.length < 1 && getNoDataText()}
      </p>
      {pageOptions.length > 1 && (
        <div className={styles.jeba__table__navigation}>
          <button
            className={`${styles.jeba__table__navigation__button} ${styles.jeba__table__navigation__button__prev}`}
            type="button"
            disabled={!canPreviousPage}
            onClick={() => {
              // previousPagination();
              previousPage();
            }}
          >
            <MdKeyboardDoubleArrowLeft />
            Previous
          </button>
          <span style={{}}>{`${pageIndex + 1} of ${pageOptions.length}`}</span>
          <button
            className={`${styles.jeba__table__navigation__button} ${styles.jeba__table__navigation__button__next}`}
            type="button"
            disabled={!canNextPage}
            onClick={() => {
              // nextPagination();
              nextPage();
            }}
          >
            Next <MdKeyboardDoubleArrowRight />
          </button>
        </div>
      )}
    </div>
  );
}

export default Table;
