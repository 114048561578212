import React from "react";

function DownArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22.5674 6.43725C22.2698 6.13957 21.7861 6.13957 21.4884 6.43725L12 15.9442L2.49303 6.43725C2.19536 6.13957 1.71163 6.13957 1.41396 6.43725C1.11629 6.73492 1.11629 7.21864 1.41396 7.51632L11.4419 17.5442C11.5907 17.6931 11.7768 17.7675 11.9814 17.7675C12.1674 17.7675 12.3721 17.6931 12.5209 17.5442L22.5488 7.51632C22.8651 7.21864 22.8651 6.73492 22.5674 6.43725Z"
        fill="black"
      />
    </svg>
  );
}

export default DownArrow;
