import React from "react";
import { useGetBuilderHistoryQuery } from "../../../store/api/requests/GET/getBuilderHistoryApiSlice";
import Table from "../../../components/Dashboard/Table/Table";
import { BUILDER_HISTORY_COLUMNS } from "../../../components/Dashboard/Table/tableData/columns";

function BuilderHistory() {
  const { data, isFetching } = useGetBuilderHistoryQuery();

  return (
    <div>
      <h1>Builder History</h1>

      {Object?.keys(data?.credit_history || {}).length === 0 ? (
        <p>No Builder History</p>
      ) : (
        <Table
          data={data?.credit_history || []}
          columns={BUILDER_HISTORY_COLUMNS}
          isLoading={isFetching}
        />
      )}
    </div>
  );
}

export default BuilderHistory;
