/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ProgressBar, Spinner } from "react-bootstrap";
import styles from "./LoginForm.module.css";
import { useAddLoanProductMutation } from "../../store/api/requests/POST/createLoanProductApiSlice";
import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";

function AddLoanProduct({ close }) {
  const { categoryName, Id } = useParams();
  const [addLoanProduct, { isLoading }] = useAddLoanProductMutation();

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    productName: "",
    minLoanAmount: "",
    maxLoanAmount: "",
    interestRate: "",
    interestCalculatedOn: "",
    interestCharged: "",
    loanTenure: "",
    repaymentFrequency: "",
    loanPurpose: "",
    collateralRequirement: "",
    loanProcessingFee: "",
    latePaymentFee: "",
    gracePeriod: "",
    loanAvailability: "",
    eligibilityCriteria: "",
    loanProductDescription: "",
    attachment: "",
  });

  const handleNext = () => {
    setStep(step + 1);
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const fileHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setFormData({ ...formData, attachment: base64String });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        groupType: categoryName,
        id: Id,
        product_name: formData.productName,
        min_loan_amount: formData.minLoanAmount,
        max_loan_amount: formData.maxLoanAmount,
        interest_rate: formData.interestRate,
        interest_calculated_on: formData.interestCalculatedOn,
        interest_charged: formData.interestCharged,
        loan_tenure: formData.loanTenure,
        repayment_frequency: formData.repaymentFrequency,
        loan_purpose: formData.loanPurpose,
        collateral_requirement: formData.collateralRequirement === "true",
        loan_processing_fee: formData.loanProcessingFee,
        late_payment_fee: formData.latePaymentFee,
        grace_period: formData.gracePeriod,
        loan_availability: formData.loanAvailability === "true",
        eligibility_creteria: formData.eligibilityCriteria,
        loan_product_description: formData.loanProductDescription,
        attachment: formData.attachment,
      };

      await addLoanProduct(data).unwrap();
      setFormData({
        productName: "",
        minLoanAmount: "",
        maxLoanAmount: "",
        interestRate: "",
        interestCalculatedOn: "",
        interestCharged: "",
        loanTenure: "",
        repaymentFrequency: "",
        loanPurpose: "",
        collateralRequirement: "",
        loanProcessingFee: "",
        latePaymentFee: "",
        gracePeriod: "",
        loanAvailability: "",
        eligibilityCriteria: "",
        loanProductDescription: "",
        attachment: "",
      });
      toast.success("Loan product created");

      close();
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <>
      <div className={styles.wrapper} />
      <form>
        <ProgressBar now={(step / 3) * 100} className="rounded-pill" />
        {step === 1 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="productName">Product Name</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="productName"
                  name="productName"
                  placeholder="Product Name"
                  value={formData.productName}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="minLoanAmount">Min. Loan Amount</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="minLoanAmount"
                  name="minLoanAmount"
                  placeholder="Min. Loan Amount"
                  value={formData.minLoanAmount}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="maxLoanAmount">Max. Loan Amount</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="maxLoanAmount"
                  name="maxLoanAmount"
                  placeholder="Max. Loan Amount"
                  value={formData.maxLoanAmount}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="interestRate">Interest Rate</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="interestRate"
                  name="interestRate"
                  placeholder="Enter Interest Rate"
                  value={formData.interestRate}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="amount">Interest Calculated On</label>
              <div className={styles.form__input}>
                <select
                  type="text"
                  id="interestCalculatedOn"
                  name="interestCalculatedOn"
                  placeholder="Enter Interest Calculated On"
                  value={formData.interestCalculatedOn}
                  onChange={inputChangeHandler}
                >
                  <option>Select...</option>
                  <option value="Reducing Balance">Reducing Balance</option>
                  <option value="Principal">Principal</option>
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="interestCharged">Interest Charged</label>
              <div className={styles.form__input}>
                <select
                  type="text"
                  id="interestCharged"
                  name="interestCharged"
                  placeholder="Enter Interest Charged"
                  value={formData.interestCharged}
                  onChange={inputChangeHandler}
                >
                  <option>Select...</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="loanTenure">Loan Tenure</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="loanTenure"
                  name="loanTenure"
                  placeholder="Enter Loan Tenure"
                  value={formData.loanTenure}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="repaymentFrequency">Repayment Frequency</label>
              <div className={styles.form__input}>
                <select
                  type="text"
                  id="repaymentFrequency"
                  name="repaymentFrequency"
                  placeholder="Enter Repayment Frequency"
                  value={formData.repaymentFrequency}
                  onChange={inputChangeHandler}
                >
                  <option>Select...</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanPurpose">Loan Purpose</label>
              <div className={styles.form__input}>
                <textarea
                  type="text"
                  id="loanPurpose"
                  name="loanPurpose"
                  placeholder="Enter Loan Purpose"
                  value={formData.loanPurpose}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="collateralRequirement">
                Collateral Requirement
              </label>
              <div className={styles.form__input}>
                <select
                  type="text"
                  id="collateralRequirement"
                  name="collateralRequirement"
                  placeholder="Enter Collateral Requirement"
                  value={formData.collateralRequirement}
                  onChange={inputChangeHandler}
                >
                  <option>Select...</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanProcessingFee">Loan Processing Fee</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="loanProcessingFee"
                  name="loanProcessingFee"
                  placeholder="Enter Loan Processing Fee"
                  value={formData.loanProcessingFee}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="latePaymentFee">Late Payment Fee</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="latePaymentFee"
                  name="latePaymentFee"
                  placeholder="Enter Late Payment Fee"
                  value={formData.latePaymentFee}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="gracePeriod">Grace Period</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="gracePeriod"
                  name="gracePeriod"
                  placeholder="Enter Grace Period"
                  value={formData.gracePeriod}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanAvailability">Loan Availability</label>
              <div className={styles.form__input}>
                <select
                  type="text"
                  id="loanAvailability"
                  name="loanAvailability"
                  placeholder="Enter Loan Availability"
                  value={formData.loanAvailability}
                  onChange={inputChangeHandler}
                >
                  <option>Select...</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="eligibilityCriteria">Eligibility Criteria</label>
              <div className={styles.form__input}>
                <select
                  type="text"
                  id="eligibilityCriteria"
                  name="eligibilityCriteria"
                  placeholder="Enter Eligibility Criteria"
                  value={formData.eligibilityCriteria}
                  onChange={inputChangeHandler}
                >
                  {" "}
                  <option>Select...</option>
                  <option value="members">Members</option>
                  <option value="clients">Clients</option>
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanProductDescription">
                Loan Product Description
              </label>
              <div className={styles.form__input}>
                <textarea
                  type="text"
                  id="loanProductDescription"
                  name="loanProductDescription"
                  placeholder="Enter Loan Product Description"
                  value={formData.loanProductDescription}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="attachment">Attachment</label>
              <div className={styles.form__input}>
                <input
                  type="file"
                  id="attachment"
                  name="attachment"
                  placeholder="Enter Attachment"
                  // value={formData.attachment}
                  onChange={fileHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </>
        )}
        <div className="d-flex justify-content-between">
          {step > 1 && (
            <button
              type="button"
              //   variant="secondary"
              onClick={handlePrevious}
              className=" btn btn-secondary rounded-pill fs-4 h-base px-4"
              disabled={isLoading}
            >
              Previous
            </button>
          )}
          {step < 3 ? (
            <button
              type="button"
              //   variant="primary"
              onClick={handleNext}
              className=" btn btn-primary rounded-pill fs-4 h-base px-4"
              disabled={isLoading}
            >
              Next
            </button>
          ) : (
            <button
              //   variant="primary"
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary rounded-pill fs-4 h-base px-4"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Add Loan Product"
              )}
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default AddLoanProduct;
