import { apiSlice } from "../../apiSlice";

export const transferToMobileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    transferToMobile: builder.mutation({
      query: (credentials) => ({
        url: "/user/mobile_money_transfers",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Balance"],
    }),
  }),
});

export const { useTransferToMobileMutation } = transferToMobileSlice;
