/* eslint-disable no-nested-ternary */
import React from "react";
import { useGetPaymentHistoryQuery } from "../../../store/api/requests/GET/getPaymentHistoryApiSlice";
import { useGetBuildCreditSubscriptionsQuery } from "../../../store/api/requests/GET/getUserBuildCreditSubscriptionsApiSlice";
import Table from "../../../components/Dashboard/Table/Table";
import { PAYMENT_HISTORY_COLUMNS } from "../../../components/Dashboard/Table/tableData/columns";

function PaymentHistory() {
  const { data } = useGetBuildCreditSubscriptionsQuery();
  const { data: paymentHistory, isFetching } = useGetPaymentHistoryQuery({
    id: data?.credit_to_build?.id,
  });

  return (
    <div>
      {" "}
      <h1>Payment History</h1>
      {isFetching ? (
        <p>Loading data..</p>
      ) : Object?.keys(paymentHistory?.payments || {}).length === 0 ? (
        <p>No Payments History</p>
      ) : (
        <Table
          data={paymentHistory?.payments || []}
          columns={PAYMENT_HISTORY_COLUMNS}
          isLoading={isFetching}
        />
      )}
    </div>
  );
}

export default PaymentHistory;
