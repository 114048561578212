import { apiSlice } from "../../apiSlice";

export const addSaving = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSaving: builder.mutation({
      query: (credentials) => ({
        url: `/${credentials.groupType}s/${credentials?.id}/savings`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Financial", "Saving"],
    }),
  }),
});

export const { useAddSavingMutation } = addSaving;
