/* eslint-disable camelcase */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";

import styles from "./LoginForm.module.css";
import { EmailIcon, PasswordIcon, PasswordHideIcon } from "../../assets/icons";
import { useLoginMutation } from "../../store/api/requests/POST/authApiSlice";
import { setCredentials } from "../../store/slice/authSlice";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

// eslint-disable-next-line react/prop-types
function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const isFormFilled = () => {
    return Object.values(inputValues).some((value) => value === "");
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (inputValues.email === "") {
      setErrors({ email: "Email is required" });
    }
    if (inputValues.password === "") {
      setErrors({ password: "Password is required" });
    }

    try {
      const userData = {
        email: inputValues.email,
        pass_word: inputValues.password,
      };
      const response = await login(userData).unwrap();

      if (response.success) {
        dispatch(
          setCredentials({
            accessToken: response?.user_access_token,
            refreshToken: response?.user_refresh_token,
          }),
        );
        toast.success("Logged in successfully");
        navigate("/dashboard/user");
      }
    } catch (err) {
      toast.error(err.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <h2>Log In</h2>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <h3 className={styles.input__error__container}>{errors.serverError}</h3>
        <div className={styles.form__control}>
          <label htmlFor="email">Email or Phone Number</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <EmailIcon />
            </div>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Enter your email or phone number"
              onChange={inputChangeHandler}
              className={errors.email ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {errors.email && errors.email}
          </span>
        </div>
        <div className={styles.form__control}>
          <label htmlFor="password">Password</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <PasswordIcon />
            </div>
            <div className={styles.password__show}>
              {" "}
              <PasswordHideIcon onClick={setShowPassword} />
            </div>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Enter your password"
              onChange={inputChangeHandler}
              className={errors.password ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {errors.password && errors.password}
          </span>
        </div>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading || isFormFilled()}>
            {isLoading ? <LoadingSpinner /> : "Login"}
          </button>
          <span>
            {" "}
            <Link to="/forgot-password" style={{ textDecoration: "none" }}>
              Forgot Password
            </Link>
          </span>
        </div>
        <h3 className={styles.form__separator}>OR</h3>
        <p className={styles.form__separator}>Sign in with</p>
        <div className={styles.form__login__google}>
          <button type="button" onClick={() => navigate("/generate-login-otp")}>
            Email OTP
          </button>
          <button
            type="button"
            onClick={() => navigate("/generate-login-sms-otp")}
          >
            SMS OTP
          </button>
        </div>
        <div className={styles.form__register}>
          <span>Don&apos;t have an account?</span>
          <span
            className={styles.signup}
            role="button"
            tabIndex={-42}
            onClick={() => navigate("/register")}
          >
            {" "}
            SignUp
          </span>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
