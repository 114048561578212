/* eslint-disable react/prop-types */
import React from "react";

import { BsPlusLg } from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
import styles from "./FilterAddNew.module.css";
import RefreshLoader from "../../Common/RefreshLoader";

function FilterAddNew({
  searchItem,
  onChange,
  buttonText,
  open,
  hideButton = true,
  refresh,
  onRefresh,
  searchTerm,
}) {
  return (
    <div className={styles.filters}>
      <div className={styles.filters__search__refresh}>
        <input
          type="search"
          placeholder={`Search ${searchItem}`}
          className={styles.filters__search}
          onChange={onChange}
          value={searchTerm}
        />
        <button
          type="button"
          className={styles.filters__refresh__button}
          onClick={refresh}
        >
          {onRefresh ? <RefreshLoader color="#fff" /> : <IoIosRefresh />}
        </button>
      </div>
      {hideButton && (
        <div className={styles.filters__add__new}>
          <button
            className={styles.filters__add__new__button}
            type="button"
            onClick={open}
          >
            {" "}
            <BsPlusLg />
          </button>
          <span> {buttonText}</span>
        </div>
      )}
    </div>
  );
}

export default FilterAddNew;
