/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FaBox, FaBoxOpen } from "react-icons/fa";
import { toast } from "react-toastify";
import styles from "../CashRound.module.css";
import { useGetCashRoundDrawsQuery } from "../../../../../store/api/requests/GET/getCashRoundDrawsApiSlice";
import { usePickDrawMutation } from "../../../../../store/api/requests/POST/pickDrawApiSlice";
import LoadingEllipse from "../../../../../components/ui/Loaders/LoadingEllipse";

function PickDrawa({ close }) {
  const { categoryName, Id } = useParams();
  const [drawId, setDrawId] = useState(null);

  const { data } = useGetCashRoundDrawsQuery({
    group: categoryName,
    groupId: Id,
  });
  const [pickDraw, { isLoading }] = usePickDrawMutation();

  const draws = data?.cashround_draws;

  const drawHandler = async (id) => {
    try {
      await pickDraw({ draw_id: id, groupType: categoryName, id: Id }).unwrap();
      close();
    } catch (error) {
      toast.error(error?.data?.message || "An error occurred");
    }
  };
  return (
    <div className="d-flex justify-content-center my-2">
      <Row md={4} lg={4} sm={4} className="g-4 m-0">
        {draws?.map((draw) => (
          <Col key={draw.id}>
            <button
              type="button"
              className={styles.random_number}
              onClick={() => {
                setDrawId(draw.id);
                drawHandler(draw.id);
              }}
              key={draw.id}
            >
              {draw?.selected_status ? (
                <p className={styles.open_box}>
                  <span>{draw.draw_no}</span>
                  <FaBoxOpen className={styles.icon} />
                </p>
              ) : isLoading && drawId === draw.id ? (
                <LoadingEllipse />
              ) : (
                <FaBox className={styles.icon} />
              )}
            </button>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default PickDrawa;
