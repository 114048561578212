import { apiSlice } from "../../apiSlice";

export const transferSharesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    transferShares: builder.mutation({
      query: (credentials) => ({
        url: `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/transfer_shares`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Shares", "ShareTransfers"],
    }),
  }),
});

export const { useTransferSharesMutation } = transferSharesSlice;
