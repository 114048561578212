/* eslint-disable react/prop-types */

import { MdCancel } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import OrderActions from "../ActionTemplates/OrderActions";

import OrderStatus from "../ActionTemplates/OrderStatus";
import { selectRole } from "../../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../../constants/constants";

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "#FFC724";
    case "successful":
      return "#30E584";
    case "failed":
      return "#D93B3B";
    default:
      return "#30E584";
  }
};

const thousandSeparator = (amount) => {
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const COLUMNS = [
  {
    Header: "Transaction ID",
    accessor: "transaction_id",
  },
  { Header: "Name", accessor: "name" },
  { Header: "Category", accessor: "category" },
  { Header: "Amount", accessor: "amount" },
  { Header: "Currency", accessor: "currency" },
  { Header: "Date", accessor: "date" },
  { Header: "Time", accessor: "time" },
  {
    Header: "Status",
    accessor: "status",

    Cell: ({ value }) => (
      <div
        style={{
          width: "100px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: getStatusColor(value),
          color: "#E8EBF2",
          fontFamily: "Gilroy-Bold",
          fontSize: "1.4rem",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {value}
      </div>
    ),
  },
];

export const LOAN_COLUMNS = [
  { Header: "Amount", accessor: "loan_amount_approved" },
  { Header: "Interest Rate", accessor: "interest_rate" },
  { Header: "Balance", accessor: "loan_amount_outstanding" },
  { Header: "Amount Paid", accessor: "loan_amount_repaid" },
  {
    Header: "Maturity Date",
    accessor: "loan_maturity_date",
    Cell: () => {
      const date = new Date("Sun, 21 Jan 2024 15:40:01 GMT");
      return <p>{date.toLocaleDateString()}</p>;
    },
  },
  {
    Header: "Next Pay Date",
    accessor: "next_pay_date",
    Cell: ({ value }) => {
      const date = new Date(`${value}`);
      return <p>{date.toLocaleDateString()}</p>;
    },
  },
];

export const LOAN_SCHEDULE_COLUMNS = [
  { Header: "Amount Paid", accessor: "Amount Paid" },
  { Header: "Interest", accessor: "Interest" },
  { Header: "Principal", accessor: "Principal" },
  { Header: "Frequency", accessor: "Reepayment Frequency" },
  { Header: "Balance", accessor: "Remaining Balanace" },
  {
    Header: "Date",
    accessor: "Repayment Date",
    Cell: ({ value }) => {
      const date = new Date(`${value}`);
      return <p>{date.toLocaleDateString()}</p>;
    },
  },
  { Header: "Total", accessor: "Total Payment" },
];

export const BORROWERS_COLUMNS = [
  { Header: "Name", accessor: "borrower_name" },
  { Header: "Amount Paid", accessor: "amount_paid" },
  {
    Header: "Start Date",
    accessor: "borrowing_date",
    Cell: ({ value }) => {
      const date = new Date(`${value}`);
      return <p>{date.toLocaleDateString()}</p>;
    },
  },
  {
    Header: "End Date",
    accessor: "maturity_date",
    Cell: ({ value }) => {
      const date = new Date(`${value}`);
      return <p>{date.toLocaleDateString()}</p>;
    },
  },
  { Header: "Balance", accessor: "balance" },
  { Header: "Loan Amount", accessor: "loan_amount" },
];

export const INCOME_COLUMNS = [
  { Header: "Project Name", accessor: "activity" },
  { Header: "Investment Amount", accessor: "ivestment_amount" },
  { Header: "Income Amount", accessor: "income_amount" },
  { Header: "Added By", accessor: "added_by_name" },
  { Header: "Date", accessor: "income_date" },
];

export const EXPENSE_COLUMNS = [
  { Header: "Project Name", accessor: "activity" },
  { Header: "Expense Amount", accessor: "expense_amount" },
  { Header: "Added By", accessor: "added_by_name" },
  { Header: "Date", accessor: "expense_date" },
];

export const TRANSACTION_COLUMNS = [
  {
    Header: "Account Balance",
    accessor: "account_balance",
    Cell: ({ value }) => {
      return `${thousandSeparator(value)}.00`;
    },
  },
  {
    Header: "Transaction Amount",
    accessor: "transaction_amount",
    Cell: ({ value }) => {
      return `${thousandSeparator(value)}.00`;
    },
  },
  {
    Header: "Date",
    accessor: "transaction_date",
    Cell: ({ value }) => {
      const date = new Date(`${value}`);
      return <p>{date.toLocaleDateString()}</p>;
    },
  },
  { Header: "Transaction Type", accessor: "transaction_type" },
  {
    Header: "Status",
    accessor: "transaction_status",
    Cell: ({ value }) => (
      <div
        style={{
          width: "100px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: getStatusColor(value),
          color: "#E8EBF2",
          fontFamily: "Gilroy-Bold",
          fontSize: "1.4rem",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {value}
      </div>
    ),
  },
];

export const GROUP_TRANSACTION_COLUMNS = [
  { Header: "Transfer Amount", accessor: "transaction_amount" },
  {
    Header: "Date",
    accessor: "transaction_date",
    Cell: ({ value }) => {
      const date = new Date(`${value}`);
      return <p>{date.toLocaleDateString()}</p>;
    },
  },
  { Header: "Type", accessor: "transaction_type" },
  { Header: "Description", accessor: "transaction_description" },
  {
    Header: "Status",
    accessor: "transaction_status",
    Cell: ({ value }) => (
      <div
        style={{
          width: "100px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: getStatusColor(value),
          color: "#E8EBF2",
          fontFamily: "Gilroy-Bold",
          fontSize: "1.4rem",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {value}
      </div>
    ),
  },
];

export const GROUP_SAVING_TRANSACTION_COLUMNS = [
  { Header: "Saved Amount", accessor: "transaction_amount" },
  {
    Header: "Date",
    accessor: "transaction_date",
    Cell: ({ value }) => {
      const date = new Date(`${value}`);
      return <p>{date.toLocaleDateString()}</p>;
    },
  },
  { Header: "Total", accessor: "account_balance" },
  { Header: "Description", accessor: "transaction_description" },
  {
    Header: "Status",
    accessor: "transaction_status",
    Cell: ({ value }) => (
      <div
        style={{
          width: "100px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: getStatusColor(value),
          color: "#E8EBF2",
          fontFamily: "Gilroy-Bold",
          fontSize: "1.4rem",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {value}
      </div>
    ),
  },
];

export const GROUP_SAVING_PRODUCT_COLUMNS = [
  { Header: "Name", accessor: "savings_product_name" },
  {
    Header: "Interest Rate",
    accessor: "savings_product_interest_rate",
  },
  { Header: "Min. Balance", accessor: "savings_product_minimum_balance" },
  { Header: "Withdrawal Limit", accessor: "savings_product_withdrawal_limit" },
  { Header: "Description", accessor: "savings_product_description" },
  {
    Header: "Status",
    accessor: "savings_product_status",
    Cell: ({ value }) => (
      <div
        style={{
          width: "100px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: getStatusColor(value),
          color: "#E8EBF2",
          fontFamily: "Gilroy-Bold",
          fontSize: "1.4rem",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {value}
      </div>
    ),
  },
];

export const GROUP_CONTRIBUTION_PRODUCT_COLUMNS = [
  { Header: "Name", accessor: "contributions_product_name" },
  {
    Header: "Interest Rate",
    accessor: "contributions_product_interest_rate",
  },
  { Header: "Min. Balance", accessor: "contributions_product_minimum_balance" },
  {
    Header: "Withdrawal Limit",
    accessor: "contributions_product_withdrawal_limit",
  },
  { Header: "Description", accessor: "contributions_product_description" },
  {
    Header: "Status",
    accessor: "contributions_product_status",
    Cell: ({ value }) => (
      <div
        style={{
          width: "100px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: getStatusColor(value),
          color: "#E8EBF2",
          fontFamily: "Gilroy-Bold",
          fontSize: "1.4rem",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {value}
      </div>
    ),
  },
];

export const BUILD_CREDIT_SCHEDULE_COLUMNS = [
  {
    Header: "Day",
    accessor: "Day",
    Cell: ({ row, value }) => {
      return (
        <>
          {row.original.Today_Scehdule && (
            <span
              style={{
                color: "#103971",
                fontSize: "12px",
                fontWeight: "bold",
                display: "block",
                textAlign: "center",
              }}
            >
              Today
            </span>
          )}
          <p
            style={{
              color: row.original.Today_Scehdule && "white",
              width: row.original.Today_Scehdule && "50px",
              height: row.original.Today_Scehdule && "50px",
              fontWeight: row.original.Today_Scehdule && "bold",
              borderRadius: "50px",
              backgroundColor: row.original.Today_Scehdule && "#103971",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Day {value}
          </p>
        </>
      );
    },
  },
  {
    Header: "Installment Payment Amount",
    accessor: "Installment Payment Amount",
  },
  {
    Header: "Installment Payment Frequency",
    accessor: "Installment Payment Frequency",
  },
  {
    Header: "Next Pay Date",
    accessor: "Next Pay Date",
  },
  {
    Header: "Paid Amount",
    accessor: "Paid Amount",
  },
  {
    Header: "Penalty",
    accessor: "Penalty",
  },
  {
    Header: "Remaining Balance",
    accessor: "Remaining Balance",
  },
  {
    Header: "Fully Paid?",
    accessor: "Full Payment Status",
    Cell: ({ value }) => {
      return (
        <span
          style={{
            color: "#103971",
            fontSize: "12px",
            fontWeight: "bold",
            display: "block",
            textAlign: "center",
          }}
        >
          {value ? (
            <FaCircleCheck
              style={{
                color: "rgb(48, 229, 132)",
                width: "20px",
                height: "20px",
              }}
            />
          ) : (
            <MdCancel style={{ color: "red", width: "24px", height: "24px" }} />
          )}
        </span>
      );
    },
  },
];

export const ORDER_COLUMNS = [
  { Header: "ID", accessor: "order_id" },
  { Header: "Customer Name", accessor: "customer_name" },
  { Header: "Phone Number", accessor: "customer_phone" },
  { Header: "Date", accessor: "order_date" },
  { Header: "Cost", accessor: "total_cost" },
  { Header: "Payment Method", accessor: "payment_method" },

  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row, value }) => {
      const user = useSelector(selectRole);
      const isAdmin = user === USER_ROLES.S_ADMIN;
      return (
        <div style={{ width: "90%" }}>
          {!isAdmin ? (
            <div
              style={{
                width: "100%",
                height: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                background: getStatusColor(value),
                color: "#fff",
                fontFamily: "Gilroy-Bold",
                fontSize: "1.4rem",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
            >
              {value}
            </div>
          ) : (
            <OrderStatus rowId={row.original.id} initialValue={value} />
          )}
        </div>
      );
    },
  },
  {
    Header: "Actions",
    Cell: ({ row }) => (
      <div>
        <OrderActions id={row.original.id} />
      </div>
    ),
  },
];

export const PAYMENT_HISTORY_COLUMNS = [
  { Header: "Amount Paid", accessor: "amount_paid" },
  { Header: "Date", accessor: "payment_date" },
  { Header: "Penalty Fee", accessor: "penalty_fee" },
  {
    Header: "Status",
    accessor: "status",
    Cell: () => (
      <div
        style={{
          width: "100%",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: getStatusColor("completed"),
          color: "#fff",
          fontFamily: "Gilroy-Bold",
          fontSize: "1.4rem",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        success
      </div>
    ),
  },
];

export const ORDER_PRODUCT_COLUMNS = [
  { Header: "Name", accessor: "name" },
  { Header: "Quantity", accessor: "quantity" },
  { Header: "Price", accessor: "price" },
  { Header: "Discount(%)", accessor: "discount" },
  { Header: "Total", accessor: "cost" },
];

export const BUILDER_HISTORY_COLUMNS = [
  { Header: "Amount", accessor: "credit_amount" },
  { Header: "Balance", accessor: "amount_outstanding" },
  { Header: "Paid", accessor: "amount_paid" },
  { Header: "Installments", accessor: "number_of_installments" },
  { Header: "Amount/Installment", accessor: "amount_per_installment" },
  { Header: "Period", accessor: "credit_period" },
  { Header: "Frequency", accessor: "repayment_frequency" },
  { Header: "Missed Payments", accessor: "no_missed_payments" },
  { Header: "Credit Status", accessor: "credit_status" },
];

// amount: 12000;
// attachment: "";
// cashin_date: "Fri, 10 May 2024 00:00:00 GMT";
// category: "income";
// date: "Fri, 10 May 2024 00:00:00 GMT";
// date_created: "Wed, 08 May 2024 18:26:42 GMT";
// id: "caabbddcae4874e513099aee94cc9c";
// last_updated: null;
// source: "Piggery Projects";
export const MONEY_COLUMNS = [
  { Header: "Source/Activity", accessor: "name" },
  { Header: "Amount", accessor: "amount" },
  { Header: "Date", accessor: "date" },
  { Header: "Category", accessor: "category" },
];

export const TEST_COLUMNS = [
  {
    Header: "Transaction ID",
    accessor: "transaction_id",
  },
  { Header: "Name", accessor: "name" },
  { Header: "Category", accessor: "category" },
  { Header: "Amount", accessor: "amount" },
  { Header: "Currency", accessor: "currency" },
  { Header: "Date", accessor: "date" },
  { Header: "Time", accessor: "time" },
  {
    Header: "Status",
    accessor: "status",

    Cell: ({ value }) => (
      <div
        style={{
          width: "100px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: getStatusColor(value),
          color: "#E8EBF2",
          fontFamily: "Gilroy-Bold",
          fontSize: "1.4rem",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {value}
      </div>
    ),
  },
];
