/* eslint-disable react/prop-types */
import React from "react";
import DisburseLoan from "../../Forms/DisburseLoan";
import { capitalizeHandler } from "../../../helperFunctions/helpersFunctions";

function LoanDetails({ data, close }) {
  return (
    <div style={{ maxHeight: "520px", overflow: "auto" }}>
      <table>
        <tbody>
          {Object.keys(data).map((key) => (
            <tr key={Math.random()}>
              <td>
                <p>{capitalizeHandler(key)}</p>
              </td>
              <td>&nbsp;</td>
              <td>
                <p>
                  <strong>{data[key]}</strong>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <DisburseLoan loadId={data.id} close={close} />
    </div>
  );
}

export default LoanDetails;
