import { apiSlice } from "../../apiSlice";

export const sendLoginOtpSmsAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendLoginOtpSms: builder.mutation({
      query: (credentials) => ({
        url: "/users/generate_sms_login_otp",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useSendLoginOtpSmsMutation } = sendLoginOtpSmsAPiSlice;
