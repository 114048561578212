import { apiSlice } from "../../apiSlice";

export const getCashRoundSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCashRound: builder.query({
      query: (urlParameters) =>
        `/${
          urlParameters.group === "club"
            ? "investment_club"
            : urlParameters.group
        }s/${urlParameters.groupId}`,
    }),
  }),
});

export const { useGetCashRoundQuery } = getCashRoundSlice;
