import { apiSlice } from "../../apiSlice";

export const addProductSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addProduct: builder.mutation({
      query: (credentials) => ({
        url: `/${credentials.groupType}s/${credentials?.id}/marketplace/products`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});

export const { useAddProductMutation } = addProductSlice;
