import { apiSlice } from "../../apiSlice";

export const getBuildCreditProductSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuildCreditProduct: builder.query({
      query: (urlParameters) => `/build_credit/products/${urlParameters.id}`,
    }),
  }),
});

export const { useGetBuildCreditProductQuery } = getBuildCreditProductSlice;
