/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useCreateSaccoMutation } from "../../store/api/requests/POST/createSaccoApiSlice";
import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";

function CreateSacco({ close }) {
  const [createSacco, { isLoading }] = useCreateSaccoMutation();
  const [errors] = useState({});

  const [inputValues, setInputValues] = useState({
    saccoName: "",
    saccoPhoneNumber: "",
    saccoEmail: "",
    saccoRegistrationNumber: "",
    saccoDistrict: "",
    saccoRegCertificate: "",
    saccoNumberOfMembers: "",
    saccoDescription: "",
    saccoAddress: "",
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };
  const fileHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setInputValues({ ...inputValues, saccoRegCertificate: base64String });
  };
  const onSubmitHandler = async (event) => {
    event.preventDefault();

    const saccoData = {
      sacco_name: inputValues.saccoName,
      sacco_phone_number: inputValues.saccoPhoneNumber,
      sacco_email_address: inputValues.saccoEmail,
      sacco_reg_no: inputValues.saccoRegistrationNumber,
      sacco_district: inputValues.saccoDistrict,
      sacco_reg_certificate: inputValues.saccoRegCertificate,
      sacco_number_of_members: +inputValues.saccoNumberOfMembers,
      sacco_description: inputValues.saccoDescription,
      sacco_address: inputValues.saccoAddress,
    };

    try {
      const response = await createSacco(saccoData).unwrap();
      if (response?.status === 201) {
        close();
      }

      toast.success(`${response?.sacco_data.name} is created successfully`);
      window.scrollTo(0, 0);
      setInputValues({
        saccoName: "",
        saccoPhoneNumber: "",
        saccoEmail: "",
        saccoRegistrationNumber: "",
        saccoDistrict: "",
        saccoRegCertificate: "",
        saccoNumberOfMembers: "",
        saccoDescription: "",
        saccoAddress: "",
      });
    } catch (err) {
      toast.error(err.data.message);
    }
  };

  const isFormFilled =
    !inputValues.saccoName ||
    !inputValues.saccoPhoneNumber ||
    !inputValues.saccoEmail ||
    !inputValues.saccoRegistrationNumber ||
    !inputValues.saccoDistrict ||
    !inputValues.saccoRegCertificate ||
    !inputValues.saccoNumberOfMembers ||
    !inputValues.saccoDescription ||
    !inputValues.saccoAddress;

  return (
    <>
      {" "}
      <div className={styles.form__wrapper}>
        <form className={styles.form}>
          <Row sm={1} xs={1} lg={2}>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="saccoName">Name</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoName"
                    name="saccoName"
                    placeholder="Enter Sacco name"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoName}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoPhoneNumber">Phone Number</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoPhoneNumber"
                    name="saccoPhoneNumber"
                    placeholder="Enter Sacco phone number"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoPhoneNumber}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoEmail">Email</label>
                <div className={styles.form__input}>
                  <input
                    type="email"
                    id="saccoEmail"
                    name="saccoEmail"
                    placeholder="Enter sacco email"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoEmail}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoRegistrationNumber">
                  Registration Number
                </label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoRegistrationNumber"
                    name="saccoRegistrationNumber"
                    placeholder="Enter sacco registration number"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoRegistrationNumber}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoDistrict">District</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoDistrict"
                    name="saccoDistrict"
                    placeholder="Enter district"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoDistrict}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
            </Col>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="saccoRegCertificate">
                  Certificate of Registration
                </label>
                <div className={styles.form__input}>
                  <input
                    type="file"
                    id="saccoRegCertificate"
                    name="saccoRegCertificate"
                    placeholder="Upload document-only pdf format"
                    onChange={fileHandler}
                    // value={inputValues.saccoRegCertificate}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoNumberOfMembers">Number of Members</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoNumberOfMembers"
                    name="saccoNumberOfMembers"
                    placeholder="Enter number of members"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoNumberOfMembers}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoDescription">Description</label>
                <div className={styles.form__input}>
                  <textarea
                    type="text"
                    id="saccoDescription"
                    name="saccoDescription"
                    placeholder="Enter sacco description"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoDescription}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoAddress">Address</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoAddress"
                    name="saccoAddress"
                    placeholder="Enter sacco address"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoAddress}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
            </Col>
          </Row>
          <div className={styles.form__login}>
            <button
              type="button"
              onClick={onSubmitHandler}
              disabled={isFormFilled || isLoading}
            >
              {isLoading ? <LoadingSpinner /> : "Create Sacco"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateSacco;
