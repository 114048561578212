import { apiSlice } from "../../apiSlice";

export const changeProductQuantitySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changeProductQuantity: builder.mutation({
      query: (credentials) => ({
        url: `/${credentials.groupType}s/${credentials?.id}/marketplace/cart/${credentials?.action}`,
        method: "PATCH",
        body: { ...credentials },
      }),
      invalidatesTags: ["Cart", "Product"],
    }),
  }),
});

export const { useChangeProductQuantityMutation } = changeProductQuantitySlice;
