import React from "react";

function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M22.33 6.08998C22.33 4.97348 21.4165 4.05998 20.3 4.05998H4.06003C2.94353 4.05998 2.03003 4.97348 2.03003 6.08998V18.27C2.03003 19.3865 2.94353 20.3 4.06003 20.3H20.3C21.4165 20.3 22.33 19.3865 22.33 18.27V6.08998ZM20.3 6.08998L12.18 11.165L4.06003 6.08998H20.3ZM20.3 18.27H4.06003V8.11998L12.18 13.195L20.3 8.11998V18.27Z"
        fill="#8F8CA8"
      />
    </svg>
  );
}

export default Email;
