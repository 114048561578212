import React from "react";
import { useParams } from "react-router-dom";
// import styles from "./CashRound.module.css";
import "./style.css";
import { useGetCashRoundRoundsQuery } from "../../../../store/api/requests/GET/getCashRoundRoundsApiSlice";

function Rounds() {
  const { categoryName, Id } = useParams();
  const { data } = useGetCashRoundRoundsQuery({
    group: categoryName,
    groupId: Id,
  });

  const rounds = data?.cashround_member_rounds
    .slice()
    .sort((a, b) => a.draw_no - b.draw_no);

  const items = rounds?.map((round, index) => {
    return {
      ...round,
      color: index % 2 === 0 ? "#0866be" : "#8ecae6",
    };
  });

  const radius = 150;

  return (
    <div className="rounds-container">
      <div className="circle-container">
        {items?.map((item, index) => {
          const angle = ((2 * Math.PI) / items.length) * index;
          const x = radius * Math.cos(angle);
          const y = radius * Math.sin(angle);
          const transform = `translate(${x}px, ${y}px)`;

          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="circle-item"
              title={item.member_name}
              style={{ backgroundColor: item.color, transform }}
              // dangerouslySetInnerHTML={{ __html: item.draw_no }}
            >
              {item.draw_no}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Rounds;
