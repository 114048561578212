// expense_reports;
import { apiSlice } from "../../apiSlice";

export const getExpenseReportsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExpenseReports: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/expense_reports`,
      providesTags: ["Expense"],
    }),
  }),
});

export const { useGetExpenseReportsQuery } = getExpenseReportsSlice;
