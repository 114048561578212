import { apiSlice } from "../../apiSlice";

export const getAllUserTransactionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUserTransactions: builder.query({
      query: () => "/user/transactions?page=1",
      providesTags: ["Transactions"],
    }),
  }),
});

export const { useGetAllUserTransactionsQuery } = getAllUserTransactionsSlice;
