import { apiSlice } from "../../apiSlice";

export const getIncomeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIncome: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/incomes`,
      providesTags: ["Income"],
    }),
  }),
});

export const { useGetIncomeQuery } = getIncomeSlice;
