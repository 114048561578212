/* eslint-disable react/prop-types */
import React from "react";
import styles from "./Button.module.css";

function Button({
  type,
  icon,
  backgroundColor,
  textColor,
  borderRadius,
  buttonText,
  onClick,
  disabled,
}) {
  return (
    <div className={styles.form__button}>
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        style={{
          background: `${backgroundColor}`,
          color: `${textColor}`,
          borderRadius: `${borderRadius}`,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && icon}
        {buttonText}
      </button>
    </div>
  );
}

export default Button;
