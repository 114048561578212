import { apiSlice } from "../../apiSlice";

export const getBuildCreditPlansSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuildCreditPlans: builder.query({
      query: () => "/build_credit/products",
    }),
  }),
});

export const { useGetBuildCreditPlansQuery } = getBuildCreditPlansSlice;
