/* eslint-disable react/prop-types */
import React from "react";

function LoanProductDetails({ loanProduct }) {
  return (
    <div style={{ height: "400px", overflow: "auto", width: "100%" }}>
      <table>
        <tbody>
          <tr>
            <td>
              <p> Interest Rate</p>
            </td>
            <td>
              <p>: </p>
            </td>
            <td>
              <p>
                <strong> {loanProduct?.interest_rate}% </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Interest Charged</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong> {loanProduct?.interest_charged} </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Grace Period</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>{loanProduct?.grace_period} </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Eligibility</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong> {loanProduct?.eligibility_creteria} </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Collateral Requirement</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>
                  {loanProduct?.collateral_requirement === true
                    ? "True"
                    : "False"}
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Interest Calculated On</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong> {loanProduct?.interest_calculated_on} </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Loan Availability</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>
                  {loanProduct?.loan_availability === true ? "True" : "False"}
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Repayment Frequency</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>{loanProduct?.repayment_frequency}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Loan Description</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>{loanProduct?.loan_product_description}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Late Payment Fee</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>{loanProduct?.late_payment_fee}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Loan Purpose</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>{loanProduct?.loan_purpose}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Loan Tenure</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>{loanProduct?.loan_tenure} </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Max. Loan Amount</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>{loanProduct?.max_loan_amount}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Min. Loan Amount</p>
            </td>
            <td>
              <p>:</p>
            </td>
            <td>
              <p>
                <strong>{loanProduct?.min_loan_amount} </strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LoanProductDetails;
