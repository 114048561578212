/* eslint-disable import/prefer-default-export */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Choose your storage engine
import { apiSlice } from "./api/apiSlice";
import authReducer from "./slice/authSlice";
import regReducer from "./slice/regSlice";
import roleReducer from "./slice/memberRoleSlice";
import modalReducer from "./slice/modalSlice";
import openReducer from "./slice/openMobileSidebarSlice";
import cartReducer from "./slice/cartSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  reg: regReducer,
  role: roleReducer,
  modal: modalReducer,
  open: openReducer,
  cart: cartReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(apiSlice.middleware),

  devTools: true, // false for production
});

export const persistor = persistStore(store);
