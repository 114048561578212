import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetLoanScheduleQuery } from "../../../store/api/requests/GET/getLoanScheduleApiSlice";
import { loanId } from "../../../store/slice/modalSlice";
import Table from "../../Dashboard/Table/Table";
import { LOAN_SCHEDULE_COLUMNS } from "../../Dashboard/Table/tableData/columns";

function LoanSchedule() {
  const { categoryName, Id } = useParams();
  const selectedLoan = useSelector(loanId);

  const { data, isFetching } = useGetLoanScheduleQuery({
    groupType: categoryName,
    id: Id,
    loanId: selectedLoan,
  });

  const loanSchedule = data?.repayment_schedule || [];

  return (
    <Table
      data={loanSchedule}
      columns={LOAN_SCHEDULE_COLUMNS}
      isLoading={isFetching}
      noDataText="Loan schedule will appear here"
    />
  );
}

export default LoanSchedule;
