import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { Offcanvas } from "react-bootstrap";
import {
  BsArrowDownUp,
  BsArrowLeftRight,
  BsCreditCard2Front,
} from "react-icons/bs";
import { CiGrid42 } from "react-icons/ci";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import {
  WalletIcon,
  HelpIcon,
  // SettingIcon,
  LogoutIcon,
  ProfileIcon,
} from "../../../../assets/icons";
import style from "./MobileSidebar.module.css";
import styles from "../Sidebar.module.css";
import ListItem from "../../ListItem/ListItem";
import {
  close,
  openSidebarMenu,
} from "../../../../store/slice/openMobileSidebarSlice";
import { useLogOutMutation } from "../../../../store/api/requests/POST/logOutApiSlice";
import { logOut as logOutUser } from "../../../../store/slice/authSlice";

function MobileSidebar() {
  const [logOut] = useLogOutMutation();
  const dispatchAction = useDispatch();
  const navigate = useNavigate();

  const onSubmitIncomeHandler = async (event) => {
    event.preventDefault();
    try {
      await logOut().unwrap();
      dispatchAction(logOutUser());
      navigate("/login");
      toast.success("You Logged Out");
    } catch (error) {
      toast.error(error);
    }
  };
  const lists = [
    {
      title: "dashboard",
      icon: <CiGrid42 />,
    },
    {
      title: "build credit",
      icon: <BsCreditCard2Front />,
    },
    {
      title: "transactions",
      icon: <BsArrowLeftRight />,
    },
    {
      title: "money tracker",
      icon: <BsArrowDownUp />,
    },
    {
      title: "my wallet",
      icon: <WalletIcon />,
    },
    {
      title: "groups",
      icon: <HiOutlineUserGroup />,
    },
    {
      title: "profile",
      icon: <ProfileIcon />,
    },
    {
      title: "help",
      icon: <HelpIcon />,
    },
    // {
    //   title: "settings",
    //   icon: <SettingIcon />,
    // },
    {
      title: "logout",
      icon: <LogoutIcon />,
      logOut: onSubmitIncomeHandler,
    },
  ];

  const openMenu = useSelector(openSidebarMenu);
  const dispatch = useDispatch();

  return (
    <Offcanvas
      show={openMenu}
      onHide={() => dispatch(close())}
      className={style.mobile}
      //   responsive="lg"
    >
      <Offcanvas.Header>
        <Offcanvas.Title className={style.sidebar_header}>
          {" "}
          <h2 className={styles.sidebar__title}>Jeba Pesa </h2>
          <button type="button" onClick={() => dispatch(close())}>
            <MdOutlineClose />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ padding: 0 }}>
        {lists.slice(0, -1).map((list) => (
          <ListItem icon={list.icon} title={list.title} key={Math.random()} />
        ))}

        <div className={styles.separator} />
        {lists.slice(-1).map((list) => (
          <ListItem
            icon={list.icon}
            title={list.title}
            key={Math.random()}
            onClick={list.logOut}
          />
        ))}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default MobileSidebar;
