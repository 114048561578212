/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useAddShareMutation } from "../../store/api/requests/POST/addShareApiSlice";

function AddShare({ close }) {
  const { categoryName, Id } = useParams();
  const [addShare, { isLoading }] = useAddShareMutation();

  const [inputValues, setInputValues] = useState({
    share: "",
    costPerShare: "",
    dividendInterest: "",
    minimumShares: "",
  });

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        available_share: inputValues.share,
        cost_per_share: inputValues.costPerShare,
        dividend_interest: inputValues.dividendInterest,
        minimum_shares_to_be_purchased: inputValues.minimumShares,
      };
      await addShare(data).unwrap();
      close();
      toast.success("Share added successfully");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <Row>
          <Col>
            <div className={styles.form__control}>
              <label htmlFor="share">Available Share</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="share"
                  name="share"
                  placeholder="Enter Available Share"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoName}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>

            <div className={styles.form__control}>
              <label htmlFor="costPerShare">Cost Per Share</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  name="costPerShare"
                  id="costPerShare"
                  placeholder="Enter Cost Per Share"
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="dividendInterest">Dividend Interest</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  name="dividendInterest"
                  id="dividendInterest"
                  placeholder="Enter Dividend Interest"
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="minimumShares">Minimum Shares</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  name="minimumShares"
                  id="minimumShares"
                  placeholder="Enter Minimum Shares"
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </Col>
        </Row>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Add Share"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddShare;
