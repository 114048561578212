import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import styles from "./SavingsSection.module.css";

import { useGetContributionsQuery } from "../../../../../store/api/requests/GET/getContributionsApiSlice";
import ContributeCard from "../../../../../components/Group/ContributeCard/ContributeCard";

function ContributionsSection({ refresh, setLoading }) {
  const { categoryName, Id } = useParams();

  const {
    data: contributions,
    isFetching,
    refetch,
  } = useGetContributionsQuery({
    groupType: categoryName,
    id: Id,
  });

  useEffect(() => {
    setLoading(isFetching);
    refetch();
    return () => {
      setLoading(false);
    };
  }, [refresh]);

  useEffect(() => {
    if (isFetching) {
      setLoading(isFetching);
    } else {
      setLoading(false);
    }
  }, [isFetching]);

  const renderedContent = () => {
    if (isFetching) {
      return <p>Loading Contribution Accounts...</p>;
    }
    if (contributions?.contributions_accounts.length < 1) {
      return <p>No Contributions Yet, Please Add Contribution</p>;
    }
    return (
      <div className={styles.saving_account_section}>
        {contributions?.contributions_accounts.map((account) => (
          <ContributeCard key={account.id} data={account} />
        ))}
      </div>
    );
  };

  return renderedContent();
}

export default ContributionsSection;
