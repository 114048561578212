/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoKey } from "react-icons/go";
import styles from "./LoginForm.module.css";

import { useVerifyMutation } from "../../store/api/requests/POST/verifyApiSlice";
import { selectUserDetails } from "../../store/slice/regSlice";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

function Verify() {
  const navigate = useNavigate();
  const [verify, { isLoading }] = useVerifyMutation();
  const { email } = useSelector(selectUserDetails);

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  useEffect(() => {
    setCodeError("");
  }, [code]);

  const codeChangeHandler = (e) => {
    setCode(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (code.length < 5) {
      setCodeError("Verification code is incorrect");
    }

    if (code.length < 1) {
      setCodeError("Verification code is required");
    }

    try {
      const userData = {
        user_email: email,
        verification_code: code,
      };
      const response = await verify(userData).unwrap();
      toast.success(`${response.message}, you can now login`);
      navigate("/login");
    } catch (err) {
      toast.error();
      toast.error(err.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <h2> Please verify your account</h2>
      <p>
        {" "}
        Enter a five digit code sent to <strong>{email}</strong> to verify your
        Jeba account.
      </p>
      <form className={styles.form}>
        <div className={styles.form__control}>
          <label htmlFor="email">Code</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <GoKey />
            </div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter code "
              onChange={codeChangeHandler}
              className={codeError ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {codeError && codeError}
          </span>
        </div>
        <div className={styles.form__login}>
          <button type="button" onClick={onSubmitHandler}>
            {isLoading ? <LoadingSpinner /> : "Verify"}
          </button>
          <span>Resend OTP</span>
        </div>
      </form>
    </div>
  );
}

export default Verify;
