import { apiSlice } from "../../apiSlice";

export const approveLoanSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    approveLoan: builder.mutation({
      query: (credentials) => ({
        url: `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/approve_loan_application`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["LoanApplications"],
    }),
  }),
});

export const { useApproveLoanMutation } = approveLoanSlice;
