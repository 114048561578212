import React from "react";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import MessageItem from "./MessageItem/MessageItem";
import ChatForm from "./ChatForm/ChatForm";
import { useGetSupportResponsesQuery } from "../../store/api/requests/GET/getSupportResponsesApiSlices";
import { selectCurrentToken } from "../../store/slice/authSlice";
import "./chatroom.css";

import LoadingEllipse from "../ui/Loaders/LoadingEllipse";

function ChatRoom() {
  const { data, isFetching } = useGetSupportResponsesQuery();

  const user = useSelector(selectCurrentToken);
  const userId = jwtDecode(user)?.sub?.id;

  return (
    <div className="d-flex justify-content-center flex-column w-50  my-5 mx-auto chat-wrapper">
      <div className="d-flex flex-column justify-content-center border-bottom border-primary mb-4">
        <h2 className="text-center my-0">Welcome To Our Support</h2>
        <p className="text-center mb-4">How can we help you today?</p>
      </div>

      <div
        style={{ maxHeight: "40vh", overflow: "auto", paddingRight: "12px" }}
      >
        {isFetching ? (
          <div className="d-flex justify-content-center">
            <LoadingEllipse />
          </div>
        ) : (
          data?.support_tickets?.map((ticket) => {
            return (
              <>
                <div className="d-flex justify-content-center" key={ticket.id}>
                  <h4 className="text-center" style={{ color: "#0866be" }}>
                    Subject: {ticket.subject}
                  </h4>
                </div>
                <MessageItem
                  received={ticket.user_id !== userId}
                  message={ticket.description}
                  date={ticket.date_created}
                />
                {ticket.responses.map((response) => {
                  return (
                    <MessageItem
                      key={response.id}
                      received
                      message={response.response}
                      date={response.date_created}
                    />
                  );
                })}
              </>
            );
          })
        )}
      </div>

      <ChatForm />
    </div>
  );
}

export default ChatRoom;
