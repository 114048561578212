import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";

import Table from "../../components/Dashboard/Table/Table";
import styles from "./MoneyTracker.module.css";
import MoneyTrackerCard from "../../components/MoneyTrackerCard/MoneyTrackerCard";
import { MONEY_COLUMNS } from "../../components/Dashboard/Table/tableData/columns";
import MoneyTrackerForm from "../../components/Forms/MoneyTrackerForm";
import JebaModal from "../../components/ui/Modal/Modal";
import { useGetTrackExpenseQuery } from "../../store/api/requests/GET/getTrackExpenseApiSlice";

import { useGetTrackIncomeQuery } from "../../store/api/requests/GET/getTrackIncomeApiSlice";
import { formatAmount } from "../../helperFunctions/helpersFunctions";
import LoadingEllipse from "../../components/ui/Loaders/LoadingEllipse";

function MoneyTracker() {
  const [open, setOpen] = useState(false);
  const { data: expenses, isFetching: isExpense } = useGetTrackExpenseQuery();
  const { data: income, isFetching: isIncome } = useGetTrackIncomeQuery();

  // "this_month": 0.0,
  //   "this_week": 0.0,
  //   "today": 0

  const todayIncome = income?.today || 0;
  const thisWeekIncome = income?.this_week || 0;
  const thisMonthIncome = income?.this_month || 0;

  const todayExpense = expenses?.today ?? 0;
  const thisWeekExpense = expenses?.this_week ?? 0;
  const thisMonthExpense = expenses?.this_month ?? 0;

  const expenseMap =
    expenses?.cash_out?.map((expense) => ({
      category: "cash out",
      date: expense.cashout_date,
      name: expense.activity,
      ...expense,
    })) || [];
  const totalExpense = expenses?.cash_out?.reduce(
    (acc, curr) => acc + curr.amount,
    0,
  );

  const incomeMap =
    income?.cash_in?.map((inc) => ({
      category: "cash in",
      date: inc.cashin_date,
      name: inc.source,
      ...inc,
    })) || [];
  const totalIncome = income?.cash_in?.reduce(
    (acc, curr) => acc + curr.amount,
    0,
  );

  const combined = [...expenseMap, ...incomeMap];

  return (
    <div className={styles.money_tracker}>
      <h3>Money Tracker</h3>
      <div className={styles.buttons}>
        <button
          className={styles.button}
          type="button"
          onClick={() => setOpen(true)}
        >
          <BsPlusLg />
          Track Money
        </button>

        <JebaModal
          show={open}
          onHide={() => setOpen(false)}
          title="Track Money (Personal Or Business)"
        >
          <MoneyTrackerForm close={() => setOpen(false)} />
        </JebaModal>
      </div>

      <div className="mb-5">
        <Row className="gap-4">
          {isExpense ? (
            <LoadingEllipse />
          ) : (
            <Col>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  backgroundColor: "#fff",
                  padding: "10px",
                  color: "#0866be",
                  borderRadius: "10px",
                }}
              >
                Cash In
              </h4>
              <Row lg={2} sm={1}>
                <Col>
                  <MoneyTrackerCard
                    title="Today"
                    value={formatAmount(todayIncome)}
                    type="income"
                  />
                </Col>
                <Col>
                  <MoneyTrackerCard
                    title="This Week"
                    value={formatAmount(thisWeekIncome)}
                    type="income"
                  />
                </Col>
                <Col>
                  <MoneyTrackerCard
                    title="This Month"
                    value={formatAmount(thisMonthIncome)}
                    type="income"
                  />
                </Col>
                <Col>
                  <MoneyTrackerCard
                    title="To Date"
                    value={formatAmount(totalIncome)}
                    type="income"
                  />
                </Col>
              </Row>
            </Col>
          )}
          {isIncome ? (
            <LoadingEllipse />
          ) : (
            <Col>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  backgroundColor: "#fff",
                  padding: "10px",
                  color: "#0866be",
                  borderRadius: "10px",
                }}
              >
                Cash Out
              </h4>

              <Row lg={2} sm={1}>
                <Col>
                  <MoneyTrackerCard
                    title="Today"
                    value={formatAmount(todayExpense)}
                    type="expense"
                  />
                </Col>
                <Col>
                  <MoneyTrackerCard
                    title="This Week"
                    value={formatAmount(thisWeekExpense)}
                  />
                </Col>
                <Col>
                  <MoneyTrackerCard
                    title="This Month"
                    value={formatAmount(thisMonthExpense)}
                  />
                </Col>
                <Col>
                  <MoneyTrackerCard
                    title="To Date"
                    value={formatAmount(totalExpense)}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>

      <div>
        <Table
          data={combined}
          columns={MONEY_COLUMNS}
          isLoading={isExpense || isIncome}
        />
      </div>
    </div>
  );
}

export default MoneyTracker;
