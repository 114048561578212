/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { GoKey } from "react-icons/go";
import { toast } from "react-toastify";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useSetWalletPinMutation } from "../../store/api/requests/POST/setWalletPinApiSlice";

function SetWalletPin({ closeModal }) {
  const [confirmWalletPin, { isLoading }] = useSetWalletPinMutation();
  const [inputValue, setInputValue] = useState({
    currentPin: "",
    walletPin: "",
  });
  const [error, setError] = useState("");

  const inputValueHandler = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  useEffect(() => {
    setError("");
  }, [inputValue]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (inputValue.walletPin.length !== 5) {
      setError("Wallet pin must have 5 digits");
      return;
    }
    try {
      const data = {
        current_pin: inputValue.currentPin,
        new_pin: inputValue.walletPin,
      };
      const response = await confirmWalletPin(data).unwrap();
      if (response?.status === 200) {
        closeModal();
        toast.success("Wallet pin updated successfully");
      }
    } catch (err) {
      if (err.status === 401) {
        setError("Please provide a correct wallet pin");
      }
      if (err.status === 400) {
        setError("Wallet pin must have 5 digits");
      }
      toast.error(err.data.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.form__control}>
          <label htmlFor="currentPin">Current Pin</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <GoKey />
            </div>
            <input
              type="number"
              id="currentPin"
              name="currentPin"
              placeholder="Enter Current PIN "
              onChange={inputValueHandler}
              className={error ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {error && error}
          </span>
        </div>
        <div className={styles.form__control}>
          <label htmlFor="walletPin">New Pin</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <GoKey />
            </div>
            <input
              type="number"
              id="walletPin"
              name="walletPin"
              placeholder="Enter New PIN "
              onChange={inputValueHandler}
              className={error ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {error && error}
          </span>
        </div>
        <div className={styles.form__login}>
          <button
            type="submit"
            disabled={
              isLoading || !inputValue.currentPin || !inputValue.walletPin
            }
          >
            {isLoading ? <LoadingSpinner /> : "Set Pin"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default SetWalletPin;
