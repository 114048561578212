import { apiSlice } from "../../apiSlice";

export const getCashRoundExpectedAmountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCashRoundExpectedAmount: builder.query({
      query: (urlParameters) =>
        `/${
          urlParameters.group === "club"
            ? "investment_club"
            : urlParameters.group
        }s/${urlParameters.groupId}/expected_pay`,
      providesTags: ["Contributions"],
    }),
  }),
});

export const { useGetCashRoundExpectedAmountQuery } =
  getCashRoundExpectedAmountApiSlice;
