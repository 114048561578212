/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

import { usePostNewsMutation } from "../../store/api/requests/POST/postNewsApiSlice";
import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";

function PostNews({ close }) {
  const { categoryName, Id } = useParams();
  const [postNews, { isLoading }] = usePostNewsMutation();

  const [inputValues, setInputValues] = useState({
    title: "",
    description: "",
    image: "",
    attachment: "",
  });

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        news_title: inputValues.title,
        news_content: inputValues.description,
        news_image: inputValues.image,
        news_attachment: inputValues.attachment,
      };
      await postNews(data).unwrap();
      close();
      toast.success("success");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const imgHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setInputValues({ ...inputValues, image: base64String });
  };

  const fileHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setInputValues({ ...inputValues, attachment: base64String });
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <Row>
          <Col>
            <div className={styles.form__control}>
              <label htmlFor="share">Title</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter Title"
                  onChange={inputChangeHandler}
                  value={inputValues.title}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>

            <div className={styles.form__control}>
              <label htmlFor="description">Description</label>
              <div className={styles.form__input}>
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Enter Description"
                  onChange={inputChangeHandler}
                  value={inputValues.description}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="image">Upload Image</label>
              <div className={styles.form__input}>
                <input
                  type="file"
                  name="image"
                  id="image"
                  placeholder="Upload Image"
                  onChange={imgHandler}
                  value={inputValues.image.name}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="attachment">Attachment</label>
              <div className={styles.form__input}>
                <input
                  type="file"
                  name="attachment"
                  id="attachment"
                  placeholder="Upload Attachment"
                  onChange={fileHandler}
                  value={inputValues.attachment.name}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </Col>
        </Row>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Post News"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default PostNews;
