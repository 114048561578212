/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { GoKey } from "react-icons/go";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useConfirmWalletPinMutation } from "../../store/api/requests/POST/confirmWalletPinApiSlice";

function ConfirmPin({ showBalance, closeModal }) {
  const [confirmWalletPin, { isLoading }] = useConfirmWalletPinMutation();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const inputValueHandler = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setError("");
  }, [inputValue]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        wallet_pin: inputValue,
      };
      const response = await confirmWalletPin(data).unwrap();
      if (response?.status === 200) {
        closeModal();
        showBalance();
      }
    } catch (err) {
      if (err.status === 401) {
        setError("Please provide a correct wallet pin");
      }
      if (err.status === 400) {
        setError("Wallet pin must have 5 digits");
      }
      toast.error(err?.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.form__control}>
          <label htmlFor="email">Wallet Pin</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <GoKey />
            </div>
            <input
              type="password"
              id="walletPin"
              name="walletPin"
              placeholder="Enter Pin "
              onChange={inputValueHandler}
              className={error ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {error && error}
          </span>
        </div>
        <div className={styles.form__login}>
          <button
            type="submit"
            disabled={!inputValue || inputValue.length < 5 || isLoading}
          >
            {isLoading ? <LoadingSpinner /> : "Confirm Pin"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ConfirmPin;
