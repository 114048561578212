import React from "react";
import { useParams } from "react-router-dom";

import { thousandSeparator } from "../../../../../../helperFunctions/helpersFunctions";
import Card from "../../../../../../components/Dashboard/Card/Card";
import { useGetFinancialDetailsQuery } from "../../../../../../store/api/requests/GET/getFinancialDetailsApiSlice";

function TotalBalance() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };

  const { data } = useGetFinancialDetailsQuery(group);
  return (
    <Card
      title="Balance"
      total={thousandSeparator(data?.sacco_wallet_balance)}
    />
  );
}

export default TotalBalance;
