import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useGetCashRoundWithdrawQuery } from "../../../../store/api/requests/GET/getCashRoundWithdrawApiSlice";
import CashRoundWithdraw from "../../../../components/CashRoundWithdraw/CashRoundWithdraw";
import { contentHandler } from "../../../../helperFunctions/helpersFunctions";

function Withdraw() {
  const { categoryName, Id } = useParams();
  const { data, isFetching } = useGetCashRoundWithdrawQuery({
    group: categoryName,
    groupId: Id,
  });
  const Content = (
    <Row lg={4} sm={6} className="g-3">
      {data?.cashround_member_withdraw_rounds.map((item) => (
        <Col>
          <CashRoundWithdraw
            key={item.id}
            amount={item.expected_pay}
            date={item.date_created}
            id={item.id}
            ready={item.ready_for_withdraw}
          />
        </Col>
      ))}
    </Row>
  );

  return (
    <div>
      {contentHandler(
        isFetching,
        data?.cashround_member_withdraw_rounds?.length,
        "Loading withdraws",
        "No Withdraws Yet!",
        Content,
      )}
    </div>
  );
}

export default Withdraw;
