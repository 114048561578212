/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import styles from "./ShareCard.module.css";
import { useBuyShareMutation } from "../../../store/api/requests/POST/buyShareApiSllice";

function ShareCard({ share, showForm }) {
  const { categoryName, Id } = useParams();
  const [buyShare, { isLoading }] = useBuyShareMutation();

  const [inputValue, setInputValue] = useState({
    amount: "",
    pin: "",
  });
  const [step, setStep] = useState(1);

  useEffect(() => {
    setInputValue({ ...inputValue, amount: share?.minimum_share_capital });
  }, [share]);

  useEffect(() => {
    if (inputValue.amount < share?.minimum_share_capital) {
      setInputValue({ ...inputValue, amount: share?.minimum_share_capital });
    }
  }, [inputValue]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        share_amount: inputValue.amount,
        wallet_pin: inputValue.pin,
      };
      await buyShare(data).unwrap();
      toast.success("Success");
      setStep(1);
    } catch (error) {
      toast.error(error);
    }
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  return (
    <div className={styles.share_card}>
      <div className={styles.share_card__header}>
        {!showForm ? (
          <h2>{share?.user_name}</h2>
        ) : (
          <form
            style={{ display: "flex", justifyContent: "space-between" }}
            onSubmit={onSubmitHandler}
          >
            {step === 1 && (
              <>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="Enter Shares"
                  value={inputValue.amount}
                  onChange={inputChangeHandler}
                  disabled={isLoading}
                />
                <button
                  disabled={isLoading}
                  type="button"
                  onClick={() => setStep(2)}
                >
                  Buy Share
                </button>
              </>
            )}
            {step === 2 && (
              <>
                <input
                  type="password"
                  name="pin"
                  id="pin"
                  placeholder="Enter Wallet Pin"
                  value={inputValue.pin}
                  onChange={inputChangeHandler}
                  disabled={isLoading}
                />
                <button disabled={isLoading} type="submit">
                  {isLoading ? <Spinner /> : "Confirm"}
                </button>
              </>
            )}
          </form>
        )}
      </div>
      <div className={styles.share_card__main}>
        <h3>
          {share?.total_share_value}
          <span>Shares {!showForm ? "Bought" : "Available"}</span>
        </h3>
        <p>{`${share?.dividend_percentage}%`} Interest</p>
      </div>
      <div className={styles.share_card__footer}>
        <h5>{share?.cost_per_share}/share</h5>
        <p>
          <strong>{share?.minimum_share_capital}</strong> Min. Share(s)
        </p>
      </div>
    </div>
  );
}

export default ShareCard;
