import React from "react";
import { Col, Row } from "react-bootstrap";
import BuildCreditCard from "../../components/Dashboard/BuildCreditCard/BuildCreditCard";
import styles from "./BuildCredit.module.css";
import { useGetBuildCreditPlansQuery } from "../../store/api/requests/GET/getBuildCreditPlansApiSlice";

function Resubscribe() {
  const { data, isFetching } = useGetBuildCreditPlansQuery();
  const sortedProducts = data?.products
    ?.slice()
    .sort((a, b) => a.subscription_period - b.subscription_period);
  return (
    <div className={styles.build_credit}>
      <div className="d-flex align-items-center ">
        <Row className="g-5 align-items-end ">
          {isFetching ? (
            <p>Loading Events...</p>
          ) : (
            sortedProducts?.map((product) => (
              <Col lg={4} md={6} key={product.id}>
                <BuildCreditCard
                  name={product.product_name}
                  days={product.subscription_period}
                  description={product.product_type}
                  fee={product.subscription_fee}
                  Id={product.id}
                  status={false}
                  pastStatus={false}
                />
              </Col>
            ))
          )}
        </Row>
      </div>
    </div>
  );
}

export default Resubscribe;
