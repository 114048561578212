import { apiSlice } from "../../apiSlice";

export const getGroupInvitationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupInvitations: builder.query({
      query: (urlParameters) =>
        `/${
          urlParameters.group === "club"
            ? "investment_club"
            : urlParameters.group
        }s/${urlParameters.groupId}/member_requests`,
      providesTags: ["Invitations"],
    }),
  }),
});

export const { useGetGroupInvitationsQuery } = getGroupInvitationsSlice;
