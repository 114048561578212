import { apiSlice } from "../../apiSlice";

export const getSupportResponsesApiSlices = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSupportResponses: builder.query({
      query: () => "/user/supports",
      providesTags: ["Message"],
    }),
  }),
});

export const { useGetSupportResponsesQuery } = getSupportResponsesApiSlices;
