import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { FilterAddNew } from "../../../../components/Filters";
import JebaModal from "../../../../components/ui/Modal/Modal";
import AddIncome from "../../../../components/Forms/AddIncome";
import Table from "../../../../components/Dashboard/Table/Table";
import { useGetIncomeQuery } from "../../../../store/api/requests/GET/getIncomeApiSlice";
import {
  EXPENSE_COLUMNS,
  INCOME_COLUMNS,
} from "../../../../components/Dashboard/Table/tableData/columns";
import { useGetExpensesQuery } from "../../../../store/api/requests/GET/getExpenseApiSlice";
import { selectRole } from "../../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../../constants/constants";

function Income() {
  const { categoryName, Id } = useParams();

  const {
    data,
    isFetching,
    refetch: refetchIncome,
  } = useGetIncomeQuery({
    groupType: categoryName,
    id: Id,
  });
  const income = data?.sacco_income || [];

  const {
    data: expenseData,
    isFetching: expenseIsFetching,
    refetch: refetchExpense,
  } = useGetExpensesQuery({
    groupType: categoryName,
    id: Id,
  });
  const expense = expenseData?.sacco_expense || [];

  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const [open, setOpen] = useState(false);

  const refreshHandler = () => {
    refetchIncome();
    refetchExpense();
  };
  return (
    <div>
      <FilterAddNew
        searchItem="Income"
        buttonText="Add Income/Expense"
        open={() => setOpen(true)}
        hideButton={isAdmin}
        refresh={refreshHandler}
        onRefresh={isFetching || expenseIsFetching}
      />
      <JebaModal
        title="Add Income/Expense"
        show={open}
        onHide={() => setOpen(false)}
      >
        <AddIncome close={() => setOpen(false)} />
      </JebaModal>

      <Row>
        <Col xl={6} md={6} sm={12} className="mb-2">
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Income
            </h4>
            <Table
              data={income}
              columns={INCOME_COLUMNS}
              isLoading={isFetching}
              noDataText="No income, please add income data"
            />
          </div>
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-2">
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Expenses
            </h4>
            <Table
              data={expense}
              columns={EXPENSE_COLUMNS}
              isLoading={expenseIsFetching}
              noDataText="No expense, please add expense data"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Income;
