// /build_credit/subscriptions

import { apiSlice } from "../../apiSlice";

export const getBuildCreditSubscriptionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuildCreditSubscriptions: builder.query({
      query: () => "/build_credit/subscriptions",
      providesTags: ["BuildCredit"],
    }),
  }),
});

export const { useGetBuildCreditSubscriptionsQuery } =
  getBuildCreditSubscriptionsSlice;
