import { apiSlice } from "../../apiSlice";

export const getUserDetailsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => "/users/profile",
      providesTags: ["User"],
    }),
  }),
});

export const { useGetUserDetailsQuery } = getUserDetailsApiSlice;
