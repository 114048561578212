/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ProgressBar, Spinner } from "react-bootstrap";
import styles from "./LoginForm.module.css";
import { useAddSavingProductMutation } from "../../store/api/requests/POST/addSavingProductApiSlice";
import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";

function AddSavingProduct({ close }) {
  const { categoryName, Id } = useParams();
  const [addSavingProduct, { isLoading }] = useAddSavingProductMutation();

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    productName: "",
    productDescription: "",
    interestRate: "",
    minimumBalance: "",
    withdrawalLimit: "",
    interestAccrualFrequency: "",
    attachment: "",
  });

  const handleNext = () => {
    setStep(step + 1);
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fileHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setFormData({ ...formData, attachment: base64String });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        groupType: categoryName,
        id: Id,
        product_name: formData.productName,
        product_description: formData.productDescription,
        product_interest_rate: formData.interestRate,
        product_minimum_balance: formData.minimumBalance,
        product_withdrawal_limit: formData.withdrawalLimit,
        product_interest_accrual_frequency: formData.interestAccrualFrequency,
        product_type: formData.type,
        product_attatchments: formData.attachment,
      };

      await addSavingProduct(data).unwrap();
      setFormData({
        productName: "",
        productDescription: "",
        interestRate: "",
        minimumBalance: "",
        withdrawalLimit: "",
        interestAccrualFrequency: "",
        type: "",
        attachment: "",
      });
      toast.success("Saving product created");

      close();
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <>
      <div className={styles.wrapper} />
      <form>
        <ProgressBar now={(step / 2) * 100} className="rounded-pill" />
        {step === 1 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="productName">Product Name</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="productName"
                  name="productName"
                  placeholder="Product Name"
                  value={formData.productName}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="productDescription">Product Description</label>
              <div className={styles.form__input}>
                <textarea
                  type="text"
                  id="productDescription"
                  name="productDescription"
                  placeholder="Product Description"
                  value={formData.productDescription}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="interestRate">Interest Rate</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="interestRate"
                  name="interestRate"
                  placeholder="Interest Rate"
                  value={formData.interestRate}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="minimumBalance">Minimum Balance</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="minimumBalance"
                  name="minimumBalance"
                  placeholder="Enter Minimum Balance"
                  value={formData.minimumBalance}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="withdrawalLimit">Withdrawal Limit</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="withdrawalLimit"
                  name="withdrawalLimit"
                  placeholder="Enter Withdrawal Limit"
                  value={formData.withdrawalLimit}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="interestAccrualFrequency">
                Interest Accrual Frequency
              </label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="interestAccrualFrequency"
                  name="interestAccrualFrequency"
                  placeholder="Enter Interest Accrual Frequency"
                  value={formData.interestAccrualFrequency}
                  onChange={inputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>

            <div className={styles.form__control}>
              <label htmlFor="attachment">Upload Product Document</label>
              <div className={styles.form__input}>
                <input
                  type="file"
                  id="attachment"
                  name="attachment"
                  placeholder="Upload attachment"
                  // value={formData.attachment}
                  onChange={fileHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </>
        )}

        <div className="d-flex justify-content-between">
          {step > 1 && (
            <button
              type="button"
              //   variant="secondary"
              onClick={handlePrevious}
              className=" btn btn-secondary rounded-pill fs-4 h-base px-4"
              disabled={isLoading}
            >
              Previous
            </button>
          )}
          {step < 2 ? (
            <button
              type="button"
              //   variant="primary"
              onClick={handleNext}
              className=" btn btn-primary rounded-pill fs-4 h-base px-4"
              disabled={isLoading}
            >
              Next
            </button>
          ) : (
            <button
              //   variant="primary"
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary rounded-pill fs-4 h-base px-4"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Submit"
              )}
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default AddSavingProduct;
