/**
 * @name thousandSeparator
 * @param {*} amount
 * @returns
 */
export const thousandSeparator = (amount) => {
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * @name formatAmount
 * @param {*} amount
 * @returns
 */
export function formatAmount(amount) {
  return amount?.toLocaleString("en-US", {
    style: "currency",
    currency: "UGX",
    minimumFractionDigits: 2,
  });
}

/**
 * @name formatNumber
 * @param {*} num
 * @param {*} precision
 * @returns
 */
export function formatNumber(num, precision = 0) {
  const map = [
    { suffix: "T+", threshold: 1e12 },
    { suffix: "B+", threshold: 1e9 },
    { suffix: "M+", threshold: 1e6 },
    { suffix: "K+", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}

/**
 * @name capitalizeHandler
 * @param {*} string
 * @returns
 */
export const capitalizeHandler = (string) => {
  const arr = string?.split("_");
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr?.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(" ");
};

/**
 * @name convertFIleToBase64
 * @param {*} file
 * @returns promise
 */
export const convertFIleToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const contentHandler = (
  isLoading,
  dataLength,
  loadingText,
  noDataText,
  content,
) => {
  if (isLoading) {
    return <p>{loadingText}</p>;
  }
  if (dataLength === 0) {
    return <p>{noDataText}</p>;
  }
  return content;
};
