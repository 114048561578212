import { apiSlice } from "../../apiSlice";

export const getTotalLoanSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTotalLoan: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/total_loans`,
      providesTags: ["Loans"],
    }),
  }),
});

export const { useGetTotalLoanQuery } = getTotalLoanSlice;
