import React from "react";
import styles from "./LoginSection.module.css";

function LoginSection() {
  return (
    <div className={styles.section__wrapper}>
      <div className={styles.section__content}>
        <div className={styles.section__text}>
          <h2>Jeba Pesa wants you financially literate starting today 😊</h2>
          <p>Create an account now and get started</p>
        </div>
        <div className={styles.section__image}>
          {/* <div className={styles.section__inner__image} /> */}
        </div>
      </div>
    </div>
  );
}

export default LoginSection;
