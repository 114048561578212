import React, { useState } from "react";

// import { TableFilters } from "../../components/Filters";
import { useGetAllUserTransactionsQuery } from "../../store/api/requests/GET/getUserTransactionsApiSlice";
import Table from "../../components/Dashboard/Table/Table";
import { TRANSACTION_COLUMNS } from "../../components/Dashboard/Table/tableData/columns";

// import styles from "./Transactions.module.css";

function Transactions() {
  const [page, setPage] = useState(1);

  const { data, isFetching } = useGetAllUserTransactionsQuery({
    pageNumber: page,
  });

  const nextPaginationHandler = () => setPage(page + 1);
  const previousPaginationHandler = () => setPage(page - 1);

  return (
    <div className=" p-2">
      {" "}
      {/* <TableFilters /> */}
      <h2 className="mb-5 text-center">Transaction</h2>
      <Table
        data={data?.user_transactions || []}
        columns={TRANSACTION_COLUMNS}
        isLoading={isFetching}
        nextPagination={nextPaginationHandler}
        previousPagination={previousPaginationHandler}
      />
    </div>
  );
}

export default Transactions;
