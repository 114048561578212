/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import styles from "./LoginForm.module.css";
import { useCheckoutMutation } from "../../store/api/requests/POST/checkoutApiSlice";
import { selectCurrentToken } from "../../store/slice/authSlice";

function CheckoutForm({ close }) {
  const { categoryName, Id } = useParams();
  const [checkout, { isLoading }] = useCheckoutMutation();
  const user = useSelector(selectCurrentToken);
  const userName = jwtDecode(user)?.sub.user_name;

  const [inputValues, setInputValues] = useState({
    customerName: userName,
    deliveryAddress: "",
    phoneNumber: "",
    paymentMethod: "",
    pin: "",
  });

  const [step, setStep] = useState(1);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const checkoutData = {
        groupType: categoryName,
        id: Id,
        customer_name: inputValues.customerName,
        delivery_address: inputValues.deliveryAddress,
        phone_number: inputValues.phoneNumber,
        payment_method: inputValues.paymentMethod,
        wallet_pin: inputValues.pin,
      };

      await checkout(checkoutData).unwrap();
      close();

      toast.success("Order Submitted");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form}>
        <Row>
          {step === 1 && (
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="customerName">Customer Name</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="customerName"
                    name="customerName"
                    placeholder="Enter Customer Name"
                    onChange={inputChangeHandler}
                    value={inputValues.customerName}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="deliveryAddress">Delivery Address</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="deliveryAddress"
                    name="deliveryAddress"
                    placeholder="Enter Delivery Address"
                    onChange={inputChangeHandler}
                    value={inputValues.deliveryAddress}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="phoneNumber">Phone Number</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    onChange={inputChangeHandler}
                    value={inputValues.phoneNumber}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>

              <div className={styles.form__control}>
                <label htmlFor="paymentMethod">Payment Method</label>
                <div className={styles.form__input}>
                  <select
                    name="paymentMethod"
                    id="paymentMethod"
                    onChange={inputChangeHandler}
                    value={inputValues.paymentMethod}
                  >
                    <option>Select Method</option>
                    <option value="Jeba Pesa Wallet">Jeba Pesa Wallet</option>
                    <option value="Pay On Delivery">Cash On Delivery</option>
                  </select>
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__login}>
                <button
                  type="button"
                  onClick={() => setStep(2)}
                  disabled={isLoading}
                >
                  {isLoading ? <LoadingSpinner /> : "Next"}
                </button>
              </div>
            </Col>
          )}
          {step === 2 && (
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="pin">Wallet Pin</label>
                <div className={styles.form__input}>
                  <input
                    type="password"
                    id="pin"
                    name="pin"
                    placeholder="Enter Pin"
                    onChange={inputChangeHandler}
                    value={inputValues.pin}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__login}>
                <button
                  type="button"
                  onClick={onSubmitHandler}
                  disabled={isLoading}
                >
                  {isLoading ? <LoadingSpinner /> : "Checkout"}
                </button>
              </div>
            </Col>
          )}
        </Row>
      </form>
    </div>
  );
}

export default CheckoutForm;
