import { apiSlice } from "../../apiSlice";

export const addContributionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addContribution: builder.mutation({
      query: (credentials) => ({
        url: `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/contributions`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Contributions"],
    }),
  }),
});

export const { useAddContributionMutation } = addContributionSlice;
