/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { GoKey } from "react-icons/go";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

import { formatAmount } from "../../helperFunctions/helpersFunctions";
import { useAcceptNumberChangeMutation } from "../../store/api/requests/POST/acceptNumberChangeApiSlice";

function ApproveChangeNumber({ closeModal, change }) {
  const { categoryName, Id } = useParams();
  const [acceptNumberChange, { isLoading }] = useAcceptNumberChangeMutation();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const inputValueHandler = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setError("");
  }, [inputValue]);

  const acceptHandler = async (e) => {
    e.preventDefault();

    try {
      await acceptNumberChange({
        groupType: categoryName,
        id: Id,
        itemId: change?.id,
        approval_status: "Accept",
        wallet_pin: inputValue,
      }).unwrap();
      closeModal();
      toast.success("Number change accepted successfully");
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={acceptHandler}>
        <div>
          <p>
            {formatAmount(change?.draw_change_cost)} will be deducted from your
            account for exchanging number, Confirm to proceed
          </p>
        </div>
        <div className={styles.form__control}>
          <label htmlFor="walletPin" style={{ color: "#808080" }}>
            Wallet Pin
          </label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <GoKey />
            </div>
            <input
              type="number"
              id="walletPin"
              name="walletPin"
              placeholder="Enter Pin "
              onChange={inputValueHandler}
              className={error ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {error && error}
          </span>
        </div>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Confirm Pin"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ApproveChangeNumber;
