import { apiSlice } from "../../apiSlice";

export const acceptInvitationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    acceptInvitation: builder.mutation({
      query: (urlParameters) => ({
        url: `/${urlParameters?.groupType}s/${
          urlParameters?.groupType !== "cashround" ? "accept_invite" : "invite"
        }`,
        method: "POST",
        body: { ...urlParameters },
      }),
      invalidatesTags: ["Invitations"],
    }),
  }),
});

export const { useAcceptInvitationMutation } = acceptInvitationSlice;
