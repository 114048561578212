import { apiSlice } from "../../apiSlice";

export const removeFromCartSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    removeFromCart: builder.mutation({
      query: (credentials) => ({
        url: `/${credentials.groupType}s/${credentials?.id}/marketplace/cart`,
        method: "DELETE",
        body: { ...credentials },
      }),
      invalidatesTags: ["Cart", "Product"],
    }),
  }),
});

export const { useRemoveFromCartMutation } = removeFromCartSlice;
