/* eslint-disable react/prop-types */
import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./ListItem.module.css";
import { close } from "../../../store/slice/openMobileSidebarSlice";

function ListItem({ title, icon, onClick }) {
  const dispatch = useDispatch();
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li className={styles.list__item} onClick={() => dispatch(close())}>
      <NavLink
        to={`${title === "dashboard" ? "user" : title.replace(/ /g, "-")}`}
        className={({ isActive }) =>
          isActive
            ? ` ${styles.active} ${styles.list__link}`
            : styles.list__link
        }
        onClick={onClick}
      >
        {icon}
        <h6>{title}</h6>
      </NavLink>
    </li>
  );
}

export default ListItem;
