import React from "react";

function Password() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
    >
      <path
        d="M14.4987 7.24937H13.4631V5.17812C13.4631 2.3198 11.1433 0 8.285 0C5.42667 0 3.10687 2.3198 3.10687 5.17812V7.24937H2.07125C0.932062 7.24937 0 8.18144 0 9.32063V19.6769C0 20.8161 0.932062 21.7481 2.07125 21.7481H14.4987C15.6379 21.7481 16.57 20.8161 16.57 19.6769V9.32063C16.57 8.18144 15.6379 7.24937 14.4987 7.24937ZM5.17812 5.17812C5.17812 3.45899 6.56586 2.07125 8.285 2.07125C10.0041 2.07125 11.3919 3.45899 11.3919 5.17812V7.24937H5.17812V5.17812ZM14.4987 19.6769H2.07125V9.32063H14.4987V19.6769ZM8.285 16.57C9.42419 16.57 10.3562 15.6379 10.3562 14.4987C10.3562 13.3596 9.42419 12.4275 8.285 12.4275C7.14581 12.4275 6.21375 13.3596 6.21375 14.4987C6.21375 15.6379 7.14581 16.57 8.285 16.57Z"
        fill="#8F8CA8"
      />
    </svg>
  );
}

export default Password;
