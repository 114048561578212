import React from "react";
import { useParams } from "react-router-dom";
import { useGetBorrowersQuery } from "../../../../store/api/requests/GET/getBorrowersApiSlice";
import Table from "../../../../components/Dashboard/Table/Table";
import { BORROWERS_COLUMNS } from "../../../../components/Dashboard/Table/tableData/columns";

function Borrowers() {
  const { categoryName, Id } = useParams();
  const { data } = useGetBorrowersQuery({ groupType: categoryName, id: Id });

  const dataBorrowers =
    data?.borrowers.filter((borrower) => borrower.disbursed) || [];
  return (
    <>
      {" "}
      <Table data={dataBorrowers} columns={BORROWERS_COLUMNS} />;
    </>
  );
}

export default Borrowers;
