import React from "react";
import PropTypes from "prop-types";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./OrderActions.module.css";

function OrderActions({ id }) {
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          <BsThreeDotsVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Link to={`${id}`} className={styles.link}>
            View
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
OrderActions.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OrderActions;
