/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import styles from "./LoginForm.module.css";
import { useWithdrawMoneyMutation } from "../../store/api/requests/POST/withdrawal";

function WithdrawForm({ close }) {
  const [withdrawMoney, { isLoading }] = useWithdrawMoneyMutation();

  const [inputValues, setInputValues] = useState({
    amount: "",
    walletPin: "",
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const data = {
        withdraw_amount: +inputValues.amount,
        wallet_pin: inputValues.walletPin,
      };

      await withdrawMoney(data).unwrap();
      close();
      toast.success("Success");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form}>
        <Row>
          <Col>
            <div className={styles.form__control}>
              <label htmlFor="amount">Amount</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoName}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>

            <div className={styles.form__control}>
              <label htmlFor="walletPin">PIN</label>
              <div className={styles.form__input}>
                <input
                  type="password"
                  id="walletPin"
                  name="walletPin"
                  placeholder="Enter Pin"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoRegistrationNumber}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </Col>
        </Row>
        <div className={styles.form__login}>
          <button type="button" onClick={onSubmitHandler} disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Withdraw Money"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default WithdrawForm;
