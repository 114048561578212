/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { formatAmount } from "../../helperFunctions/helpersFunctions";
import { useWithdrawCashROundMutation } from "../../store/api/requests/POST/withdrawCashROundApiSLice";
import JebaModal from "../ui/Modal/Modal";

function CashRoundWithdraw({ amount, date, id, ready }) {
  const { categoryName, Id } = useParams();

  const [withdrawCashROund, { isLoading }] = useWithdrawCashROundMutation();
  const [walletPin, setWalletPin] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await withdrawCashROund({
        round_id: id,
        id: Id,
        groupType: categoryName,
        wallet_pin: walletPin,
      }).unwrap();
      toast.success("Withdrawal successful");
    } catch (error) {
      toast.error("Withdrawal failed");
    }
  };
  return (
    <div
      style={{ backgroundColor: "#fff", borderRadius: "15px", padding: "12px" }}
    >
      <JebaModal
        show={showModal}
        title="Confirm Pin"
        onHide={() => setShowModal(false)}
      >
        <form onSubmit={onSubmitHandler}>
          <input
            name="pin"
            type="password"
            placeholder="Enter Wallet Pin"
            value={walletPin}
            onChange={(e) => setWalletPin(e.target.value)}
          />
          <button
            disabled={isLoading}
            type="submit"
            className="btn btn-lg w-100 btn-primary fs-4 my-3"
          >
            {isLoading ? "Confirming..." : "Confirm Pin"}
          </button>
        </form>
      </JebaModal>
      <div className="mb-3">
        <span style={{ fontSize: "12px", color: "#adb5bd" }}>
          Amount Contributed
        </span>
        <h4 style={{ fontSize: "20px" }}>{formatAmount(amount)}</h4>
      </div>
      <div>
        <span style={{ fontSize: "12px", color: "#adb5bd" }}>Payment Date</span>
        <p>{date}</p>
      </div>
      <div>
        <button
          type="button"
          className="btn btn-lg btn-primary"
          onClick={() => setShowModal(true)}
          disabled={!ready}
        >
          {isLoading ? "Withdrawing..." : ready ? "Withdraw" : " Not ready"}
        </button>
      </div>
    </div>
  );
}

export default CashRoundWithdraw;
