/* eslint-disable react/prop-types */
import React from "react";

import { GoArrowDownLeft, GoArrowUpRight } from "react-icons/go";
import { BsCloudDownload } from "react-icons/bs";

import styles from "./Reportcard.module.css";
import LoadingEllipse from "../../ui/Loaders/LoadingEllipse";
import {
  formatAmount,
  formatNumber,
} from "../../../helperFunctions/helpersFunctions";

function ReportCard({ plus, title, amount, isLoading, data }) {
  const content = (item, itemData) => {
    if (item === "loans") {
      return (
        <>
          <div className={styles.jeba_report_card__items}>
            <h6>Disbursed</h6>
            <h6>Outstanding</h6>
            <h6>Repaid</h6>
            <h6>Application</h6>
          </div>
          <div className={styles.jeba_report_card__values}>
            <h6>{formatAmount(itemData?.total_loan_amount_disbursed)}</h6>
            <h6>{formatAmount(itemData?.total_loan_amount_outstanding)}</h6>
            <h6>{formatAmount(itemData?.total_loan_amount_repaid)}</h6>
            <h6>{itemData?.no_loan_applications}</h6>
          </div>
        </>
      );
    }

    if (item === "savings") {
      return (
        <>
          <div className={styles.jeba_report_card__items}>
            <h6>Current month</h6>
            <h6>Current year</h6>
            <h6>Products</h6>
            <h6>Accounts</h6>
          </div>
          <div className={styles.jeba_report_card__values}>
            <h6>{formatAmount(itemData?.current_month_savings)}</h6>
            <h6>{formatAmount(itemData?.current_year_savings)}</h6>
            <h6>{itemData?.no_savings_products}</h6>
            <h6>{itemData?.number_of_savings_accounts}</h6>
          </div>
        </>
      );
    }
    if (item === "shares") {
      return (
        <>
          <div className={styles.jeba_report_card__items}>
            <h6>Accounts</h6>
            <h6>Available Shares</h6>
            <h6>No. Transfers</h6>
            <h6>Transfers</h6>
          </div>
          <div className={styles.jeba_report_card__values}>
            <h6>{itemData?.active_share_accounts}</h6>
            <h6>{itemData?.available_shares}</h6>
            <h6>{itemData?.number_of_share_transfers}</h6>
            <h6>{itemData?.transfered_shares}</h6>
          </div>
        </>
      );
    }
    if (item === "contributions") {
      return (
        <>
          <div className={styles.jeba_report_card__items}>
            <h6>Accounts</h6>
            <h6>Products</h6>
            <h6>Current month</h6>
            <h6>Current year</h6>
          </div>
          <div className={styles.jeba_report_card__values}>
            <h6>{itemData?.number_of_contributions_accounts}</h6>
            <h6>{itemData?.no_contributions_products}</h6>
            <h6>{itemData?.current_month_contributions}</h6>
            <h6>{itemData?.current_year_contributions}</h6>
          </div>
        </>
      );
    }

    if (item === "incomes") {
      return (
        <>
          <div className={styles.jeba_report_card__items}>
            <h6>Current month</h6>
            <h6>Current year</h6>
            <h6>Investment</h6>
            <h6>Projects</h6>
          </div>
          <div className={styles.jeba_report_card__values}>
            <h6>{formatAmount(itemData?.current_month_income)}</h6>
            <h6>{formatAmount(itemData?.current_month_investment_amount)}</h6>
            <h6>{itemData?.total_ivestment_amount_todate}</h6>
            <h6>{itemData?.no_income_generating_projects}</h6>
          </div>
        </>
      );
    }

    if (item === "expenses") {
      return (
        <>
          <div className={styles.jeba_report_card__items}>
            <h6>Current month</h6>
            {/* <h6>Current year</h6> */}
            {/* <h6>Investment</h6> */}
            <h6>Projects</h6>
          </div>
          <div className={styles.jeba_report_card__values}>
            <h6>{formatAmount(itemData?.current_month_expense)}</h6>
            {/* <h6>{formatAmount(itemData?.current_month_investment_amount)}</h6> */}
            {/* <h6>{itemData?.total_ivestment_amount_todate}</h6> */}
            <h6>{itemData?.no_expense_generating_activities}</h6>
          </div>
        </>
      );
    }

    return <p>No data!</p>;
  };

  return (
    <div className={styles.jeba_report_card}>
      <h5 className={styles.jeba_report_card__heading}>
        {title}{" "}
        <button type="button" style={{}}>
          <BsCloudDownload />
          Download
        </button>
      </h5>
      <div className={styles.jeba_report_card__main}>
        {content(title, data)}
      </div>
      <div className={styles.jeba_report_card__detail}>
        <div className={styles.jeba_report_card__amount}>
          <h6>
            {plus ? "+UGX " : "-UGX "}
            {isLoading ? <LoadingEllipse /> : formatNumber(amount) || 0}
          </h6>
        </div>

        <div
          className={`${styles.jeba_report_card__percentage} ${
            plus ? styles.plus : styles.minus
          }`}
        >
          <p>
            {plus ? (
              <GoArrowDownLeft style={{ width: "25px", height: "25px" }} />
            ) : (
              <GoArrowUpRight style={{ width: "25px", height: "25px" }} />
            )}

            <span>60%</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ReportCard;
