import { apiSlice } from "../../apiSlice";

export const getAllUserInvitationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUserInvitations: builder.query({
      query: () => "/user/group_invitations",
      providesTags: ["Invitations"],
    }),
  }),
});

export const { useGetAllUserInvitationsQuery } = getAllUserInvitationsSlice;
