import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { FilterAddNew } from "../../../../components/Filters";

import JebaModal from "../../../../components/ui/Modal/Modal";

import ContributionProductsSection from "./ContributionProductsSection/ContributionProductsSection";
import ContributionsSection from "./ContributionsSection/ContributionsSection";
import AddContributionProduct from "../../../../components/Forms/AddContributionProduct";
import AddContribution from "../../../../components/Forms/AddContribution";
import { selectRole } from "../../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../../constants/constants";

function Contributions() {
  const [isOpen, setIsOpen] = useState(false);
  const [openSaccoProduct, setOpenSaccoProduct] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const openHandler = () => setIsOpen(true);
  const closeHandler = () => setIsOpen(false);

  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const refreshHandler = () => {
    setRefresh(refresh + 1);
  };

  return (
    <>
      <FilterAddNew
        searchItem="Contribution"
        buttonText="Contribute Money"
        open={openHandler}
        refresh={refreshHandler}
        onRefresh={isLoading}
      />

      <JebaModal title="Contribute" show={isOpen} onHide={closeHandler}>
        <AddContribution close={closeHandler} />
      </JebaModal>
      <Row>
        <Col lg={6} md={6}>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginTop: "12px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
                textTransform: "capitalize",
              }}
            >
              Contributions
            </h4>
            <ContributionsSection refresh={refresh} setLoading={setIsLoading} />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginTop: "12px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
                textTransform: "capitalize",
              }}
            >
              Contribution products
              {isAdmin && (
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    border: "none",
                    borderRadius: "50px",
                    backgroundColor: "#0866be",
                    fontSize: "10px",
                    textTransform: "capitalize",
                    padding: "8px",
                  }}
                  type="button"
                  onClick={() => setOpenSaccoProduct(true)}
                >
                  Add Contribution Product
                </button>
              )}
              <JebaModal
                show={openSaccoProduct}
                title="Add Contribution Product"
                onHide={() => setOpenSaccoProduct(false)}
              >
                <AddContributionProduct
                  close={() => setOpenSaccoProduct(false)}
                />
              </JebaModal>
            </h4>
            <ContributionProductsSection
              refresh={refresh}
              setLoading={setIsLoading}
            />
          </div>
        </Col>
      </Row>
      {/* <Table data={data} columns={COLUMNS} /> */}
    </>
  );
}

export default Contributions;
