import { apiSlice } from "../../apiSlice";

export const declineInvitationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    declineInvitation: builder.mutation({
      query: (urlParameters) => ({
        url: `/${urlParameters?.groupType}s/decline_invite`,
        method: "POST",
        body: { ...urlParameters },
      }),
      invalidatesTags: ["Invitations"],
    }),
  }),
});

export const { useDeclineInvitationMutation } = declineInvitationSlice;
