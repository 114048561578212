// /build_credit/caabbddcae4874e513099aee94cc9c/schedules

import { apiSlice } from "../../apiSlice";

export const getAllRedemptionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRedemptions: builder.query({
      query: () => "/build_credit/redemptions",
      providesTags: ["BuildCredit"],
    }),
  }),
});

export const { useGetAllRedemptionsQuery } = getAllRedemptionsSlice;
