import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Table from "../../../components/Dashboard/Table/Table";
import { BUILD_CREDIT_SCHEDULE_COLUMNS } from "../../../components/Dashboard/Table/tableData/columns";
import { useGetBuildCreditSubscriptionsQuery } from "../../../store/api/requests/GET/getUserBuildCreditSubscriptionsApiSlice";
import { useGetBuildCreditScheduleProductQuery } from "../../../store/api/requests/GET/getBuildCreditScheduleApiSlice";
// import styles from "./Schedule.module.css";

function Schedule() {
  const { data } = useGetBuildCreditSubscriptionsQuery();
  const { data: schedule, isFetching } = useGetBuildCreditScheduleProductQuery({
    id: data?.credit_to_build?.id,
  });

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (data?.credit_to_build?.no_missed_payments >= 3) {
      setDisabled(true);
    }
  }, [isFetching]);

  const navigate = useNavigate();

  return (
    <>
      <h1>Schedule</h1>
      <div className="bg-image position-relative ">
        <Table
          data={schedule?.repayment_schedule || []}
          columns={BUILD_CREDIT_SCHEDULE_COLUMNS}
          isLoading={isFetching}
        />
        <div
          style={{
            display: disabled ? "block" : "none",
          }}
        >
          <div
            className="mask text-light d-flex justify-content-center align-items-center flex-column text-center position-absolute w-100 h-100 top-0"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <h4>Build Credit Failed</h4>
            <button
              type="button"
              className="m-0 btn btn-primary w-25 font-weight-bold mt-3"
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                padding: "12px",
                borderRadius: "10px",
              }}
              onClick={() => navigate("/dashboard/re-subscribe")}
            >
              Subscribe Again
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Schedule;
