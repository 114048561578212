import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FaCheck } from "react-icons/fa6";
import Image from "../../../assets/images/noun-credit-score-2479138.svg";
import styles from "./BuildCreditCard.module.css";

function BuildCreditCard({
  name,
  days,
  description,
  fee,
  Id,
  status,
  rollover,
  pastStatus,
}) {
  const HeightCalculator = (numberOfDays) => {
    if (numberOfDays === 180) {
      return "350px";
    }
    if (numberOfDays === 365) {
      return "400px";
    }
    return "300px";
  };
  const navigate = useNavigate();

  const enable = () => {
    if (pastStatus) {
      if (status) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <div
      className={styles.build_credit}
      style={{ height: HeightCalculator(days) }}
    >
      <div className={styles.clip_path}>
        <h4>{days}</h4>
        <p>Days</p>
      </div>
      <div className={styles.build_credit_image}>
        <img src={Image} alt="price plan" />
      </div>
      <div>
        <div className={styles.build_credit_title}>
          <p>Most Popular</p>
        </div>
        <div className={styles.build_credit_cost}>
          <h3>{name}</h3>
        </div>
      </div>
      <div className={styles.build_credit_includes}>
        <ul>
          <li>
            {" "}
            <FaCheck /> {description}
          </li>
          <li>
            {" "}
            <FaCheck /> Only <em>UGX{fee}</em> Subscription Fee
          </li>
          <li>
            {" "}
            <FaCheck /> Unlock All Features
          </li>
          <li>
            {" "}
            <FaCheck /> Unlock All Features
          </li>
          <li>
            {" "}
            <FaCheck /> Unlock All Features
          </li>
        </ul>
      </div>
      <div className={styles.build_credit_button}>
        {!rollover ? (
          <button
            type="button"
            onClick={
              status
                ? () => navigate("../build-credit/overview")
                : () => navigate(Id)
            }
            disabled={enable()}
          >
            {status ? <span>Continue Building &rarr;</span> : "Subscribe"}
          </button>
        ) : (
          <button type="button" onClick={() => navigate(Id)}>
            Roll Over
          </button>
        )}
      </div>
    </div>
  );
}

BuildCreditCard.propTypes = {
  name: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  fee: PropTypes.number.isRequired,
  Id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  pastStatus: PropTypes.bool.isRequired,
  rollover: PropTypes.bool.isRequired,
};

export default BuildCreditCard;
