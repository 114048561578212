/* eslint-disable react/prop-types */
import React from "react";

function LoanProductCard({
  openLoanProductDetailsHandler,
  data,
  setLoanProduct,
  products,
}) {
  return (
    <div
      //   className={styles.jeba_loan_product}
      style={{
        backgroundColor: "#EAF1FE",
        borderRadius: "10px",
        padding: "8px",
        marginBottom: "8px",
      }}
    >
      <h5
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <strong
          style={{
            color: "#0866be",
            lineHeight: 1.5,
            textTransform: "capitalize",
          }}
        >
          {data.product_name}
        </strong>
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#0866be",
            border: "none",
            borderRadius: "50px",
            backgroundColor: "transparent",
            fontSize: "12px",
            lineHeight: 1.5,
            textDecoration: "underline",
            textTransform: "capitalize",
            padding: "8px",
          }}
          type="button"
          onClick={() => {
            const product = products?.find((item) => item.id === data.id);
            setLoanProduct(product);
            openLoanProductDetailsHandler(true);
          }}
        >
          View Details
        </button>
      </h5>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>
          Min: <strong>{data.min_loan_amount}</strong>
        </p>
        <p>
          Max: <strong>{data.max_loan_amount}</strong>
        </p>
        <p>
          Interest: <strong>{data.interest_rate}% </strong>
          <strong>{data.interest_charged}</strong>
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>
          Period: <strong>{data.loan_tenure}</strong>
        </p>
        <p>
          Grace Period: <strong>{data.grace_period}</strong>
        </p>
        <p>
          Late repayment: <strong>{data.late_payment_fee}</strong>
        </p>
      </div>
    </div>
  );
}

export default LoanProductCard;
