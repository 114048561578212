import React from "react";
import { Col, Row } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import OrderCard from "../../../../../components/Dashboard/OrderCard/OrderCard";
import { useGetOrderProductsQuery } from "../../../../../store/api/requests/GET/getProductOrdersApiSlice";

function OrdersIndex() {
  const { categoryName, Id } = useParams();
  const { data } = useGetOrderProductsQuery({
    groupType: categoryName,
    id: Id,
  });

  const pendingOrders = data?.orders?.filter(
    (order) => order.status === "Pending",
  );

  return (
    <>
      <h2>Orders</h2>

      <Row>
        <Col lg={2} sm={12}>
          <OrderCard title="New" value="360" />
        </Col>
        <Col lg={2} sm={12}>
          <OrderCard title="Processing" value={pendingOrders?.length} />
        </Col>
        <Col lg={2} sm={12}>
          <OrderCard title="Dispatched" value="20" />
        </Col>
        <Col lg={2} sm={12}>
          <OrderCard title="Delivered" value="14" />
        </Col>
        <Col lg={2} sm={12}>
          <OrderCard title="Completed" value="300" />
        </Col>
        <Col lg={2} sm={12}>
          <OrderCard title="Failed" value="380" />
        </Col>
      </Row>

      <div className="mt-5 overflow-auto">
        <Outlet />
      </div>
    </>
  );
}

export default OrdersIndex;
