/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { toast } from "react-toastify";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";
import { useTrackExpenseMutation } from "../../store/api/requests/POST/trackExpenseApiSlice";
import { useTrackIncomeMutation } from "../../store/api/requests/POST/trackIncomeApiSlice";

function MoneyTrackerForm({ close }) {
  const [addIncome, { isLoading }] = useTrackIncomeMutation();
  const [addExpense, { isLoading: expenseIsLoading }] =
    useTrackExpenseMutation();
  const [isExpense, setIsExpense] = useState(false);

  const [inputValues, setInputValues] = useState({
    projectName: "",
    investmentAmount: "",
    incomeAmount: "",
    attachment: "",
    incomeDate: "",
    expenseActivity: "",
    expenseAmount: "",
    expenseAttachment: "",
    expenseDate: "",
  });

  useEffect(() => {
    setInputValues({
      projectName: "",
      investmentAmount: "",
      incomeAmount: "",
      attachment: "",
      incomeDate: "",
      expenseActivity: "",
      expenseAmount: "",
      expenseAttachment: "",
      expenseDate: "",
    });
  }, [isExpense]);

  const onSubmitIncomeHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        amount: inputValues.incomeAmount,
        source: inputValues.projectName,
        attachment: inputValues.attachment,
        cashin_date: inputValues.incomeDate,
      };
      await addIncome(data).unwrap();
      close();
      toast.success("Income Added");
    } catch (error) {
      toast.error(error.data.message);
    }
  };
  const onSubmitExpenseHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        activity: inputValues.expenseActivity,
        amount: inputValues.expenseAmount,
        attachment: inputValues.expenseAttachment,
        cashout_date: inputValues.expenseDate,
      };
      await addExpense(data).unwrap();
      close();
      toast.success("Expense Added");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const getButtonContent = (loading, expenseState) => {
    if (loading) {
      return <LoadingSpinner />;
    }
    if (expenseState) {
      return "Add Cash Out";
    }
    return "Add Cash In";
  };
  const fileHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setInputValues({ ...inputValues, attachment: base64String });
  };

  const incomeIsChecked =
    !inputValues.incomeAmount ||
    !inputValues.projectName ||
    !inputValues.incomeDate;

  const expenseIsChecked =
    !inputValues.expenseAmount ||
    !inputValues.expenseActivity ||
    !inputValues.expenseDate;

  return (
    <div className={styles.form__wrapper}>
      <form
        className={styles.form}
        onSubmit={!isExpense ? onSubmitIncomeHandler : onSubmitExpenseHandler}
      >
        <div className={`${styles.form__login} ${styles.form__top__button}`}>
          <button
            className={isExpense && styles.active}
            type="button"
            disabled={isLoading || expenseIsLoading}
            onClick={() => setIsExpense(false)}
          >
            Add Cash In
          </button>
          <button
            className={!isExpense && styles.active}
            type="button"
            disabled={isLoading || expenseIsLoading}
            onClick={() => setIsExpense(true)}
          >
            Add Cash Out
          </button>
        </div>
        {!isExpense ? (
          <Row>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="projectName">Source</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="projectName"
                    name="projectName"
                    placeholder="What is the source of cash?"
                    onChange={inputChangeHandler}
                    value={inputValues.projectName}
                    //   value={inputValues.saccoName}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>

              <div className={styles.form__control}>
                <label htmlFor="incomeAmount">Amount</label>
                <div className={styles.form__input}>
                  <input
                    type="number"
                    name="incomeAmount"
                    id="incomeAmount"
                    placeholder="How much is the amount?"
                    onChange={inputChangeHandler}
                    value={inputValues.incomeAmount}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="attachment">Receipt/Evidence</label>
                <div className={styles.form__input}>
                  <input
                    type="file"
                    name="attachment"
                    id="attachment"
                    placeholder="Upload Attachment"
                    onChange={fileHandler}
                    // value=""
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="incomeDate">Date</label>
                <div className={styles.form__input}>
                  <input
                    type="date"
                    name="incomeDate"
                    id="incomeDate"
                    placeholder="Enter Income Date"
                    onChange={inputChangeHandler}
                    value={inputValues.incomeDate}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="expenseActivity">Activity</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="expenseActivity"
                    name="expenseActivity"
                    placeholder="What did you spend on?"
                    onChange={inputChangeHandler}
                    value={inputValues.expenseActivity}
                    //   value={inputValues.saccoName}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>

              <div className={styles.form__control}>
                <label htmlFor="expenseAmount">Amount</label>
                <div className={styles.form__input}>
                  <input
                    type="number"
                    name="expenseAmount"
                    id="expenseAmount"
                    placeholder="How much did you spend?"
                    onChange={inputChangeHandler}
                    value={inputValues.expenseAmount}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="expenseAttachment">Receipt/Evidence</label>
                <div className={styles.form__input}>
                  <input
                    type="file"
                    name="expenseAttachment"
                    id="expenseAttachment"
                    placeholder="Upload Attachment"
                    onChange={fileHandler}
                    // value={inputValues.expenseAttachment}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="expenseDate">Date</label>
                <div className={styles.form__input}>
                  <input
                    type="date"
                    name="expenseDate"
                    id="expenseDate"
                    placeholder="Enter Expense Date"
                    onChange={inputChangeHandler}
                    value={inputValues.expenseDate}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
            </Col>
          </Row>
        )}
        <div className={styles.form__login}>
          <button
            type="submit"
            disabled={
              isLoading || expenseIsLoading || isExpense
                ? expenseIsChecked
                : incomeIsChecked
            }
          >
            {/* {isLoading ? <LoadingSpinner /> : "Add Income"} */}
            {getButtonContent(isLoading || expenseIsLoading, isExpense)}
          </button>
        </div>
      </form>
    </div>
  );
}

export default MoneyTrackerForm;
