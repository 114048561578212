import { apiSlice } from "../../apiSlice";

export const verifyPasswordResetCodeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyPasswordResetCode: builder.mutation({
      query: (credentials) => ({
        url: "/verify_otp",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useVerifyPasswordResetCodeMutation } =
  verifyPasswordResetCodeApiSlice;
