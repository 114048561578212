import React from "react";
import PropTypes from "prop-types";

import styles from "./TransactionSummary.module.css";
import { MoneyInIcon, LeftArrowIcon } from "../../../assets/icons";

function TransactionSummary({ item, itemValue }) {
  return (
    <div className={styles.transaction__wrapper}>
      <div className={styles.transaction__icon}>
        <MoneyInIcon />
      </div>
      <div className={styles.transaction__details}>
        <h5>{item}</h5>
      </div>
      <div className={styles.transaction__amount}>+UGX, {itemValue}</div>
      <div className={styles.next__arrow__icon}>
        <LeftArrowIcon />
      </div>
    </div>
  );
}
TransactionSummary.propTypes = {
  item: PropTypes.string.isRequired,
  itemValue: PropTypes.number.isRequired,
};

export default TransactionSummary;
