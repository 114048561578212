/* eslint-disable import/prefer-default-export */
import { apiSlice } from "../../apiSlice";

export const extendSessionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    extendSession: builder.mutation({
      query: (credentials) => ({
        url: "/refresh",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useExtendSessionMutation } = extendSessionApiSlice;
