import React, { useState } from "react";

import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useCreateClubMutation } from "../../store/api/requests/POST/createClubApiSlice";

// eslint-disable-next-line react/prop-types
function CreateClub({ onClick }) {
  const [createClub, { isLoading }] = useCreateClubMutation();
  const [errors] = useState({});
  const [inputValues, setInputValues] = useState({
    saccoName: "",
    saccoPhoneNumber: "",
    saccoEmail: "",
    saccoRegistrationNumber: "",
    saccoDistrict: "",
    saccoRegCertificate: "",
    saccoNumberOfMembers: "",
    saccoDescription: "",
    saccoAddress: "",
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    const clubData = {
      investment_club_name: inputValues.saccoName,
      investment_club_phone_number: inputValues.saccoPhoneNumber,
      investment_club_email_address: inputValues.saccoEmail,
      investment_club_reg_no: inputValues.saccoRegistrationNumber,
      investment_club_district: inputValues.saccoDistrict,
      investment_club_reg_certificate: inputValues.saccoRegCertificate,
      investment_club_number_of_members: +inputValues.saccoNumberOfMembers,
      investment_club_description: inputValues.saccoDescription,
      investment_club_address: inputValues.saccoAddress,
    };

    try {
      const response = await createClub(clubData).unwrap();

      toast.success(`${response?.club_data.name} is created successfully`);
      window.scrollTo(0, 0);
      setInputValues({
        saccoName: "",
        saccoPhoneNumber: "",
        saccoEmail: "",
        saccoRegistrationNumber: "",
        saccoDistrict: "",
        saccoRegCertificate: "",
        saccoNumberOfMembers: "",
        saccoDescription: "",
        saccoAddress: "",
      });
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <div>
      {" "}
      <div className={styles.form__wrapper}>
        <div style={{ display: "flex", alignItems: "center", gap: "6rem" }}>
          <button
            type="button"
            style={{ background: "none", border: "none", color: "#0866be" }}
            onClick={onClick}
          >
            <HiMiniArrowUturnLeft />
          </button>
          <h2>Register Your Club</h2>
        </div>

        <form className={styles.form}>
          <Row>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="saccoName">Name</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoName"
                    name="saccoName"
                    placeholder="Enter  club name"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoName}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoPhoneNumber">Phone Number</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoPhoneNumber"
                    name="saccoPhoneNumber"
                    placeholder="Enter  club phone number"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoPhoneNumber}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoEmail">Email</label>
                <div className={styles.form__input}>
                  <input
                    type="email"
                    id="saccoEmail"
                    name="saccoEmail"
                    placeholder="Enter  club email"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoEmail}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoRegistrationNumber">
                  Registration Number
                </label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoRegistrationNumber"
                    name="saccoRegistrationNumber"
                    placeholder="Enter  club registration number"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoRegistrationNumber}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoDistrict">District</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoDistrict"
                    name="saccoDistrict"
                    placeholder="Enter district"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoDistrict}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
            </Col>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="saccoRegCertificate">
                  Certificate of Registration
                </label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoRegCertificate"
                    name="saccoRegCertificate"
                    placeholder="Upload document-only pdf format"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoRegCertificate}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoNumberOfMembers">Number of Members</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoNumberOfMembers"
                    name="saccoNumberOfMembers"
                    placeholder="Enter number of members"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoNumberOfMembers}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoDescription">Description</label>
                <div className={styles.form__input}>
                  <textarea
                    type="text"
                    id="saccoDescription"
                    name="saccoDescription"
                    placeholder="Enter  club description"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoDescription}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="saccoAddress">Address</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="saccoAddress"
                    name="saccoAddress"
                    placeholder="Enter club address"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoAddress}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
            </Col>
          </Row>
          <div className={styles.form__login}>
            <button type="button" onClick={onSubmitHandler}>
              {isLoading ? <LoadingSpinner /> : "Create Club"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateClub;
