// build_credit / subscriptions;

import { apiSlice } from "../../apiSlice";

export const makePaymentBuildCreditSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    makePaymentBuildCredit: builder.mutation({
      query: (credentials) => ({
        url: `/build_credit/${credentials.id}/payments`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["BuildCredit"],
    }),
  }),
});

export const { useMakePaymentBuildCreditMutation } =
  makePaymentBuildCreditSlice;
