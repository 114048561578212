/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import styles from "./InvitationCard.module.css";
import LoadingEllipse from "../../ui/Loaders/LoadingEllipse";
import { useAcceptInvitationMutation } from "../../../store/api/requests/POST/acceptInvitationApiSlice";
import { useDeclineInvitationMutation } from "../../../store/api/requests/POST/declineInvitationApiSlice";
import { useApproveShareTransferMutation } from "../../../store/api/requests/POST/approveShareTransferApiSlice";
import { useDeclineShareTransferMutation } from "../../../store/api/requests/POST/declineShareTransferApiSlice";
import { useAcceptInvitationCashRoundMutation } from "../../../store/api/requests/POST/acceptInvitationCashRoundApiSlice";
import { useDeclineInvitationCashRoundMutation } from "../../../store/api/requests/POST/declineInvitationCashRoundApiSlice";

function InvitationCardButton({
  buttonText,
  onClick,
  buttonStatus,
  isLoading,
}) {
  const getStatusColor = (status) => {
    switch (status) {
      case "Accept":
        return styles.accept;
      case "Completed":
        return styles.approved;
      case "Decline":
        return styles.decline;
      case "Declined":
        return styles.decline;
      case "Pending":
        return styles.pending;
      default:
        return styles.accept;
    }
  };
  return (
    <button
      type="button"
      disabled={isLoading}
      onClick={onClick}
      className={`${styles.jeba_invitation_card__button} ${getStatusColor(
        buttonStatus,
      )}`}
    >
      {isLoading ? <LoadingEllipse /> : buttonText}
    </button>
  );
}

function InvitationCard({
  sender,
  receiver,
  middleText,
  groupName,
  message,
  expDate,
  status,
  declineButtonText,
  approveButtonText,
  groupType,
  groupId,
  transferId,
  isTransfer,
  id,
  isCashRound,
}) {
  const dataObject = (group) => {
    switch (group) {
      case "sacco":
        return { groupType, sacco_id: groupId };
      case "vsla":
        return { groupType, vsla_id: groupId };
      case "cashround":
        return { groupType, Id: id, response: "Accept" };
      default:
        return { groupType, investment_club_id: groupId };
    }
  };

  const { categoryName, Id } = useParams();

  const [acceptInvitation, { isLoading: acceptIsLoading }] =
    useAcceptInvitationMutation();

  const [declineInvitation, { isLoading: declineIsLoading }] =
    useDeclineInvitationMutation();

  const [approveShareTransfer, { isLoading: approveIsLoading }] =
    useApproveShareTransferMutation();

  const [declineShareTransfer, { isLoading: declineShareIsLoading }] =
    useDeclineShareTransferMutation();

  const [acceptInvitationCashRound, { isLoading: acceptCashIsLoading }] =
    useAcceptInvitationCashRoundMutation();

  const [declineCashRoundInvitation, { isLoading: declineCashIsLoading }] =
    useDeclineInvitationCashRoundMutation();

  const declineHandler = async () => {
    try {
      const urlParameters = dataObject(groupType);
      toast.success("Invitation Declined");

      await declineInvitation(urlParameters).unwrap();
    } catch (error) {
      toast.error(error);
    }
  };

  const acceptInvitationHandler = async () => {
    try {
      const urlParameters = dataObject(groupType);

      await acceptInvitation(urlParameters).unwrap();
      toast.success("Invitation Accepted");
    } catch (error) {
      toast.error(error);
    }
  };
  const acceptCashRoundInvitationHandler = async () => {
    try {
      const urlParameters = dataObject(groupType);

      await acceptInvitationCashRound(urlParameters).unwrap();
      toast.success("Cash Round Invitation Accepted");
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  const declineCashRoundInvitationHandler = async () => {
    try {
      const urlParameters = dataObject(groupType);

      await declineCashRoundInvitation({
        ...urlParameters,
        response: "Decline",
      }).unwrap();
      toast.success("Cash Round Invitation Decline");
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const approveShareTransferHandler = async () => {
    try {
      await approveShareTransfer({
        groupType: categoryName,
        id: Id,
        transfer_id: transferId,
      }).unwrap();
      toast.success("Share accepted");
    } catch (error) {
      toast.error(error);
    }
  };
  const declineShareTransferHandler = async () => {
    try {
      await declineShareTransfer({
        groupType: categoryName,
        id: Id,
        transfer_id: transferId,
      }).unwrap();
      toast.success("Share decline");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.jeba_invitation_card__wrapper}>
      <div className={styles.jeba_invitation_card}>
        <div className={styles.jeba_invitation_card__details}>
          <p className={styles.jeba_invitation_card__sender}>{sender}</p>
          <p className={styles.jeba_invitation_card__text}>{middleText}</p>

          <p className={styles.jeba_invitation_card__group}>
            {groupName || receiver}
          </p>
          <p className={styles.jeba_invitation_card__message}>{message}</p>
        </div>
        <div className={styles.jeba_invitation_card__buttons}>
          {status ? (
            <InvitationCardButton buttonText={status} buttonStatus={status} />
          ) : (
            <>
              <InvitationCardButton
                buttonText={declineButtonText || "Decline"}
                buttonStatus="Decline"
                isLoading={
                  declineShareIsLoading ||
                  declineIsLoading ||
                  declineCashIsLoading
                }
                onClick={
                  isTransfer
                    ? declineShareTransferHandler
                    : isCashRound
                    ? declineCashRoundInvitationHandler
                    : declineHandler
                }
              />
              <InvitationCardButton
                buttonText={approveButtonText || "Accept"}
                buttonStatus="Accept"
                isLoading={
                  approveIsLoading || acceptIsLoading || acceptCashIsLoading
                }
                onClick={
                  isTransfer
                    ? approveShareTransferHandler
                    : isCashRound
                    ? acceptCashRoundInvitationHandler
                    : acceptInvitationHandler
                }
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.jeba_invitation_card__expiration}>
        Expires on {expDate}
      </div>
    </div>
  );
}

export default InvitationCard;
