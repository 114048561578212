import { apiSlice } from "../../apiSlice";

export const getUserTotalLoansSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserTotalLoans: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/total_loans`,
      providesTags: ["Loans"],
    }),
  }),
});

export const { useGetUserTotalLoansQuery } = getUserTotalLoansSlice;
