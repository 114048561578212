/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { Alert } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useTransferToGroupMutation } from "../../store/api/requests/POST/transferToGroupApiSlice";

function DepositToGroup({ closeModal }) {
  const [transferToGroup, { isLoading }] = useTransferToGroupMutation();
  const { categoryName, Id } = useParams();
  const [inputValue, setInputValue] = useState({
    amount: "",
    pin: "",
  });
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);

  const inputValueHandler = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  useEffect(() => {
    setError("");
  }, [inputValue]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!inputValue.pin) {
      setError("Please enter your wallet pin");
      return;
    }
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        deposit_amount: inputValue.amount,
        wallet_pin: inputValue.pin,
      };
      const response = await transferToGroup(data).unwrap();
      if (response?.status === 200) {
        closeModal();
        toast.success("Transferred successfully");
      }
    } catch (err) {
      toast.error(err.data.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <Alert
        variant="danger"
        style={{
          fontSize: "16px",
          borderRadius: "10px",
          fontStyle: "italic",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "14px",
          }}
        >
          <BsInfoCircle style={{ width: "40px", height: "40px" }} />
          <span>
            Money will be transferred from your wallet to the sacco wallet. If
            your wallet balance is low please top up
          </span>
        </div>
      </Alert>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        {step === 1 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="amount">Amount To Transfer</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount "
                  onChange={inputValueHandler}
                  className={error ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {error && error}
              </span>
            </div>
            <div className={styles.form__login}>
              <button
                type="button"
                disabled={isLoading}
                onClick={() => {
                  if (!inputValue.amount) {
                    setError("Please enter amount to transfer");
                    return;
                  }
                  setStep(2);
                }}
              >
                {isLoading ? <LoadingSpinner /> : "Next"}
              </button>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="pin">Wallet Pin</label>
              <div className={styles.form__input}>
                <input
                  type="password"
                  id="pin"
                  name="pin"
                  placeholder="Enter Pin "
                  onChange={inputValueHandler}
                  className={error ? styles.input__error : ""}
                  autoComplete="off"
                />
              </div>
              <span className={styles.input__error__container}>
                {error && error}
              </span>
            </div>
            <div className={styles.form__login}>
              <button type="submit" disabled={isLoading}>
                {isLoading ? <LoadingSpinner /> : "Transfer Money"}
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default DepositToGroup;
