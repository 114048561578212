/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";

import { GoKey } from "react-icons/go";

import { BsInfoCircle } from "react-icons/bs";
import { Alert } from "react-bootstrap";
import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { useCashOutMutation } from "../../store/api/requests/POST/cashOutApiSlice";

function CashOutForm({ showBalance, closeModal, id }) {
  const [cashOut, { isLoading }] = useCashOutMutation();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const inputValueHandler = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setError("");
  }, [inputValue]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!inputValue) {
      setError("Please enter your wallet pin");
      return;
    }
    try {
      const data = {
        Id: id,
        wallet_pin: inputValue,
      };

      const response = await cashOut(data).unwrap();
      if (response?.status === 200) {
        closeModal();
        showBalance();
      }
    } catch (err) {
      setError(err?.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <Alert
        variant="info"
        style={{
          fontSize: "16px",
          borderRadius: "10px",
          fontStyle: "italic",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "14px",
          }}
        >
          <BsInfoCircle style={{ width: "24px", height: "24px" }} />
          <span>
            <em>UGX 5000 will be charged for event subscription on cash out</em>{" "}
          </span>
        </div>
      </Alert>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.form__control}>
          <label htmlFor="email">Wallet Pin</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <GoKey />
            </div>
            <input
              type="number"
              id="walletPin"
              name="walletPin"
              placeholder="Enter Pin "
              onChange={inputValueHandler}
              className={error ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {error && error}
          </span>
        </div>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Confirm Pin"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CashOutForm;
