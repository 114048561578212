import React from "react";
import PropTypes from "prop-types";

import ReactApexChart from "react-apexcharts";
import { capitalizeHandler } from "../../../../helperFunctions/helpersFunctions";

function BarChart({ months, data, title }) {
  const dataMonths = months;
  const yLabel = `Monthly ${capitalizeHandler(title)}`;
  const barSeries = data;

  // const barSeries = [
  //   {
  //     name: "Ordinary Savings",
  //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
  //   },
  //   {
  //     name: "Normal Contribution",
  //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
  //   },
  //   {
  //     name: "Fixed Savings",
  //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
  //   },
  // ];

  const barOptions = {
    chart: {
      id: "area-datetime",
      type: "bar",
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    title: {
      text: title,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Rubik",
        color: "#111111",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: dataMonths,
      // categories: [
      //   "Jan",
      //   "Feb",
      //   "March",
      //   "Apr",
      //   "May",
      //   "June",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Nov",
      //   "Dec",
      // ],
    },
    yaxis: {
      title: {
        text: yLabel,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `UGX ${val}`;
        },
      },
    },
  };
  return (
    <div>
      {" "}
      <ReactApexChart
        options={barOptions}
        series={barSeries}
        type="bar"
        height={250}
        width="100%"
      />
    </div>
  );
}

BarChart.defaultProps = {
  months: [],
};

BarChart.propTypes = {
  months: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BarChart;
