// ;
import { apiSlice } from "../../apiSlice";

export const getEventToCashOutSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEventToCashOut: builder.query({
      query: () => "/build_credit/cashouts",
      providesTags: ["BuildCredit"],
    }),
  }),
});

export const { useGetEventToCashOutQuery } = getEventToCashOutSlice;
