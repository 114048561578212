import React from "react";

function MoneyIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <rect width="40" height="40" rx="4" fill="#E8EBF2" />
      <path
        d="M10.1583 24.0385H26.1501L19.1635 31.5607C18.6052 32.1619 18.6052 33.1484 19.1635 33.7495C19.7219 34.3507 20.6238 34.3507 21.1822 33.7495L30.6169 23.5915C31.1752 22.9904 31.1752 22.0193 30.6169 21.4181L21.1965 11.2447C20.6381 10.6435 19.7362 10.6435 19.1778 11.2447C18.6195 11.8458 18.6195 12.8169 19.1778 13.4181L26.1501 20.9557H10.1583C9.37092 20.9557 8.72667 21.6493 8.72667 22.4971C8.72667 23.3449 9.37092 24.0385 10.1583 24.0385Z"
        fill="#30E584"
      />
    </svg>
  );
}

export default MoneyIn;
