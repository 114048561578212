/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetSavingProductQuery } from "../../../../../store/api/requests/GET/getSavingProductApiSlice";
import Table from "../../../../../components/Dashboard/Table/Table";
import { GROUP_SAVING_PRODUCT_COLUMNS } from "../../../../../components/Dashboard/Table/tableData/columns";

function SavingProductsSection({ refresh, setLoading }) {
  const { categoryName, Id } = useParams();
  const { data, isFetching, refetch } = useGetSavingProductQuery({
    groupType: categoryName,
    id: Id,
  });

  useEffect(() => {
    setLoading(isFetching);
    refetch();
    return () => {
      setLoading(false);
    };
  }, [refresh]);

  useEffect(() => {
    if (isFetching) {
      setLoading(isFetching);
    } else {
      setLoading(false);
    }
  }, [isFetching]);

  return (
    <Table
      data={data?.data || []}
      isLoading={isFetching}
      columns={GROUP_SAVING_PRODUCT_COLUMNS}
      noDataText="No saving product"
    />
  );
}

export default SavingProductsSection;
