/* eslint-disable react/prop-types */
import React from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

function PasswordHide({ onClick, show }) {
  return (
    <button
      type="button"
      onClick={() => {
        onClick((prevState) => !prevState);
      }}
      style={{ background: "none", border: "none" }}
    >
      {show ? <IoEyeOutline /> : <IoEyeOffOutline />}
    </button>
  );
}

export default PasswordHide;
