// /build_credit/caabbddcae4874e513099aee94cc9c/schedules

import { apiSlice } from "../../apiSlice";

export const getBuildCreditScheduleProductSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuildCreditScheduleProduct: builder.query({
      query: (urlParameters) => `/build_credit/${urlParameters.id}/schedules`,
      providesTags: ["BuildCredit"],
    }),
  }),
});

export const { useGetBuildCreditScheduleProductQuery } =
  getBuildCreditScheduleProductSlice;
