import { apiSlice } from "../../apiSlice";

export const getCashRoundDrawsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCashRoundDraws: builder.query({
      query: (urlParameters) =>
        `/${
          urlParameters.group === "club"
            ? "investment_club"
            : urlParameters.group
        }s/${urlParameters.groupId}/draws`,
      providesTags: ["Draws"],
    }),
  }),
});

export const { useGetCashRoundDrawsQuery } = getCashRoundDrawsApiSlice;
