import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

import { FaPlus } from "react-icons/fa";
import StatCard from "../../components/Dashboard/StatCard/StatCard";
import Card from "../../components/Dashboard/Card/Card";
import TransactionSummary from "../../components/Dashboard/TransactionSummary/TransactionSummary";
import ProgressBar from "../../components/Dashboard/ProgressBar/ProgressBar";
import Button from "../../components/ui/Button/Button";
import Modal from "../../components/ui/Modal/Modal";

import styles from "./Welcome.module.css";
import WithdrawForm from "../../components/Forms/WithdrawForm";

function Welcome() {
  const [isWithdrawal, setIsWithdrawal] = useState(false);

  const series = [
    {
      name: "2022",
      data: [31, 40, 28, 51, 42, 109, 100, 11, 32, 45, 32, 200],
    },
    {
      name: "2023",
      data: [11, 32, 45, 32, 34, 52, 41, 31, 40, 28, 51, 200],
    },
  ];
  const options = {
    chart: {
      id: "area-datetime",
      height: 250,
      width: "100%",
      type: "area",
    },
    title: {
      text: "Total Savings",
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: "Gilroy-Medium",
        color: "#000",
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const barSeries = [
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Revenue",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: "Free Cash Flow",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ];

  const barOptions = {
    chart: {
      id: "area-datetime",
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `$ ${val} thousands`;
        },
      },
    },
  };

  const donutSeries = [44, 55, 41, 17, 15];
  const donutOptions = {
    chart: {
      type: "donut",
    },
    labels: ["Water", "Umeme", "Yaka", "Airtime", "Others"],

    options: {
      chart: {
        height: 250,
        width: 380,
      },

      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
      },
    },
  };

  return (
    <>
      <section className={styles.section}>
        <div>
          <h1>Dashboard</h1>
          <h4>Hello Sharon Norah, Welcome back</h4>
          <p>Save and get loans with ease</p>
        </div>
        <div>
          <button
            type="button"
            className={`${styles.button} ${styles.button__blue}`}
          >
            Add payment <FaPlus />
          </button>
          <button
            type="button"
            className={`${styles.button} ${styles.button__white}`}
            onClick={() => setIsWithdrawal(true)}
          >
            Make Withdraw
          </button>
        </div>
        {isWithdrawal && (
          <Modal>
            <WithdrawForm close={() => setIsWithdrawal(false)} />
          </Modal>
        )}
      </section>
      <section className={styles.section__summary}>
        <Card />
        <Card />
        <Card />
        <Card />
      </section>
      <section className={styles.section}>
        <div className={styles.savings}>
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={250}
            width="100%"
          />
        </div>
        <div className={styles.statistics}>
          <h4 className={styles.statistics__title}>Statistics</h4>
          <div className={styles.statistics__cards}>
            <StatCard />
            <StatCard />
            <StatCard />
            <StatCard />
          </div>
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.barChart}>
          <div className={styles.savings}>
            <ReactApexChart
              options={barOptions}
              series={barSeries}
              type="bar"
              height={250}
              width="100%"
            />
          </div>
          <div className={styles.savings}>
            {" "}
            <ReactApexChart
              options={donutOptions}
              series={donutSeries}
              height={250}
              type="donut"
              width="100%"
            />
          </div>
        </div>
        <div className={styles.statistics} style={{ backgroundColor: "#fff" }}>
          <h4 className={styles.statistics__title}>Transactions</h4>
          <div className={styles.transactions__cards}>
            <TransactionSummary />
            <TransactionSummary />
            <TransactionSummary />
            <TransactionSummary />
          </div>
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.benefits}>
          <ProgressBar />
          <ProgressBar />
          <ProgressBar />
        </div>
        <div className={styles.deposit}>
          <div className={styles.button}>
            {" "}
            <Button
              textColor="#fff"
              type="button"
              borderRadius="4px"
              backgroundColor="#0866BE"
              buttonText="Get Started"
            />
          </div>
          <div>
            <h4>Add a new deposit</h4>
            <p>Please create a new deposit for your savings account</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Welcome;
