import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiEdit2 } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { IoCloudUploadOutline } from "react-icons/io5";

import styles from "./Profile.module.css";
import Image from "../../assets/images/user-profile-svgrepo-com.svg";
import Button from "../../components/ui/Button/Button";
import { useGetUserDetailsQuery } from "../../store/api/requests/GET/getUserDetailsApiSlice";
import { useUpdateUserDetailsMutation } from "../../store/api/requests/PATCH/updateUserDetailsAPiSlice";
import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";

function Profile() {
  const [edit, setEdit] = useState(true);
  const { data, isFetching } = useGetUserDetailsQuery();
  const [updateUserDetails, { isLoading }] = useUpdateUserDetailsMutation();
  const [formData, setFormData] = useState({
    image: "",
    fname: "",
    email: "",
    bio: "",
    lname: "",
    phone: "",
    nin: "",
  });
  const [image, setImage] = useState("");

  const inputRef = useRef();

  useEffect(() => {
    setFormData({
      fname: data?.user_profile?.name?.split(" ")[0],
      lname: data?.user_profile?.name?.split(" ")[1],
      email: data?.user_profile?.email,
      bio: data?.user_profile?.jsn,
      phone: data?.user_profile?.phone_number,
      nin: data?.user_profile?.nin,
    });
    setImage(
      `https://hagfish-vocal-scarcely.ngrok-free.app/api/${data?.user_profile?.image}`,
    );
  }, [isFetching]);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const userData = {
        email: formData.email,
        image: formData.image,
        name: `${formData.fname} ${formData.lname}`,
        nin: formData.nin,
        phone_number: formData.phone,
      };
      await updateUserDetails(userData).unwrap();
      toast.success("Changes Saved");
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  const imageHandler = async (event) => {
    const imageFile = event.target.files[0];
    const imageLink = window?.URL?.createObjectURL(imageFile);
    setImage(imageLink);

    const base64File = await convertFIleToBase64(imageFile);
    const base64String = base64File?.split(",")[1];
    setFormData({ ...formData, image: base64String });
  };
  return (
    <div className="mt-5">
      <h1>Profile</h1>
      <div className="bg-white rounded-md mt-4 p-4 py-5">
        <Row className="g-5">
          <Col lg={2}>
            <ul className={styles.navigation}>
              <li>
                <NavLink
                  className={({ isActive }) => isActive && styles.active}
                  to="../profile"
                >
                  My Profile
                </NavLink>
              </li>
            </ul>
            {/* <ul className={styles.navigation}>
              <li>
                <NavLink to="../security">Security</NavLink>
              </li>
            </ul>
            <ul className={styles.navigation}>
              <li>
                <NavLink
                  className={({ isActive }) => isActive && styles.active}
                  to="/"
                >
                  Security
                </NavLink>
              </li>
            </ul> */}
          </Col>
          <Col lg={10}>
            <h4>My Profile</h4>
            <section className={styles.details_section}>
              <div className={styles.profile}>
                <div className={styles.profile_img}>
                  {!image ? (
                    <img src={Image} alt="avatar" />
                  ) : (
                    <img
                      src="https://eu.ui-avatars.com/api/?name=JP&size=100"
                      alt={data?.user_profile?.name}
                    />
                  )}
                </div>
                <div>
                  <h3 style={{ textTransform: "capitalize" }}>
                    {data?.user_profile?.name}
                  </h3>
                  <p>UI/UX Expert</p>
                  <span>Kasubi, Kampala</span>
                </div>
              </div>
              <div>
                <button type="button" className={styles.edit}>
                  <FiEdit2 />
                  Edit
                </button>
              </div>
            </section>
            <section className={styles.personal_info}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h4>Personal Information</h4>
                </div>
                <div>
                  <button
                    type="button"
                    className={styles.edit}
                    onClick={() => {
                      inputRef.current.focus();
                      setEdit(false);
                    }}
                  >
                    <FiEdit2 />
                    Edit
                  </button>
                </div>
              </div>
              <div>
                <form style={{ opacity: isFetching ? 0.5 : 1 }}>
                  <div className={styles.form__control}>
                    <label
                      htmlFor="image"
                      className={styles.image_upload}
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#0866be",
                      }}
                    >
                      <div className={styles.overlay} />
                      <IoCloudUploadOutline
                        className={`${image && "text-white"}`}
                        style={{ zIndex: 2 }}
                      />
                      <span
                        className={`${image && "text-white"}`}
                        style={{ zIndex: 2 }}
                      >
                        {!image
                          ? "Click To Upload Image"
                          : "Click To Change Image"}
                      </span>
                      <div className={styles.form__input}>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          placeholder="Enter Available Share"
                          onChange={imageHandler}
                          // value={formData.fname}
                          // readOnly={edit}
                          // ref={inputRef}
                          //   className={errors.emailError ? styles.input__error : ""}
                        />
                      </div>
                    </label>

                    <span className={styles.input__error__container}>
                      {/* {errors.emailError && errors.emailError} */}
                    </span>
                  </div>
                  <Row sm={1} xs={1} md={2}>
                    <Col>
                      <div className={styles.form__control}>
                        <label htmlFor="fname">First Name</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="fname"
                            name="fname"
                            placeholder="Enter Available Share"
                            onChange={inputChangeHandler}
                            value={formData.fname}
                            readOnly={edit}
                            ref={inputRef}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                      <div className={styles.form__control}>
                        <label htmlFor="email">Email</label>
                        <div className={styles.form__input}>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter Email"
                            onChange={inputChangeHandler}
                            value={formData.email}
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                      <div className={styles.form__control}>
                        <label htmlFor="bio">JSN</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="bio"
                            name="bio"
                            placeholder="Enter JSN"
                            onChange={inputChangeHandler}
                            value={formData.bio}
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className={styles.form__control}>
                        <label htmlFor="lname">Last Name</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="lname"
                            name="lname"
                            placeholder="Enter Last Name"
                            onChange={inputChangeHandler}
                            value={formData.lname}
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                      <div className={styles.form__control}>
                        <label htmlFor="phone">Phone</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone Number"
                            onChange={inputChangeHandler}
                            value={formData.phone}
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                      <div className={styles.form__control}>
                        <label htmlFor="phone">NIN</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="nin"
                            name="nin"
                            placeholder="Enter NIN"
                            onChange={inputChangeHandler}
                            value={formData.nin}
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </section>
            <section className={styles.personal_info}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h4>Address</h4>
                </div>
                <div>
                  <button
                    type="button"
                    className={styles.edit}
                    onClick={() => setEdit(false)}
                    disabled
                  >
                    <FiEdit2 />
                    Edit
                  </button>
                </div>
              </div>
              <div>
                <form>
                  <Row sm={1} xs={1} md={2}>
                    <Col>
                      <div className={styles.form__control}>
                        <label htmlFor="country">Country</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="country"
                            name="country"
                            placeholder="Enter Country"
                            // onChange={inputChangeHandler}
                            value="Uganda"
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                      <div className={styles.form__control}>
                        <label htmlFor="postalCode">Postal Code</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="postalCode"
                            name="postalCode"
                            placeholder="Enter Postal Code"
                            // onChange={inputChangeHandler}
                            value="10010"
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className={styles.form__control}>
                        <label htmlFor="city">City/State</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            placeholder="Enter City"
                            // onChange={inputChangeHandler}
                            value="Kampala"
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                      <div className={styles.form__control}>
                        <label htmlFor="tax">Tax ID</label>
                        <div className={styles.form__input}>
                          <input
                            type="text"
                            id="tax"
                            name="tax"
                            placeholder="Enter Tax Id"
                            // onChange={inputChangeHandler}
                            value="0010010002005336689"
                            readOnly={edit}
                            //   className={errors.emailError ? styles.input__error : ""}
                          />
                        </div>
                        <span className={styles.input__error__container}>
                          {/* {errors.emailError && errors.emailError} */}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </section>
            <div style={{ width: "50%" }}>
              <Button
                type="button"
                backgroundColor="#0866be"
                textColor="#fff"
                buttonText="Update Profile"
                onClick={onSubmitHandler}
                disabled={isLoading}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Profile;
