/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/** React icons imports */
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { BsArrowDown, BsCreditCard2Front } from "react-icons/bs";
import { BiTransfer, BiCheckShield } from "react-icons/bi";
import { CiWallet, CiSettings } from "react-icons/ci";

/** React bootstrap imports */
import { Row, Col } from "react-bootstrap";

/** styles imports */
import styles from "./UserDashboard.module.css";

/** RTK query imports */
import { useGetUserBalanceQuery } from "../../../store/api/requests/GET/getBalanceApiSlice";
import LoadingEllipse from "../../../components/ui/Loaders/LoadingEllipse";

import JebaModal from "../../../components/ui/Modal/Modal";
import ConfirmPin from "../../../components/Forms/ConfirmPin";
import SetWalletPin from "../../../components/Forms/SetWalletPin";
import WithdrawForm from "../../../components/Forms/WithdrawForm";
import DepositForm from "../../../components/Forms/DepositForm";
import { useGetAllUserInvitationsQuery } from "../../../store/api/requests/GET/getAllUserInvitationsApiSlice";

function UserDashboardButton({ buttonText, onClick, badgeData, allowData }) {
  return (
    <button
      type="button"
      className={styles.user_dashboard__button}
      onClick={onClick}
    >
      {buttonText}
      {allowData && (
        <span className={styles.user_dashboard__button__invitations__badge}>
          {badgeData > 9 ? `${badgeData}+` : badgeData || 0}
        </span>
      )}
    </button>
  );
}

function UserDashboardActionButton({ buttonText, onClick, icon }) {
  return (
    <button
      type="button"
      className={styles.user_dashboard__button}
      onClick={onClick}
    >
      <span className={styles.user_dashboard__action__button__icon}>
        {icon}
      </span>
      {buttonText}
    </button>
  );
}

function QuickSettingButton({ buttonText, icon, onClick }) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={styles.user_dashboard__quick__setting__button}
    >
      <div className={styles.user_dashboard__quick__setting__button__inner}>
        <div
          className={styles.user_dashboard__quick__setting__button__inner__icon}
        >
          {icon}
        </div>
        <p>{buttonText}</p>
      </div>
    </button>
  );
}

function UserDashboard() {
  const navigate = useNavigate();
  const { data, isFetching } = useGetUserBalanceQuery();
  const { data: invitations } = useGetAllUserInvitationsQuery();

  const clubMemberInvitations =
    invitations?.investment_club_member_requests.length;
  const saccoMemberInvitations = invitations?.sacco_member_requests.length;
  const vslaMemberInvitations = invitations?.vsla_member_requests.length;
  const cashRoundMemberInvitations =
    invitations?.cash_round_group_invitations?.length;

  const totalInvitations =
    clubMemberInvitations +
    saccoMemberInvitations +
    vslaMemberInvitations +
    cashRoundMemberInvitations;

  const [confirmBalance, setConfirmBalance] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [walletPin, setWalletPin] = useState(false);
  const [walletAction, setWalletAction] = useState({
    isWithdrawal: false,
    isTopUp: false,
  });

  const viewButtonHandler = () => {
    if (showBalance) {
      setConfirmBalance(false);
      setShowBalance(false);
    } else {
      setConfirmBalance(true);
    }
  };

  const showBalanceHandler = () => {
    setShowBalance(true);
  };

  const setPinHandler = () => {
    setWalletPin(true);
  };

  const thousandSeparator = (amount) => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const userBalance = thousandSeparator(data?.wallet_balance);

  return (
    <div className={styles.user_dashboard}>
      <Row>
        <Col className="p-0">
          <div className={styles.user_balance}>
            <h2>Account balance</h2>
            <span className={styles.user_balance__currency}>UGX</span>
            <span className={styles.user_balance__amount}>
              {" "}
              {isFetching ? (
                <LoadingEllipse />
              ) : showBalance ? (
                `${userBalance || 0}`
              ) : (
                <>
                  <GoDotFill />
                  <GoDotFill />
                  <GoDotFill />
                  <GoDotFill />
                  <GoDotFill />
                  <GoDotFill />
                  <GoDotFill />
                  <GoDotFill />
                </>
              )}
            </span>
            <button
              type="button"
              className={styles.view_balance}
              onClick={viewButtonHandler}
            >
              {showBalance ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>

            <JebaModal
              show={confirmBalance}
              onHide={() => setConfirmBalance(false)}
              title="Enter Wallet Pin"
            >
              <ConfirmPin
                showBalance={showBalanceHandler}
                closeModal={() => setConfirmBalance(false)}
              />
            </JebaModal>
          </div>
        </Col>
      </Row>
      <div className={styles.user_dashboard__wrapper}>
        <Row className="gap-3">
          <Col
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <div className={styles.user_dashboard__welcome}>
              <h4>Quick Actions </h4>
              <p>
                Take your first step toward building a successful financial
                goals
              </p>
              <div className={styles.user_dashboard__buttons}>
                <Row xs={1} sm={1} md={2} xl={2}>
                  <Col>
                    {" "}
                    <UserDashboardButton
                      buttonText="Add your first Group"
                      onClick={() =>
                        navigate("/dashboard/groups", {
                          options: { replace: true },
                        })
                      }
                    />
                    <UserDashboardButton
                      buttonText="Group Invitations"
                      allowData
                      badgeData={totalInvitations}
                      onClick={() =>
                        navigate("/dashboard/invitations", {
                          options: { replace: true },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    {" "}
                    <UserDashboardButton
                      buttonText="Build Credit"
                      onClick={() =>
                        navigate("/dashboard/build-credit", {
                          options: { replace: true },
                        })
                      }
                    />
                    <UserDashboardButton
                      buttonText="Get Help"
                      onClick={() =>
                        navigate("/dashboard/help", {
                          options: { replace: true },
                        })
                      }
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <div className={styles.user_dashboard__actions}>
              <h4>Wallet Actions</h4>
              <p>Manage your wallet and make transactions here</p>

              <div className={styles.user_dashboard__action__buttons}>
                <Row>
                  <Col>
                    {" "}
                    <UserDashboardActionButton
                      buttonText="Deposit Money"
                      icon={<BsArrowDown />}
                      onClick={() =>
                        setWalletAction({ ...walletAction, isTopUp: true })
                      }
                    />
                    <JebaModal
                      show={walletAction.isTopUp}
                      title="Deposit Money"
                      onHide={() =>
                        setWalletAction({
                          ...walletAction,
                          isTopUp: false,
                        })
                      }
                    >
                      {" "}
                      <DepositForm
                        close={() =>
                          setWalletAction({
                            ...walletAction,
                            isTopUp: false,
                          })
                        }
                      />
                    </JebaModal>
                    <UserDashboardActionButton
                      buttonText="Set Wallet Pin"
                      icon={<BsCreditCard2Front />}
                      onClick={setPinHandler}
                    />
                  </Col>
                  <Col>
                    {" "}
                    <UserDashboardActionButton
                      buttonText="Send Money"
                      icon={<BiTransfer />}
                      onClick={() =>
                        navigate("/dashboard/my-wallet", {
                          options: { replace: true },
                        })
                      }
                    />
                    <UserDashboardActionButton
                      buttonText="Withdraw Money"
                      icon={<CiWallet />}
                      onClick={() =>
                        setWalletAction({ ...walletAction, isWithdrawal: true })
                      }
                    />
                    <JebaModal
                      show={walletAction.isWithdrawal}
                      title="Withdraw Money"
                      onHide={() =>
                        setWalletAction({
                          ...walletAction,
                          isWithdrawal: false,
                        })
                      }
                    >
                      {" "}
                      <WithdrawForm
                        close={() =>
                          setWalletAction({
                            ...walletAction,
                            isWithdrawal: false,
                          })
                        }
                      />
                    </JebaModal>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.user_dashboard__wrapper}>
        <Row className="gap-3">
          <Col
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <div className={styles.app_download__section}>
              <h4>Download </h4>
              <p>
                In the battle against poverty, our weapon of choice is financial
                literacy and life skills
              </p>
              <button type="button" className={styles.app_download__button}>
                Download Mobile App
              </button>
            </div>
          </Col>
          <Col
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <QuickSettingButton
              buttonText="Account Settings"
              icon={<CiSettings />}
            />
          </Col>

          <Col
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <QuickSettingButton
              buttonText="Set Your Pin"
              icon={<BiCheckShield />}
              onClick={setPinHandler}
            />
            <JebaModal
              show={walletPin}
              onHide={() => setWalletPin(false)}
              title="Set Wallet Pin"
            >
              <SetWalletPin closeModal={() => setWalletPin(false)} />
            </JebaModal>
          </Col>
          <Col
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <QuickSettingButton
              buttonText="Another option here"
              icon={<BiCheckShield />}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default UserDashboard;
