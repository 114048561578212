import React from "react";
import { useParams } from "react-router-dom";

import { thousandSeparator } from "../../../../../../helperFunctions/helpersFunctions";
import Card from "../../../../../../components/Dashboard/Card/Card";
import { useGetTotalContributionQuery } from "../../../../../../store/api/requests/GET/getTotalContributionApiSlice";

function TotalContributions() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };

  const { data } = useGetTotalContributionQuery(group);

  return (
    <Card
      title="Contributions"
      total={thousandSeparator(data?.total_contributions)}
    />
  );
}

export default TotalContributions;
