/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { ProgressBar, Spinner } from "react-bootstrap";
import styles from "./LoginForm.module.css";
import { useRequestLoanMutation } from "../../store/api/requests/POST/requestLoanApiSlice";
import { useGetGroupMembersQuery } from "../../store/api/requests/GET/groupMembersApiSlice";
import { useGetLoanProductsQuery } from "../../store/api/requests/GET/getLoanProductsApiSlice";
import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";

function LoanApplicationForm({ close }) {
  const { categoryName, Id } = useParams();
  const [requestLoan, { isLoading }] = useRequestLoanMutation();
  const { data: LoanProducts } = useGetLoanProductsQuery({
    groupType: categoryName,
    id: Id,
  });

  const { data: groupMembers } = useGetGroupMembersQuery({
    group: categoryName,
    groupId: Id,
  });

  const [inputValues, setInputValues] = useState({
    amount: "",
    loanPurpose: "",
    loanProduct: "",
    loanCollateral: "",
    repaymentPeriod: "",
    loanRepaymentSource: "",
    guarantorId: "",
    loanRequiredDate: "",
    loanAttachment: "",
  });
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };
  const imgHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setInputValues({ ...inputValues, loan_collateral: base64String });
  };

  const fileHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setInputValues({ ...inputValues, attachment: base64String });
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const loanData = {
        groupType: categoryName,
        id: Id,
        loan_amount_requested: inputValues.amount,
        guarantor_id: inputValues.guarantorId,
        loan_purpose: inputValues.loanPurpose,
        loan_product_id: inputValues.loanProduct,
        loan_collateral: inputValues.loanCollateral,
        repayment_period: inputValues.repaymentPeriod,
        loan_required_date: inputValues.loanRequiredDate,
        loan_repayment_source: inputValues.loanRepaymentSource,
        attachment: inputValues.loanAttachment,
      };

      await requestLoan(loanData).unwrap();
      close();
      toast.success("Loan Application Submitted");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form}>
        <ProgressBar now={(step / 2) * 100} />
        {step === 1 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="amount">Loan Amount</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  onChange={inputChangeHandler}
                  value={inputValues.amount}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanPurpose">Loan Purpose</label>
              <div className={styles.form__input}>
                <textarea
                  type="text"
                  id="loanPurpose"
                  name="loanPurpose"
                  placeholder="Enter Loan Purpose"
                  onChange={inputChangeHandler}
                  value={inputValues.loanPurpose}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanProduct">Loan Product</label>
              <div className={styles.form__input}>
                <select
                  id="loanProduct"
                  name="loanProduct"
                  onChange={inputChangeHandler}
                  value={inputValues.loanProduct}
                >
                  <option>Select product</option>
                  {LoanProducts?.sacco_loan_products?.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.product_name}
                    </option>
                  ))}
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanCollateral">
                Upload Loan Collateral Image
              </label>
              <div className={styles.form__input}>
                <input
                  type="file"
                  id="loanCollateral"
                  name="loanCollateral"
                  placeholder="Upload Loan Collateral Image"
                  onChange={imgHandler}
                  // value={inputValues.loanCollateral}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className={styles.form__control}>
              <label htmlFor="repaymentPeriod">Repayment Period</label>
              <div className={styles.form__input}>
                <input
                  type="number"
                  id="repaymentPeriod"
                  name="repaymentPeriod"
                  placeholder="Enter Repayment Period"
                  onChange={inputChangeHandler}
                  value={inputValues.repaymentPeriod}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanRepaymentSource">Loan Repayment Source</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="loanRepaymentSource"
                  name="loanRepaymentSource"
                  placeholder="Enter Repayment Source"
                  onChange={inputChangeHandler}
                  value={inputValues.loanRepaymentSource}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="guarantorId">Loan Guarantor</label>
              <div className={styles.form__input}>
                <select
                  id="guarantorId"
                  name="guarantorId"
                  onChange={inputChangeHandler}
                  value={inputValues.guarantorId}
                >
                  <option>Select product</option>
                  {groupMembers?.data?.map((member) => (
                    <option value={member?.id} key={member?.id}>
                      {member.user_name}
                    </option>
                  ))}
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanRequiredDate">Loan Required Date</label>
              <div className={styles.form__input}>
                <input
                  type="date"
                  id="loanRequiredDate"
                  name="loanRequiredDate"
                  placeholder="Enter Loan Required Date"
                  onChange={inputChangeHandler}
                  value={inputValues.loanRequiredDate}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="loanRequiredDate">
                Upload Supportive Document
              </label>
              <div className={styles.form__input}>
                <input
                  type="file"
                  id="loanAttachment"
                  name="loanAttachment"
                  placeholder="Enter Loan Attachment"
                  onChange={fileHandler}
                  // value={inputValues.loanAttachment}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </>
        )}

        <div className="d-flex justify-content-between">
          {step > 1 && (
            <button
              type="button"
              //   variant="secondary"
              onClick={handlePrevious}
              className=" btn btn-secondary rounded-pill fs-4 h-base px-4"
              disabled={isLoading}
            >
              Previous
            </button>
          )}
          {step < 2 ? (
            <button
              type="button"
              //   variant="secondary"
              onClick={handleNext}
              className=" btn btn-primary rounded-pill fs-4 h-base px-4"
              disabled={
                isLoading ||
                !inputValues.amount ||
                !inputValues.loanPurpose ||
                !inputValues.loanProduct
              }
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={onSubmitHandler}
              className="btn btn-primary rounded-pill fs-4 h-base px-4"
              disabled={
                isLoading ||
                !inputValues.repaymentPeriod ||
                !inputValues.loanRepaymentSource ||
                !inputValues.guarantorId ||
                !inputValues.loanRequiredDate
              }
            >
              {isLoading ? <Spinner /> : "Request Loan"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default LoanApplicationForm;
