import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { NavLink, Outlet } from "react-router-dom";
import { FaBars } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import BuildCreditSummaryCard from "../../../components/Dashboard/BuildCreditSummaryCard/BuildCreditSummaryCard";
import JebaModal from "../../../components/ui/Modal/Modal";
import BuildCreditForm from "../../../components/Forms/BuildCreditForm";
import { useGetBuildCreditSubscriptionsQuery } from "../../../store/api/requests/GET/getUserBuildCreditSubscriptionsApiSlice";
import { useGetCreditScoreQuery } from "../../../store/api/requests/GET/getCreditScoreApiSlice";
import styles from "./Overview.module.css";

function Overview() {
  const NavButtons = [
    { buttonText: "Schedules", link: "schedule" },
    { buttonText: "Payment History", link: "payment-history" },
    { buttonText: "Redemption", link: "redemption" },
    { buttonText: "Score History", link: "score-history" },
    { buttonText: "Rollover", link: "rollover" },
    { buttonText: "Builder History", link: "builder-history" },
    { buttonText: "Cash Out", link: "cash-out" },
  ];
  const { data: score } = useGetCreditScoreQuery();

  const { data } = useGetBuildCreditSubscriptionsQuery();

  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const slideRef = useRef();
  const breakpoints = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  return (
    <>
      <div>
        {" "}
        <div
          style={{
            borderRadius: "10px",
            backgroundColor: "#fff",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "25px",
            paddingBottom: "25px",
            marginTop: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "30px",
              paddingBottom: "15px",
              border: "1px solid #0866be",
              borderStyle: "none none solid none",
            }}
          >
            <h1>Build Credit</h1>
            <div className={styles.nav_section}>
              <button
                type="button"
                onClick={() => setOpenMenu((prev) => !prev)}
              >
                {openMenu ? <IoClose /> : <FaBars />}
                Menu
              </button>
              <div
                className={styles.nav_buttons}
                style={{ opacity: openMenu ? 1 : "" }}
              >
                {NavButtons.map((button) => (
                  <NavLink
                    to={button.link}
                    key={Math.random()}
                    className={({ isActive }) =>
                      isActive ? ` ${styles.active}` : ""
                    }
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "5px 5px",
                      textDecoration: "none",
                    }}
                    onClick={() => setOpenMenu(false)}
                  >
                    {button.buttonText}
                  </NavLink>
                ))}
              </div>
            </div>

            <button
              style={{
                width: "150px",
                height: "40px",
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "16px" /* 88.889% */,
                border: "none",
                background: "rgb(48, 229, 132)",
                color: "#fff",
              }}
              type="button"
              onClick={() => setOpen(true)}
              disabled={data?.credit_to_build?.deactivate_paymant}
              // data-bs-toggle="tooltip"
              // data-bs-custom-class="custom-tooltip"
              // data-bs-placement="top"
              // title="You have 2 missed payments please redeem your credit to continue building"
            >
              Make A Payment
            </button>
            <JebaModal
              title="Make A Payment"
              show={open}
              onHide={() => setOpen(false)}
            >
              <BuildCreditForm
                close={() => setOpen(false)}
                Id={data?.credit_to_build?.id}
              />
            </JebaModal>
          </div>
          <div
            style={{ width: "1280px", maxWidth: "100%", textAlign: "center" }}
          >
            <Slide
              easing="ease"
              arrows
              duration={2000}
              ref={slideRef}
              defaultIndex={0}
              responsive={breakpoints}
            >
              <BuildCreditSummaryCard
                item="Credit Amount (UGX)"
                itemValue={data?.credit_to_build?.credit_amount}
              />
              <BuildCreditSummaryCard
                item="Amount Paid (UGX)"
                itemValue={data?.credit_to_build?.amount_paid}
              />
              <BuildCreditSummaryCard
                item="Balance (UGX)"
                itemValue={data?.credit_to_build?.amount_outstanding}
              />
              <BuildCreditSummaryCard
                item="Credit Score"
                itemValue={score?.credit_scores}
              />
              <BuildCreditSummaryCard
                item="Credit Period (Days)"
                itemValue={data?.credit_to_build?.credit_period}
              />

              <BuildCreditSummaryCard
                item="No. Payments To Make"
                itemValue={data?.credit_to_build?.number_of_installments}
              />
              <BuildCreditSummaryCard
                item="Daily Payment Amount (UGX)"
                itemValue={data?.credit_to_build?.amount_per_installment}
              />
              <BuildCreditSummaryCard
                item="On time Payments"
                itemValue={data?.credit_to_build?.no_ontime_payments}
              />
            </Slide>
          </div>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "25px",
              paddingBottom: "25px",
              marginTop: "12px",
            }}
          >
            <Outlet />{" "}
          </div>{" "}
        </Col>
      </Row>
    </>
  );
}

export default Overview;
