import { apiSlice } from "../../apiSlice";

export const getCashRoundMemberContributionsApiSlice = apiSlice.injectEndpoints(
  {
    endpoints: (builder) => ({
      getCashRoundMemberContributions: builder.query({
        query: (urlParameters) =>
          `/${
            urlParameters.group === "club"
              ? "investment_club"
              : urlParameters.group
          }s/${urlParameters.groupId}/contributions`,
        providesTags: ["Contributions"],
      }),
    }),
  },
);

export const { useGetCashRoundMemberContributionsQuery } =
  getCashRoundMemberContributionsApiSlice;
