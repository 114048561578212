import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FilterAddNew } from "../../../../components/Filters";
import JebaModal from "../../../../components/ui/Modal/Modal";
import AddContributionCashRound from "../../../../components/Forms/AddContributionCashRound";
import { useGetCashRoundMemberContributionsQuery } from "../../../../store/api/requests/GET/getCashRoundMemberContributionsApiSlice";
import CashRoundContributionCard from "../../../../components/CashRoundContributionCard/CashRoundContributionCard";
import { contentHandler } from "../../../../helperFunctions/helpersFunctions";

function Contribution() {
  const { categoryName, Id } = useParams();
  const { data, isFetching, refetch } = useGetCashRoundMemberContributionsQuery(
    {
      group: categoryName,
      groupId: Id,
    },
  );

  const [open, setOpen] = useState(false);
  const openHandler = () => {
    setOpen(true);
  };
  const Content = (
    <Row lg={4} sm={6}>
      {data?.cashround_contributions?.map((contribution) => (
        <Col>
          <CashRoundContributionCard
            amount={contribution?.amount}
            date={contribution.payment_date}
          />
        </Col>
      ))}
    </Row>
  );

  return (
    <div>
      <FilterAddNew
        searchItem="Contribution"
        buttonText="Contribute"
        open={openHandler}
        refresh={refetch}
        onRefresh={isFetching}
        // hideButton={isAdmin}
      />
      <JebaModal
        title="Make A Contribution"
        show={open}
        onHide={() => setOpen(false)}
      >
        <AddContributionCashRound close={() => setOpen(false)} />
      </JebaModal>
      <div>
        {contentHandler(
          isFetching,
          data?.cashround_contributions?.length,
          "Loading Contributions",
          "No Contributions Yet!",
          Content,
        )}
      </div>
    </div>
  );
}

export default Contribution;
