import { apiSlice } from "../../apiSlice";

export const redeemSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    redeem: builder.mutation({
      query: (credentials) => ({
        url: "/build_credit/redemptions",
        method: "POST",
        body: { ...credentials },
      }),
      invalidateTags: ["BuildCredit"],
    }),
  }),
});

export const { useRedeemMutation } = redeemSlice;
