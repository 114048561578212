/* eslint-disable react/prop-types */
import React from "react";
import ReactApexChart from "react-apexcharts";

function SparkLineChart({ title, itemColor = "#0866be", total = 0 }) {
  const options = {
    chart: {
      id: "sparkline1",
      type: "area",
      height: 160,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "straight",
    },
    fill: {
      opacity: 1,
    },
    series: [
      {
        name: title,
        data: [
          1, 2, 5, 6, 7, 8, 9, 25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54, 12,
          20, 1, 25, 54, 25,
        ],
      },
    ],
    // labels: [...Array(24).keys()].map((n) => `2018-09-0${n + 1}`),
    // yaxis: {
    //   min: 0,
    // },
    // xaxis: {
    //   type: "datetime",
    // },
    colors: ["#9cc2e5"],
    title: {
      text: `Shs.${total}`,
      offsetX: 15,
      offsetY: 15,
      style: {
        fontSize: "22px",
        cssClass: "apexcharts-yaxis-title",
        color: itemColor,
        fontFamily: "Rubik",
        fontWeight: 600,
      },
    },
    subtitle: {
      text: title,
      offsetX: 30,
      offsetY: 40,
      style: {
        fontSize: "14px",
        cssClass: "apexcharts-yaxis-title",
        color: "#021426",
        fontFamily: "Rubik",
      },
    },
  };

  return (
    <div>
      {" "}
      <ReactApexChart
        options={options}
        series={options.series}
        height={150}
        type="area"
        width="100%"
      />
    </div>
  );
}

export default SparkLineChart;
