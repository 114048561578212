/* eslint-disable react/prop-types */
import React from "react";

import { Link } from "react-router-dom";
import styles from "./GroupCard.module.css";

function GroupCard({ icon, title, subtitle, description, bgColor, link }) {
  return (
    <div
      className={styles.group__card}
      style={{ background: `${bgColor || "#fff"}` }}
    >
      <div className={styles.group__details}>
        <div className={styles.group__icon}>{icon}</div>
        <div className={styles.group__name}>
          <Link to={link}>
            <h4>{title.toLowerCase()}</h4>
          </Link>
          <p>{subtitle}</p>
        </div>
      </div>
      <div className={styles.group__created__date}>
        <span>{description}</span>
      </div>
    </div>
  );
}

export default GroupCard;
