import React, { useState } from "react";
import { toast } from "react-toastify";
import styles from "./ChatForm.module.css";
import { useSendMessageMutation } from "../../../store/api/requests/POST/sendMessageApiSlice";

function ChatForm() {
  const [sendMessage, { isLoading }] = useSendMessageMutation();
  const [message, setMessage] = useState({
    subject: "",
    body: "",
  });

  /**
   * @name handleChange
   * @param {*} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessage((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * @name handleSubmit
   * @param {*} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { subject: message.subject, description: message.body };
      await sendMessage(data).unwrap();
      toast.success("Message sent successfully");
      setMessage({ subject: "", body: "" });
    } catch (error) {
      toast.error("An error occurred. Please try again");
    }
  };

  return (
    <div className={styles.chat_form}>
      <form className="w-100" onSubmit={handleSubmit}>
        <div>
          <label className="w-100" htmlFor="subject">
            Subject
            <input
              type="text"
              className={styles.message_subject}
              name="subject"
              id="subject"
              onChange={handleChange}
              value={message.subject}
              placeholder="Enter subject here..."
            />
          </label>
        </div>
        <div>
          <label className="w-100" htmlFor="body">
            Message
            <textarea
              type="text"
              className={styles.message_body}
              name="body"
              id="body"
              onChange={handleChange}
              value={message.body}
              placeholder="Enter message here..."
            />
          </label>
        </div>
        {/* <div>
          <label className="w-100">
            Attachment
            <input type="file" className={styles.message_attachment} />
          </label>
        </div> */}
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            disabled={isLoading || !message.subject || !message.body}
            className="px-5 btn btn-primary fs-2 text-left"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChatForm;
