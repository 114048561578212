import { apiSlice } from "../../apiSlice";

export const getProductDetailsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductDetails: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/marketplace/products/${credentials.productId}`,
      providesTags: ["Cart"],
    }),
  }),
});

export const { useGetProductDetailsQuery } = getProductDetailsSlice;
