import React from "react";
import { Outlet } from "react-router-dom";

function ProductToRollover() {
  return (
    <div>
      <h1>Rollover</h1>
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default ProductToRollover;
