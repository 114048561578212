import { apiSlice } from "../../apiSlice";

export const getTotalContributionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTotalContribution: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/total_contributions`,
      providesTags: ["Saving"],
    }),
  }),
});

export const { useGetTotalContributionQuery } = getTotalContributionSlice;
