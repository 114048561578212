/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Row, Col, Alert } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import styles from "./LoginForm.module.css";

import { formatAmount } from "../../helperFunctions/helpersFunctions";
import { useMakeContributionCashRoundMutation } from "../../store/api/requests/POST/makeContributionCashRoundApiSlice";
import { useGetCashRoundExpectedAmountQuery } from "../../store/api/requests/GET/getCashRoundExpectedAmountApiSlice";

function AddContributionCashRound({ close }) {
  const { categoryName, Id } = useParams();
  const { data, isFetching } = useGetCashRoundExpectedAmountQuery({
    group: categoryName,
    groupId: Id,
  });

  const isToPay = data?.expected_pay_amount === 0;

  const [makeContributionCashRound, { isLoading }] =
    useMakeContributionCashRoundMutation();

  const [inputValues, setInputValues] = useState({
    amount: "",
    pin: "",
  });
  const [errors, setErrors] = useState({
    passError: "",
    amountError: "",
  });

  const [step, setStep] = useState(1);

  useEffect(() => {
    setErrors({ passError: "", amountError: "" });
  }, [inputValues]);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const paymentData = {
        id: Id,
        groupType: categoryName,
        paid_amount: +inputValues.amount,
        amount: +inputValues.amount,
        wallet_pin: inputValues.pin,
      };

      await makeContributionCashRound(paymentData).unwrap();
      close();
      toast.success("Payment made successfully");
    } catch (error) {
      toast.error(error);

      setErrors({ ...errors, passError: error?.data?.message });
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <Alert
        variant={isToPay ? "danger" : "info"}
        style={{
          fontSize: "16px",
          borderRadius: "10px",
          fontStyle: "italic",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "14px",
          }}
        >
          <BsInfoCircle style={{ width: "24px", height: "24px" }} />

          {isToPay ? (
            <span>
              <em>No Payment required today</em>{" "}
            </span>
          ) : (
            <span>
              {" "}
              Expected amount
              <strong>
                {" "}
                {isFetching ? (
                  <p style={{ display: "inline-block" }}>Loading...</p>
                ) : (
                  formatAmount(data?.expected_pay_amount)
                )}
              </strong>{" "}
            </span>
          )}
        </div>
      </Alert>
      <form className={styles.form} style={{ display: isToPay && "none" }}>
        {step === 1 && (
          <>
            <Row>
              <Col>
                <div className={styles.form__control}>
                  <label htmlFor="amount">Amount</label>
                  <div className={styles.form__input}>
                    <input
                      type="text"
                      id="amount"
                      name="amount"
                      placeholder="Enter Amount"
                      onChange={inputChangeHandler}
                      disabled={isToPay}
                      className={
                        errors.amountError !== "" && styles.input__error
                      }
                    />
                  </div>
                  <span className={styles.input__error__container}>
                    {errors.amountError && errors.amountError}
                  </span>
                </div>
              </Col>
            </Row>
            <div className={styles.form__login}>
              <button
                type="button"
                onClick={() => {
                  if (inputValues.amount === "") {
                    setErrors({
                      ...errors,
                      amountError: "Amount is required",
                    });
                    return;
                  }
                  if (inputValues.amount > data?.expected_payment) {
                    setErrors({
                      ...errors,
                      amountError: `Only ${formatAmount(
                        data?.expected_payment,
                      )} or below is required`,
                    });
                    return;
                  }
                  setStep(2);
                }}
                disabled={isLoading || isToPay}
              >
                Next
              </button>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <Row>
              <Col>
                <div className={styles.form__control}>
                  <label htmlFor="amount">Wallet Pin</label>
                  <div className={styles.form__input}>
                    <input
                      type="password"
                      id="pin"
                      name="pin"
                      placeholder="Enter Pin"
                      onChange={inputChangeHandler}
                      disabled={isToPay}
                      className={errors.passError !== "" && styles.input__error}
                    />
                  </div>
                  <span className={styles.input__error__container}>
                    {errors.passError && errors.passError}
                  </span>
                </div>
              </Col>
            </Row>
            <div className={styles.form__login}>
              <button
                type="button"
                onClick={onSubmitHandler}
                disabled={isLoading || isToPay}
              >
                {isLoading ? <LoadingSpinner /> : "Make A Payment"}
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default AddContributionCashRound;
