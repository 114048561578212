import React from "react";
// import { Col, Row } from "react-bootstrap";
import styles from "./Help.module.css";
import ChatRoom from "../../components/ChatRoom/ChatRoom";

export function Help() {
  // const edit = true;
  return (
    <div className={styles.help}>
      <ChatRoom />
    </div>
  );
}
