import React from "react";

function Wallet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.643 1.59756C24.1808 0.92582 26.6666 2.83929 26.6666 5.46438V6.66665C28.8758 6.66665 30.6666 8.45752 30.6666 10.6666V26.6666C30.6666 28.8759 28.8758 30.6666 26.6666 30.6666H5.33331C3.12418 30.6666 1.33331 28.8759 1.33331 26.6666V10.0526C1.33331 8.23769 2.55525 6.65022 4.30974 6.1858L21.643 1.59756ZM12.9141 6.66668L22.3254 4.17544C23.1713 3.95153 24 4.58936 24 5.46438V6.66672L12.9141 6.66668ZM26.6666 9.33332H5.33331C4.59694 9.33332 3.99998 9.93028 3.99998 10.6666V26.6666C3.99998 27.403 4.59694 28 5.33331 28H26.6666C27.403 28 28 27.403 28 26.6666V22.6668H24C21.7908 22.6668 20 20.8758 20 18.6668C20 16.4576 21.7908 14.6668 24 14.6668H28V10.6666C28 9.93029 27.403 9.33334 26.6666 9.33332ZM28 17.3334H24C23.2636 17.3334 22.6666 17.9304 22.6666 18.6668C22.6666 19.403 23.2636 20.0001 24 20.0001H28V17.3334Z"
        fill="white"
      />
    </svg>
  );
}

export default Wallet;
