import { apiSlice } from "../../apiSlice";

export const getContributionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContributions: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/contributions`,
      providesTags: ["Contributions"],
    }),
  }),
});

export const { useGetContributionsQuery } = getContributionsSlice;
