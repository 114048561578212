import { apiSlice } from "../../apiSlice";

export const acceptNumberChangeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    acceptNumberChange: builder.mutation({
      query: (urlParameters) => ({
        url: `/${urlParameters?.groupType}s/${urlParameters?.id}/draw_changes/${urlParameters?.itemId}`,
        method: "POST",
        body: { ...urlParameters },
      }),
      invalidatesTags: ["CashRoundInvitations"],
    }),
  }),
});

export const { useAcceptNumberChangeMutation } = acceptNumberChangeApiSlice;
