import { apiSlice } from "../../apiSlice";

export const fundAccountSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fundAccount: builder.mutation({
      query: (credentials) => ({
        url: "/users/fund_account",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Balance"],
    }),
  }),
});

export const { useFundAccountMutation } = fundAccountSlice;
