import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Col, Row } from "react-bootstrap";
import { FilterAddNew } from "../../../../components/Filters";

import SavingForm from "../../../../components/Forms/SavingForm";
import JebaModal from "../../../../components/ui/Modal/Modal";
import AddSavingProduct from "../../../../components/Forms/AddSavingProduct";
import SavingsSection from "./SavingsSection/SavingsSection";
import SavingProductsSection from "./SavingProductsSection/SavingProductsSection";
import { selectRole } from "../../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../../constants/constants";

function Savings() {
  const [isOpen, setIsOpen] = useState(false);
  const [openSaccoProduct, setOpenSaccoProduct] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const openHandler = () => setIsOpen(true);
  const closeHandler = () => setIsOpen(false);

  const refreshHandler = () => {
    setRefresh(refresh + 1);
  };

  return (
    <>
      <FilterAddNew
        searchItem="Saving"
        buttonText="Save Money"
        open={openHandler}
        refresh={refreshHandler}
        onRefresh={isLoading}
      />

      <JebaModal title="Save Money" show={isOpen} onHide={closeHandler}>
        <SavingForm close={closeHandler} />
      </JebaModal>
      <Row>
        <Col lg={6} md={6}>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginTop: "12px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
                textTransform: "capitalize",
              }}
            >
              Savings
            </h4>
            <SavingsSection refresh={refresh} setLoading={setIsLoading} />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginTop: "12px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
                textTransform: "capitalize",
              }}
            >
              Saving products
              {isAdmin && (
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    border: "none",
                    borderRadius: "50px",
                    backgroundColor: "#0866be",
                    fontSize: "10px",
                    textTransform: "capitalize",
                    padding: "8px",
                  }}
                  type="button"
                  onClick={() => setOpenSaccoProduct(true)}
                >
                  Add Saving product
                </button>
              )}
              <JebaModal
                show={openSaccoProduct}
                title="Add Saving Product"
                onHide={() => setOpenSaccoProduct(false)}
              >
                <AddSavingProduct close={() => setOpenSaccoProduct(false)} />
              </JebaModal>
            </h4>
            <SavingProductsSection
              refresh={refresh}
              setLoading={setIsLoading}
            />
          </div>
        </Col>
      </Row>
      {/* <Table data={data} columns={COLUMNS} /> */}
    </>
  );
}

export default Savings;
