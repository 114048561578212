import { apiSlice } from "../../apiSlice";

export const getAllProductsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/marketplace/products`,
      providesTags: ["Product"],
    }),
  }),
});

export const { useGetAllProductsQuery } = getAllProductsSlice;
