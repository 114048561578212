// /build_credit/caabbddcae4874e513099aee94cc9c/schedules

import { apiSlice } from "../../apiSlice";

export const getBuilderHistorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuilderHistory: builder.query({
      query: () => "/build_credit/history",
      providesTags: ["BuildCredit"],
    }),
  }),
});

export const { useGetBuilderHistoryQuery } = getBuilderHistorySlice;
