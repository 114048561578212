import { apiSlice } from "../../apiSlice";

export const trackIncomeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    trackIncome: builder.mutation({
      query: (credentials) => ({
        url: "/user/money-tracker/cash-in",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["TRACK_INCOME", "TRACK_EXPENSE"],
    }),
  }),
});

export const { useTrackIncomeMutation } = trackIncomeSlice;
