/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import jwtDecode from "jwt-decode";

import "./App.css";
import {
  Dashboard,
  Groups,
  Invitations,
  Login,
  Transactions,
  UserDashboard,
} from "./pages";
import LoginForm from "./components/Forms/LoginForm";
import SignUp from "./components/Forms/SignUp";
import Verify from "./components/Forms/Verify";
import Sacco from "./pages/Group/Sacco/Sacco";
import Vsla from "./pages/Group/Vsla/Vsla";
import Club from "./pages/Group/Clubs/Club";
import GroupSingle from "./pages/Group/GroupSingle/GroupSingle";
import {
  logOut,
  selectCurrentToken,
  selectIsAuthenticated,
} from "./store/slice/authSlice";
import Profile from "./pages/Profile/Profile";
import {
  GroupTransactions,
  Loans,
  Members,
  Savings,
  Shares,
} from "./pages/Group/GroupSingle";
import Borrowers from "./pages/Group/GroupSingle/Borrowers/Borrowers";
import News from "./pages/Group/GroupSingle/News/News";
import Income from "./pages/Group/GroupSingle/Income/Income";
import { useGetAllUserInvitationsQuery } from "./store/api/requests/GET/getAllUserInvitationsApiSlice";
import Wallet from "./pages/Wallet/Wallet";
import Reports from "./pages/Group/GroupSingle/Reports/Reports";
import BuildCredit from "./pages/BuildCredit/BuildCredit";
import Contributions from "./pages/Group/GroupSingle/Contributions/Contributions";
import BuildCreditProduct from "./pages/BuildCredit/BuildCreditProduct/BuildCreditProduct";
import Overview from "./pages/BuildCredit/Overview/Overview";
import MarketPlace from "./pages/Group/GroupSingle/MarketPlace/MarketPlace";
import Cart from "./pages/Group/GroupSingle/MarketPlace/Cart/Cart";
import Products from "./pages/Group/GroupSingle/MarketPlace/Products/Products";
import ProductSingle from "./pages/Group/GroupSingle/MarketPlace/ProductSingle/ProductSingle";
import Schedule from "./pages/BuildCredit/Schedule/Schedule";
import Redemption from "./pages/BuildCredit/Redemption/Redemption";
import Rollover from "./pages/BuildCredit/Rollover/Rollover";
import RolloverProducts from "./pages/BuildCredit/Rollover/RolloverProducts/RolloverProducts";
import ProductToRollover from "./pages/BuildCredit/Rollover/ProductToRollover";
import PaymentHistory from "./pages/BuildCredit/Payment/PaymentHistory";
import BuilderHistory from "./pages/BuildCredit/BuilderHistory/BuilderHistory";
import ScoreHistory from "./pages/BuildCredit/ScoreHistory/ScoreHistory";
import OrderSingle from "./pages/Group/GroupSingle/MarketPlace/Orders/OrderSingle/OrderSingle";
import OrdersIndex from "./pages/Group/GroupSingle/MarketPlace/Orders";
import Orders from "./pages/Group/GroupSingle/MarketPlace/Orders/Orders/Orders";
import ForgotPassword from "./components/Forms/ForgotPassword";
import VerifyForgotPasswordCode from "./components/Forms/VerifyForgotPasswordCode";
import SetNewPassword from "./components/Forms/SetNewPassword";
import JebaModal from "./components/ui/Modal/Modal";
import CashOut from "./pages/BuildCredit/CashOut/CashOut";
import Resubscribe from "./pages/BuildCredit/Resubscribe";
import { Help } from "./pages/Help/Help";
import GenerateLoginOtp from "./components/Forms/GenerateLoginOtp";
import LoginWithOtp from "./components/Forms/LoginWithOtp";
import ExtendSession from "./components/Common/ExtendSession/ExtendSession";
import MoneyTracker from "./pages/MoneyTracker/MoneyTracker";
import CashRound from "./pages/Group/CashRound/CashRound";
import GroupSingleCashRound from "./pages/Group/GroupSingle/GroupSingleCashRound";
import Rounds from "./pages/Group/GroupSingle/CashRound/Rounds";
import Withdraw from "./pages/Group/GroupSingle/CashRound/Withdraw";
import Draws from "./pages/Group/GroupSingle/CashRound/Draws";
import Contribution from "./pages/Group/GroupSingle/CashRound/Contribution";
import Policy from "./pages/Group/GroupSingle/CashRound/Policy";
import CashRoundMembers from "./pages/Group/GroupSingle/CashRound/CashRoundMembers";
import ConfirmPin from "./components/Forms/ConfirmPin";
import GenerateSmsOtp from "./components/Forms/GenerateSmsOtp";
import LoginWithSms from "./components/Forms/LoginWithSms";

function App() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(selectCurrentToken);
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(false);
  const [confirmPin, setConfirmPin] = useState(false);

  let timeout;

  useEffect(() => {
    window.onpopstate = () => {
      setConfirmPin(false);
      dispatch(logOut());
    };

    if (isAuthenticated) {
      setConfirmPin(true);
      const decodedToken = jwtDecode(token);
      const time = new Date(decodedToken.exp * 1000);
      const currentTime = new Date();
      const expTime = time.getTime() - currentTime.getTime() - 600000;
      timeout = setTimeout(() => {
        setTimer(true);
      }, expTime);
    }
    return () => {
      clearTimeout(timeout);
      setConfirmPin(false);
    };
  }, [isAuthenticated]);

  const timeOutHandler = () => {
    dispatch(logOut());
    setTimer(false);
    clearTimeout(timeout);
    navigate("/login");
  };

  const extendHandler = () => {
    setTimer(false);
    clearTimeout(timeout);
  };

  useGetAllUserInvitationsQuery();
  return (
    <>
      <ToastContainer
        position="top-center"
        style={{ fontFamily: "Gilroy-Medium", fontSize: "16px" }}
      />
      <JebaModal
        show={confirmPin}
        onHide={() => {
          dispatch(logOut());
          setConfirmPin(false);
          navigate("/login");
        }}
        title="Confirm Wallet Pin"
      >
        <ConfirmPin
          closeModal={() => {
            setConfirmPin(false);
          }}
        />
      </JebaModal>

      <JebaModal title="Extend Session" show={timer} onHide={timeOutHandler}>
        <ExtendSession close={timeOutHandler} extend={extendHandler} />
      </JebaModal>

      <Routes>
        <Route path="/*" element={<Login />}>
          <Route index element={<Navigate to="/login" replace />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="register" element={<SignUp />} />
          <Route path="verify" element={<Verify />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="verify-code" element={<VerifyForgotPasswordCode />} />
          <Route path="password-reset" element={<SetNewPassword />} />
          <Route path="generate-login-otp" element={<GenerateLoginOtp />} />
          <Route path="generate-login-sms-otp" element={<GenerateSmsOtp />} />
          <Route path="login-with-otp" element={<LoginWithOtp />} />
          <Route path="login-with-sms-otp" element={<LoginWithSms />} />
        </Route>

        <Route
          path="/dashboard/*"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
        >
          {/* <Route path="/dashboard/*" element={<Dashboard />}> */}
          <Route index element={<Navigate to="user" replace />} />
          <Route path="user" element={<UserDashboard />} />
          <Route path="my-wallet" element={<Wallet />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="invitations" element={<Invitations />} />
          <Route path="money-tracker" element={<MoneyTracker />} />

          <Route path="groups/*" element={<Groups />}>
            <Route index element={<Navigate to="sacco" replace />} />
            <Route path="sacco" element={<Sacco />} />
            <Route path="club" element={<Club />} />
            <Route path="vsla" element={<Vsla />} />
            <Route exact path="cashround" element={<CashRound />} />
          </Route>
          <Route
            exact
            path="groups/:categoryName/:Id/*"
            element={<GroupSingle />}
          >
            <Route index element={<Navigate to="transactions" replace />} />
            <Route path="transactions" element={<GroupTransactions />} />
            <Route path="members" element={<Members />} />
            <Route path="loans" element={<Loans />} />
            <Route path="savings" element={<Savings />} />
            <Route path="shares" element={<Shares />} />
            <Route path="borrowers" element={<Borrowers />} />
            <Route path="news" element={<News />} />
            <Route path="income" element={<Income />} />
            <Route path="reports" element={<Reports />} />

            <Route path="contributions" element={<Contributions />} />
            <Route path="market-place/*" element={<MarketPlace />}>
              <Route index element={<Products />} />
              <Route path="cart" element={<Cart />} />
              <Route path="orders/*" element={<OrdersIndex />}>
                <Route index element={<Orders />} />
                <Route path=":orderId" element={<OrderSingle />} />
              </Route>
              <Route path=":productId" element={<ProductSingle />} />
            </Route>
            <Route path="cr" element={<GroupSingleCashRound />}>
              <Route index element={<Navigate to="rounds" replace />} />
              <Route path="rounds" element={<Rounds />} />
              <Route path="withdraw" element={<Withdraw />} />
              <Route path="draws" element={<Draws />} />
              <Route path="contribution" element={<Contribution />} />
              <Route path="policy" element={<Policy />} />
              <Route path="members" element={<CashRoundMembers />} />
            </Route>
          </Route>

          <Route path="profile" element={<Profile />} />
          <Route path="help" element={<Help />} />
          <Route path="build-credit" element={<BuildCredit />} />
          <Route path="re-subscribe" element={<Resubscribe />} />
          <Route path="re-subscribe/:Id" element={<BuildCreditProduct />} />
          <Route path="build-credit/:Id" element={<BuildCreditProduct />} />
          <Route path="build-credit/overview/*" element={<Overview />}>
            <Route index element={<Navigate to="schedule" replace />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="redemption" element={<Redemption />} />
            <Route path="payment-history" element={<PaymentHistory />} />
            <Route path="cash-out" element={<CashOut />} />
            <Route path="builder-history" element={<BuilderHistory />} />
            <Route path="score-history" element={<ScoreHistory />} />
            <Route path="rollover/*" element={<ProductToRollover />}>
              <Route index element={<RolloverProducts />} />
              <Route path=":Id" element={<Rollover />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
