import React from "react";
import { useParams } from "react-router-dom";

import TransactionSummary from "../../../../../../components/Dashboard/TransactionSummary/TransactionSummary";
import { useGetSavingsPerAccountTypeQuery } from "../../../../../../store/api/requests/GET/getSavingsPerAccountType";

function SavingAccounts() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };
  const { data } = useGetSavingsPerAccountTypeQuery(group);

  return (
    <div
      style={{
        height: "100%",
        borderRadius: "10px",
        backgroundColor: "#fff",
        paddingLeft: "18px",
        paddingRight: "18px",
        paddingTop: "15px",
        paddingBottom: "18px",
      }}
    >
      <h5
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Savings Accounts
      </h5>
      {data?.sacco_savings.map((account) => (
        <TransactionSummary
          key={Math.random()}
          item={account?.savings_account_name}
          itemValue={account.total_transaction_amount[0]}
        />
      ))}
    </div>
  );
}

export default SavingAccounts;
