/* eslint-disable react/prop-types */
import React from "react";

import { Row, Col } from "react-bootstrap";

import styles from "./Wallet.module.css";
import AccountTransfers from "./AccountTransfer/AccountTransfers";
import MobileTransfer from "./MobileTransfer/MobileTransfer";

function Wallet() {
  return (
    <div className={styles.wallet__container}>
      <Row className="gy-3" g={3}>
        <Col lg={6} md={5} style={{ background: "#fff" }}>
          <div className={styles.wallet__transfers}>
            <AccountTransfers />
          </div>
        </Col>
        <Col lg={6} md={4} style={{ background: "#fff" }}>
          <MobileTransfer />
          {/* <div className={styles.wallet__wallets}>
            <div>wallets</div>
            <div>pay limit</div>
          </div> */}
        </Col>
      </Row>
    </div>
  );
}

export default Wallet;
