import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbArrowsRightLeft } from "react-icons/tb";
import { GiTakeMyMoney, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { FaSackDollar } from "react-icons/fa6";
import { PiArrowsDownUpBold } from "react-icons/pi";
import { HiDocumentReport } from "react-icons/hi";
import { IoShareSharp, IoPeople } from "react-icons/io5";
import { FaCartArrowDown, FaNewspaper } from "react-icons/fa";

import styles from "./GroupSIngle.module.css";
import GroupSingleButton from "../../../components/Group/GroupSingleButton/GroupSingleButton";
import { useGetGroupQuery } from "../../../store/api/requests/GET/groupApiSlice";
import {
  resetRole,
  selectRole,
  setRole,
} from "../../../store/slice/memberRoleSlice";
import { useGetGroupBalanceQuery } from "../../../store/api/requests/GET/getGroupBalanceApiSlice";
import LoadingEllipse from "../../../components/ui/Loaders/LoadingEllipse";
import { USER_ROLES } from "../../../constants/constants";
import LoadingText from "../../../components/ui/Loaders/LoadingText";
import { useGetFinancialDetailsQuery } from "../../../store/api/requests/GET/getFinancialDetailsApiSlice";

function GroupSingle() {
  const dispatch = useDispatch();

  const { categoryName, Id } = useParams();
  const urlParameters = {
    group: categoryName,
    groupId: Id,
  };

  const { data: groupData, isFetching: dataIsFetching } =
    useGetGroupQuery(urlParameters);
  const { data: walletBalance, isFetching } = useGetGroupBalanceQuery({
    groupType: categoryName,
    id: Id,
  });
  const { data: financialDetails } = useGetFinancialDetailsQuery({
    groupType: categoryName,
    id: Id,
  });

  useEffect(() => {
    dispatch(resetRole());
    dispatch(setRole(groupData?.data?.member_role_id));
  }, [groupData]);

  // eslint-disable-next-line no-unsafe-optional-chaining

  const thousandSeparator = (amount) => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const groupWalletBalanceContent = (total) => {
    if (isFetching) {
      return <LoadingEllipse />;
    }

    return `${walletBalance?.wallet.currency || "UGX"} ${
      thousandSeparator(total) || 0
    }`;
  };

  return (
    <div className={styles.group__single__wrapper}>
      {categoryName !== "cashround" && (
        <div className={styles.group_single__head}>
          <div className={styles.group_single__image}>
            <div
              alt="group name"
              className={styles.group_single__image__profile}
            />
          </div>
          <div className={styles.group_single__details}>
            <div className={styles.group_single__name}>
              {" "}
              <h2 className={styles.group__single__heading}>
                {dataIsFetching ? (
                  <LoadingText css={{ marginLeft: "40px" }} />
                ) : (
                  groupData?.data?.name || "Group"
                )}
              </h2>
            </div>
            <div className={styles.group_single__description}>
              {" "}
              <p className={styles.group_description}>
                {dataIsFetching ? (
                  <LoadingText css={{ marginLeft: "40px" }} />
                ) : (
                  groupData?.data?.description || "Description"
                )}
              </p>
            </div>
            <div className={styles.group_single__financial__details}>
              <div
                className={styles.group__single__header}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <span>Account Balance: </span>
                  <span className={styles.group__single__amount}>
                    {groupWalletBalanceContent(
                      financialDetails?.sacco_wallet_balance,
                    )}
                  </span>
                </div>
                <div>
                  <span>Savings: </span>
                  <span className={styles.group__single__amount}>
                    {groupWalletBalanceContent(financialDetails?.sacco_savings)}
                  </span>
                </div>

                <div>
                  <span>Contributions: </span>
                  <span className={styles.group__single__amount}>
                    {" "}
                    {groupWalletBalanceContent(
                      financialDetails?.sacco_contributions,
                    )}
                  </span>
                </div>

                <div>
                  <span>Loans: </span>
                  <span className={styles.group__single__amount}>
                    {groupWalletBalanceContent(financialDetails?.sacco_loan)}
                  </span>
                </div>

                <div>
                  <span>Repayments: </span>
                  <span
                    className={styles.group__single__amount}
                    style={{ borderStyle: "none none none none" }}
                  >
                    {groupWalletBalanceContent(
                      financialDetails?.sacco_loan_repayment,
                    )}
                  </span>
                </div>

                <div>
                  <span>Members: </span>
                  <span
                    className={styles.group__single__amount}
                    style={{ borderStyle: "none none none none" }}
                  >
                    {groupData?.data?.active_members}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.group_single}>
        <div className={styles.group_single_info}>
          {categoryName !== "cashround" && (
            <div className={styles.group__single__buttons}>
              <div className={styles.group__single__nav__buttons}>
                <GroupSingleButton
                  text="Transactions"
                  link="transactions"
                  icon={<TbArrowsRightLeft />}
                />
                <GroupSingleButton
                  text="Loans"
                  link="loans"
                  icon={<GiTakeMyMoney />}
                />
                <GroupSingleButton
                  text="Savings"
                  link="savings"
                  icon={<FaSackDollar />}
                />
                <GroupSingleButton
                  text="Contributions"
                  link="contributions"
                  icon={<GiPayMoney />}
                />
                <GroupSingleButton
                  text="Income/Expenses"
                  link="income"
                  icon={<PiArrowsDownUpBold />}
                />
                {isAdmin && (
                  <GroupSingleButton
                    text="Borrowers"
                    link="borrowers"
                    icon={<GiReceiveMoney />}
                  />
                )}
                <GroupSingleButton
                  text="Reports"
                  link="reports"
                  icon={<HiDocumentReport />}
                />
                <GroupSingleButton
                  text="Shares"
                  link="shares"
                  icon={<IoShareSharp />}
                />
                <GroupSingleButton
                  text="Members"
                  link="members"
                  icon={<IoPeople />}
                />
                <GroupSingleButton
                  text="Market Place"
                  link="market-place"
                  icon={<FaCartArrowDown />}
                />
                <GroupSingleButton
                  text="News"
                  link="news"
                  icon={<FaNewspaper />}
                />
              </div>
            </div>
          )}
          <div className={styles.group__single__members}>
            <Outlet />
          </div>
        </div>
        {/* <div className={styles.group__chat}>
          <h2>Chat</h2>

          <div className={styles.group__chat__container__sender}>
            <div className={styles.group__chat__sender}>
              <img
                className={styles.group__chat__sender__image}
                src={Image}
                alt="customer support"
              />
              <span className={styles.group__chat__sender__name}>sharon</span>
            </div>
            <div className={styles.group__chat__message}>
              <p>Hello, I can’t make a payment with my wallet money</p>
              <span style={{ display: "block", textAlign: "right" }}>
                5:17 PM
              </span>
            </div>
          </div>

          <div className={styles.group__chat__container__receiver}>
            <div className={styles.group__chat__message__receiver}>
              <p>Hello, I can’t make a payment with my wallet money</p>
              <span style={{ display: "block", textAlign: "right" }}>
                5:17 PM
              </span>
            </div>
            <div className={styles.group__chat__receiver}>
              <span className={styles.group__chat__receiver__name}>
                isaac ssemugenyi
              </span>
              <img
                className={styles.group__chat__receiver__image}
                src={Image}
                alt="customer support"
              />
            </div>
          </div>
          <div className={styles.group__chat__form}>
            <form className={styles.group__chat__form__message}>
              <div className={styles.form__control}>
                <textarea placeholder="Type message" />
              </div>
              <div className={styles.form__control__button}>
                <GroupSingleButton text="Send" bgColor="#0866BE" />
              </div>
            </form>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default GroupSingle;
