import React, { useState } from "react";

import { toast } from "react-toastify";

import styles from "../Wallet.module.css";
import style from "./MobileTransfer.module.css";
import JebaModal from "../../../components/ui/Modal/Modal";
import { useTransferToMobileMutation } from "../../../store/api/requests/POST/transferToMobileApiSlice";

function MobileTransfer() {
  const [transferToMobile, { isLoading }] = useTransferToMobileMutation();
  const [isPin, setIsPin] = useState(false);

  const [inputValues, setInputValues] = useState({
    walletPin: "",
    selectedDate: "",
    mobileNumber: "",
    mobileMoney: "",
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitMobileTopUpHandler = async (event) => {
    event.preventDefault();
    try {
      const formData = {
        phone_number: inputValues.mobileNumber,
        amount: inputValues.mobileMoney,
        wallet_pin: inputValues.walletPin,
        scheduled_date: inputValues.selectedDate,
      };

      const response = await transferToMobile(formData).unwrap();
      if (response?.status === 200) {
        setIsPin(false);
        toast.success("Transferred successfully");
        setInputValues({
          walletPin: "",
          selectedDate: "",
          mobileNumber: "",
          mobileMoney: "",
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.wallet__wallet__mobile__top_up}>
      <h4>Send Money To Mobile Number</h4>
      <form className={style.mobile_transfer}>
        <label>Mobile Number</label>
        <input
          type="number"
          placeholder="Enter Number here"
          name="mobileNumber"
          onChange={inputChangeHandler}
        />
        <div style={{ marginTop: "12px" }}>
          <label>Amount</label>
          <input
            type="number"
            placeholder="Enter Amount"
            name="mobileMoney"
            onChange={inputChangeHandler}
          />
          <div className={styles.form__buttons}>
            <button
              type="button"
              className={styles.active}
              onClick={() => setIsPin(true)}
              disabled={!inputValues.mobileMoney}
            >
              Send Money
            </button>
          </div>
          <JebaModal
            title="Enter Wallet Pin"
            show={isPin}
            onHide={() => setIsPin(false)}
          >
            <form onSubmit={onSubmitMobileTopUpHandler}>
              <div>
                <h5>Enter Pin</h5>
                <input
                  type="password"
                  placeholder="Enter Pin here"
                  name="walletPin"
                  id="walletPin"
                  onChange={inputChangeHandler}
                />
              </div>
              <div className={styles.form__buttons}>
                <button
                  type="submit"
                  className={styles.active}
                  disabled={
                    isLoading ||
                    !inputValues.walletPin ||
                    inputValues.walletPin.length < 5
                  }
                >
                  Confirm Pin
                </button>
              </div>
            </form>
          </JebaModal>
        </div>
      </form>
    </div>
  );
}

export default MobileTransfer;
