import { apiSlice } from "../../apiSlice";

export const restPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    restPassword: builder.mutation({
      query: (credentials) => ({
        url: "/reset_password",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useRestPasswordMutation } = restPasswordApiSlice;
