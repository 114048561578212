import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { loanId } from "../../../store/slice/modalSlice";
import { useGetLoansQuery } from "../../../store/api/requests/GET/getLoansApiSlice";

function ViewLoan() {
  const { categoryName, Id } = useParams();
  const { data: loans } = useGetLoansQuery({
    groupType: categoryName,
    id: Id,
  });
  const selectedLoan = useSelector(loanId);
  const loanData =
    loans?.loan_data?.find((loan) => loan.id === selectedLoan) || {};

  const capitalizeHandler = (string) => {
    const arr = string.split("_");
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  };
  return (
    <div style={{ maxHeight: "520px", overflow: "auto" }}>
      {" "}
      <table>
        <tbody>
          {Object?.keys(loanData)?.map((key) => (
            <tr key={Math.random()}>
              <td>
                <p>{capitalizeHandler(key)}</p>
              </td>
              <td>&nbsp;</td>
              <td>
                <p>
                  <strong>{loanData[key]}</strong>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ViewLoan;
