/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { BsPerson, BsTelephone } from "react-icons/bs";
import styles from "./LoginForm.module.css";
import { EmailIcon, PasswordIcon, PasswordHideIcon } from "../../assets/icons";

import { useRegisterMutation } from "../../store/api/requests/POST/regApiSlice";
import { setUserDetails } from "../../store/slice/regSlice";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

// eslint-disable-next-line react/prop-types
function SignUp() {
  const [register, { isLoading }] = useRegisterMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    phonenumber: "",
    password: "",
    confpassword: "",
  });

  const [errors, setErrors] = useState({});
  const isFormFilled = () => {
    return Object.values(inputValues).some((value) => value === "");
  };

  useEffect(() => {}, [inputValues]);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (inputValues.name === "") {
      setErrors({ name: "Name is required" });
    }
    if (inputValues.email === "") {
      setErrors({ email: "Email is required" });
    }
    if (inputValues.phonenumber === "") {
      setErrors({ phonenumberError: "Phone number is required" });
    }
    if (inputValues.password === "") {
      setErrors({ password: "Password is required" });
    }
    if (inputValues.confpassword === "") {
      setErrors({ confpassword: "Confirm password is required" });
    }

    try {
      const userData = {
        user_name: inputValues.name,
        email: inputValues.email,
        phone_number: inputValues.phonenumber,
        pass_word: inputValues.password,
        pass_word1: inputValues.confpassword,
        user_type: "Normal User",
      };

      const response = await register(userData).unwrap();
      dispatch(setUserDetails(userData));
      toast.success(`${response.message}`);
      navigate("/verify");
    } catch (err) {
      toast.error(err.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <h2>Get Started Now</h2>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.form__control}>
          <label htmlFor="name">Full name</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <BsPerson />
            </div>
            <input
              type="text"
              id="name"
              placeholder="Enter your full name"
              name="name"
              onChange={inputChangeHandler}
              className={errors.name ? styles.input__error : ""}
            />
          </div>
          {errors.name && (
            <span className={styles.input__error__container}>
              {errors.name}
            </span>
          )}
        </div>
        <div className={styles.form__control}>
          <label htmlFor="email">Email</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <EmailIcon />
            </div>
            <input
              type="email"
              id="email"
              placeholder="Enter your full email"
              name="email"
              onChange={inputChangeHandler}
              className={errors.email ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>{errors.email}</span>
        </div>
        <div className={styles.form__control}>
          <label htmlFor="phonenumber">Phone Number</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <BsTelephone />
            </div>
            <input
              type="text"
              id="phonenumber"
              placeholder="Enter your phone"
              name="phonenumber"
              onChange={inputChangeHandler}
              className={errors.phonenumberError ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {errors.phonenumberError}
          </span>
        </div>
        <div className={styles.form__control}>
          <label htmlFor="password">Password</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <PasswordIcon />
            </div>
            <div className={styles.password__show}>
              {" "}
              <PasswordHideIcon onClick={setShowPassword} />
            </div>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Create your password"
              name="password"
              onChange={inputChangeHandler}
              className={errors.password ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {errors.password}
          </span>
        </div>
        <div className={styles.form__control}>
          <label htmlFor="confpassword">Confirm Password</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <PasswordIcon />
            </div>
            <div className={styles.password__show}>
              {" "}
              <PasswordHideIcon onClick={setShowPassword} />
            </div>
            <input
              type={showPassword ? "text" : "password"}
              id="confpassword"
              placeholder="Confirm password"
              name="confpassword"
              onChange={inputChangeHandler}
              className={errors.confpassword ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {errors.confpassword}
          </span>
        </div>

        <div className={styles.form__login}>
          <button type="submit" disabled={isFormFilled() || isLoading}>
            {isLoading ? <LoadingSpinner /> : " Sign up"}
          </button>
          <span />
        </div>

        <div className={styles.form__register}>
          <span>Already have an account?</span>
          <span
            role="button"
            tabIndex={-42}
            className={styles.signup}
            onClick={() => navigate("/login")}
          >
            {" "}
            Login
          </span>
        </div>
      </form>
    </div>
  );
}

export default SignUp;
