import { apiSlice } from "../../apiSlice";

export const acceptInvitationCashRoundApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    acceptInvitationCashRound: builder.mutation({
      query: (urlParameters) => ({
        url: `/${urlParameters?.groupType}s/invite/${urlParameters?.Id}`,
        method: "POST",
        body: { ...urlParameters },
      }),
      invalidatesTags: ["Invitations"],
    }),
  }),
});

export const { useAcceptInvitationCashRoundMutation } =
  acceptInvitationCashRoundApiSlice;
