import { apiSlice } from "../../apiSlice";

export const getUserBalanceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserBalance: builder.query({
      query: () => "/users/wallet_balance",
      providesTags: ["Balance"],
    }),
  }),
});

export const { useGetUserBalanceQuery } = getUserBalanceSlice;
