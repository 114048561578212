/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import styles from "./LoginForm.module.css";

import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import { EmailIcon } from "../../assets/icons";
import { setUserDetails } from "../../store/slice/regSlice";
import { useSendLoginOtpMutation } from "../../store/api/requests/POST/sendLoginOtpApiSlice";

function GenerateLoginOtp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendLoginOtp, { isLoading }] = useSendLoginOtpMutation();

  const [userEmail, setUserEmail] = useState("");
  const [codeError, setCodeError] = useState("");

  useEffect(() => {
    setCodeError("");
  }, [userEmail]);

  const codeChangeHandler = (e) => {
    setUserEmail(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (userEmail.length < 1) {
      setCodeError("Please provide your Email");
    }

    try {
      const userData = {
        email: userEmail,
      };
      const response = await sendLoginOtp(userData).unwrap();
      toast.success(`${response.message}`);
      dispatch(setUserDetails(userData));
      navigate("/login-with-otp");
    } catch (err) {
      toast.error(err.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <h2>Generate OTP</h2>
      <p>Enter your e-mail address and we will send you a code to login</p>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.form__control}>
          <label htmlFor="email">Email</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <EmailIcon />
            </div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email"
              onChange={codeChangeHandler}
              className={codeError ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {codeError && codeError}
          </span>
        </div>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading || !userEmail}>
            {isLoading ? <LoadingSpinner /> : "Send OTP"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default GenerateLoginOtp;
