import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { BsCart3 } from "react-icons/bs";
import { HiOutlineShoppingBag } from "react-icons/hi2";

import styles from "../MarketPlace.module.css";
import { selectRole } from "../../../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../../../constants/constants";
import { FilterAddNew } from "../../../../../components/Filters";
import JebaModal from "../../../../../components/ui/Modal/Modal";
import AddProduct from "../../../../../components/Forms/AddProduct";
import ProductCard from "../../../../../components/Dashboard/ProductCard/ProductCard";
import { useGetAllProductsQuery } from "../../../../../store/api/requests/GET/getAllProductApiSlice";
import { useGetCartProductsQuery } from "../../../../../store/api/requests/GET/getCartProductsApiSlice";
import { useGetOrderProductsQuery } from "../../../../../store/api/requests/GET/getProductOrdersApiSlice";
import { updateCart } from "../../../../../store/slice/cartSlice";

function Products() {
  const dispatch = useDispatch();
  const { categoryName, Id } = useParams();
  const {
    data,
    isFetching,
    refetch: refetchAllProducts,
  } = useGetAllProductsQuery({
    groupType: categoryName,
    id: Id,
  });
  const { data: cart, isFetching: cartIsFetching } = useGetCartProductsQuery({
    groupType: categoryName,
    id: Id,
  });
  const { data: orders } = useGetOrderProductsQuery({
    groupType: categoryName,
    id: Id,
  });
  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(updateCart(cart?.marketplace_products));
  }, [cartIsFetching]);

  return (
    <>
      <Row
        style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}
      >
        <Col lg={8} sm={12}>
          {" "}
          <FilterAddNew
            searchItem="Product"
            buttonText="Add Product"
            open={() => setOpen(true)}
            hideButton={isAdmin}
            refresh={refetchAllProducts}
            onRefresh={isFetching}
          />
        </Col>
        <Col lg={2} sm={6} xs={6}>
          <div className={styles.cart}>
            {cart?.marketplace_products?.length > 0 && (
              <span className={styles.cart_items}>
                {cart?.marketplace_products?.length}
              </span>
            )}
            <NavLink to="cart">
              <BsCart3 />
              Cart
            </NavLink>
          </div>
        </Col>
        <Col lg={2} sm={6} xs={6}>
          <div className={styles.cart}>
            {orders?.orders?.length > 0 && (
              <span className={styles.cart_items}>
                {orders?.orders?.length}
              </span>
            )}
            <NavLink to="orders">
              <HiOutlineShoppingBag />
              Orders
            </NavLink>
          </div>
        </Col>
      </Row>
      <JebaModal show={open} onHide={() => setOpen(false)} title="Add Product">
        <AddProduct close={() => setOpen(false)} />
      </JebaModal>
      <Row className="gy-2 gx-2" style={{ opacity: isFetching ? 0.2 : 1 }}>
        {data?.marketplace_products?.map((product) => (
          <Col lg={2} md={3} sm={4} xs={6} key={Math.random()}>
            <ProductCard
              name={product.name}
              description={product.description}
              price={product.price}
              discount={product.discount}
              id={product.id}
              isAdded={product.in_user_cart}
              qtyInCart={product?.quantity_in_user_cart?.[0]?.quantity}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Products;
