import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetAllRedemptionsQuery } from "../../../store/api/requests/GET/getAllRedemptionsApiSlice";
import { capitalizeHandler } from "../../../helperFunctions/helpersFunctions";
import { useRedeemMutation } from "../../../store/api/requests/POST/redeemApiSlice";
import JebaModal from "../../../components/ui/Modal/Modal";
import LoadingSpinner from "../../../components/ui/Loaders/LoadingSpinner";

function Redemption() {
  const navigate = useNavigate();

  const { data, isFetching } = useGetAllRedemptionsQuery();
  const [redeem, { isLoading }] = useRedeemMutation();
  const [walletPin, setWalletPin] = useState("");
  const [openModal, setOpenModal] = useState("");

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      await redeem({
        builder_id: data?.credit_under_redemption?.id,
        wallet_pin: walletPin,
      }).unwrap();
      setOpenModal(false);
      toast.success("Redeemed");
      navigate("../schedule");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <div>
      <h1>Redemption</h1>
      <div style={{ overflow: "auto" }}>
        {isFetching ? (
          <p>Loading details...</p>
        ) : (
          <div>
            {Object?.keys(data?.credit_under_redemption)?.length === 0 ? (
              <p>No Credit To Redeem, Continue Building Your Credit</p>
            ) : (
              <div>
                <table className="table">
                  <tbody>
                    {Object?.keys(data?.credit_under_redemption)?.map((key) => (
                      <tr key={Math.random()} className="table-row">
                        <td className="table-cell">
                          <p>{capitalizeHandler(key)}</p>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                          <p>
                            <strong>
                              {data?.credit_under_redemption[key]}
                            </strong>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  type="button"
                  disabled={isLoading}
                  className="btn btn-primary fs-2 font-weight-bold btn-lg btn-block text-right w-100"
                  onClick={() => setOpenModal(true)}
                >
                  <span className="font-weight-bold">Redeem</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <JebaModal
        show={openModal}
        onHide={() => setOpenModal(false)}
        title="Confirm Pin"
      >
        <form onSubmit={submitHandler}>
          <div>
            <label htmlFor="pin">Wallet Pin</label>
            <div>
              <input
                type="password"
                id="pin"
                name="pin"
                placeholder="Enter Wallet Pin"
                onChange={(event) => setWalletPin(event.target.value)}
                style={{ padding: "8px" }}
              />
            </div>
            <span>{/* {errors.emailError && errors.emailError} */}</span>
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary fs-2 font-weight-bold btn-lg btn-block text-right w-100 mt-3"
            >
              {isLoading ? <LoadingSpinner /> : "Confirm Pin"}
            </button>
          </div>
        </form>
      </JebaModal>
    </div>
  );
}

export default Redemption;
