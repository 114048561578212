import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../slice/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://hagfish-vocal-scarcely.ngrok-free.app/api",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = getState().auth;
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    headers.set("Content-Type", "application/json");
    headers.set("ngrok-skip-browser-warning", "true");

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  // send refresh token to get a new access token
  if (result?.error?.originalStatus === 401) {
    const refreshResult = await baseQuery("/refresh", api, extraOptions);
    if (refreshResult?.data) {
      const { user } = api.getState().auth;
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 10,
  tagTypes: [
    "Balance",
    "Group",
    "Invitations",
    "LoanProducts",
    "Loans",
    "LoanApplications",
    "News",
    "Income",
    "Expense",
    "Financial",
    "SavingProduct",
    "Saving",
    "ContributionProducts",
    "Contributions",
    "Product",
    "BuildCredit",
    "Cart",
    "Orders",
    "GroupTransactions",
    "Shares",
    "ShareTransfers",
    "User",
    "Message",
    "TRACK_INCOME",
    "TRACK_EXPENSE",
    "Draws",
    "CashRoundInvitations",
  ],
  endpoints: () => ({}),
});
