import React from "react";
import PropTypes from "prop-types";
import styles from "./MessageItem.module.css";

function MessageItem({ received = false, message, date }) {
  const time = new Date(date);

  return (
    <div
      className={styles.message_item}
      style={{ alignItems: received ? "flex-start" : "flex-end" }}
    >
      <div className={styles.message_info}>
        <span className={styles.name}>Isaac</span>
        <span className={styles.time}>{time.toLocaleTimeString()}</span>
        <span className={styles.period}>Today</span>
      </div>
      <div
        className={styles.message}
        style={{
          backgroundColor: received ? "#0866be" : "#fff",
          color: received ? "#fff" : "#0866be",
        }}
      >
        <p>{message}</p>
      </div>
    </div>
  );
}
MessageItem.propTypes = {
  received: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
export default MessageItem;
