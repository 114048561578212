import React from "react";

function Help() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_205_69)">
        <path
          d="M10.5125 24.0562C9.575 23.7188 8.53125 23.8625 7.725 24.4562C7.2125 24.8312 6.33125 25.3813 5.2625 25.875C5.6125 24.9562 5.88125 23.9188 5.96875 22.7875C6.03125 21.9813 5.7625 21.1812 5.23125 20.5688C3.775 18.925 3 17 3 15C3 10.0312 8.20625 5 16 5C23.7938 5 29 10.0312 29 15C29 19.9688 23.7938 25 16 25C14.025 25 12.1687 24.6562 10.5125 24.0562ZM1.64375 26.4875C1.54375 26.6562 1.4375 26.825 1.325 26.9937L1.30625 27.025C1.20625 27.1687 1.10625 27.3125 1.00625 27.4562C0.7875 27.75 0.55 28.0375 0.3 28.3C0.0125 28.5875 -0.06875 29.0125 0.0875 29.3875C0.24375 29.7625 0.60625 30.0063 1.0125 30.0063C1.33125 30.0063 1.65 29.9875 1.96875 29.9562L2.0125 29.95C2.2875 29.9188 2.5625 29.8812 2.8375 29.8312C2.8875 29.825 2.9375 29.8125 2.9875 29.8C4.1 29.5812 5.16875 29.2063 6.11875 28.7938C7.55 28.1688 8.76875 27.425 9.5125 26.8813C11.5 27.6 13.7 28 16.0187 28C24.8562 28 32.0187 22.1812 32.0187 15C32.0187 7.81875 24.8375 2 16 2C7.1625 2 0 7.81875 0 15C0 17.8188 1.10625 20.425 2.98125 22.5562C2.8625 24.0875 2.26875 25.45 1.64375 26.4875ZM9 17C9.53043 17 10.0391 16.7893 10.4142 16.4142C10.7893 16.0391 11 15.5304 11 15C11 14.4696 10.7893 13.9609 10.4142 13.5858C10.0391 13.2107 9.53043 13 9 13C8.46957 13 7.96086 13.2107 7.58579 13.5858C7.21071 13.9609 7 14.4696 7 15C7 15.5304 7.21071 16.0391 7.58579 16.4142C7.96086 16.7893 8.46957 17 9 17ZM18 15C18 14.4696 17.7893 13.9609 17.4142 13.5858C17.0391 13.2107 16.5304 13 16 13C15.4696 13 14.9609 13.2107 14.5858 13.5858C14.2107 13.9609 14 14.4696 14 15C14 15.5304 14.2107 16.0391 14.5858 16.4142C14.9609 16.7893 15.4696 17 16 17C16.5304 17 17.0391 16.7893 17.4142 16.4142C17.7893 16.0391 18 15.5304 18 15ZM23 17C23.5304 17 24.0391 16.7893 24.4142 16.4142C24.7893 16.0391 25 15.5304 25 15C25 14.4696 24.7893 13.9609 24.4142 13.5858C24.0391 13.2107 23.5304 13 23 13C22.4696 13 21.9609 13.2107 21.5858 13.5858C21.2107 13.9609 21 14.4696 21 15C21 15.5304 21.2107 16.0391 21.5858 16.4142C21.9609 16.7893 22.4696 17 23 17Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_205_69">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Help;
