import React from "react";
import { useParams } from "react-router-dom";

import { Col, Row } from "react-bootstrap";

import styles from "./Reports.module.css";
import AreaChart from "../../../../components/Dashboard/Charts/AreaChart/AreaChart";
import ReportCard from "../../../../components/Dashboard/ReportCard/ReportCard";

import { useGetIncomeReportsQuery } from "../../../../store/api/requests/GET/getIncomeReportsApiSlice";
import { useGetShareReportsQuery } from "../../../../store/api/requests/GET/getShareReportsApiSlice";

import DonutChart from "../../../../components/Dashboard/Charts/DonutChart/DonutChart";
import StatCard from "../../../../components/Dashboard/StatCard/StatCard";
import ProgressBar from "../../../../components/Dashboard/ProgressBar/ProgressBar";
import Button from "../../../../components/ui/Button/Button";
import TotalValueSection from "./TotalValueSection/TotalValueSection";

import MemberSection from "./MembersSection/MemberSection";
import SavingsSection from "./SavingsSection/SavingsSection";
import { useGetUserTotalLoansQuery } from "../../../../store/api/requests/GET/getUserTotalLoansApiSlice";
import { useGetUserTotalSavingsQuery } from "../../../../store/api/requests/GET/getTotalUserSavingsApiSlice";
import { useGetUserTotalContributionsQuery } from "../../../../store/api/requests/GET/getTotalUserContributionApiSlice";
import { useGetExpenseReportsQuery } from "../../../../store/api/requests/GET/getExpenseReportsApiSlice";
import { useGetTransactionReportsQuery } from "../../../../store/api/requests/GET/getTransactionReportsApiSlice";
import MonthlyContributions from "./ContributionsSection/MonthlyContributions/MonthlyContributions";
import ContributionAccount from "./ContributionsSection/ContributionAccounts/ContributionAccount";

function Reports() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };

  const { data: income, isFetching } = useGetIncomeReportsQuery(group);
  const { data: share, isFetching: shareIsFetching } =
    useGetShareReportsQuery(group);

  const { data: totalLoans, isFetching: totalLoansIsFetching } =
    useGetUserTotalLoansQuery(group);
  const { data: totalSavings, isFetching: totalSavingIsLoading } =
    useGetUserTotalSavingsQuery(group);
  const { data: totalContributions, isFetching: totalContributionsIsFetching } =
    useGetUserTotalContributionsQuery(group);

  const { data: expense, isFetching: expenseIsLoading } =
    useGetExpenseReportsQuery(group);

  const { data: transactions } = useGetTransactionReportsQuery(group);

  return (
    <>
      <div>
        <TotalValueSection />
      </div>
      <Row style={{ marginTop: "50px" }}>
        <Col lg={5} md={5}>
          <Row>
            <Col md={6} lg={6}>
              <ReportCard
                /**
                 * loan card props
                 * @Applications
                 * @repayments
                 * @approved
                 * @rejected
                 * @products
                 * @outstandingBalance
                 */
                title="loans"
                amount={totalLoans?.total_loan_amount_approved}
                isLoading={totalLoansIsFetching}
                data={totalLoans}
              />
              <ReportCard
                /**
                 * saving card props
                 * @products
                 * @accounts
                 * @savings
                 * @
                 */
                title="savings"
                plus
                amount={totalSavings?.total_savings}
                isLoading={totalSavingIsLoading}
                data={totalSavings}
              />
              <ReportCard
                /**
                 * Incomes card props
                 * @income
                 * @investmentAmount
                 *
                 */
                title="incomes"
                plus
                amount={income?.total_income_todate}
                isLoading={isFetching}
                data={income}
              />
            </Col>
            <Col md={6} lg={6}>
              <ReportCard
                /**
                 * Shares card props
                 * @numberOfTotalShareHolders
                 * @totalShares
                 * @availableShares
                 * @costPerShare
                 */
                title="shares"
                plus
                amount={share?.all_members_share_value}
                data={share}
                isLoading={shareIsFetching}
              />
              <ReportCard
                /**
                 * Transaction Props
                 * @totalDisbursement
                 * @repaymentTransactions
                 * @SavingTransactions
                 * @shareTransfer
                 * @sharePurchase
                 */
                title="contributions"
                amount={totalContributions?.total_contributions}
                isLoading={totalContributionsIsFetching}
                data={totalContributions}
              />
              <ReportCard
                /**
                 * Expense card props
                 * @expense
                 * @expenseActivities
                 *
                 *
                 */
                title="expenses"
                amount={expense?.total_expense}
                isLoading={expenseIsLoading}
                data={expense}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={7} md={7}>
          <div
            style={{
              height: "100%",
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <AreaChart
              title="Transactions"
              data={transactions?.transactions || []}
              months={transactions?.months || []}
            />
          </div>
        </Col>
      </Row>

      <SavingsSection />

      <Row style={{ marginTop: "25px" }}>
        <Col lg={5}>
          <div
            style={{
              height: "100%",
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <MonthlyContributions title="Contributions" />
          </div>
        </Col>
        <Col lg={7}>
          <Row>
            <Col lg={7}>
              <div
                style={{
                  height: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  paddingTop: "15px",
                  paddingBottom: "18px",
                }}
              >
                <h5
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Contribution Accounts
                </h5>
                <ContributionAccount />
              </div>
            </Col>
            <Col lg={5}>
              <div
                style={{
                  height: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  paddingTop: "15px",
                  paddingBottom: "18px",
                }}
              >
                <h5
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Contributions Trends
                </h5>

                <StatCard
                  item="Current Month"
                  value={totalContributions?.current_month_contributions}
                />
                <StatCard
                  item="Member Accounts"
                  value={totalContributions?.number_of_contributions_accounts}
                />
                <StatCard
                  item="Contributions To Date"
                  value={totalContributions?.total_contributions}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <MemberSection />

      <Row style={{ marginTop: "25px", display: "none" }}>
        <Col lg={5}>
          <div
            style={{
              height: "100%",
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <DonutChart />
          </div>
        </Col>
        <Col lg={7}>
          <Row style={{ margin: 0 }}>
            <Col lg={7}>
              <Row style={{ margin: 0 }}>
                <Col lg={6} style={{ padding: 0 }}>
                  <ProgressBar />
                  <ProgressBar />
                </Col>
                <Col lg={6} style={{ padding: "16px !important" }}>
                  <ProgressBar />
                  <ProgressBar />
                </Col>
              </Row>
            </Col>
            <Col lg={5}>
              {" "}
              <div className={styles.deposit}>
                <div className={styles.button}>
                  {" "}
                  <Button
                    textColor="#fff"
                    type="button"
                    borderRadius="4px"
                    backgroundColor="#0866BE"
                    buttonText="Download Report"
                  />
                </div>
                <div>
                  <h4>Download Full Report</h4>
                  <p>Please download group report</p>
                </div>
              </div>
              <div className={styles.deposit}>
                <div className={styles.button}>
                  {" "}
                  <Button
                    textColor="#fff"
                    type="button"
                    borderRadius="4px"
                    backgroundColor="#0866BE"
                    buttonText="Download Mobile App"
                  />
                </div>
                <div>
                  <h4>Add a new deposit</h4>
                  <p>Please create a new deposit for your savings account</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Welcome /> */}
    </>
  );
}

export default Reports;
