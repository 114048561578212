import { apiSlice } from "../../apiSlice";

export const inviteMemberCashRound = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    inviteMemberCashRound: builder.mutation({
      query: (credentials) => ({
        url: `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/invite`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["CashRoundInvitations"],
    }),
  }),
});

export const { useInviteMemberCashRoundMutation } = inviteMemberCashRound;
