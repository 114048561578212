import React from "react";

import { NavLink, Outlet } from "react-router-dom";

import styles from "./Group.module.css";

function Group() {
  const menuItems = [
    { name: "Sacco", link: "sacco" },
    { name: "Cash Round", link: "cashround" },
    { name: "Clubs", link: "club" },
    { name: "Vslas", link: "vsla" },
    { name: "Saving Groups", link: "saving-groups" },
  ];
  return (
    <div className={styles.group__container}>
      <div className={styles.group__navigation}>
        <nav className={styles.navigation}>
          <ul className={styles.list__items}>
            {menuItems.map((item) => (
              <li className={styles.list__item} key={Math.random()}>
                <NavLink
                  className={({ isActive }) =>
                    styles.list__item__link +
                    (isActive ? ` ${styles.active}` : "")
                  }
                  to={item.link}
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className={styles.group_content}>
        <Outlet />
      </div>
    </div>
  );
}

export default Group;
