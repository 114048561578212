import { apiSlice } from "../../apiSlice";

export const getSharesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShares: builder.query({
      query: (credentials) =>
        `/${
          credentials.groupType === "club"
            ? "investment_club"
            : credentials.groupType
        }s/${credentials.id}/shares`,
      providesTags: ["Shares"],
    }),
  }),
});

export const { useGetSharesQuery } = getSharesSlice;
