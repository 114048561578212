/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

import { useAddIncomeMutation } from "../../store/api/requests/POST/addIncomeApiSlice";
import { useAddExpenseMutation } from "../../store/api/requests/POST/addExpenseApiSlice";
import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";

function AddIncome({ close }) {
  const { categoryName, Id } = useParams();
  const [addIncome, { isLoading }] = useAddIncomeMutation();
  const [addExpense, { isLoading: expenseIsLoading }] = useAddExpenseMutation();
  const [isExpense, setIsExpense] = useState(false);

  const [inputValues, setInputValues] = useState({
    projectName: "",
    investmentAmount: "",
    incomeAmount: "",
    attachment: "",
    incomeDate: "",
    expenseActivity: "",
    expenseAmount: "",
    expenseAttachment: "",
    expenseDate: "",
  });

  useEffect(() => {
    setInputValues({
      projectName: "",
      investmentAmount: "",
      incomeAmount: "",
      attachment: "",
      incomeDate: "",
      expenseActivity: "",
      expenseAmount: "",
      expenseAttachment: "",
      expenseDate: "",
    });
  }, []);

  const onSubmitIncomeHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        income_generating_project: inputValues.projectName,
        investment_amount: inputValues.investmentAmount,
        income_amount: inputValues.incomeAmount,
        attachment: inputValues.attachment,
        income_date: inputValues.incomeDate,
      };
      await addIncome(data).unwrap();
      close();
      toast.success("Income Added");
    } catch (error) {
      toast.error(error.data.message);
    }
  };
  const onSubmitExpenseHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        expense_activity: inputValues.expenseActivity,
        expense_amount: inputValues.expenseAmount,
        attachment: inputValues.expenseAttachment,
        expense_date: inputValues.expenseDate,
      };
      await addExpense(data).unwrap();
      close();
      toast.success("Expense Added");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const getButtonContent = (loading, expenseState) => {
    if (loading) {
      return <LoadingSpinner />;
    }
    if (expenseState) {
      return "Add Expense";
    }
    return "Add Income";
  };
  const fileHandler = async (event) => {
    const file = event?.target?.files[0];
    const base64File = await convertFIleToBase64(file);
    const base64String = base64File?.split(",")[1];
    setInputValues({ ...inputValues, attachment: base64String });
  };

  const isChecked =
    !inputValues.projectName ||
    !inputValues.investmentAmount ||
    !inputValues.incomeAmount ||
    !inputValues.attachment ||
    !inputValues.incomeDate;

  return (
    <div className={styles.form__wrapper}>
      <form
        className={styles.form}
        onSubmit={!isExpense ? onSubmitIncomeHandler : onSubmitExpenseHandler}
      >
        <div className={`${styles.form__login} ${styles.form__top__button}`}>
          <button
            className={isExpense && styles.active}
            type="button"
            disabled={isLoading || expenseIsLoading}
            onClick={() => setIsExpense(false)}
          >
            Add Income
          </button>
          <button
            className={!isExpense && styles.active}
            type="button"
            disabled={isLoading || expenseIsLoading}
            onClick={() => setIsExpense(true)}
          >
            Add Expense
          </button>
        </div>
        {!isExpense ? (
          <Row>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="projectName">Project Name</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="projectName"
                    name="projectName"
                    placeholder="Enter Project Name"
                    onChange={inputChangeHandler}
                    value={inputValues.projectName}
                    //   value={inputValues.saccoName}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>

              <div className={styles.form__control}>
                <label htmlFor="investmentAmount">Investment Amount</label>
                <div className={styles.form__input}>
                  <input
                    type="number"
                    name="investmentAmount"
                    id="investmentAmount"
                    placeholder="Enter Investment Amount"
                    onChange={inputChangeHandler}
                    value={inputValues.investmentAmount}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="incomeAmount">Income Amount</label>
                <div className={styles.form__input}>
                  <input
                    type="number"
                    name="incomeAmount"
                    id="incomeAmount"
                    placeholder="Enter Income Amount"
                    onChange={inputChangeHandler}
                    value={inputValues.incomeAmount}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="attachment">Upload Attachment</label>
                <div className={styles.form__input}>
                  <input
                    type="file"
                    name="attachment"
                    id="attachment"
                    placeholder="Upload Attachment"
                    onChange={fileHandler}
                    value=""
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="incomeDate">Date</label>
                <div className={styles.form__input}>
                  <input
                    type="date"
                    name="incomeDate"
                    id="incomeDate"
                    placeholder="Enter Income Date"
                    onChange={inputChangeHandler}
                    value={inputValues.incomeDate}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="expenseActivity">Expense Activity</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="expenseActivity"
                    name="expenseActivity"
                    placeholder="Enter Expense Activity"
                    onChange={inputChangeHandler}
                    value={inputValues.expenseActivity}
                    //   value={inputValues.saccoName}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>

              <div className={styles.form__control}>
                <label htmlFor="expenseAmount">Expense Amount</label>
                <div className={styles.form__input}>
                  <input
                    type="number"
                    name="expenseAmount"
                    id="expenseAmount"
                    placeholder="Enter Expense Amount"
                    onChange={inputChangeHandler}
                    value={inputValues.expenseAmount}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="expenseAttachment">Upload Attachment</label>
                <div className={styles.form__input}>
                  <input
                    type="file"
                    name="expenseAttachment"
                    id="expenseAttachment"
                    placeholder="Upload Attachment"
                    onChange={fileHandler}
                    value=""
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="expenseDate">Date</label>
                <div className={styles.form__input}>
                  <input
                    type="date"
                    name="expenseDate"
                    id="expenseDate"
                    placeholder="Enter Expense Date"
                    onChange={inputChangeHandler}
                    value={inputValues.expenseDate}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
            </Col>
          </Row>
        )}
        <div className={styles.form__login}>
          <button
            type="submit"
            disabled={isLoading || expenseIsLoading || isChecked}
          >
            {/* {isLoading ? <LoadingSpinner /> : "Add Income"} */}
            {getButtonContent(isLoading || expenseIsLoading, isExpense)}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddIncome;
