/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { toast } from "react-toastify";
import Select from "react-select";
import { FiPlusCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

import styles from "../Wallet.module.css";
import style from "./AccountTransfer.module.css";
import { useGetRegisteredMembersQuery } from "../../../store/api/requests/GET/registeredMembersApiSlice";

import JebaModal from "../../../components/ui/Modal/Modal";
import { selectCurrentToken } from "../../../store/slice/authSlice";
import { useTransferToAccountMutation } from "../../../store/api/requests/POST/transferToAccountApiSlice";

function SelectedUser({ name, email }) {
  return (
    <div className={style.receiver}>
      <div className={style.receiver_image}>
        <span>
          {name
            ?.split(" ")
            .map((n) => n[0]?.toUpperCase())
            .join("")}
        </span>
      </div>
      <div className={style.receiver_details}>
        <h5 className={style.receiver_name}>{name}</h5>
        <p className={style.receiver_email}>{email}</p>
      </div>
    </div>
  );
}

function AccountTransfers() {
  const { data } = useGetRegisteredMembersQuery();
  const [transferToAccount, { isLoading }] = useTransferToAccountMutation();
  const sender = useSelector(selectCurrentToken);
  const senderName = jwtDecode(sender)?.sub.user_name;
  const senderEmail = jwtDecode(sender)?.sub.email;

  const [isReceiver, setIsReceiver] = useState(false);
  const [isPin, setIsPin] = useState(false);

  const [inputValues, setInputValues] = useState({
    userId: "",
    userName: "",
    userEmail: "",
    amount: "",
    walletPin: "",
    selectedDate: "",
  });

  const usersData = data?.map((user) => {
    return {
      value: user.id,
      label: user.user_name,
      email: user.email,
    };
  });

  const selectInputChangeHandler = (event) => {
    setInputValues({
      ...inputValues,
      userId: event.value,
      userName: event.label,
      userEmail: event.email,
    });
    setIsReceiver(false);
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const formData = {
        receiver_id: inputValues.userId,
        amount: inputValues.amount,
        wallet_pin: inputValues.walletPin,
        scheduled_date: inputValues.selectedDate,
      };

      const response = await transferToAccount(formData).unwrap();
      if (response?.status === 200) {
        setIsPin(false);
        toast.success("Transferred successfully");
        setInputValues({
          userId: "",
          userName: "",
          amount: "",
          walletPin: "",
          selectedDate: "",
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <div className={styles.wallet__transfer__quick__transfer}>
      <h4>Send Money To Wallet</h4>
      <form className={style.account_transfer}>
        <div className={style.account_transfer__form_control}>
          <label className={style.account_transfer__sending}>
            How much would you like to send?
          </label>
          <input
            type="number"
            placeholder="Enter Amount here"
            id="amount"
            name="amount"
            value={inputValues.amount}
            onChange={inputChangeHandler}
          />
        </div>
        <div className={style.account_transfer__form_control}>
          <label>Sender</label>
          <SelectedUser email={senderEmail} name={senderName} />
        </div>
        <div className={style.account_transfer__form_control}>
          <div className={style.receiver_section}>
            <label>Receiver</label>
            <button
              type="button"
              className={style.select_receiver__button}
              onClick={() => setIsReceiver(true)}
              disabled={isLoading || !inputValues.amount}
            >
              {" "}
              <FiPlusCircle />
              Add Recipient
            </button>
          </div>
          {inputValues.userEmail !== "" && inputValues.userName !== "" ? (
            <SelectedUser
              email={inputValues.userEmail}
              name={inputValues.userName}
            />
          ) : (
            <p>No Recipient Added Yet</p>
          )}
        </div>
        <div className={styles.form__buttons}>
          {/* <button type="button">Schedule for later</button> */}
          <button
            type="button"
            className={styles.active}
            onClick={() => setIsPin(true)}
            disabled={!inputValues.userId}
          >
            Send Money
          </button>{" "}
        </div>
      </form>

      <div className={styles.wallet__contacts}>
        <JebaModal
          title="Select Receiver"
          show={isReceiver}
          onHide={() => setIsReceiver(false)}
        >
          <div style={{ width: "100%" }}>
            <h5>Receiver</h5>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#0866be" : "#0866be",
                  borderRadius: "10px",
                }),
              }}
              className={styles.jeba_select__container}
              classNamePrefix="jeba_select"
              name="userId"
              options={usersData}
              onChange={selectInputChangeHandler}
            />
          </div>
        </JebaModal>
      </div>

      <JebaModal
        title="Enter Wallet Pin"
        show={isPin}
        onHide={() => setIsPin(false)}
      >
        <form onSubmit={onSubmitHandler}>
          <div>
            <h5>Enter Pin</h5>
            <input
              type="password"
              placeholder="Enter Pin here"
              name="walletPin"
              id="walletPin"
              onChange={inputChangeHandler}
            />
          </div>
          <div className={styles.form__buttons}>
            <button
              type="button"
              onClick={() => {
                setIsPin(false);
                setInputValues({
                  userId: "",
                  userName: "",
                  amount: "",
                  walletPin: "",
                  selectedDate: "",
                });
              }}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={styles.active}
              disabled={isLoading || !inputValues.walletPin}
            >
              Confirm Pin
            </button>
          </div>
        </form>
      </JebaModal>
    </div>
  );
}

export default AccountTransfers;
