import React from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import styles from "./ProductCard.module.css";
import Image from "../../../assets/images/51-TFGHQE2L._AC_SL1000_.jpg";
import { useAddToCartMutation } from "../../../store/api/requests/POST/addToCartApiSlice";
import ProductCounter from "../../Common/ProductCounter/ProductCounter";

function ProductCard({
  description,
  discount,
  name,
  price,
  id,
  isAdded,
  qtyInCart,
}) {
  const { categoryName, Id } = useParams();

  const [addToCart, { isLoading }] = useAddToCartMutation();

  const addToCartHandler = async () => {
    try {
      const productData = {
        groupType: categoryName,
        id: Id,
        product_id: id,
        quantity: 1,
      };
      await addToCart(productData).unwrap();
      toast.success("Product Added Successfully");
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <div className={styles.product_card}>
      <div className={styles.product_image}>
        {/* <div className={styles.overlay} /> */}
        <img src={Image} alt="Product Name" />
        <div
          className={`${styles.product_discount__section} ${styles.no_flicker}`}
        >
          <span className={styles.product_discount}>-{discount}%</span>
          {/* <span className={styles.product_text}>Discount</span> */}
        </div>
      </div>
      <div className={styles.product_title}>
        <h3>
          <Link to={id}>{name}</Link>
        </h3>
      </div>
      <div className={styles.product_description}>
        <p>{description}</p>
      </div>
      <div className={styles.product_card__bottom}>
        {isAdded ? (
          <ProductCounter initialValue={qtyInCart} productId={id} />
        ) : (
          <button
            type="button"
            onClick={addToCartHandler}
            disabled={isLoading}
            className={styles.add_to_cart}
          >
            Add to cart
          </button>
        )}

        <h4 className={styles.product_price}>UGX{price}</h4>
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  qtyInCart: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isAdded: PropTypes.bool.isRequired,
};

export default ProductCard;
