import { apiSlice } from "../../apiSlice";

export const sendPasswordResetCodeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendPasswordResetCode: builder.mutation({
      query: (credentials) => ({
        url: "/forgot_password",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useSendPasswordResetCodeMutation } =
  sendPasswordResetCodeApiSlice;
