/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import styles from "./LoginForm.module.css";
import { useFundAccountMutation } from "../../store/api/requests/POST/fundAccountApiSlice";

function DepositForm({ close }) {
  const [fundAccount, { isLoading }] = useFundAccountMutation();
  const [inputValues, setInputValues] = useState({
    amount: "",
    method: "",
    phoneNumber: "",
    cardNumber: "",
    cardHolderName: "",
    cardExpiryDate: "",
    cardCvv: "",
    walletPin: "",
  });

  const [paymentMethod, setPaymentMethod] = useState({
    mobileMoney: false,
    visa: false,
  });

  useEffect(() => {
    if (inputValues.method === "Mobile Money") {
      setPaymentMethod({ visa: false });
      setPaymentMethod({ mobileMoney: true });
    }
    if (inputValues.method === "VISA") {
      setPaymentMethod({ mobileMoney: false });
      setPaymentMethod({ visa: true });
    }
  }, [inputValues.method]);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const data = () => {
        if (inputValues.method === "Mobile Money") {
          return {
            fund_amount: +inputValues.amount,
            fund_type: inputValues.method,
            momo_number: inputValues.phoneNumber,
            wallet_pin: inputValues.walletPin,
          };
        }
        return {
          fund_amount: +inputValues.amount,
          fund_type: "Visa Card",
          card_details: {
            card_number: inputValues.cardNumber,
            card_holder_name: inputValues.cardHolderName,
            card_expiry_date: inputValues.cardExpiryDate,
            card_cvv: inputValues.cardCvv,
            wallet_pin: inputValues.walletPin,
          },
        };
      };

      console.log(data());
      const response = await fundAccount(data()).unwrap();
      if (response?.status === 200) {
        close();
        toast.success(response?.message);
      } else if (response?.status === 400) {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <Row>
          <Col>
            <div className={styles.form__control}>
              <label htmlFor="amount">Amount</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoName}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="method">Payment Method</label>
              <div className={styles.form__input}>
                <select name="method" id="method" onChange={inputChangeHandler}>
                  <option>Select method</option>
                  <option>VISA</option>
                  <option>Mobile Money</option>
                </select>
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            {paymentMethod.mobileMoney && (
              <div className={styles.form__control}>
                <label htmlFor="date">Phone Number</label>
                <div className={styles.form__input}>
                  <input
                    type="number"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    onChange={inputChangeHandler}
                    //   value={inputValues.saccoRegistrationNumber}
                    //   className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {/* {errors.emailError && errors.emailError} */}
                </span>
              </div>
            )}

            {paymentMethod.visa && (
              <>
                <div className={styles.form__control}>
                  <label htmlFor="cardNumber">Card Number</label>
                  <div className={styles.form__input}>
                    <input
                      type="number"
                      id="cardNumber"
                      name="cardNumber"
                      placeholder="Enter Card Number"
                      onChange={inputChangeHandler}
                      pattern="[0-9]{4}-[0-9]{2}"
                      value={inputValues.cardExpiryDate}
                    />
                  </div>
                  <span className={styles.input__error__container}>
                    {/* {errors.emailError && errors.emailError} */}
                  </span>
                </div>
                <Row>
                  <Col>
                    <div className={styles.form__control}>
                      <label htmlFor="cardExpiryDate">Card Expiry Date</label>
                      <div className={styles.form__input}>
                        <input
                          type="month"
                          id="cardExpiryDate"
                          name="cardExpiryDate"
                          placeholder="Enter Card Holder Name"
                          onChange={inputChangeHandler}
                          style={{ padding: "8px" }}
                        />
                      </div>
                      <span className={styles.input__error__container}>
                        {/* {errors.emailError && errors.emailError} */}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className={styles.form__control}>
                      <label htmlFor="cardHolderName">CVV</label>
                      <div className={styles.form__input}>
                        <input
                          type="text"
                          id="cardCvv"
                          name="cardCvv"
                          placeholder="Enter Card CVV"
                          onChange={inputChangeHandler}
                          style={{ padding: "8px" }}
                        />
                      </div>
                      <span className={styles.input__error__container}>
                        {/* {errors.emailError && errors.emailError} */}
                      </span>
                    </div>
                  </Col>
                </Row>
                <div className={styles.form__control}>
                  <label htmlFor="cardHolderName">Card Holder Name</label>
                  <div className={styles.form__input}>
                    <input
                      type="text"
                      id="cardHolderName"
                      name="cardHolderName"
                      placeholder="Enter Card Holder Name"
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <span className={styles.input__error__container}>
                    {/* {errors.emailError && errors.emailError} */}
                  </span>
                </div>
              </>
            )}
            <div className={styles.form__login}>
              <button type="submit" disabled={isLoading}>
                {isLoading ? <LoadingSpinner /> : "Deposit Money"}
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default DepositForm;
