import { apiSlice } from "../../apiSlice";

export const getCreditScoreSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCreditScore: builder.query({
      query: () => "/build_credit/credit_scores",
    }),
  }),
});

export const { useGetCreditScoreQuery } = getCreditScoreSlice;
