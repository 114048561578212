import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { GiPayMoney } from "react-icons/gi";
import { FaArrowsRotate, FaCircleInfo } from "react-icons/fa6";

import { IoPeople } from "react-icons/io5";

import { BiMoneyWithdraw } from "react-icons/bi";

import styles from "./GroupSIngle.module.css";
import GroupSingleButton from "../../../components/Group/GroupSingleButton/GroupSingleButton";

import { resetRole, setRole } from "../../../store/slice/memberRoleSlice";
import { useGetGroupBalanceQuery } from "../../../store/api/requests/GET/getGroupBalanceApiSlice";
import LoadingEllipse from "../../../components/ui/Loaders/LoadingEllipse";

import LoadingText from "../../../components/ui/Loaders/LoadingText";

import DrawIcon from "../../../assets/icons/DrawIcon/DrawIcon";
import { useGetCashRoundQuery } from "../../../store/api/requests/GET/getCashRoundApiSlice";
import PickDrawa from "./CashRound/PickDraw/PickDrawa";

import JebaModal from "../../../components/ui/Modal/Modal";
import SwitchButton from "../../../components/SwitchButton";

function GroupSingleCashRound() {
  const dispatch = useDispatch();

  const { categoryName, Id } = useParams();
  const urlParameters = {
    group: categoryName,
    groupId: Id,
  };

  const {
    data: groupData,
    isFetching: dataIsFetching,
    isSuccess,
  } = useGetCashRoundQuery(urlParameters);

  const { data: walletBalance, isFetching } = useGetGroupBalanceQuery({
    groupType: categoryName,
    id: Id,
  });

  const [isDrawModalOpen, setIsDrawModalOpen] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setIsDrawModalOpen(groupData?.cashround?.draw_status);
    } else {
      setIsDrawModalOpen(true);
    }
    // setIsDrawModalOpen(true);
    dispatch(resetRole());
    dispatch(setRole(groupData?.cashround?.group_role));
  }, [groupData, isSuccess]);

  // eslint-disable-next-line no-unsafe-optional-chaining
  const thousandSeparator = (amount) => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const groupWalletBalanceContent = (total) => {
    if (isFetching) {
      return <LoadingEllipse />;
    }

    return `${walletBalance?.wallet.currency || "UGX"} ${
      thousandSeparator(total) || 0
    }`;
  };

  return (
    <div className={styles.group__single__wrapper}>
      <JebaModal closeButton show={!isDrawModalOpen} title="Pick A Draw">
        <PickDrawa close={() => setIsDrawModalOpen(true)} />
      </JebaModal>
      <div className={styles.group_single__head}>
        <div className={styles.group_single__image}>
          <div
            alt="group name"
            className={styles.group_single__image__profile}
          />
        </div>
        <div className={styles.group_single__desc_container}>
          <div className={styles.group_single__details}>
            <div className={styles.group_single__name}>
              {" "}
              <h2 className={styles.group__single__heading}>
                {dataIsFetching ? (
                  <LoadingText css={{ marginLeft: "40px" }} />
                ) : (
                  groupData?.cashround?.name || "Group"
                )}
              </h2>
            </div>
            <div className={styles.group_single__description}>
              {" "}
              <p className={styles.group_description}>
                {dataIsFetching ? (
                  <LoadingText css={{ marginLeft: "40px" }} />
                ) : (
                  groupData?.cashround?.description || "Description"
                )}
              </p>
            </div>
            <div className={styles.group_single__financial__details}>
              <div
                className={styles.group__single__header}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <span>Account Balance: </span>
                  <span className={styles.group__single__amount}>
                    {groupWalletBalanceContent(
                      groupData?.cashround?.account_balance,
                    )}
                  </span>
                </div>

                <div>
                  <span>My Contribution: </span>
                  <span className={styles.group__single__amount}>
                    {groupWalletBalanceContent(
                      groupData?.cashround?.my_contribution,
                    )}
                  </span>
                </div>
                <div>
                  <span>Members Contribution: </span>
                  <span className={styles.group__single__amount}>
                    {" "}
                    {groupWalletBalanceContent(
                      groupData?.cashround?.member_contribution,
                    )}
                  </span>
                </div>
                <div>
                  <span>Active Round: </span>
                  <span className={styles.group__single__amount}>
                    {groupData?.cashround?.active_round}
                  </span>
                </div>
                <div>
                  <span>My Round: </span>
                  <span
                    className={styles.group__single__amount}
                    style={{ borderStyle: "none none none none" }}
                  >
                    {groupData?.cashround?.my_round}
                  </span>
                </div>

                <div>
                  <span>Members: </span>
                  <span
                    className={styles.group__single__amount}
                    style={{ borderStyle: "none none none none" }}
                  >
                    {groupData?.cashround?.no_members}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <SwitchButton status={groupData?.cashround?.status === "Active"} />
          </div>
        </div>
      </div>

      <div className={styles.group_single}>
        <div className={styles.group_single_info}>
          <div className={styles.group__single__buttons}>
            <div
              className={`${styles.group__single__nav__buttons} ${styles.group__single__nav__buttons_cash}`}
            >
              <GroupSingleButton
                text="Rounds"
                link="rounds"
                icon={<FaArrowsRotate />}
              />
              <GroupSingleButton
                text="Contribution"
                link="contribution"
                icon={<GiPayMoney />}
              />
              <GroupSingleButton
                text="Draws"
                link="draws"
                icon={<DrawIcon />}
              />
              <GroupSingleButton
                text="Policy"
                link="policy"
                icon={<FaCircleInfo />}
              />

              <GroupSingleButton
                text="Members"
                link="members"
                icon={<IoPeople />}
              />
              <GroupSingleButton
                text="Withdraw"
                link="withdraw"
                icon={<BiMoneyWithdraw />}
              />
            </div>
          </div>
          <div className={styles.group__single__members}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupSingleCashRound;
