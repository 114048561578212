/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import styles from "./LoginForm.module.css";

import { useApproveLoanMutation } from "../../store/api/requests/POST/approveLoanApiSlice";
import { useDeclineLoanMutation } from "../../store/api/requests/POST/declineLoanApplicationApiSlice";

function LoanApproval({ loanId, close }) {
  const { categoryName, Id } = useParams();
  const [approveLoan, { isLoading }] = useApproveLoanMutation();
  const [declineLoan, { isLoading: declineIsLoading }] =
    useDeclineLoanMutation();

  const [inputValues, setInputValues] = useState({
    adminComment: "",
  });

  const onAcceptHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        loan_application_id: loanId,
        comment: inputValues.adminComment,
      };
      await approveLoan(data).unwrap();
      close();
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const onDeclineHandler = async (event) => {
    event.preventDefault();
    try {
      const data = {
        groupType: categoryName,
        id: Id,
        loan_application_id: loanId,
        comment: inputValues.adminComment,
      };
      await declineLoan(data).unwrap();
    } catch (error) {
      toast.error(error);
    }
  };

  const inputValueHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form}>
        <div className={styles.form__control}>
          <label htmlFor="email">Add Comment</label>
          <div className={styles.form__input}>
            {/* <div className={styles.form__input__icon}>
              <GoKey />
            </div> */}
            <textarea
              type="number"
              id="adminComment"
              name="adminComment"
              placeholder="Enter Comment"
              onChange={inputValueHandler}
              //   className={error ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {/* {error && error} */}
          </span>
        </div>

        <div className="d-flex justify-content-between">
          <button
            type="button"
            onClick={onDeclineHandler}
            disabled={declineIsLoading}
            className=" btn btn-secondary rounded-pill fs-4 h-base px-4"
          >
            {declineIsLoading ? <Spinner /> : "Decline"}
          </button>
          <button
            type="button"
            onClick={onAcceptHandler}
            disabled={isLoading}
            className=" btn btn-success rounded-pill fs-4 h-base px-4"
          >
            {isLoading ? <Spinner /> : "Approve"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default LoanApproval;
