import React from "react";
import PropTypes from "prop-types";

import styles from "./Card.module.css";
import SparkLineChart from "../Charts/SparkLineChart/SparkLineChart";

function Card({ title, total }) {
  return (
    <div className={styles.card__wrapper}>
      <SparkLineChart title={title} total={total} />
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

export default Card;
