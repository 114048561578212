/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import styles from "./CashRound.module.css";

function CashRoundCard({ icon, title, description, link }) {
  return (
    <div className={styles.cash_round_card}>
      <div className={styles.cash_round_card_header}>
        <div>
          <Link
            to={`${link}/cr`}
            className={styles.cash_round_card_header_link}
          >
            {icon}
          </Link>
        </div>
        <div>Green</div>
      </div>
      <div className={styles.cash_round_card_footer}>
        <div>
          <Link
            to={`${link}/cr`}
            className={styles.cash_round_card_header_link}
          >
            {title}
          </Link>
        </div>
        <div>{description}</div>
      </div>
    </div>
  );
}

export default CashRoundCard;
