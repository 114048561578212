import React from "react";
import PropTypes from "prop-types";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaArrowUp } from "react-icons/fa";

function BuildCreditSummaryCard({ item, itemValue, percentage = 60 }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "25px",
        width: "250px",
        height: "100px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <h5>{item}</h5>
        <h6 className="display-6 fw-bolder">{itemValue}</h6>
        <div>
          <span>
            <FaArrowUp />
            15%
          </span>
          <span>+1% Last Week</span>
        </div>
      </div>
      <div style={{ width: "100px", height: "100px" }}>
        <CircularProgressbar value={percentage} text={`${percentage}%`} />
      </div>
    </div>
  );
}
BuildCreditSummaryCard.propTypes = {
  item: PropTypes.string.isRequired,
  itemValue: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
};
export default BuildCreditSummaryCard;
