/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import styles from "./LoginForm.module.css";
import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

import { useCreateCashRoundMutation } from "../../store/api/requests/POST/createCashRoundApiSlice";
// import { convertFIleToBase64 } from "../../helperFunctions/helpersFunctions";

function AddCashRound({ close }) {
  const [createCashRound, { isLoading }] = useCreateCashRoundMutation();
  const [errors] = useState({});

  const [inputValues, setInputValues] = useState({
    name: "",
    members: "",
    cycle: "",
    amount: "",
    description: "",
    phone: "",
    email: "",
    location: "",
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };
  //   const fileHandler = async (event) => {
  //     const file = event?.target?.files[0];
  //     const base64File = await convertFIleToBase64(file);
  //     const base64String = base64File?.split(",")[1];
  //     setInputValues({ ...inputValues, saccoRegCertificate: base64String });
  //   };
  const onSubmitHandler = async (event) => {
    event.preventDefault();

    const Data = {
      name: inputValues.name,
      no_members: +inputValues.members,
      contribution_cycle: inputValues.cycle,
      contribution_amount: +inputValues.amount,
      description: inputValues.description,
      tel_no: inputValues.phone,
      email: inputValues.email,
      location: inputValues.location,
    };

    try {
      const response = await createCashRound(Data).unwrap();
      if (response?.status === 201) {
        close();
      }

      toast.success("created successfully");
      window.scrollTo(0, 0);
      setInputValues({
        name: "",
        members: "",
        cycle: "",
        amount: "",
        description: "",
        phone: "",
        email: "",
        location: "",
      });
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  const isFormFilled =
    !inputValues.name ||
    !inputValues.members ||
    !inputValues.cycle ||
    !inputValues.amount ||
    !inputValues.description ||
    !inputValues.phone ||
    !inputValues.email ||
    !inputValues.location;

  return (
    <>
      {" "}
      <div className={styles.form__wrapper}>
        <form className={styles.form}>
          <Row sm={1} xs={1} lg={2}>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="name">Name</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    onChange={inputChangeHandler}
                    value={inputValues.name}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="phone">Phone Number</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Enter phone number"
                    onChange={inputChangeHandler}
                    value={inputValues.saccoPhoneNumber}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="email">Email</label>
                <div className={styles.form__input}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter  email"
                    onChange={inputChangeHandler}
                    value={inputValues.email}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>

              <div className={styles.form__control}>
                <label htmlFor="cycle">Cycle</label>
                <div className={styles.form__input}>
                  <select
                    type="text"
                    id="cycle"
                    name="cycle"
                    placeholder="Enter cycle"
                    onChange={inputChangeHandler}
                    value={inputValues.cycle}
                    className={errors.emailError ? styles.input__error : ""}
                  >
                    <option value="">Select Cycle</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="amount">Contribution Amount</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Enter amount"
                    onChange={inputChangeHandler}
                    value={inputValues.amount}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
            </Col>
            <Col>
              <div className={styles.form__control}>
                <label htmlFor="members">Number of Members</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="members"
                    name="members"
                    placeholder="Enter number of members"
                    onChange={inputChangeHandler}
                    value={inputValues.members}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="description">Description</label>
                <div className={styles.form__input}>
                  <textarea
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Enter description"
                    onChange={inputChangeHandler}
                    value={inputValues.description}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
              <div className={styles.form__control}>
                <label htmlFor="location">Location</label>
                <div className={styles.form__input}>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Enter location"
                    onChange={inputChangeHandler}
                    value={inputValues.location}
                    className={errors.emailError ? styles.input__error : ""}
                  />
                </div>
                <span className={styles.input__error__container}>
                  {errors.emailError && errors.emailError}
                </span>
              </div>
            </Col>
          </Row>
          <div className={styles.form__login}>
            <button
              type="button"
              onClick={onSubmitHandler}
              disabled={isFormFilled || isLoading}
            >
              {isLoading ? <LoadingSpinner /> : "Add Cash Round"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddCashRound;
