/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { GoKey } from "react-icons/go";
import styles from "./LoginForm.module.css";

import LoadingSpinner from "../ui/Loaders/LoadingSpinner";

import { selectUserDetails } from "../../store/slice/regSlice";
import { setCredentials } from "../../store/slice/authSlice";
import { useLoginWithOtpMutation } from "../../store/api/requests/POST/loginWithOtpApiSlice";

function LoginWithOtp() {
  const navigate = useNavigate();
  const [loginWithOtp, { isLoading }] = useLoginWithOtpMutation();
  const { email: useEmail } = useSelector(selectUserDetails);

  const [userEmail, setUserEmail] = useState({
    email: useEmail,
    code: "",
  });
  const [codeError, setCodeError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setCodeError("");
  }, [userEmail]);

  const codeChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserEmail({ ...userEmail, [name]: value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (userEmail.length < 1) {
      setCodeError("Please provide your Email");
    }

    try {
      const userData = {
        email: userEmail.email,
        otp_code: userEmail.code,
      };
      const response = await loginWithOtp(userData).unwrap();
      if (response.success) {
        dispatch(
          setCredentials({
            accessToken: response?.user_access_token,
            refreshToken: response?.user_refresh_token,
          }),
        );
        toast.success("Logged in successfully");
        navigate("/dashboard/user");
      }
    } catch (err) {
      toast.error(err.data?.message);
    }
  };

  return (
    <div className={styles.form__wrapper}>
      <h2>Verify Code</h2>
      <p>Enter a code sent to login</p>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.form__control}>
          <label htmlFor="code">OTP</label>
          <div className={styles.form__input}>
            <div className={styles.form__input__icon}>
              <GoKey />
            </div>
            <input
              type="type"
              id="code"
              name="code"
              placeholder="Enter OTP"
              onChange={codeChangeHandler}
              className={codeError ? styles.input__error : ""}
            />
          </div>
          <span className={styles.input__error__container}>
            {codeError && codeError}
          </span>
        </div>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading || !userEmail.code}>
            {isLoading ? <LoadingSpinner /> : "Login"}
          </button>
          <span
            role="button"
            tabIndex={0}
            onClick={() => navigate("/generate-login-otp")}
          >
            Resend OTP
          </span>
        </div>
      </form>
    </div>
  );
}

export default LoginWithOtp;
