import { apiSlice } from "../../apiSlice";

export const getDrawChangesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDrawChanges: builder.query({
      query: (urlParameters) =>
        `/${
          urlParameters.group === "club"
            ? "investment_club"
            : urlParameters.group
        }s/${urlParameters.groupId}/draw_changes`,
      providesTags: ["CashRoundInvitations"],
    }),
  }),
});

export const { useGetDrawChangesQuery } = getDrawChangesApiSlice;
