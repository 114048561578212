import React from "react";
import { Link, Outlet } from "react-router-dom";

import LoginSection from "../../components/LoginSection/LoginSection";
import styles from "./LoginPage.module.css";

function LoginPage() {
  return (
    <div className={styles.login__page}>
      <div style={{ width: "80%" }}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className={styles.login__page__header}>
            <div>
              <h1 className={styles.title}>
                <span className={styles.jba_logo_name}>Jeba Pesa</span>
              </h1>
              <div className={styles.jeba_tagline}>
                <span className={styles.slog1}>&quot;Empowering</span>
                <span className={styles.slog2}>
                  Digital Cooperative Groups&quot;
                </span>
              </div>
            </div>
          </div>
        </Link>
        <div className={styles.login}>
          <div className={styles.login__form__column}>
            <Outlet />
          </div>
          <div className={styles.banner}>
            <LoginSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
