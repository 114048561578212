/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FaBox, FaBoxOpen } from "react-icons/fa";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";
import styles from "./CashRound.module.css";
import { useGetCashRoundDrawsQuery } from "../../../../store/api/requests/GET/getCashRoundDrawsApiSlice";
import { FilterAddNew } from "../../../../components/Filters";
import JebaModal from "../../../../components/ui/Modal/Modal";
import { useGetCashRoundRoundsQuery } from "../../../../store/api/requests/GET/getCashRoundRoundsApiSlice";
import { useRequestNumberChangeMutation } from "../../../../store/api/requests/POST/requestNumberChangeApiSlice";
import NumberChangeCard from "../../../../components/NumberChangeCard/NumberChangeCard";
import { useGetDrawChangesQuery } from "../../../../store/api/requests/GET/getDrawChangesApiSlice";
import ApproveChangeNumber from "../../../../components/Forms/ApproveChangeNumber";
import { formatAmount } from "../../../../helperFunctions/helpersFunctions";

function Draws() {
  const { categoryName, Id } = useParams();
  const [requestNumberChange, { isLoading }] = useRequestNumberChangeMutation();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  const [request, setRequest] = useState({});
  const [errors, setErrors] = useState({});

  const {
    data,
    isFetching,
    refetch: refetchDraws,
  } = useGetCashRoundDrawsQuery({
    group: categoryName,
    groupId: Id,
  });
  const {
    data: roundsData,
    isFetching: fetchingRounds,
    refetch: refetchRounds,
  } = useGetCashRoundRoundsQuery({
    group: categoryName,
    groupId: Id,
  });

  const {
    data: changes,
    isFetching: fetchDrawChanges,
    refetch: refetchDrawsChanges,
  } = useGetDrawChangesQuery({
    group: categoryName,
    groupId: Id,
  });

  const draws = data?.cashround_draws;
  const rounds = roundsData?.cashround_member_rounds;

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!selected) {
      setErrors({ selected: "Select User" });
      return;
    }
    try {
      await requestNumberChange({
        groupType: categoryName,
        id: Id,
        recipient_id: selected,
      }).unwrap();
      setOpen(false);
      toast.success("Number Change Requested");
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  return (
    <>
      <FilterAddNew
        searchItem="Draw"
        buttonText="Change Round Number "
        open={() => setOpen(true)}
        refresh={() => {
          refetchDraws();
          refetchRounds();
          refetchDrawsChanges();
        }}
        onRefresh={isFetching || fetchingRounds || fetchDrawChanges}
      />
      <JebaModal
        show={open}
        onHide={() => setOpen(false)}
        title="Request Number "
      >
        <form onSubmit={onSubmitHandler}>
          <select onChange={(e) => setSelected(e.target.value)}>
            <option>Select Number</option>
            {rounds?.map((draw) => (
              <option key={draw.id} value={draw.group_member_id}>
                {draw.draw_no}-{draw.member_name}
              </option>
            ))}
          </select>
          {errors && (
            <span className="text-danger fs-5">{errors.selected}</span>
          )}
          <button
            type="submit"
            className="btn btn-lg w-100 btn-primary fs-4 my-3"
            disabled={isLoading}
          >
            {isLoading ? "Requesting..." : "Request Number"}
          </button>
        </form>
      </JebaModal>
      <JebaModal
        show={isRequest}
        title="Accept Number Change"
        onHide={() => setIsRequest(false)}
      >
        <ApproveChangeNumber
          change={request}
          closeModal={() => setIsRequest(false)}
        />
      </JebaModal>
      <div className="">
        <Row className="g-5" xs={1} sm={1} md={2} lg={2}>
          <Col>
            <div>
              <h4>Member Draws</h4>
              <Row md={4} lg={4} sm={6} className="g-4 m-0">
                {draws?.map((draw) => (
                  <Col key={draw.id}>
                    <button
                      type="button"
                      className={styles.random_number}
                      key={draw.id}
                    >
                      {draw?.selected_status ? (
                        <p className={styles.open_box}>
                          <span>{draw.draw_no}</span>
                          <FaBoxOpen className={styles.icon} />
                        </p>
                      ) : (
                        <FaBox className={styles.icon} />
                      )}
                    </button>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "12px",
            }}
          >
            <h4>Change Number Requests</h4>
            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              {changes?.cashround_member_draw_change_requests?.map((change) => (
                <div key={change.id}>
                  <NumberChangeCard
                    sender={change.sender_name}
                    receiver={change.receiver_name}
                    status={change?.received_status}
                    approval={change?.approval_status}
                    itemId={change.id}
                    unitCost={formatAmount(change.draw_change_cost)}
                    onClick={() => {
                      setIsRequest(true);
                      setRequest(change);
                    }}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Draws;
