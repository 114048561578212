import { apiSlice } from "../../apiSlice";

export const sendLoginOtpApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendLoginOtp: builder.mutation({
      query: (credentials) => ({
        url: "/users/generate_login_otp",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useSendLoginOtpMutation } = sendLoginOtpApiSlice;
