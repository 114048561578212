/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import { useSelector } from "react-redux";

import styles from "./Members.module.css";

import GroupCard from "../../../../components/Group/GroupCard/GroupCard";
import { FilterAddNew } from "../../../../components/Filters";
import InviteGroupMember from "../../../../components/Forms/InviteGroupMember";
import JebaModal from "../../../../components/ui/Modal/Modal";
import InvitationCard from "../../../../components/Dashboard/InvitationCard/InvitationCard";

import { useGetGroupMembersQuery } from "../../../../store/api/requests/GET/groupMembersApiSlice";
import { useGetGroupInvitationsQuery } from "../../../../store/api/requests/GET/getGroupInvitationsApiSlice";
import { contentHandler } from "../../../../helperFunctions/helpersFunctions";
import { selectRole } from "../../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../../constants/constants";

function InvitationsContent({ data }) {
  if (data?.length > 0) {
    return data?.map((invitation) => (
      <InvitationCard
        key={invitation.id}
        sender={invitation.requested_by_name}
        middleText="invited"
        receiver={invitation.invited_user_name}
        message={invitation.message}
        expDate={invitation.expiration_date}
        status={invitation.approval_status}
      />
    ));
  }
  return <p>No sent invitations yet</p>;
}

function Members() {
  const { categoryName, Id } = useParams();
  const urlParameters = {
    group: categoryName,
    groupId: Id,
  };

  const {
    data: groupMembers,
    isFetching: groupMembersIsFetching,
    refetch: refetchGroupMembers,
  } = useGetGroupMembersQuery(urlParameters);
  const {
    data: groupInvitations,
    isFetching,
    refetch: refetchInvitations,
  } = useGetGroupInvitationsQuery(urlParameters);

  const [isOpen, setIsOpen] = useState(false);

  const openHandler = () => setIsOpen(true);
  const closeHandler = () => setIsOpen(false);
  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const memberContent = groupMembers?.data.map((member) => (
    <GroupCard
      key={member?.id}
      title={member.user_name}
      description={`Joined on ${member.date_joined}`}
      icon={<CgProfile />}
    />
  ));
  return (
    <div>
      <FilterAddNew
        searchItem="Member"
        buttonText="Add Member"
        open={openHandler}
        hideButton={isAdmin}
        refresh={() => {
          refetchGroupMembers();
          refetchInvitations();
        }}
        onRefresh={groupMembersIsFetching || isFetching}
      />

      <JebaModal show={isOpen} onHide={closeHandler} title="Invite A Member">
        <InviteGroupMember close={closeHandler} />
      </JebaModal>

      <Row>
        <Col xl={7} md={7} sm={12} className="mb-2">
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
            className={styles.content_container}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                textTransform: "capitalize",
              }}
            >
              {urlParameters.group} Members
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50px",
                  backgroundColor: "#0866be",
                  fontSize: "12px",
                }}
              >
                {groupMembers?.data?.length || 0}
              </span>
            </h4>
            <div style={{ overflow: "auto" }}>
              {contentHandler(
                groupMembersIsFetching,
                groupMembers?.data?.length,
                "Members Loading...",
                "No Members",
                memberContent,
              )}
            </div>
          </div>
        </Col>
        <Col xl={5} md={5} sm={12} className="mb-2">
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
              }}
            >
              Sent Invitations
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50px",
                  backgroundColor: "#d11124",
                  fontSize: "12px",
                }}
              >
                {groupInvitations?.request_data?.length || 0}
              </span>
            </h4>
            <div style={{ height: "400px", overflow: "auto" }}>
              <InvitationsContent data={groupInvitations?.request_data} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Members;
