/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { FilterAddNew } from "../../../../components/Filters";
import ShareCard from "../../../../components/Group/ShareCard/ShareCard";
import JebaModal from "../../../../components/ui/Modal/Modal";
import AddShare from "../../../../components/Forms/AddShare";
import { useGetSharesQuery } from "../../../../store/api/requests/GET/getSharesApiSlice";
import { selectRole } from "../../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../../constants/constants";
import { useGetMembersSharesQuery } from "../../../../store/api/requests/GET/getMembersSharesApiSlice";
import InvitationCard from "../../../../components/Dashboard/InvitationCard/InvitationCard";
import TransferShares from "../../../../components/Forms/TransferShares";
import { useGetShareTransfersQuery } from "../../../../store/api/requests/GET/getShareTransfersApiSlice";
import { contentHandler } from "../../../../helperFunctions/helpersFunctions";

function Shares() {
  const { categoryName, Id } = useParams();
  const { data, isFetching } = useGetSharesQuery({
    groupType: categoryName,
    id: Id,
  });
  const shareData = data?.sacco_shares;

  const {
    data: membersSharesData,
    isFetching: membersSharesDataLoading,
    refetch: refetchMembersSharesData,
  } = useGetMembersSharesQuery({
    groupType: categoryName,
    id: Id,
  });
  const membersShares = membersSharesData?.sacco_member_shares;

  const {
    data: shareTransfers,
    isFetching: shareTransfersIsFetching,
    refetch: refetchShareTransfers,
  } = useGetShareTransfersQuery({
    groupType: categoryName,
    id: Id,
  });

  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const [open, setOpen] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);

  const content = shareTransfers?.shares?.map((transfer) => (
    <Col lg={12} md={12} sm={12} key={transfer.id}>
      <InvitationCard
        key={transfer.id}
        // sender={transfer?.from_member_name}
        // middleText="Transferred Share Of "
        // receiver={transfer.share_amount}
        message={`${transfer?.from_member_name} transferred ${
          transfer.share_amount
        } shares to ${isAdmin ? transfer?.to_member_name : "you"}`}
        approveButtonText="Approve"
        status={
          isAdmin ||
          transfer.transfer_status === "Completed" ||
          transfer.transfer_status === "Declined"
            ? transfer.transfer_status
            : false
        }
        isTransfer
        transferId={transfer.id}
      />
    </Col>
  ));

  return (
    <div>
      <FilterAddNew
        searchItem="Share"
        buttonText="New Share"
        open={() => setOpen(true)}
        hideButton={isAdmin}
        refresh={() => {
          refetchMembersSharesData();
          refetchShareTransfers();
        }}
        onRefresh={
          isFetching || membersSharesDataLoading || shareTransfersIsFetching
        }
      />
      <Row>
        <Col xl={5} md={5} sm={12} className="mb-2">
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
            // className={styles.content_container}
          >
            {" "}
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
                textTransform: "capitalize",
              }}
            >
              {" "}
              Available Shares
            </h4>
            <div
              style={{
                maxHeight: "520px",
                overflow: "auto",
              }}
            >
              <JebaModal
                title="New Share"
                show={open}
                onHide={() => setOpen(false)}
              >
                <AddShare close={() => setOpen(false)} />
              </JebaModal>
              <Row style={{ margin: 0, opacity: isFetching ? 0.5 : 1 }}>
                <Col lg={12} md={12}>
                  {Object.keys(shareData || {}).length >= 1 ? (
                    <ShareCard share={shareData} showForm />
                  ) : (
                    <p>No Shares</p>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <Row>
            <Col xl={12} md={12} sm={12} className="mb-2">
              <div
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  marginTop: "12px",
                }}
                // className={styles.content_container}
              >
                {" "}
                <h4
                  style={{
                    marginBottom: "18px",
                    paddingLeft: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    textTransform: "capitalize",
                  }}
                >
                  {" "}
                  Bought Shares
                </h4>
                <div
                  style={{
                    maxHeight: "520px",
                    overflow: "auto",
                  }}
                >
                  {membersShares?.length === 0 ? (
                    <p>No Bought Shares</p>
                  ) : isAdmin ? (
                    <Row
                      style={{
                        margin: 0,
                        opacity: membersSharesDataLoading ? 0.5 : 1,
                      }}
                    >
                      {membersShares?.length > 0 &&
                        membersShares?.map((membersShare) => (
                          <Col lg={12} md={12} key={membersShare.id}>
                            <p>Members share</p>
                            <ShareCard share={membersShare} />
                          </Col>
                        ))}
                    </Row>
                  ) : (
                    <Row
                      style={{
                        margin: 0,
                        opacity: membersSharesDataLoading ? 0.5 : 1,
                      }}
                    >
                      {membersShares?.length > 0 && (
                        <Col lg={12} md={12}>
                          <ShareCard share={membersShares[0]} />
                        </Col>
                      )}
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Share transfers
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  border: "none",
                  borderRadius: "50px",
                  backgroundColor: "#0866be",
                  fontSize: "10px",
                  textTransform: "capitalize",
                  padding: "8px",
                }}
                type="button"
                onClick={() => setOpenTransfer(true)}
              >
                Transfer Share
              </button>
            </h4>

            <div style={{ maxHeight: "500px", overflow: "auto" }}>
              {contentHandler(
                shareTransfersIsFetching,
                shareTransfers?.shares?.length,
                "Loading Share Transfers... ",
                "No Share Transfer",
                content,
              )}
            </div>
            <JebaModal
              title="Transfer Share"
              show={openTransfer}
              onHide={() => setOpenTransfer(false)}
            >
              <TransferShares close={() => setOpenTransfer(false)} />
            </JebaModal>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Shares;
