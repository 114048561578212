import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";

import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

import StatCard from "../../../../../components/Dashboard/StatCard/StatCard";
import MemberReportsCard from "../../../../../components/Group/MemberReportsCard/MemberReportsCard";
import styles from "./MemberSection.module.css";
import { useGetMemberReportsQuery } from "../../../../../store/api/requests/GET/getMemberReportsApiSlice";
import JebaModal from "../../../../../components/ui/Modal/Modal";
import { capitalizeHandler } from "../../../../../helperFunctions/helpersFunctions";

function MemberSection() {
  const { categoryName, Id } = useParams();
  const group = { groupType: categoryName, id: Id };
  const { data } = useGetMemberReportsQuery(group);
  const contributions = data?.members;

  const [open, setOpen] = useState(false);
  const [memberId, setMemberId] = useState("");

  const memberDetails = data?.members?.find(
    (member) => member.member_id === memberId,
  );

  const breakpoints = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  const slideRef = useRef();
  return (
    <Row style={{ marginTop: "25px" }}>
      <Col lg={9}>
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            backgroundColor: "#fff",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Members Data
            {contributions?.length > 0 && (
              <div className={styles.members_slide__buttons}>
                <button type="button" onClick={() => slideRef.current.goBack()}>
                  <FaAngleLeft />
                </button>
                <button type="button" onClick={() => slideRef.current.goNext()}>
                  <FaAngleRight />
                </button>
              </div>
            )}
          </h5>

          <div style={{ width: "926px", maxWidth: "100%" }}>
            {contributions?.length > 0 ? (
              <Slide
                easing="ease"
                arrows={false}
                duration={2000}
                ref={slideRef}
                defaultIndex={0}
                autoplay={!(contributions?.length === 1) || !open}
                responsive={breakpoints}
              >
                {contributions?.map((contribution) => (
                  <MemberReportsCard
                    key={contribution.id}
                    contribution={contribution}
                    onOpen={() => setOpen(true)}
                    setMemberId={setMemberId}
                  />
                ))}
              </Slide>
            ) : (
              <p>No Members Data Yet!</p>
            )}
          </div>
          <JebaModal
            title="Member Details"
            show={open}
            onHide={() => {
              setMemberId("");
              setOpen(false);
            }}
          >
            <table>
              <tbody>
                {open &&
                  Object.keys(memberDetails).map((key) => (
                    <tr key={Math.random()}>
                      <td>
                        <p>{capitalizeHandler(key)}</p>
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        <p>
                          <strong>{memberDetails[key]}</strong>
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </JebaModal>
        </div>
      </Col>
      <Col lg={3}>
        <Row>
          <Col lg={12}>
            <div
              style={{
                height: "100%",
                borderRadius: "10px",
                backgroundColor: "#fff",
                paddingLeft: "18px",
                paddingRight: "18px",
                paddingTop: "15px",
                paddingBottom: "18px",
              }}
            >
              <h5
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Members Trends
              </h5>
              <StatCard item="Active" value={data?.active_members} />
              <StatCard item="Inactive" value={data?.no_inactive_members} />
              <StatCard item="Administration" value={data?.no_administrators} />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default MemberSection;
