import React from "react";
import { useParams } from "react-router-dom";
import { FilterAddNew } from "../../../../../../components/Filters";
import Table from "../../../../../../components/Dashboard/Table/Table";
import { useGetOrderProductsQuery } from "../../../../../../store/api/requests/GET/getProductOrdersApiSlice";
import { ORDER_COLUMNS } from "../../../../../../components/Dashboard/Table/tableData/columns";

function Orders() {
  const { categoryName, Id } = useParams();
  const { data, isFetching } = useGetOrderProductsQuery({
    groupType: categoryName,
    id: Id,
  });
  return (
    <div>
      {" "}
      <FilterAddNew searchItem="orders" hideButton={false} />
      <Table
        data={data?.orders || []}
        columns={ORDER_COLUMNS}
        isLoading={isFetching}
        noDataText="No Orders yet!"
      />
    </div>
  );
}

export default Orders;
