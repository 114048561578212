/* eslint-disable import/prefer-default-export */
import { apiSlice } from "../../apiSlice";

export const logOutApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    logOut: builder.mutation({
      query: (credentials) => ({
        url: "/auth/logout",
        method: "DELETE",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useLogOutMutation } = logOutApiSlice;
