import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

function AreaChart({ title, data, months }) {
  const series = data;
  // const series = [
  //   {
  //     name: "Shares",
  //     data: [
  //       10000, 12000, 15000, 8000, 11000, 9000, 13000, 10000, 12000, 14000,
  //       16000, 18000,
  //     ],
  //   },
  //   {
  //     name: "Incomes",
  //     data: [
  //       50000, 55000, 60000, 52000, 58000, 62000, 55000, 58000, 60000, 63000,
  //       67000, 70000,
  //     ],
  //   },
  //   {
  //     name: "Loans",
  //     data: [
  //       2000, 2500, 3000, 2000, 1800, 2200, 2500, 2300, 2000, 1800, 2100, 1900,
  //     ],
  //   },
  //   {
  //     name: "Expenses",
  //     data: [
  //       3000, 3500, 4000, 3800, 4200, 4500, 4100, 3800, 4000, 4300, 4600, 4800,
  //     ],
  //   },
  //   {
  //     name: "Savings",
  //     data: [
  //       45000, 48000, 52000, 48000, 55000, 59000, 51000, 55000, 58000, 60000,
  //       64000, 67000,
  //     ],
  //   },
  //   {
  //     name: "Deposits",
  //     data: [
  //       45000, 48000, 52000, 48000, 55000, 59000, 51000, 55000, 58000, 60000,
  //       64000, 67000,
  //     ],
  //   },
  //   {
  //     name: "Withdraw",
  //     data: [
  //       45000, 48000, 52000, 48000, 55000, 59000, 51000, 55000, 58000, 60000,
  //       64000, 67000,
  //     ],
  //   },
  // ];

  const options = {
    chart: {
      id: "area-datetime",
      height: 250,
      width: "100%",
      type: "area",
    },
    title: {
      text: title,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Rubik",
        color: "#111111",
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: months,
      // categories: [
      //   "Jan",
      //   "Feb",
      //   "March",
      //   "Apr",
      //   "May",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Nov",
      //   "Dec",
      // ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  return (
    <div style={{ height: "100%" }}>
      {" "}
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="100%"
        width="100%"
      />
    </div>
  );
}

AreaChart.defaultProps = {
  title: "Title",
};

AreaChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.checkPropTypes()).isRequired,
  months: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AreaChart;
