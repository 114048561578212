import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import Table from "../../../../components/Dashboard/Table/Table";

import { FilterAddNew } from "../../../../components/Filters";
import { GROUP_TRANSACTION_COLUMNS } from "../../../../components/Dashboard/Table/tableData/columns";
import { useGetGroupTransactionsQuery } from "../../../../store/api/requests/GET/getGroupTransactionsApiSlice";
import { USER_ROLES } from "../../../../constants/constants";
import { selectRole } from "../../../../store/slice/memberRoleSlice";
import JebaModal from "../../../../components/ui/Modal/Modal";
import DepositToGroup from "../../../../components/Forms/DepositToGroup";

function GroupTransactions() {
  const { categoryName, Id } = useParams();
  const { data, isFetching, refetch } = useGetGroupTransactionsQuery({
    groupType: categoryName,
    id: Id,
  });
  const loans = data?.sacco_loan_transactions || [];

  const savings = data?.sacco_saving_transactions || [];

  const transData = [...loans, ...savings];

  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  const [search, setSearch] = useState("");

  const searchHandler = (event) => setSearch(event?.target?.value);

  const [open, setOpen] = useState(false);

  // React.useEffect(() => {
  //   const getData = setTimeout(() => {
  //     searchHandler();
  //   }, 2000);

  //   return () => clearTimeout(getData);
  // }, [search]);

  return (
    <>
      <FilterAddNew
        searchItem="Transaction"
        buttonText="Deposit To Sacco"
        onChange={searchHandler}
        hideButton={isAdmin}
        open={() => setOpen(true)}
        refresh={refetch}
        onRefresh={isFetching}
        searchTerm={search}
      />
      <JebaModal
        show={open}
        title="Transfer Money To Sacco Wallet"
        onHide={() => setOpen(false)}
      >
        <DepositToGroup closeModal={() => setOpen(false)} />
      </JebaModal>
      <Table
        data={transData}
        columns={GROUP_TRANSACTION_COLUMNS}
        search={search}
        isLoading={isFetching}
      />
      ;
    </>
  );
}

export default GroupTransactions;
