import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CiSearch } from "react-icons/ci";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import jwtDecode from "jwt-decode";
import { IoIosNotificationsOutline } from "react-icons/io";
import styles from "./Header.module.css";
import { selectCurrentToken } from "../../../store/slice/authSlice";
import { open } from "../../../store/slice/openMobileSidebarSlice";
import NotificationSidebar from "../../Notifications/NotificationSidebar";

function Header() {
  const user = useSelector(selectCurrentToken);
  const userName = jwtDecode(user)?.sub?.user_name?.split(" ")[2];

  const dispatch = useDispatch();

  const timeNow = new Date().getHours();
  const greeting = () => {
    if (timeNow >= 5 && timeNow < 12) {
      return "Good Morning";
    }
    if (timeNow >= 12 && timeNow < 17) {
      return "Good Afternoon";
    }
    return "Good evening";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={styles.dashboard__header}>
      <div className={styles.hamburger}>
        <button type="button" onClick={() => dispatch(open())}>
          <AiOutlineMenuUnfold />
        </button>
      </div>
      <div className={styles.greeting}>
        <h4>{`Welcome ${userName || ""}, ${greeting()}!`} </h4>
      </div>
      <div className={styles.search}>
        <CiSearch />
        <input placeholder="Search" />
      </div>

      <div className="d-flex align-items-center">
        <div className="mx-4">
          <button
            type="button"
            className={styles.notification}
            onClick={handleShow}
          >
            <IoIosNotificationsOutline />
            <div className={styles.count}>
              <span>0</span>
            </div>
          </button>
          <NotificationSidebar show={show} handleClose={handleClose} />
        </div>
        <div className={styles.avatar} />
      </div>
    </div>
  );
}

export default Header;
