/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import "./style.css";
import { useStartCashRoundMutation } from "../../store/api/requests/PATCH/startCashRoundApiSlice";

function SwitchButton({ status }) {
  const { categoryName, Id } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [startCashRound] = useStartCashRoundMutation();
  const handleChange = async () => {
    try {
      const data = {
        status: "start",
        id: Id,
        groupType: categoryName,
      };

      await startCashRound(data).unwrap();
      setIsChecked(!isChecked);
    } catch (error) {
      toast.error(error?.data?.message || "An error occurred");
    }
  };

  return (
    <div className="container mt-5 d-flex align-items-center gap-4">
      <h5 className="text">
        {status ? "Cash Round Started" : "Start Cash Round"}
      </h5>
      <label className="switch" disabled={status}>
        <input
          type="checkbox"
          checked={status}
          onChange={handleChange}
          disabled={status}
        />
        <span className="slider" />
      </label>
    </div>
  );
}

export default SwitchButton;
