/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const memberRoleSlice = createSlice({
  name: "role",
  initialState: { memberRole: "" },
  reducers: {
    setRole: (state, { payload }) => {
      state.memberRole = payload;
    },
    resetRole: (state) => {
      state.memberRole = "";
    },
  },
});
export const { setRole, resetRole } = memberRoleSlice.actions;
export default memberRoleSlice.reducer;

export const selectRole = (state) => state.role.memberRole;
