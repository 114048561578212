import { apiSlice } from "../../apiSlice";

export const checkoutSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkout: builder.mutation({
      query: (credentials) => ({
        url: `/${credentials.groupType}s/${credentials?.id}/marketplace/orders`,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Cart", "Orders"],
    }),
  }),
});

export const { useCheckoutMutation } = checkoutSlice;
