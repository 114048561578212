import React from "react";
import PropTypes from "prop-types";
import styles from "./OrderCard.module.css";

function OrderCard({ title, value }) {
  return (
    <div className={styles.order_card}>
      <div>
        <h4>{title}</h4>
        <p>{value}</p>
      </div>
    </div>
  );
}
OrderCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
export default OrderCard;
