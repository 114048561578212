import React from "react";
import PropTypes from "prop-types";

import Offcanvas from "react-bootstrap/Offcanvas";

function NotificationSidebar({ show, handleClose }) {
  return (
    <>
      {" "}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notifications Center</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>No notifications yet</Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
NotificationSidebar.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default NotificationSidebar;
