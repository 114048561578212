import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { FilterAddNew } from "../../../../components/Filters";
import { selectRole } from "../../../../store/slice/memberRoleSlice";
import { USER_ROLES } from "../../../../constants/constants";
import JebaModal from "../../../../components/ui/Modal/Modal";
import PostNews from "../../../../components/Forms/PostNews";
import NewsCard from "../../../../components/Group/NewsCard/NewsCard";
import { useGetNewsQuery } from "../../../../store/api/requests/GET/getNewsApiSlice";

function News() {
  const { categoryName, Id } = useParams();
  const { data, isFetching, refetch } = useGetNewsQuery({
    groupType: categoryName,
    id: Id,
  });
  const news = data?.news;

  const [open, setOpen] = useState(false);

  const user = useSelector(selectRole);
  const isAdmin = user === USER_ROLES.S_ADMIN;

  return (
    <div>
      {" "}
      <FilterAddNew
        searchItem="News"
        buttonText="Post News"
        open={() => setOpen(true)}
        hideButton={isAdmin}
        refresh={refetch}
        onRefresh={isFetching}
      />
      <JebaModal title="Post News" show={open} onHide={() => setOpen(false)}>
        <PostNews close={() => setOpen(false)} />
      </JebaModal>
      <Row>
        <Col xl={12} md={12} sm={12} className="mb-2">
          <div
            style={{
              borderRadius: "10px",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
            // className={styles.content_container}
          >
            {" "}
            <h4
              style={{
                marginBottom: "18px",
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "space-between",
                textTransform: "capitalize",
                color: "#367BE3",
              }}
            >
              {" "}
              News Updates
            </h4>
            <div
              style={{
                maxHeight: "520px",
                overflow: "auto",
              }}
            >
              {" "}
              <Row style={{ margin: 0 }}>
                {isFetching ? (
                  <p>Loading News...</p>
                ) : (
                  news?.map((item) => (
                    <Col lg={6} md={6} key={item.id}>
                      <NewsCard
                        title={item.news_title}
                        content={item.news_content}
                        author={item.posted_by_name}
                        date={item.news_date}
                      />
                    </Col>
                  ))
                )}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default News;
