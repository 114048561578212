import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BuildCreditCard from "../../components/Dashboard/BuildCreditCard/BuildCreditCard";
import JebaModal from "../../components/ui/Modal/Modal";
import Image1 from "../../assets/images/free-credit-card-1795347-1522706.png";
import Image2 from "../../assets/images/undraw_online_payments_re_y8f2.svg";
import Image3 from "../../assets/images/credit-card.svg";
import { useGetBuildCreditPlansQuery } from "../../store/api/requests/GET/getBuildCreditPlansApiSlice";
import styles from "./BuildCredit.module.css";

function BuildCredit() {
  const [step, setStep] = useState(1);
  const { data, isFetching } = useGetBuildCreditPlansQuery();
  const [open, setOpen] = useState(false);
  // homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

  const sortedProducts = data?.products
    ?.slice()
    .sort((a, b) => a.subscription_period - b.subscription_period);

  useEffect(() => {
    if (isFetching) {
      setOpen(false);
    } else {
      const statusCheck = data?.products?.some(
        (product) => product.user_subscribed,
      );
      setOpen(!statusCheck);
    }
  }, [isFetching]);

  const handleNext = () => {
    setStep(step + 1);
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };
  return (
    <div className={styles.build_credit}>
      <div className="d-flex align-items-center ">
        <Row className="g-3 align-items-end ">
          {isFetching ? (
            <p>Loading Events...</p>
          ) : (
            sortedProducts?.map((product) => (
              <Col lg={4} md={6} key={product.id}>
                <BuildCreditCard
                  name={product.product_name}
                  days={product.subscription_period}
                  description={product.product_type}
                  fee={product.subscription_fee}
                  Id={product.id}
                  status={product.user_subscribed}
                  pastStatus={product.past_subscription_status}
                />
              </Col>
            ))
          )}
        </Row>
      </div>
      <JebaModal
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        // size="lg"
      >
        <div style={{ width: "100%" }}>
          {step === 1 && (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1>Build Your </h1>
                <h1>Credit Score </h1>
                <h1>With Ease!</h1>
              </div>
              <div>
                <img src={Image1} className="img-fluid" alt="First step" />
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1>Choose </h1>
                <h1>A Jeba </h1>
                <h1>Event</h1>
              </div>
              <div style={{ maxWidth: "260px" }}>
                <img
                  src={Image2}
                  style={{ maxWidth: "100%" }}
                  alt="First step"
                />
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1>Subscribe </h1>
                <h1> And Start</h1>
                <h1>Building</h1>
                <h1>Your Credit Score </h1>
              </div>
              <div style={{ maxWidth: "260px" }}>
                <img src={Image3} className="img-fluid" alt="First step" />
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between">
            {step > 1 && (
              <button
                type="button"
                //   variant="secondary"
                onClick={handlePrevious}
                className=" btn btn-secondary rounded-pill fs-4 h-base px-4"
              >
                Previous
              </button>
            )}
            {step < 3 ? (
              <button
                type="button"
                //   variant="primary"
                onClick={handleNext}
                className=" btn btn-primary rounded-pill fs-4 h-base px-4"
              >
                Next
              </button>
            ) : (
              <button
                //   variant="primary"
                type="button"
                className="btn btn-primary rounded-pill fs-4 h-base px-4"
                onClick={() => setOpen(false)}
              >
                Get Started
              </button>
            )}
          </div>
        </div>
      </JebaModal>
    </div>
  );
}

export default BuildCredit;
