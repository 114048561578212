/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import LoadingSpinner from "../ui/Loaders/LoadingSpinner";
import styles from "./LoginForm.module.css";
import { useGetRegisteredMembersQuery } from "../../store/api/requests/GET/registeredMembersApiSlice";
import { useTransferSharesMutation } from "../../store/api/requests/POST/transferSharesApiSlice";

function TransferShares({ close }) {
  const { categoryName, Id } = useParams();
  const { data } = useGetRegisteredMembersQuery();
  const [transferShares, { isLoading }] = useTransferSharesMutation();

  const [inputValues, setInputValues] = useState({
    receiverId: "",
    amount: "",
  });

  const usersData = data?.map((user) => {
    return {
      value: user.id,
      label: user.user_name,
    };
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setInputValues({ ...inputValues, [name]: value });
  };

  const selectInputChangeHandler = (event) => {
    setInputValues({ ...inputValues, receiverId: event.value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const transferData = {
        id: Id,
        groupType: categoryName,
        receiver_id: inputValues.receiverId,
        share_transfer_amount: inputValues.amount,
      };

      const response = await transferShares(transferData).unwrap();
      if (response?.status === 200) {
        close();
        toast.success("Share request sent");
      }
    } catch (error) {
      toast.error(error.data.message);
    }
  };
  return (
    <div className={styles.form__wrapper}>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <Row>
          <Col>
            <div className={styles.form__control}>
              <label htmlFor="receiverId">Receiver</label>
              <div className={styles.form__input}>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#0866be" : "#0866be",
                      borderRadius: "10px",
                    }),
                  }}
                  className={styles.jeba_select__container}
                  classNamePrefix="jeba_select"
                  name="receiverId"
                  options={usersData}
                  onChange={selectInputChangeHandler}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
            <div className={styles.form__control}>
              <label htmlFor="amount">Amount</label>
              <div className={styles.form__input}>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  onChange={inputChangeHandler}
                  //   value={inputValues.saccoPhoneNumber}
                  //   className={errors.emailError ? styles.input__error : ""}
                />
              </div>
              <span className={styles.input__error__container}>
                {/* {errors.emailError && errors.emailError} */}
              </span>
            </div>
          </Col>
        </Row>
        <div className={styles.form__login}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : "Transfer Share"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default TransferShares;
